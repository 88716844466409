export default {
    name: "页面说明组件",
    component: "kq-lowcode-component-page-explain",
    type: "默认分类",
    demo: {
        name: "页面说明组件",
        explain: "页面说明组件，该组件内容支持富文本，请不要设置太复杂的富文本样式，会影响到整个页面的排版的美观",
        readonly: true,
    },
    configList: [
        {
            zid: "async",
            name: "加载数据源",
            subname: "该数据对用组件所有数据源",
            formList: [
                {
                    zid: "name",
                    zname: "数据名称",
                    type: "text-text",
                    required: true,
                    value: "",
                },
                {
                    zid: "explain",
                    zname: "数据值",
                    type: "text-news",
                    required: true,
                    value: "",
                },
            ],
        },
    ],
};
