/**
 * @description 流程的相关信息
 * @date 2023-06-09
 * @author maxi
 */

import Vue from "vue";
import _ from "lodash";

export default {
    namespaced: true,
    state: {
        /** 当前流程的别名数据 */
        processMbAliasList: [],
    },
    mutations: {
        /** 当前页面名称 */
        set_process_mb_alias_list(state, aliasList) {
            state.processMbAliasList = aliasList;
        },
    },
    actions: {
        initAliasList(store, aliasList) {
            return new Promise((resolve, reject) => {
                const commit = store.commit;
                let infoList = aliasList;
                if (!infoList || !infoList.subs) {
                    infoList = Vue.prototype.$japi.mb
                        .getMbByCode("tgjd")
                        .then((infoList) => {
                            if (!infoList || !infoList.subs) {
                                resolve();
                            } else {
                                const subs = _.get(infoList, "subs");
                                if (!kq.Tools.isEmpty(subs)) {
                                    commit("set_process_mb_alias_list", subs);
                                }
                                resolve(subs);
                            }
                        });
                }
            });
        },
        // async initAliasList(store, aliasList) {
        //     const commit = store.commit;
        //     let infoList = aliasList;
        //     if (!infoList || !infoList.subs) {
        //         infoList = await Vue.prototype.$japi.mb.getMbByCode('tgjd');
        //     }
        //     if (!infoList || !infoList.subs) return;
        //     const subs = _.get(infoList, "subs");
        //     if (!kq.Tools.isEmpty(subs)) commit("set_process_mb_alias_list", subs);
        // }
    },
};
