/**
 * @description 流程相关接口模块
 * @author tonny
 * @date 2021-12-24
 */

import Login from "./Login";
import Tools from "kooci-lib/src/PubComponents/Utils/tools";
import Select from "./Select";
import View from "./View";
import System from "./System";
import _ from "lodash";

export default class Process extends Tools.mixin(Login, View, Select, System) {
    constructor(params) {
        super(params);
        /** 流程配置 */
        this._processConfig = {};
    }
    /**
     * 获取流程信息
     * @see 可以用来获取对应经历是否有流程,流程再哪一步,
     * @param {object} param 参数对象
     * @param {number} [param.vid] 视图号(与fid必须传一个)
     * @param {number} [param.fid] 功能点号(与vid必须传一个)
     * @param {number} [param.uid] 人员号(默认取当前登录人)
     * @param {number} [param.rid] 经历号(没有默认传0)
     * @param {number} [param.fuid] 经历所属人员号
     * @returns {Promise<object>} 成功返回流程信息对象,失败抛错
     * @example {pid<number>: 流程号, readonly<boolean>: 是否只读, stat<number>: 节点状态(11:开始,12:中间, 13:结束) isStart<boolean>: 是否再开始节点, idMiddle<boolean>:是否在中间节点, isEnd<boolean>: 是否再结束节点, currentZid<number>: 当前审核节点号, nextZid<number>: 下一个审核节点号}
     */
    async getProcessInfo({ vid, uid, rid, fuid, fid }) {
        const console = false,
            current = arguments[0],
            from = "kqRequest.Process.getProcessInfo";
        if (rid === undefined) {
            throw new kq.Err({
                message: "经历号(rid)不能为空",
                current,
                from,
                console,
            });
        }
        this._processConfig.rid = rid;
        if (!uid) {
            const { uid: uid2 } = await this.getLoginInfo().catch((err) => {
                throw err;
            });
            uid = uid2;
        }
        this._processConfig.uid = uid;
        fuid = fuid || uid;
        this._processConfig.fuid = fuid;
        if (!fid && !vid) {
            throw new kq.Err({
                message: "功能点号(fid)与视图号(vid)参数不能同时为空",
                current,
                from,
                console,
            });
        }
        // 查询视图号
        if (!vid) {
            vid = await this.getVid({ fid }).catch((err) => {
                throw err;
            });
        }
        this._processConfig.vid = vid;
        const result =
            (await this.request(
                {
                    url: "/api/process/processInformation",
                    params: {
                        parvalue: [vid, fuid, rid, uid].join() + ";997",
                        gntype: 381,
                        pagesize: 10,
                        page: 1,
                    },
                    convert: {
                        compares: {
                            /** 流程id */
                            pid: "billid",
                            /** 审核状态 */
                            stat: "col1",
                            /** 只读 */
                            readonly: "col2",
                            /** 当前的审核节点 */
                            currentZid: "col3",
                            /** 下一个审核节点 */
                            nextZid: "col4",
                            /** 视图号(编辑视图号) */
                            vid: "viewid",
                        },
                        convertValueHook({ to, value }) {
                            // 给只读赋值boolean
                            if (to === "readonly") {
                                return Boolean(Number(value));
                            }
                            if (to === "stat") {
                                if (value == 11) {
                                    return "start";
                                }
                                if (value == 12) {
                                    return "middle";
                                }
                                if (value == 13) {
                                    return "end";
                                }
                            }
                            return value;
                        },
                    },
                },
                arguments[0]
            ).catch((err) => {
                throw err;
            })) || {};
        this._processConfig.currentZid = result.currentZid;
        this._processConfig.nextZid = result.nextZid;
        this._processConfig.pid = result.pid;
        return result;
    }
    /**
     * 查询流程的审核(下一个流程)人员列表
     * @param {object} [param] 参数对象
     * @param {number} [param.pid] 流程id
     * @param {number} [param.nextZid] 下一个流程id
     * @param {number} [param.rid] 经历号
     * @param {number} [param.uid] 当前登录人员号
     * @param {number} [param.fuid] 经历所属人员号(不传则取当前登录人)
     * @returns {Promise<[object]>} 成功返回人员信息列表,失败抛错
     * @example [{uid<number>: 人员id, info<string>: 人员信息, uname<string>: 姓名, wid<string>: 工号, dname<string>: 部门名称}]
     */
    async getProcessUserList({ pid, nextZid, uid, fuid, rid } = {}) {
        // 默认值覆盖
        pid = pid || this._processConfig.pid;
        nextZid = nextZid || this._processConfig.nextZid;
        uid = uid || this._processConfig.uid;
        fuid = fuid || this._processConfig.fuid;
        rid = rid || this._processConfig.rid;
        const current = arguments[0],
            from = "kqRequest.Process.getProcessUserList";
        if (!nextZid) {
            throw new kq.Err({
                message:
                    "当前流程可能不存在下一个审核节点,请联系管理员查询后操作",
                current,
                from,
                console: false,
            });
        }
        if (!pid || !nextZid || !rid) {
            throw new kq.Err({
                message:
                    "流程id(pid)与下一个节点id(nextZid,如果没有下一个节点,则可以直接提交结束流程)与经历号(rid)不能为空",
                current,
                from,
                console: false,
            });
        }
        if (!uid) {
            const { uid: uid2 } = await this.getLoginInfo().catch((err) => {
                this.errHand_(err);
                throw err;
            });
            uid = uid2;
        }
        fuid = fuid || uid;
        const result = await this.request(
            {
                url: "/api/process/userList",
                params: {
                    parvalue: [pid, nextZid, uid, rid, fuid].join() + ";996",
                    gntype: 381,
                    page: 1,
                    pagesize: 10,
                },
                convert: {
                    compares: {
                        uid: "billid",
                        info: "col1",
                    },
                    convertValueHook({ to, value, parent }) {
                        if (to === "info" && value) {
                            const [uname, wid, dname] = value.split(",");
                            parent.uname = uname;
                            parent.wid = wid;
                            parent.dname = dname;
                        }
                        return value;
                    },
                    isStrict: false,
                },
            },
            arguments[0]
        ).catch((err) => {
            throw err;
        });
        return Tools.isEmpty(result)
            ? []
            : result.filter((user) => !Tools.isEmpty(user));
    }
    /**
     * 提交流程
     * @see 提交流程包括:通过与未通过,执行具体操作请调用pass与nopass
     * @see 参数可以为空将自动应用审核信息参数
     * @param {object} [param] 参数对象
     * @param {number} [param.pid] 流程id
     * @param {number} [param.fuid] 经历所属人员id
     * @param {number} [param.rid] 经历号
     * @param {number} [param.currentZid] 下一个节点字段号
     * @returns {Promise<void>} 失败抛错
     */
    async commitProcess({ pid, fuid, rid, currentZid, nextZid, nextUid }) {
        if (!pid) {
            // 重新获取一遍审核信息
            await this.getProcessInfo(this._processConfig).catch((err) => {
                throw err;
            });
        }
        // 参数替换
        pid = pid || this._processConfig.pid;
        fuid = fuid || this._processConfig.fuid;
        rid = rid || this._processConfig.rid;
        currentZid = currentZid || this._processConfig.currentZid;
        nextZid = nextZid || this._processConfig.nextZid;
        // 没有下一个节点:终止流程参数
        if (!nextZid || nextZid == "-1000") {
            nextUid = -1000;
            nextZid = -1000;
        } else if (!nextUid) {
            throw new kq.Err({
                message: "审核下一个人员id(nextUid)不能为空",
                current: arguments[0],
                from: "kqRequest.Process.commitProcess",
                console: false,
            });
        }
        await this.request(
            {
                url: "/api/process/submit",
                params: {
                    leixing: 17,
                    IdentyID: pid,
                    neirong: [fuid, rid, currentZid, nextZid, nextUid].join(
                        ";"
                    ),
                },
            },
            arguments[0]
        );
    }
    /**
     * 流程-通过
     * @see 走下一步流程
     * @param {object} [param] 参数对象
     * @param {number} [param.pid] 流程id
     * @param {number} [param.fuid] 经历所属人员id
     * @param {number} [param.rid] 经历号
     * @param {number} [param.currentZid] 下一个节点字段号
     * @returns {Promise<void>} 失败抛错
     */
    async pass(params = {}) {
        // 保存流程
        if (!this.saveDisabled) {
            await this.saveProcess({ ...params, slabel: "SV_PASS" }).catch(
                (err) => {
                    throw err;
                }
            );
        }
        await this.commitProcess(params).catch((err) => {
            throw err;
        });
    }
    /**
     * 流程-未通过
     * @see 中断流程
     * @param {object} [param] 参数对象
     * @param {number} [param.pid] 流程id
     * @param {number} [param.fuid] 经历所属人员id
     * @param {number} [param.rid] 经历号
     * @param {number} [param.currentZid] 下一个节点字段号
     * @returns {Promis<void>} 失败抛错
     */
    async nopass(params = {}) {
        // 保存流程
        if (!params.saveDisabled) {
            await this.saveProcess({ ...params, slabel: "SV_NOPASS" }).catch(
                (err) => {
                    throw err;
                }
            );
        }
        await this.commitProcess({ ...params, nextZid: -1000 }).catch((err) => {
            throw err;
        });
    }
    /**
     * 驳回流程
     * @see 参数可以为空将自动应用审核信息参数
     * @param {object} [param] 参数对象
     * @param {number} [param.pid] 流程id
     * @param {number} [param.fuid] 经历所属人员id
     * @param {number} [param.rid] 经历号
     * @param {number} [param.currentZid] 当前节点字段号
     * @returns {Promise<void>} 失败抛错
     */
    async reject(params = {}) {
        let { pid, fuid, rid, currentZid, saveDisabled } = params;
        // 参数合并
        pid = pid || this._processConfig.pid;
        fuid = fuid || this._processConfig.fuid;
        rid = rid || this._processConfig.rid;
        currentZid = currentZid || this._processConfig.currentZid;
        if (!pid) {
            // 重新获取一遍审核信息
            await this.getProcessInfo(this._processConfig).catch((err) => {
                throw err;
            });
        }
        // 保存流程
        if (!saveDisabled) {
            await this.saveProcess({ ...params, slabel: "SV_REJECT" }).catch(
                (err) => {
                    throw err;
                }
            );
        }
        await this.request({
            url: "/api/process/reject",
            params: {
                leixing: 18,
                IdentyID: pid,
                neirong: [fuid, rid, currentZid].join(";"),
            },
        }).catch((err) => {
            throw err;
        });
    }
    /**
     * 保存流程审核信息
     * @see 审核前被调用
     * @param {object} param
     * @param {number} [param.sid] 审核码表码值(不传会自动请求)
     * @param {string} [param.svalue] 审核码表码值
     * @param {string} [param.result] 审核结果字符串(最终结果串:1-通过;意见;人员;事件;签名)
     * @param {string} [param.signature] 签名链接
     * @param {string} [param.uname] 审核人姓名
     * @param {string} [param.comment] 审核人意见
     * @param {number} [param.rid] 经历号
     * @param {number} [param.fuid] 经历所属人人员id
     * @param {string} [param.slabel] 审核码表结果别名
     * @returns {Promise<void>} 失败抛错
     */
    async saveProcess({
        sid,
        svalue,
        result,
        signature,
        uname,
        comment,
        currentZid,
        rid,
        fuid,
        slabel,
    }) {
        const console2 = false,
            from = "kqRequest.Process.saveProcess",
            current = arguments[0];
        // 判断审核结果
        if (!result && !svalue && !slabel) {
            throw new kq.Err({
                message:
                    "审核结果(result)与审核码表码值(svalue)与审核别名(slabel)不能同时为空",
                console: console2,
                from,
                current,
            });
        }
        // 用户名判断
        if (!uname) {
            throw new kq.Err({
                message: "审核人姓名(uname)不能为空",
                current,
                from,
                console: console2,
            });
        }
        // 获取码值号
        if (!sid) {
            const data = await this.getSelectList({
                zlabel: "S_ISPASS",
                convert: false,
            }).catch((err) => {
                throw err;
            });
            if (data) {
                const tempResult = (result || svalue).split(";")[0];
                sid = selectList.find(
                    ({ name, label }) => name === tempResult || label === slabel
                ).id;
            } else {
                throw new kq.Err({
                    title: "查询码表失败",
                    message:
                        "当前未查询到审核通过码表内容(label:S_ISPASS),无法更新流程信息",
                    console: console2,
                    from,
                    current: data,
                });
            }
        }
        if (!result) {
            // 打枪编码
            result = await Process.encodeReviewInfo({
                result: svalue,
                signature,
                uname,
                comment,
            }).catch((err) => {
                throw err;
            });
        }
        // 附加参数
        currentZid = currentZid || this._processConfig.currentZid;
        rid = rid || this._processConfig.rid;
        fuid = fuid || this._processConfig.fuid;
        // 最终接口请求
        await this.request(
            {
                url: "/api/process/updateProcess104",
                method: "post",
                data: {
                    id: fuid,
                    jinglihao: rid,
                    ziduan: currentZid,
                    neirong: result,
                    dictId: sid,
                },
            },
            arguments[0]
        ).catch((err) => {
            throw err;
        });
    }
    /**
     * 获取流程节点信息
     * @see 此接口仅做展示查询使用
     * @param {object} param 参数对象
     * @param {number} param.rid 经历号
     * @param {number} param.pid 流程号
     * @returns {Promise<object>} 失败抛错
     * @example {fid<number>: 功能点号, zidList<[object]>: 审核节点列表, currentZid<number>: 当前活跃节点id, pname<string>: 流程名称, stat<number>: 流程状态(1:进行中,2:已完成,3:未通过,0:未处理)}
     */
    async getProcessZidInfo({ rid, pid }) {
        rid = rid || this._processConfig.rid;
        pid = pid || this._processConfig.pid;
        if (!Number(rid) || !Number(pid)) {
            throw new kq.Err({
                message: "经历号(rid)与流程号(pid)不能为空",
                current: arguments[0],
                from: "kqRequest.Process.getProcessZidList",
            });
        }
        const data = await this.request({
            url: "/api/process/processState",
            params: {
                billId: rid,
                flowId: pid,
            },
            convert: {
                compares: {
                    fid: "functionId",
                    zidList: ["nodes"],
                    zid: "nodeId",
                    zname: "nodeName",
                    uid: "rootBillId",
                    uname: "userName",
                    currentZid: "currentNodeId",
                    pname: "flowName",
                    stat: "flowStatus",
                    pid: "flowId",
                },
            },
        }).catch((err) => {
            throw err;
        });
        return data;
    }
    /**
     * 获取流程历史记录
     * @param {object} param
     * @param {number} [param.rid] 经历号
     * @returns {[object]}
     * @example
     */
    async getProcessHistoryList({ rid } = {}) {
        rid = rid || this._processConfig.rid;
        if (!rid) {
            throw new kq.Err({
                message: "经历号(rid)不能为空",
                current: arguments[0],
                from: "kqRequest.Process.getProcessHistoryList",
                console: false,
            });
        }
        const result = await this.request(
            {
                url: "/api/process/processRowHistory",
                params: { billId: rid },
                convert: {
                    compares: {
                        zid: "FunctionID", // 节点号
                        pid: "FlowID", // 流程号
                        actionName: "ActionName", // 响应名称
                        zname: "FunctionName", // 节点名称
                        uid: "EnabledUserID", // 处理人
                        info: "yj",
                        uname: "EnabledUserName",
                        date: "EnabledDate",
                        pname: "flowName",
                    },
                    convertValueHook({ to, parent, value }) {
                        if (to === "result" && value) {
                            const decode = Process.decodeReviewInfo(value);
                            // 混入值
                            _.forEach(decode, (val, key) => {
                                parent[key] = val || "";
                            });
                        }
                        return value;
                    },
                },
            },
            arguments[0]
        );
        return result;
    }
    /**
     * 审核信息编码
     * @param {object} param
     * @param {string} result 审核结果(比如:1-通过)
     * @param {string} [signature=""] 签名链接
     * @param {string} uname 用户名
     * @param {string} [comment] 评论内容
     * @param {string} [time] 审核时间(建议:不传自动从服务器取值)
     * @returns {string}
     */
    static async encodeReviewInfo({
        result,
        signature = "",
        uname,
        comment = "",
        time,
    } = {}) {
        const console = false,
            from = "kqRequest.Process.encodeReviewInfo",
            current = arguments[0];
        if (!result || !uname) {
            throw new kq.Err({
                message: "审核结果(result)与审核人姓名(uname)不能为空",
                current,
                from,
                console,
            });
        }
        if (!time) {
            time = await kq.Request.mixin({ modules: ["system"] })
                .getTime()
                .catch((err) => {
                    throw err;
                });
        }
        /** 若签名为空，则需要多传一个；
         * 例如：1-通过;;赵静静;2023-05-22 17:24:07;;
         */
        return signature? [result, comment, uname, time, signature].join(";"): [result, comment, uname, time, signature,,].join(";");
    }
    /**
     * 审核信息解码
     * @param {string} value 结果字符串
     * @returns {string}
     * @example {result<string>: 审核结果(自动去除结果), isPass<boolean>: 是否通过, isNopass<boolean>: 是否未通过, isReject<boolean>: 是否驳回, comment<string>: 审核意见, signature<string>: 签名, time<string>: 审核时间}
     */
    static decodeReviewInfo(value = "") {
        value = value || "";
        const [
            result = "",
            comment = "",
            uname = "",
            time = "",
            signature = "",
        ] = value.split(";");
        return {
            result: result.replace(/[^\u4e00-\u9fa5]/g, ""),
            isPass: /^1/.test(result),
            isNopass: /^2/.test(result),
            isReject: /^3/.test(result),
            comment,
            uname,
            time,
            signature,
        };
    }
}
