/**
 * 前置路由基类模块
 * @description 此处管理前置路由导航守卫类公共逻辑
 * @author tonny
 * @date 2022-07-16
 */

export default class Guard {
    /**
     * 前置路由导航守卫基类
     * @param {object} options 配置对象
     * @param {object} options.router 路由实例对象
     */
    constructor({ router }) {
        if (!router) {
            throw new Error(`${this.constructor.name}: router参数不能为空`);
        }
        this._router = router;
    }
    /**
     * 导航守卫核心钩子函数
     * @description Router类将统一调用该函数来执行前置守卫逻辑
     * @param {object} to 目标路由对象
     * @param {object} from 路由源对象
     * @returns void
     */
    beforeEach() {
        console.error(
            `${this.constructor.name}:路由钩子派生类必须重写beforeEach函数`
        );
    }
}
