/** 码表缓存对象 */
const SELECTS = {};
/**
 * 码表相关
 * @see 码表查询,修改,配置
 */
export default class Select {
    constructor() {
        this._selects = SELECTS;
    }
    /**
     * 获取码表项
     * @desc 自动缓存
     * @param {object} param
     * @param {string|number} [param.zid] 字段号
     * @param {string} [param.zlabel] 字段别名
     * @param {boolean} [param.cacheDisabled] 是否禁用缓存
     * @returns {Promise<[object]>} 成功返回码表数据表,失败抛错
     */
    async getSelectList({ zid, zlabel, cacheDisabled, convert }) {
        if (!zid && !zlabel) {
            throw new kq.Err({
                title: "参数错误",
                message: "字段号(zid)与字段别名(zlabel)不能同时为空字符串",
                from: "Request.getSelectList",
                console: false,
            });
        }
        let selectList = this._selects[zid || zlabel];
        if (kq.Tools.isEmpty(selectList) || cacheDisabled) {
            let url, params;
            if (zid) {
                url = "/api/common/dict/getDict";
                params = {
                    nodeId: zid,
                };
            } else {
                url = "/api/common/dict/labelDict";
                params = {
                    label: zlabel,
                };
            }
            selectList = await this.request({ url, params, convert: url }, arguments[0]).catch(
                (err) => {
                    throw err;
                }
            );
            // 转换器为空再缓存，否则会出现自定义转换器与标准转换器冲突导致的缓存错误的结果
            if (kq.Tools.isEmpty(convert)) {
                this._selects[zid || zlabel] = selectList;
            }
        }
        return selectList;
    }

    /**
     * 将码表数据扁平化并添加路径信息
     * @param {Array} selectList 码表数据
     * @param {Object} parent 父级节点
     * @param {Object} result 扁平化后的码表数据
     * @returns {Object} 扁平化后的码表数据
     */
    spreadSelectList(selectList, parent, result = {}) {
        return kq.Tools.spreadSelectList(selectList, parent, result);
    }
    /**
     * 获取用户表
     * @param {object} param 参数表
     * @param {string} [param.fuzzyQuery] 模糊查询参数
     * @param {string} [param.dataAuth] 数据权限（0:个人；1：二级；2：校级）；二级只能
     * @returns {Promise<[object]>} 失败抛错
     * @example [{uname<string>: 用户名, wid<number>: 工号, dname<string>: "部门", info<string>: 组合字符串, uid<number>: 用户号, label<string>: 提供给selectList使用的label(info), value<number>: 提供给selectList使用的value值(uid)}]
     */
    async getUserList({ fuzzyQuery, uidList, dataAuth = 2 }) {
        if (fuzzyQuery === undefined && _.isEmpty(uidList)) {
            return [];
        } else {
            if (fuzzyQuery !== undefined) {
                const userList = await this.request(
                    {
                        url: `/api/process/getPersonJson?gntype=225&pagesize=-1&page=-1&encode=1&dataAuth=${dataAuth}&parvalue=-2000$${fuzzyQuery}`,
                        method: "post",
                    },
                    arguments[0]
                ).catch((err) => {
                    throw err;
                });
                return userList.map(({ col1: info, col2: uid }) => {
                    const [uname, wid, dname] = (info || "").split(",");
                    return {
                        uname,
                        wid,
                        dname,
                        info,
                        uid,
                        label: info,
                        value: uid,
                    };
                });
            } else {
                return this.request(
                    {
                        url: "/api/process/getPersonByIds",
                        method: "post",
                        data: uidList,
                        convert: {
                            compares: {
                                label: "xm",
                                uname: "userName",
                                wid: "userCode",
                                dname: "deptName",
                                value: "nodeid",
                            },
                        },
                    },
                    arguments[0]
                );
            }
        }
    }
}
