<script>
/**
 * @description 翻页混入
 * @author tonny
 * @date 2021-08-16
 */
</script>

<script>
import _ from "lodash";

export default {
    name: "v-kq-mixin-page-num",
    props: {
        // 加载函数
        load: Function,
        // 每页多少个
        onePageSize: {
            type: Number,
            default: 20,
        },
    },
    data() {
        return {
            currentPage_: 0, // 当前页码
            sumCount_: 0, // 总个数
            dpLoading_: false, // 整页加载态
            dpNextLoading_: false, // 下一页加载状态
            dataList_: [], // 异步请求数据表
            dpInitMethod_: null, // 初始化方法(私有)
            dpConfig_: {
                // 配置
                debounceTime: 500, // 节流请求(0表示不使用节流)
            },
        };
    },
    computed: {
        /**
         * 是否已经完成请求
         * @returns {boolean} true:已完成
         */
        isFinish() {
            return (
                this.currentPage_ !== 0 &&
                this.currentPage_ * this.onePageSize >= this.sumCount_
            );
        },
    },
    watch: {
        /**
         * 观察配置
         * @see 1.设置节流
         * @returns void
         */
        dpConfig_: {
            handler({ debounceTime }) {
                if (debounceTime) {
                    this.dpInitMethod_ = _.debounce(this.dpInit_, debounceTime);
                } else {
                    this.dpInitMethod_ = this.dpInit_;
                }
            },
            immediate: true,
            deep: true,
        },
    },
    methods: {
        /**
         * 加载函数
         * @see 当改组件作为混入组件时,重写次函数实现加载
         * @returns {Promise<void>}
         */
        async load_() {},
        /**
         * 数据初始化
         * @see 初始页码,重新加载数据
         */
        dpUpdate_() {
            this.currentPage_ = 0;
            this.dataList_ = [];
            this.dpNext_();
        },
        /**
         * 加载下一页数据
         * @see 根据this.load参数进行组件内初始化
         * @returns void
         */
        async dpNext_() {
            if (!this.isFinish || this.currentPage_ === 0) {
                this.dpInitMethod_ && this.dpInitMethod_();
            }
        },
        /**
         * 初始化函数(私有)
         * @see 提供给this.dpInitMethod_赋值实现动态设置节流
         * @returns void
         */
        dpInit_() {
            let loadMethod;
            if (_.isFunction(this.load)) {
                loadMethod = this.load.bind(this);
            } else {
                loadMethod = this.load_.bind(this);
            }
            // 第一页:清空数据
            const result = loadMethod(this.dpGetInitParam_());
            if (result instanceof Promise) {
                if (this.currentPage_) {
                    this.dpNextLoading_ = true;
                } else {
                    this.dpLoading_ = true;
                    // 第一页清空数据
                    this.dataList_ = [];
                }
                result
                    .then((datas) => {
                        let dataList = [];
                        if (_.isArray(datas)) {
                            this.sumCount_ = dataList.length;
                            dataList = datas;
                        } else if (_.isPlainObject(datas)) {
                            this.sumCount_ = datas.sum || 0;
                            dataList = datas.dataList || [];
                        } else {
                            this.errHand_(
                                new Error(
                                    `[${
                                        this.$options.name
                                    }-dataInit]返回参数错误!只能返回数组或者对象!当前${JSON.stringify(
                                        datas
                                    )}`
                                )
                            );
                        }
                        this.dataList_ = this.dataList_.concat(dataList);
                        this.dpLoading_ = false;
                        this.dpNextLoading_ = false;
                        this.currentPage_++;
                    })
                    .catch(() => {
                        this.dpNextLoading_ = false;
                        this.dpLoading_ = false;
                    });
            } else {
                // 第一页清空数据
                if (!this.currentPage_) {
                    this.dataList_ = [];
                }
                let dataList = [];
                if (_.isArray(result)) {
                    this.sumCount_ = dataList.length;
                    dataList = result;
                } else if (_.isPlainObject(result)) {
                    this.sumCount_ = result.sum || 0;
                    dataList = result.dataList || [];
                } else {
                    this.errHand_(
                        new Error(
                            `[${
                                this.$options.name
                            }-dataInit]返回参数错误!只能返回数组或者对象!当前${JSON.stringify(
                                result
                            )}`
                        )
                    );
                }
                this.dataList_ = this.dataList_.concat(dataList);
                this.dpLoading_ = false;
                this.dpNextLoading_ = false;
                this.currentPage_++;
            }
        },
        /**
         * 获取初始化参数
         * @see 提供给子类重写提供不同的初始化函数参数
         * @returns {object} {currentPage: 当前页码, pageSize: }
         */
        dpGetInitParam_() {
            return {
                currentPage_: this.currentPage_,
                currentPage: this.currentPage_, // 兼容
                onePageSize: this.onePageSize,
            };
        },
    },
};
</script>