/**
 * @description 接口请求-权限相关
 * @author tonny
 * @date 2022-01-13
 */

import App from "./App.js";

export default class Permission extends App {
    /**
     * 获取数据权限列表
     * @param {object} param 参数对象
     * @param {number} param.appid app号
     * @param {number} param.fid 功能点号
     * @returns {[object]}
     * @example [{name<string>: 权限名称, dataAuth<string>: 权限值}]
     */
    async getDataAuthList({ appid, fid }) {
        if (!appid && !fid) {
            throw new kq.Err({
                message: "功能点号(fid)与app号(appid)不能同时为空",
                current: arguments[0],
                from: "kq.Request.Permission.getDataAuthList",
            });
        }
        const { authList } = await this.getAppAuthInfo(arguments[0]).catch(err => {
            throw err;
        });
        return authList;
    }
    /**
     * 判断当前登录人与目标人员是否为同一个部门
     * @param {object} param 参数对象
     * @param {number} param.uid 人员号
     * @returns {Promise<boolean>} true 同一个部门
     */
    async isEqualDepartment({uid}) {
        if (!uid) {
            throw new kq.Err({
                message: "人员号(uid)不能为空",
                current: arguments[0],
                from: "kq.Request.Permission.isEqualDepartment",
                console: false,
            });
        }
        // const res = await this.request("/api/isEqualDepartment").catch(err => {throw err});
        const res = true;
        return Boolean(res);
    }
}
