/**
 * @description 低码组件工具集
 * @author tonny
 * @date 2022-06-06
 */

/**
 * 取值
 * @param {string|number} zid 字段号
 * @param {[object]} fromDataList 数据源
 * @returns {string|array} 成功返回数据值,失败抛错
 */
export function getValue(zid, fromDataList) {
    if (!kq.Tools.isEmpty(fromDataList)) {
        if (zid) {
            let results;
            fromDataList.some(info => {
                if (kq.Tools.isEqual(info.zid, zid)) {
                    results = { ...info.forms };
                    return true;
                } else {
                    results = info.forms[zid];
                    return results;
                }
            });
            return results;
        } else {
            const results = {};
            fromDataList.forEach(({ zid: typeZid, forms }) => {
                results[typeZid] = { ...forms };
            });
            return results;
        }
    }
}
