<script>
/**
 * @description 表单-历史记录公共混入
 * @description 注意:混入该值的值存储data变量必须为current
 * @author tonny
 * @date 2022-05-05
 */
</script>

<script>
import _ from "lodash";

export default {
    props: {
        /** 字段号 */
        zid: [String, Number],
        /** 字段名称 */
        zname: String,
        imposeIsChange: Boolean,
    },
    data() {
        return {
            /** 当前值 */
            current: undefined,
            /** 历史记录表 */
            historyList: [],
            /** 首次值 */
            firstValue: undefined,
            /** 强制更改状态 */
            imposeIsChange_: null,
            currentStepIndex: -1,
        };
    },
    watch: {
        /**
         * 判断当前值
         * @description 设置更改
         * @description 设置首次值
         * @description 提交一次记录
         * @description 不要加immediate会出错
         */
        current: {
            handler(value, old) {
                if (this.firstValue === undefined) {
                    this.firstValue = _.cloneDeep(value);
                }
                if (
                    old !== undefined &&
                    !this.isEqual__(this.historyList[this.currentStepIndex], value)
                ) {
                    this.commit(value);
                }
            },
            immediate: true,
        },
        /**
         * @desc 设置历史数据初始值
         */
        firstValue(first) {
            if (first !== null && this.isEmpty__(this.historyList)) {
                this.historyList = [first];
            }
        },
        /**
         * @desc 自动初始化当前步骤
         */
        currentStepIndex(index) {
            if (index >= 0) {
                setTimeout(() => {
                    this.currentStepIndex = -1;
                }, 2000);
            }
        },
    },
    computed: {
        /**
         * 值是否已更新
         * @returns {boolean} true:已更改
         */
        isChange() {
            return this.imposeIsChange_ || !this._isSame(this.firstValue, this.current);
        },
    },
    created() {
        this.sync__("imposeIsChange");
    },
    methods: {
        /**
         * 提交
         * @description 确认表单更改
         * @description 需要在每次确认更改后调用
         * @description 向历史记录中添加当前值作为记录
         * @param {*} value 提交的值
         * @param {boolean} [alwaysChange=false] 是否强制更改(不受同值判断影响)
         * @returns void
         */
        commit(value, alwaysChange = false) {
            const lastItem = this.historyList.kqLastItem();
            let isSame = this._isSame(lastItem, value || this.current);
            if (!isSame || alwaysChange) {
                !isSame && this.appendHistory(this.current);
                this.onChange(
                    this.historyList.kqLastItem(),
                    this.historyList[this.historyList.length - 2],
                    alwaysChange
                );
            }
        },
        /**
         * 追加一个历史数据
         * @description 判重追加
         * @param {*} value 追加值
         * @returns void
         */
        appendHistory(value) {
            if (!this._isSame(this.historyList.kqLastItem(), value)) {
                this.historyList.push(value);
            }
        },
        /**
         * 更改事件
         * @emits change {zid<string|number>: 字段号, value<string>:新的值, old?<string>:旧的值}
         * @param {boolean} [alwaysChange=false] 是否强制更改(不受同值判断影响)
         * @param {string} value 新的值
         * @param {string} old 旧的值
         * @returns void
         */
        onChange(value, old, alwaysChange) {
            this.$nextTick(() => {
                const params = this._getChangeParams(value, old);
                if (params.zid) {
                    if (this.isChange || alwaysChange) {
                        this.$emit("change", params);
                    } else if (!this.isChange) {
                        this.$emit("revoke", params);
                    }
                }
            });
        },
        /**
         * 去往历史记录
         * @desc 指定值去往第几步更改
         * @param {number} step 指定的步数(正数:表示第几步,负数:表示倒数第几步)
         * @param {boolean} [isSlice=false] 是否截断(true时会清空剩下的历史记录)
         * @returns {any} 返回对应步数的值,查询错误返回undefined
         */
        go(step, isSlice = false) {
            if (this.isEmpty__(step)) {
                return;
            }
            let index;
            if (step >= 0) {
                index = step;
            } else {
                index = this.historyList.length - Math.abs(step);
            }
            if (index >= 0 && index < this.historyList.length) {
                this.currentStepIndex = index;
                const old = this.current;
                this.current = this.historyList[index];
                if (isSlice) {
                    this.historyList = this.historyList.slice(index);
                }
                this.$nextTick(() => {
                    const isSame = this._isSame(this.current, old);
                    if (!isSame) {
                        this.onChange(this.current, old);
                    }
                });
                return this.current;
            }
        },
        /**
         * 后退
         * @description 将值重置到上n步
         * @param {number} [step=1] 撤销几步
         * @returns void
         */
        back(step = 1) {
            let index = this.historyList.length - step;
            if (index >= 0) {
                const old = this.current;
                this.current = this.historyList[index];
                this.$emit("back", {
                    zid: this.zid,
                    value: this.current,
                    old: this.historyList[this.historyList.length - 2],
                });
                this.$nextTick(() => {
                    this.onChange(this.current, old);
                });
            }
        },
        /**
         * 清空历史数据
         * @returns void
         */
        clear() {
            this.historyList = [this.firstValue];
            this.firstValue = _.cloneDeep(this.current);
            this.imposeIsChange_ = false;
        },
        /**
         * 刷新清空
         * @description 将首次值设置为历史值,再把当前值设置为首次值
         * @returns void
         */
        refreshClear() {
            this.clear();
        },
        /**
         * 保存清空
         * @description 将当前值设置为初始值与首次值
         * @returns void
         */
        saveClear() {
            this.save();
            this.imposeIsChange_ = false;
        },
        /**
         * 保存值
         * @description 将当前值设置为首次值
         * @description 清空历史记录
         * @returns void
         */
        save() {
            this.firstValue = _.cloneDeep(this.current);
            this.historyList = [this.firstValue];
        },
        /**
         * 获取更改事件参数
         * @see 子混入组件可以重写实现自定义change事件参数
         * @returns {object}
         * @example {zid<number>:字段号, value<[number]|string|number|null>:字段值, old:旧的字段值}
         */
        _getChangeParams(value, old) {
            return {
                zid: this.zid,
                zname: this.zname,
                value,
                old,
            };
        },
        /**
         * 判断两个值是否全等
         */
        _isSame(arg1, arg2) {
            if (this.isEmpty__(arg1) && this.isEmpty__(arg2)) {
                return true;
            }
            if (this.isEmpty__(arg1) || this.isEmpty__(arg2)) {
                return false;
            }
            return this.isEqual__(arg1, arg2);
        },
    },
};
</script>
