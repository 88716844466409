<script>
/**
 * @description 详情页与列表页公共混入
 * @author tonny
 * @date 2022-08-02
 */
</script>

<script>
import _ from "lodash";

export default {
    methods: {
        /**
         * map单个数据基类合并
         * @desc 将单个数据与基类返回的单个数据进行合并
         * @param {object} target 当个数据源
         * @param {string} baseMethod 基类函数方法名
         * @param {[any]} params 参数列表
         * @param {string} SUPER 超类名
         * @param {boolean} [isReverseMixin=false] 是否反向混入
         * @returns {object} 合并后的对象
         */
        _mergeBaseMap(target, SUPER, baseMethod, params, isReverseMixin) {
            const baseData = this.$super(SUPER)[baseMethod](...params);
            if (!baseData?.$empty) {
                if (isReverseMixin) {
                    return { ...baseData, ...target };
                } else {
                    return { ...target, ...baseData };
                }
            }
            return target;
        },
        /**
         * 获取基类筛选结果
         * @desc 根据基类调用与当前过滤条件综合判断过滤结果
         * @param {boolean} currentFilter 当前过滤结果
         * @param {string} baseMethod 基类方法名
         * @param {[any]} params 参数列表
         * @param {string} SUPER 超类名
         * @returns {boolean} true:通过过滤
         */
        _getBaseFilter(currentFilter, SUPER, baseMethod, params) {
            let isBaseFilter = true;
            const baseFilter = this.$super(SUPER)[baseMethod](...params);
            if (!baseFilter.$empty) {
                isBaseFilter = baseFilter;
            }
            return !currentFilter ? false : isBaseFilter;
        },
    },
};
</script>
