import { _getLinkageTypeFunc } from "../PieChart/config.js";
export default {
    name: "进度采集组件",
    component: "kq-lowcode-component-progress-chart",
    type: "统计",
    demo: {
        name: "关键信息完成率",
        /** 数据列表 */
        dataList: [
            {
                zid: 1,
                zname: "姓名",
                children: [
                    {
                        value: "1",
                        zname: "组织部",
                    },
                    {
                        value: "1",
                        zname: "法学院",
                    },
                    {
                        value: "4",
                        zname: "档案馆",
                    },
                    {
                        value: "3",
                        zname: "教育科学学院",
                    },
                ],
            },
            {
                zid: 2,
                zname: "工号",
                children: [
                    {
                        value: "3",
                        zname: "组织部",
                    },
                    {
                        value: "1",
                        zname: "法学院",
                    },
                    {
                        value: "4",
                        zname: "档案馆",
                    },
                    {
                        value: "1",
                        zname: "教育科学学院",
                    },
                ],
            },
            {
                zid: 3,
                zname: "部门",
                children: [
                    {
                        value: "3",
                        zname: "组织部",
                    },
                    {
                        value: "3",
                        zname: "法学院",
                    },
                    {
                        value: "2",
                        zname: "档案馆",
                    },
                    {
                        value: "7",
                        zname: "教育科学学院",
                    },
                ],
            },
            {
                zid: 4,
                zname: "照片",
                children: [
                    {
                        value: "4",
                        zname: "组织部",
                    },
                    {
                        value: "1",
                        zname: "法学院",
                    },
                    {
                        value: "2",
                        zname: "档案馆",
                    },
                    {
                        value: "4",
                        zname: "教育科学学院",
                    },
                ],
            },
            {
                zid: 5,
                zname: "年龄",
                children: [
                    {
                        value: "0",
                        zname: "组织部",
                    },
                    {
                        value: "4",
                        zname: "法学院",
                    },
                    {
                        value: "2",
                        zname: "档案馆",
                    },
                    {
                        value: "1",
                        zname: "教育科学学院",
                    },
                ],
            },
        ],
        maxSelectLength: 5
    },
    configList: [
        {
            zid: "baseinfo",
            zname: "基础信息",
            subname: "写死的卡片基本信息",
            formList: [
                {
                    zid: "name",
                    zname: "标题",
                    type: "text-text",
                    required: true,
                },
                {
                    zid: "maxSelectLength",
                    zname: "最大选择图例的长度",
                    type: "text-number",
                    required: true,
                },
                {
                    zid: "actionType",
                    zname: "穿透操作",
                    type: "select-single-1",
                    help: "注意:\n打开应用必须得保证每个数据项中包含应用号(appid)\n打开功能点必须得保证每个数据项中包含功能点号(fid)",
                    value:'',
                    svalue:'',
                    selectList: [
                        { label: "打开应用", value: "app", disabled: true },
                        { label: "打开功能点", value: "function" },
                        {
                            label: "打开功能点详情页",
                            value: "function-detail",
                            disabled: true,
                        },
                    ],
                },
            ],
        },
        {
            zid: "async",
            zname: "数据源",
            type: "multi",
            znameField: { zid: "dataName" },
            /** 多个数据源可用的字段对象(当满足特定值时,可用多数据) */
            multiDisabledField: {
                zid: "type",
                value: ["all", ""],
                message:
                    "只有异步数据源[数据类型]都为单项数据时,才支持添加数据源",
            },
            formList: [
                {
                    zid: "type",
                    zname: "数据类型",
                    type: "select-single-1",
                    value: "",
                    svalue: "",
                    required: true,
                    selectList: [
                        { label: "所有数据", value: "all" },
                        { label: "单项数据", value: "single" },
                    ],
                },
                {
                    zid: "from",
                    zname: "数据来源",
                    type: "select-single-1",
                    required: true,
                    value: "",
                    svalue: "",
                    selectList: [
                        { label: "接口", value: "api" },
                        { label: "功能点", value: "function" },
                    ],
                },
                {
                    zid: "dataName",
                    zname: "数据名称",
                    type: "text-text",
                    required: true,
                    value: "",
                },
                {
                    zid: "apiRoute",
                    zname: "接口地址",
                    type: "text-text",
                    required: true,
                    value: "",
                },
                {
                    zid: "apiConvert",
                    zname: "接口转换",
                    type: "code",
                    required: true,
                    value: "",
                    codeFormat: "javascript",
                },
                {
                    zid: "fid",
                    zname: "功能点",
                    type: "text-text",
                    required: true,
                    value: "",
                },
                {
                    zid: "valueType",
                    zname: "值类型",
                    type: "select-single-1",
                    required: true,
                    svalue: "",
                    value: "",
                    selectList: [{ label: "列表页总数", value: "list-total" }],
                },
            ],
            linkage: [
                {
                    name: "数据类型-数据源联动",
                    subname: "选择数据类型后,联动数据源字段显示",
                    from: [
                        {
                            zid: "type",
                            value: ["all"],
                        },
                    ],
                    to: [
                        {
                            zid: "from",
                            action: "visible-select-disabled",
                            value: ["function"],
                        },
                        {
                            zid: "from",
                            action: "code",
                            value: [_getLinkageTypeFunc()],
                        },
                    ],
                },
                {
                    name: "数据类型-数据源联动",
                    subname: "选择数据类型后,联动数据源字段显示",
                    from: [
                        {
                            zid: "type",
                            value: ["single"],
                        },
                    ],
                    to: [
                        {
                            zid: "from",
                            action: "visible-select-disabled",
                            value: ["api"],
                        },
                        {
                            zid: "from",
                            action: "code",
                            value: [_getLinkageTypeFunc()],
                        },
                    ],
                },
                {
                    name: "数据类型-数据名称联动",
                    subname: "数据类型:单项数据关联数据名称显示",
                    from: [
                        {
                            zid: "type",
                            value: ["all"],
                        },
                    ],
                    to: [
                        {
                            zid: "from",
                            action: "visible-select-disabled",
                            value: ["function"],
                        },
                    ],
                },
                {
                    name: "数据来源联动",
                    subname: "数据来源-api显示对应api的相关",
                    from: [
                        {
                            zid: "type",
                            value: ["all"],
                        },
                    ],
                    to: [
                        {
                            zid: "apiRoute",
                            action: "visible-field",
                            value: true,
                        },
                        {
                            zid: "apiConvert",
                            action: "visible-field",
                            value: true,
                        },
                    ],
                },
                {
                    name: "数据来源-字段联动",
                    subname: "数据来源码值:功能点,关联显示功能点号与值类型",
                    type: "visible",
                    from: [
                        {
                            zid: "type",
                            value: ["single"],
                        },
                    ],
                    to: [
                        {
                            zid: "fid",
                            action: "visible-field",
                            value: true,
                        },
                        {
                            zid: "valueType",
                            action: "visible-field",
                            value: true,
                        },
                    ],
                },
            ],
        },
    ],
};