<script>
/**
 * 详情页公共数据混入
 * @desc 必须混入view公共仓库
 * @desc 1.提供公共数据格式化与过滤逻辑(通过formatDataList取数据)
 * @desc 2.提供初始化获取字段列表调用
 * @desc 3.自动混入公共列表页与详情页公共混入逻辑
 * @desc 4.自动混入数据请求(kq.DataMixin)逻辑
 * @see https://www.tapd.cn/62073498/documents/show/1162073498001004804
 * @author tonny
 * @date 2022-08-31
 */
</script>

<script>
import DataMixin from "../Data/Data.vue";
import ListPublicMixin from "../List/DataMixin.vue";
import FormChangeMixin from "../Data/FormChange.vue";
const SUPER = "KQ_DETAIL_DATA_MIXIN";

export default {
    SUPER,
    mixins: [DataMixin, ListPublicMixin, FormChangeMixin],
    data() {
        return {
            /** 数据字段列表 */
            dataList: [],
            /** 字段联动 */
            linkageList: [],
            /** 混入请求对象 */
            detailMixinRequest: kq.Request.mixin({
                vm: this,
                modules: ["field", "system"],
            }),
            /** 仅刷新数据加载状态 */
            refreshDataLoading: false,
            /** 功能点绑定的视图号 */
            fvid: null,
        };
    },
    computed: {
        /**
         * 格式化字段数据列表
         * @desc 将类型与字段进行过滤与格式化
         * @desc 所有的数据源存储在from中
         * @see this.dataList_
         * @returns {object}
         */
        formatDataList() {
            return (this.dataList || [])
                .map((type, typeIndex) => ({
                    ...(this._formatDataTypeMap(type, typeIndex, type) || {}),
                    children: (type.children || [])
                        .filter(this._formatDataFilter)
                        .map((field, index) =>
                            this._formatDataMap(field, index, field)
                        ),
                }))
                .filter(this._formatDataTypeFilter);
        },
    },
    methods: {
        /**
         * 字段过滤(重写)
         * @desc 附加字段隐藏判断
         * @returns {boolean} true:通过
         */
        _formatDataFilter(...params) {
            const [field] = params;
            const superFilter = this.$super(SUPER)._formatDataFilter(...params);
            return superFilter;
        },
        /**
         * 更改数据(重写)
         * @desc 附加行集不参加临存机制
         * @returns {void}
         */
        changeItem(field) {
            if (!field.type.includes("rowset")) {
                this._autoSetSignFieldTime(field);
                this.$super(SUPER).changeItem(arguments[0]);
            }
        },
        /**
         * 设置设置签名时间字段
         * @desc 触发自动更新条件:1变化的字段类型时签名类型 && 字段名称包含签名两个字
         * @desc 2.变化字段下一个字段为日期型 && 字段名称包含签名两个字
         * @param {object} field
         * @param {number} field.zid 字段号
         * @param {string} field.zname 字段名称
         * @param {string} field.type 字段类型
         * @returns void
         */
        _autoSetSignFieldTime({ zid, zname, type }) {
            if (zname.includes("签名") && type.includes("signature")) {
                let nextField;
                this.dataList.some(({ children }) =>
                    children.some((childfield, index) => {
                        if (this.isEqual__(childfield.zid, zid)) {
                            nextField = children[index + 1];
                            return !!nextField;
                        }
                    })
                );
                if (
                    nextField &&
                    nextField.type.includes("date") &&
                    nextField.zname.includes("签名")
                ) {
                    this.detailMixinRequest
                        .getTime()
                        .then((time) => {
                            this.$set(nextField, "readonly", true);
                            this.$set(nextField, "value", time);
                        })
                        .catch((err) => {
                            this.errHand__(err);
                        });
                }
            }
        },
        /**
         * 初始化数据(重写)
         * @desc 重新加载数据
         * @returns {Promise<void>} 失败抛错
         */
        async init() {
            await this.refreshData().catch((err) => {
                throw err;
            });
        },
        /**
         * 重新加载数据
         * @desc 调用此函数可以只重新加载数据
         * @returns {Promise<void>} 成功设置this.dataList_,失败抛错
         */
        async refreshData() {
            this.refreshDataLoading = true;
            const params = this._getLoadParams();
            if (
                Object.keys(params).length === 2 &&
                params.vid !== undefined &&
                params.fid !== undefined
            ) {
                console.warn(
                    "是否需要重写_getLoadParams函数,否则可能导致查询的数据错误的情况~"
                );
            }
            let vinfo = await this.$store
                .dispatch("view/getVinfo", { fid: this.fid })
                .catch((err) => {
                    this.errHand__(err);
                    throw err;
                });
            this.fvid = vinfo.vid;
            if (this.$props.vid === undefined) {
                this.vid = vinfo.vid;
            } else {
                this.$emit("upate:vid", vinfo.vid);
            }
            // 入参视图号与当前功能点视图号不同(指定视图号),则直接使用入参视图号获取字段联动
            if (
                !this.isEmpty__(this.vid) &&
                !this.isEqual__(this.vid, this.fvid)
            ) {
                vinfo = await this.$store
                    .dispatch("view/getVinfo", { vid: this.vid })
                    .catch((err) => {
                        this.errHand__(err);
                        throw err;
                    });
            }
            this.linkageList = vinfo.linkageList;
            this.dataList = await this.detailMixinRequest
                .getFieldList(params)
                .catch((err) => {
                    this.errHand__(err);
                    this.refreshDataLoading = false;
                    throw err;
                });
            this.refreshDataLoading = false;
            this.$nextTick(() => {
                this.clear();
                setTimeout(() => {
                    this.fillDefaultValue();
                }, 500);
            });
        },
        /**
         * 获取加载数据参数
         * @virtucal
         * @returns {object}
         */
        _getLoadParams() {
            return {
                fid: this.fid || null,
                vid: this.vid || null,
            };
        },
        /**
         * 数据类型过滤
         * @param {object} type 类型对象
         * @param {number} index 类型索引
         * @returns {boolean} true:过滤通过
         */
        _formatDataTypeFilter(type, index) {
            return !this.isEmpty__(type.children);
        },
        /**
         * 数据类型格式化
         * @param {object} type 类型对象
         * @param {number} index 类型索引
         * @param {object} from 类型源对象
         * @returns {object} 格式化后的类型
         */
        _formatDataTypeMap(type, index, from) {
            return {
                ...type,
                from,
                index,
            };
        },
        /**
         * 填充默认值
         * @returns void
         */
        fillDefaultValue() {
            this.dataList.kqEachDeep((field) => {
                if (
                    // 默认值不为空
                    !this.isEmpty__(field.defaultValue) &&
                    // 人员号不能为空
                    !this.isEqual__(this.uid, 0) &&
                    // 新经历
                    this.isEqual__(this.rid, 0) &&
                    // 行集字段才才存在默认值
                    /^FR/.test(field.zlabel) &&
                    // 字段只读
                    !field.readonly
                ) {
                    field.value = field.defaultValue;
                }
            });
        },
    },
};
</script>
