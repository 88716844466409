/**
 * 深度循环内部调用
 * @param {function} handler 回调函数
 * @param {string} [deepKey=children] 深度参照key
 * @param {function} [isLeafHandler] 判断是否为叶子节点的函数(true:直接调用回调,false:根据情况继续递归)
 * @param {object} indexItem 当前深度索引对象
 * @returns {boolean} 是否中断循环
 */
function _eachDeep(handler, deepKey = "children", indexItem, isLeafHandler) {
    let isBreak = false;
    this.some((item) => {
        /** 是否为叶子节点 */
        let isLeaf = _.isFunction(isLeafHandler) ? isLeafHandler(item) : false;
        if (_.isArray(item[deepKey]) && !isLeaf) {
            isBreak = _eachDeep.call(
                item[deepKey],
                handler,
                deepKey,
                indexItem,
                isLeafHandler
            );
        } else if (handler(item, indexItem.index++) === false) {
            isBreak = true;
        }
        return isBreak;
    });
    return isBreak;
}
/**
 * 深度遍历
 * @param {function} handler 回调函数(item, index)(返回false中断循环)
 * @param {string} [deepKey="children"] 递归的key
 * @param {function} [isLeafHandler] 判断是否为叶子节点的函数(true:直接调用回调,false:根据情况继续递归)
 * @returns {[any]} 返回当前的数组
 */
export function deepEach(handler, deepKey = "children", isLeafHandler = null) {
    const temp = { index: 0 };
    _eachDeep.call(this, handler, deepKey, temp, isLeafHandler);
    return this;
}

export function eachDeep(...args) {
    return deepEach.apply(this, args);
}

/**
 * 深度查询
 * @param {function} handler 回调函数
 * @param {string} deepKey 递归的key
 * @param {function} [isLeafHandler] 判断是否为叶子节点的函数(true:直接调用回调,false:根据情况继续递归)
 * @returns {object|void}
 */
export function deepFind(handler, deepKey = "children", isLeafHandler = null) {
    let result;
    this.some((item) => {
        let isLeaf = _.isFunction(isLeafHandler) ? isLeafHandler(item) : false;
        if (_.isArray(item[deepKey]) && !isLeaf) {
            result = deepFind.call(
                item[deepKey],
                handler,
                deepKey,
                isLeafHandler
            );
        } else {
            if (handler(item)) {
                result = item;
            }
        }
        return result;
    });
    return result;
}

export function findDeep(...args) {
    return deepFind.apply(this, args);
}

/**
 * 深度map
 * @param {function} handler 回调函数
 * @param {string} deepKey 递归的key
 * @param {function} [isLeafHandler] 判断是否为叶子节点的函数(true:直接调用回调,false:根据情况继续递归)
 * @returns {[any]} 返回通过handler返回值组合的数组
 */
export function mapDeep(handler, deepKey = "children", isLeafHandler = null) {
    const resultList = [];
    this.kqEachDeep(
        (data) => {
            const res = handler(data);
            if (res !== false) {
                resultList.push(res);
            }
        },
        deepKey,
        isLeafHandler
    );
    return resultList;
}

/**
 * 深度filter
 * @param {string} deepKey 递归的key
 * @param {function} [isLeafHandler] 判断是否为叶子节点的函数(true:直接调用回调,false:根据情况继续递归)
 * @returns {[any]} 返回通过handler返回值组合的数组
 */
export function filterDeep(
    handler,
    deepKey = "children",
    isLeafHandler = null
) {
    const resultList = [];
    this.kqEachDeep(
        (data) => {
            const res = handler(data);
            if (res !== false) {
                resultList.push(data);
            }
        },
        deepKey,
        isLeafHandler
    );
    return resultList;
}

/**
 * 排序
 * @desc 此排序不会更改源数组
 * @param {function} sortHandler 排序回调
 * @returns {[any]} 排序后的数据
 */
export function sort(sortHandler) {
    return _.clone(this).sort(sortHandler);
}
