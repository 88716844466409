<script>
/**
 * @description 详情-流程混入
 * @author tonny
 * @date 2021-12-08
 */
</script>

<script>
import _ from "lodash";
const SUPER = "KQ_PROCESS_MIXIN";

export default {
    SUPER,
    props: {
        /** 功能点号 */
        fid: [String, Number],
        /** 经历号 */
        rid: { type: [String, Number], default: 0 },
        /** 经历所属人员id */
        fuid: [String, Number],
    },
    data() {
        return {
            /** 流程加载器 */
            processData_: kq.Request.mixin({
                vm: this,
                modules: ["process"],
            }),
            /** 下一个流程人员列表 */
            nextUserList: [],
            /** 流程号 */
            pid: null,
            /** 流程审核结果码表 */
            processSelectList: [],
            /** 审核加载状态 */
            reviewLoading: false,
            /** 下一个人员id */
            nextUid: null,
            /** 流程历史信息表 */
            processHistoryList: [],
            /** 流程节点信息表 */
            processZidList: [],
            /** 流程是否只读 */
            processReadonly: false,
            /** 流程当前审核节点 */
            processCurrentZid: null,
            /** 流程下一个审核节点 */
            processNextZid: null,
            /** 流程状态(start:开始(11), ing:进行中(12), end:结束(13)) */
            processStat: null,
            /** 经历号 */
            rid_: null,
        };
    },
    computed: {
        /**
         * 是否含有流程
         * @see this.pid
         * @returns {boolean} true:有流程
         */
        hasProcess() {
            return Boolean(this.pid);
        },
        /**
         * 审核结果对象
         * @see 存储着pass,nopass,reject的结果值
         * @see this.processSelectList
         * @returns {object}
         * @example {pass<object>: 通过值, nopass<object>: 不通过值, reject<object>: 驳回值}
         */
        reviewResults() {
            let result = {};
            if (!_.isEmpty(this.processSelectList)) {
                this.processSelectList.forEach(({ value, label, zlabel }) => {
                    /** 结果key(存储result中) */
                    let key;
                    switch (zlabel) {
                        case "SV_PASS":
                            key = "pass";
                            break;
                        case "SV_NOPASS":
                            key = "nopass";
                            break;
                        default:
                            key = "reject";
                            break;
                    }
                    result[key] = { sid: value, svalue: label };
                });
            }
        },
    },
    watch: {
        /**
         * @see 1.重置流程状态
         */
        rid_(rid) {
            if (!rid || this.isEqual__(rid, 0)) {
                this.resetProcess();
            }
        },
        /**
         * @see 1.重置流程状态
         */
        rid(rid) {
            if (!rid || this.isEqual__(rid, 0)) {
                this.resetProcess();
            }
        },
    },
    methods: {
        /**
         * 清空操作(重写)
         * @description 附加清空流程号与人员表
         * @returns void
         */
        clear(...params) {
            this.$super(SUPER).clear(...params);
            this.pid = null;
            this.nextUserList = [];
        },
        /**
         * 流程初始化
         * @see created调用,初始化流程参数
         * @returns {Promise<void>} 失败抛错
         */
        async processInit(rid) {
            rid = Number(rid) || this.rid_ || this.rid || 0;
            // 获取流程信息
            const { pid, readonly, currentZid, nextZid, stat } =
                await this.processData_
                    .getProcessInfo({
                        vid: this.fvid || this.vid,
                        fuid: this.fuid || this.uid,
                        rid,
                        fid: this.fid,
                    })
                    .catch((err) => {
                        this.errHand__(err);
                        throw err;
                    });
            this.pid = pid;
            if (pid) {
                // 获取审核字段码表信息
                this.processSelectList = await this.processData_
                    .getSelectList({ zlabel: "S_ISPASS" })
                    .catch((err) => {
                        this.errHand__(err);
                        throw err;
                    });
                this.processReadonly =
                    (!this.isEqual__(this.rid, 0) && readonly) ||
                    stat != "start";
                this.processCurrentZid = currentZid;
                this.processNextZid = nextZid;
                this.processStat = stat;
            }
            return { pid, readonly, currentZid, nextZid, stat };
        },
        /**
         * 初始化重置流程
         * @see 无流程时调用
         * @returns void
         */
        resetProcess() {
            this.processReadonly = false;
            this.pid = null;
            this.processCurrentZid = null;
            this.processNextZid = null;
            this.nextUserList = [];
            this.nextUid = null;
        },
        /**
         * 审核通过
         * @param {number} [nextUid] 下一个人员id(如果最后一个流程,传空)
         * @returns {Promise<void>} 失败抛错
         */
        pass(nextUid) {
            return this._processCommit("pass", {
                ...this.reviewResults.pass,
                nextUid,
            });
        },
        /**
         * 审核未通过
         * @returns {Promise<void>} 失败抛错
         */
        nopass() {
            return this._processCommit("nopass", this.reviewResults.nopass);
        },
        /**
         * 审核驳回
         * @returns {Promise<void>} 失败抛错
         */
        reject() {
            return this._processCommit("reject", this.reviewResults.reject);
        },
        /**
         * 提交流程
         * @see 首次提交
         * @returns {Promise<void>} 失败抛错
         */
        async commitProcess(nextUid) {
            if (_.isPlainObject(nextUid)) {
                nextUid = nextUid.uid;
            }
            await this.processData_.commitProcess({ nextUid }).catch((err) => {
                this.errHand__(err);
                throw err;
            });
            return this.processInit(this.rid_);
        },
        /**
         * 审核提交
         * @see 自动设置加载状态
         * @param {string} methodName 方法名称
         * @param {object} param 使用参数
         * @returns {Promise<void>} 失败抛错
         */
        async _processCommit(methodName, param) {
            this.reviewLoading = true;
            await this.processData_[methodName](param).catch((err) => {
                this.reviewLoading = false;
                this.errHand__(err);
                throw err;
            });
            this.reviewLoading = false;
            this.success__ = "审核成功~";
        },
        /**
         * 获得下一个流程的人员列表
         * @see 自动设置加载状态
         * @returns {Promise<void>} 失败抛错
         */
        async getNextProcessUserList() {
            if (_.isEmpty(this.nextUserList)) {
                this.nextUserListLoading = true;
                const nextUserList = await this.processData_
                    .getProcessUserList()
                    .catch((err) => {
                        this.errHand__(err);
                        this.nextUserListLoading = false;
                        throw err;
                    });
                if (
                    _.isEmpty(nextUserList.filter((user) => !_.isEmpty(user)))
                ) {
                    this.nextUserListLoading = false;
                    throw new kq.Err({
                        type: "CONFIG",
                        title: "流程出错",
                        message: "未匹配到审核人员,请确认流程配置是否正确~",
                        current: nextUserList,
                        from: `${this.$options.name}.getNextProcessUserList`,
                    });
                } else {
                    this.nextUserList = nextUserList;
                }
                this.nextUserListLoading = false;
            }
        },
    },
};
</script>
