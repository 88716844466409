<script>
/**
 * @description 低码-组件公共混入
 * @author tonny
 * @date 2022-05-26
 */
</script>

<script>
import _ from "lodash";
import { getValue } from "./Tools";

export default {
    mixins: [kq.DataMixin],
    props: {
        /** 数据源 */
        fromDataList: Array,
        /** 是否为演示模式 */
        isDemo: Boolean,
        /** 功能点号 */
        fid: [String, Number],
        /** 应用号 */
        appid: [String, Number],
        /** 数据权限 */
        dataAuth: [String, Number],
    },
    data() {
        return {
            /** 打开模式字段key */
            openModeZid: "actionType",
            /** 请求对象 */
            request: kq.Request.mixin({ vm: this, modules: ["app", "field"] }),
            /** 加载状态 */
            loading: false,
        };
    },
    computed: {
        hasClickMode() {
            return !this.isEmpty__(this.getValue(this.openModeZid));
        },
    },
    // created() {
    //     this.refresh();
    // },
    methods: {
        /**
         * 获取配置值
         * @param {number} zid 字段号
         * @returns {any} 成功返回值,失败返回void
         */
        getValue(zid) {
            return getValue(zid, this.fromDataList);
        },
        /**
         * 请求api数据
         * @param {object} config 配置
         * @returns {Promise<*>} 成功返回数据, 失败抛错
         */
        requestApiData({
            apiRoute,
            apiConvert,
            apiParam = [],
            apiParamConvert,
        }) {
            return new Promise((resolve, reject) => {
                let params = {};
                (apiParam || []).forEach((value) => {
                    params[value] = this[value];
                });
                if (apiParamConvert) {
                    try {
                        let paramConvert;
                        eval(`paramConvert = ${apiParamConvert}`);
                        if (_.isFunction(paramConvert)) {
                            params = paramConvert.call(this, params);
                        }
                    } catch (error) {
                        console.error(error);
                        reject(
                            new kq.Err({
                                title: "转换错误",
                                message: `当前参数转换函数可能不是一个标准的函数,请联系管理员,详细信息:${error.message}`,
                                type: "DATA",
                                current: apiParamConvert,
                                from: "kq.Lowcode.ComponentMixin",
                            })
                        );
                    }
                }
                this.request
                    .request({ url: apiRoute, params })
                    .then((datas) => {
                        if (apiConvert) {
                            try {
                                let convert;
                                eval(`convert = ${apiConvert}`);
                                if (_.isFunction(convert)) {
                                    datas = convert.call(this, datas);
                                    resolve(datas);
                                } else {
                                    resolve(datas);
                                }
                            } catch (error) {
                                console.error(error);
                                reject(
                                    new kq.Err({
                                        title: "转换错误",
                                        message: `当前参数转换函数可能不是一个标准的函数,请联系管理员,详细信息:${error.message}`,
                                        type: "DATA",
                                        current: apiParamConvert,
                                        from: "kq.Lowcode.ComponentMixin",
                                    })
                                );
                            }
                        } else {
                            resolve(datas);
                        }
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        /**
         * 设置数据
         * @description 设置预设数据的功能函数,适用于:接口获取全部数据,接口获取单项数据,功能点获取单项数据(列表页信息与列表页数量)
         * @param {object} params
         * 数据类型-------------------------------------------------------------------------------------------------------------------
         * @param {string} [params.dataTypeZid="type"] 数据类型配置的字段号
         * @param {string} [params.allValue="all"] 数据类型为所有数据的svalue
         * @param {function} [allHandler] 所有数据(api数据类型)的请求完成回调(data<[any]>:转换后的数据)
         * 请求类型-------------------------------------------------------------------------------------------------------------------
         * @param {string} [params.requestType="all"] 请求类型为一次性请求 times为分次请求
         * 数据来源-------------------------------------------------------------------------------------------------------------------
         * @param {string} [params.dataFromZid="from"] 数据来源字段号
         * @param {string} [params.functionValue="function"] 数据来源为功能点时的svalue
         * @param {string} [params.apiValue="api"] 数据来源为api数据的svalue
         * @param {function} [apiHandler] 单项数据-接口请求完成回调(value<any>:单项数据-数据值, item<object>:单项数据配置对象),
         * 数据值类型-------------------------------------------------------------------------------------------------------------------
         * @param {string} [params.typeValueZid="valueType"] 数据值类型字段号
         * @param {string} [params.functionListTotalValue="list-total"] 数据值类型为列表页总数的svalue
         * @param {function} [functionListTotalHandler] 数据类型为列表页总数-单项数据请求成功回调
         * 接口-------------------------------------------------------------------------------------------------------------------
         * @param {string} [params.apiRouteZid="apiRoute"] 接口路由字段号
         * @param {string} [params.apiConvertZid="apiConvert"] 接口转换函数字段号
         * @param {string} [params.apiParamZid="apiParam"] 接口参数字段号
         * @param {string} [params.apiParamConvertZid="apiParamConvert"] 接口参数转换字段号
         * -------------------------------------------------------------------------------------------------------------------
         * @param {string} [params.fidZid="fid"] 配置功能点号(取值)字段号
         * @param {[object]} fromDataList 数据源参数配置表
         * @param {function} [beforeSuccess] 所有请求成功前钩子回调
         * @param {function} [afterSuccess] 所有请求成功后的钩子回调
         */
        _setData({
            allValue = "all",
            apiValue = "api",
            lazyFidList = [],
            functionValue = "function",
            functionListTotalValue = "list-total",
            functionListInfoValue = "list-info",
            typeValueZid = "valueType",
            apiRouteZid = "apiRoute",
            apiConvertZid = "apiConvert",
            apiParamZid = "apiParam",
            apiParamConvertZid = "apiParamConvert",
            dataFromZid = "from",
            dataTypeZid = "type",
            fidZid = "fid",
            fromDataList,
            allHandler,
            apiHandler,
            functionListTotalHandler,
            beforeSuccess,
            afterSuccess,
            functionListInfoHandler,
            functionListCurrentPage = 1,
            functionListPageSize = 20,
            functionListFilterList = [],
        }) {
            return new Promise((resolve, reject) => {
                const config = this.getValue();
                fromDataList = fromDataList || this.fromDataList;
                if (!this.isEmpty__(fromDataList) && !this.isDemo) {
                    this.loading = true;
                    /** 接口对象列表 */
                    const apiList = [];
                    /** 功能点数目列表 */
                    const fidCountList = [];
                    /** 是否中断 */
                    const isBreak = fromDataList.some(({ zid }) => {
                        /** 当前配置 */
                        const currentConfig = config[zid];
                        /** 数据类型 */
                        const type = currentConfig[dataTypeZid],
                            /** 数据源 */
                            from = currentConfig[dataFromZid],
                            /** api路由 */
                            apiRoute = currentConfig[apiRouteZid],
                            /** api转换器 */
                            apiConvert = currentConfig[apiConvertZid],
                            /** api入参 */
                            apiParam = currentConfig[apiParamZid],
                            /** api入参转换 */
                            apiParamConvert = currentConfig[apiParamConvertZid];
                        /** 设置全部值 */
                        if (type === allValue) {
                            if (currentConfig.requestType == "times") {
                                const requestList = lazyFidList.map((param) => {
                                    return this.request.getFunctionDataCount({
                                        fidList: param,
                                    });
                                });
                                Promise.all(requestList)
                                    .then((data) => {
                                        beforeSuccess && beforeSuccess();
                                        allHandler && allHandler(data);
                                        afterSuccess && afterSuccess();
                                        this.loading = false;
                                        resolve(data);
                                    })
                                    .catch((err) => {
                                        this.loading = false;
                                        this.errHand__(err);
                                        reject(err);
                                    });
                            } else {
                                this.requestApiData({
                                    apiRoute,
                                    apiConvert,
                                    apiParam,
                                    apiParamConvert,
                                })
                                    .then((data) => {
                                        if (data) {
                                            beforeSuccess && beforeSuccess();
                                            allHandler && allHandler(data);
                                            afterSuccess && afterSuccess();
                                            this.loading = false;
                                            resolve(data);
                                        } else {
                                            this.loading = false;
                                            this.error__ =
                                                "当前接口未请求到数据~";
                                            reject(this.error__);
                                        }
                                    })
                                    .catch((err) => {
                                        this.loading = false;
                                        this.errHand__(err);
                                        reject(err);
                                    });
                            }

                            return true;
                        } else {
                            // api获取值
                            if (from === apiValue) {
                                apiList = push({
                                    ...currentConfig,
                                    method: () =>
                                        this.requestApiData({
                                            apiRoute,
                                            apiConvert,
                                            apiParam,
                                            apiParamConvert,
                                        }),
                                });
                            }
                            // 功能点-列表页数值
                            else if (
                                from === functionValue &&
                                currentConfig[typeValueZid] ===
                                    functionListTotalValue
                            ) {
                                const fid = currentConfig[fidZid];
                                if (fid) {
                                    fidCountList.push({
                                        ...currentConfig,
                                        fid,
                                        id: zid,
                                    });
                                } else {
                                    console.warn(
                                        "功能点数据源未查询到功能点号值"
                                    );
                                }
                            }
                            // 功能点-列表页信息
                            else if (
                                from === functionValue &&
                                currentConfig[typeValueZid] ===
                                    functionListInfoValue
                            ) {
                                const fid = currentConfig[fidZid];
                                if (fid) {
                                    this.getTableList({
                                        fid,
                                        currentPage: functionListCurrentPage,
                                        pageSize: functionListPageSize,
                                        filterList: functionListFilterList,
                                    })
                                        .then((data) => {
                                            if (data.dataList) {
                                                beforeSuccess &&
                                                    beforeSuccess();
                                                functionListInfoHandler &&
                                                    functionListInfoHandler(
                                                        data
                                                    );
                                                afterSuccess && afterSuccess();
                                                this.loading = false;
                                                resolve(data);
                                            } else {
                                                this.loading = false;
                                                this.error__ =
                                                    "当前接口未请求到数据~";
                                                reject(this.error__);
                                            }
                                        })
                                        .catch((err) => {
                                            this.errHand__(err);
                                            this.loading = false;
                                            reject(err);
                                        });
                                    return true;
                                } else {
                                    console.warn(
                                        "功能点数据源未查询到功能点号值"
                                    );
                                }
                            }
                        }
                    });
                    if (isBreak) {
                        return;
                    }
                    beforeSuccess && beforeSuccess();
                    // 执行接下来的异步操作
                    (async () => {
                        // 当前存在接口列表:进行接口逻辑请求
                        if (!this.isEmpty__(apiList)) {
                            const apiDataList = await Promise.all(
                                apiList.map(({ method }) => method())
                            ).catch((err) => {
                                this.errHand__(err);
                                this.loading = false;
                                throw err;
                            });
                            apiDataList.forEach((value, index) => {
                                apiHandler && apiHandler(value, apiList[index]);
                            });
                        }
                        // 当前存在功能列表:处理功能列表请求
                        if (!this.isEmpty__(fidCountList)) {
                            const fidCounts = await this.request
                                .getFunctionDataCount({
                                    fidList: fidCountList.map(({ fid }) => fid),
                                })
                                .catch((err) => {
                                    this.errHand__(err);
                                    this.loading = false;
                                    throw err;
                                });
                            fidCountList.forEach((item) => {
                                functionListTotalHandler &&
                                    functionListTotalHandler(
                                        fidCounts[item.fid],
                                        item
                                    );
                            });
                        }
                        afterSuccess && afterSuccess();
                        this.loading = false;
                    })()
                        .then(resolve)
                        .catch(reject);
                }
            });
        },
        /**
         * @desc 获取列表页数据s
         * @param {string} fid 功能点号
         * @param {number} currentPage 当前页数
         * @param {number} pageSize 每一页条数
         * @param {Array} filterList 筛选条件
         */
        async getTableList({
            fid,
            currentPage = 1,
            pageSize = 20,
            filterList = [],
        }) {
            const [headerList, { dataList }] = await Promise.all([
                this.request.getFieldList({
                    fid: this.fid,
                    isRowset: true,
                }),
                this.request.getList({
                    fid: this.fid,
                    currentPage,
                    pageSize,
                    filterList,
                }),
            ]).catch((err) => {
                throw err;
            });
            return { headerList, dataList };
        },
        /*
         * 打开应用|功能点
         * @returns void
         */
        open({ fid }) {
            return new Promise((resolve, reject) => {
                if (!this.isDemo) {
                    const openMode = this.getValue(this.openModeZid);
                    const open = window.kqConfig?.open;
                    if (openMode === "function" && _.isFunction(open)) {
                        open({ fid });
                    } else {
                        reject(
                            new kq.Err({
                                title: "温馨提示",
                                message:
                                    "此处未设置穿透，如有需要请联系系统管理员",
                                from: "kq.Lowcode.open",
                                type: false,
                            })
                        );
                    }
                } else {
                    reject(
                        new kq.Err({
                            message: "展示组件不支持穿透操作！",
                            from: "kq.Lowcode.open",
                            type: false,
                        })
                    );
                }
            });
        },
    },
};
</script>
