/**
 * @description 仓库模块基类
 * @desc 所有仓库模块都要继承该类
 * @author tonny
 * @date 2022-08-14
 */

import Tools from "../Utils/tools";
import moment from "moment";

/**
 * 仓库模块基类
 * @desc 实例化后提供了vuex需要的数据结构
 * @desc 方便针对模块的重写与扩展
 * @class
 * @param {object} options 构造配置对象
 * @param {boolean} [options.namespaced=true] 是否使用模块命名
 * @param {boolean} [options.watch] 是否启用观察模式(仅支持commit与dispatch的观察模式)
 * @example new kq.Module({watch: true}) //全局启用观察模式
 * @example new kq.Module({watch: {commit: true}}) // 仅启用commit观察模式
 */
export default class StoreModule {
    constructor({ namespaced = true, watch } = {}) {
        /** 仓库存储对象 */
        this.state = this.mapState();
        /** 命名空间 */
        this.namespaced = namespaced;
        this._watch = watch;
    }
    /**
     * 注入配置项
     * @pulic
     * @desc 外部调用注入配置项以方便此处全局调用
     * @param {object} options
     * @param {object} options.store 仓库全局变量
     * @param {object} options.modules 全部模块
     * @returns void
     */
    injectOptions({ store, modules, name }) {
        this.store = store;
        this.modules = modules;
        this.moduleName = name;
    }
    /**
     * 预置state变量
     * @desc 需要通过重写来重新设置变量
     * @virtucal
     * @returns {object}
     */
    mapState() {
        throw new kq.Err({
            title: "纯虚函数重写错误",
            message: "当前仓库模块缺少state仓库存储对象,请重写mapState函数",
            from: "kq.StoreModule",
            type: "CODE",
        });
    }
    /**
     * 观察Mutations
     * @desc 替换vue.devtools的观察失效
     * @param {object} param0 参数对象
     * @param {string} param0.methodName 调用仓库方法名称
     * @param {object} param0.oldState 旧的仓库存储对象
     * @param {object} param0.newState 变更后的仓库存储对象
     * @param {object} param0.time 开始调用的时间
     * @returns void
     */
    watchMutations({ methodName, oldState, newState, payloads, time }) {
        console.blue(
            `kq.Store.commit.${methodName}  >>>>>>>>> [time]: ${moment(
                time
            ).format("HH:mm:ss.SSS")}`
        );
        console.table({
            method: methodName,
            oldState,
            newState,
            payloads,
        });
    }
    /**
     * 观察Actions
     * @desc 替换vue.devtools的观察失效
     * @param {object} param0 参数对象
     * @param {string} param0.methodName 调用仓库方法名称
     * @param {object} param0.oldState 旧的仓库存储对象
     * @param {object} param0.newState 变更后的仓库存储对象
     * @param {object} param0.beforeTime 开始调用的时间
     * @param {date} param0.afterTime 结束调用的事件
     * @returns void
     */
    watchActions({
        methodName,
        oldState,
        newState,
        payloads,
        beforeTime,
        afterTime,
    }) {
        console.orange(
            `kq.Store.dispatch.${methodName}  >>>>>>>>> [beforeTime]: ${moment(
                beforeTime
            ).format("HH:mm:ss.SSS")} [afterTime]: ${moment(afterTime).format(
                "HH:mm:ss.SSS"
            )}`
        );
        console.table({
            method: methodName,
            oldState,
            newState,
            payloads,
        });
    }
    /**
     * 监听打印
     * @desc 节流函数,将输出按照开启式时间进行排序后输出
     * @param {string} key 监听方法名称
     * @param {object} params 参数表
     */
    _logWatch(key, params) {
        if (!this._watchLogList) {
            this._watchLogList = [];
        }
        this._watchLogList.push({ key, params });
        clearTimeout(this._watchLogTimer);
        this._watchLogTimer = setTimeout(() => {
            this._watchLogList
                .sort((prev, next) => {
                    return (
                        prev.params.time.getTime() - next.params.time.getTime()
                    );
                })
                .forEach((log) => {
                    this[log.key](log.params);
                });
            this._watchLogList = [];
            clearTimeout(this._watchLogTimer);
            this._watchLogTimer = null;
        }, 200);
    }
    /**
     * vuex的mutations对象
     * @desc 计算属性
     * @returns {object}
     */
    get mutations() {
        const mutations = this._getVuexObject("mutation");
        // 监听注入
        if (
            this._watch === true ||
            _.get(this._watch, "mutations") ||
            _.get(this._watch, "commit")
        ) {
            const results = {};
            _.forEach(mutations, (mutation, key) => {
                results[key] = (...params) => {
                    const [state, payloads] = params;
                    const oldState = _.cloneDeep(state);
                    const time = new Date();
                    mutation(...params);
                    this._logWatch("watchMutations", {
                        methodName: key,
                        newState: _.cloneDeep(state),
                        oldState,
                        payloads,
                        time,
                    });
                };
            });
            return results;
        }
        return mutations;
    }
    /**
     * vuex的actions对象
     * @desc 计算属性
     * @returns {object}
     */
    get actions() {
        const actions = this._getVuexObject("action");
        // 监听注入
        if (
            this._watch === true ||
            _.get(this._watch, "actions") ||
            _.get(this._watch, "dispatch")
        ) {
            const results = {};
            _.forEach(actions, (action, key) => {
                results[key] = (...params) =>
                    Tools.promise(async () => {
                        const [{ state }, payloads] = params;
                        const oldState = _.cloneDeep(state);
                        const beforeTime = new Date();
                        await action(...params).catch((err) => {
                            throw err;
                        });
                        const afterTime = new Date();
                        this._logWatch("watchActions", {
                            methodName: key,
                            newState: _.cloneDeep(state),
                            oldState,
                            payloads,
                            time: beforeTime,
                            beforeTime,
                            afterTime,
                        });
                    });
            });
            return results;
        }
        return actions;
    }
    /**
     * vuex的getters对象
     * @desc 计算属性
     * @returns {object}
     */
    get getters() {
        return this._getVuexObject("getter");
    }
    /**
     * vuex全局getters对象
     * @desc 该对象将自动被注入到全局中
     * @desc 请使用方法转发(即:不用直接在此处定义方法,通过转发已经存在getters,mutaions与actions方便复用)
     * @desc 如果配置指定namespaced为false则此方法不会被调用
     * @virtucal 虚函数,需要被重写后生效
     * @returns {object}
     */
    get globalGetters() {
        return {};
    }
    /**
     * vuex全局actions对象
     * @desc 该对象将自动被注入到全局中
     * @desc 请使用方法转发(即:不用直接在此处定义方法,通过转发已经存在getters,mutaions与actions方便复用)
     * @desc 如果配置指定namespaced为false则此方法不会被调用
     * @virtucal 虚函数,需要被重写后生效
     * @returns {object}
     */
    get globalActions() {
        return {};
    }
    /**
     * vuex全局mutations对象
     * @desc 该对象将自动被注入到全局中
     * @desc 请使用方法转发(即:不用直接在此处定义方法,通过转发已经存在getters,mutaions与actions方便复用)
     * @desc 如果配置指定namespaced为false则此方法不会被调用
     * @virtucal 虚函数,需要被重写后生效
     * @returns {object}
     */
    get globalMutations() {
        return {};
    }
    /**
     * 是否为模块类本身
     * @desc 通过这个方法判断是否遍历到基类了
     * @returns true
     */
    _isBaseModule() {
        return true;
    }
    /**
     * 根据属性名称获取Vuex对象
     * @desc 从成员方法中直接拼接到对应的参数中
     * @private
     * @param {string} attr 属性名称
     * @returns {object}
     */
    _getVuexObject(attr) {
        const results = {};
        /** 原型链对象 */
        let proto = Object.getPrototypeOf(this);
        let methodList = Reflect.ownKeys(proto);
        // 循环进行原型链方法合并
        while (!methodList.includes("_isBaseModule")) {
            // 遍历成员方法
            methodList.forEach((methodName) => {
                // 名称匹配(比如:mutationGetapp|mGetapp)
                if (
                    new RegExp(`^${attr[0]}(${attr.slice(1)})?[A-Z]\\w+$`).test(
                        methodName
                    )
                ) {
                    const key = methodName.replace(
                        /^([a-z]+)(\w)(\w+)$/,
                        ($, $1, $2, $3) => $2.toLowerCase() + $3
                    );
                    if (Tools.isEmpty(results[key])) {
                        results[key] = this[methodName].bind(this);
                    }
                }
            });
            proto = Object.getPrototypeOf(proto);
            methodList = Reflect.ownKeys(proto);
        }
        return results;
    }
}
