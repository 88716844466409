/* eslint-disable no-alert */
export default {
    /** 输出提示是否禁用 */

    consoleDisabled: false,
    /**
     * 排除的错误捕捉
     * @desc 在此添加新的错误排除
     * @desc 只要存在一个返回true的错误,就会中断捕捉与上报
     * @param [function]
     */
    excludeList: [
        (error) => error == "ResizeObserver loop limit exceeded",
        (error) => error.message.includes("Function statements require a function name"),
    ],
    /**
     * 错误捕捉核心函数
     * @param {Error} error 错误Error对象
     * @param {object} [vm] 错误vm示例
     * @param {number} [line] 行号
     * @param {number} [colno] 列号
     * @param {Error} [error2] 错误Error对象(在window.onerror错误时被捕捉)
     * @returns void
     */
    catchError(error, vm, line, colno, error2) {
        if (!(error instanceof Error) && error2 instanceof Error) {
            error = error2;
        }
        if (!this.excludeList.some((exclude) => exclude(error))) {
            console.error(error);
            if (error.type !== false && !(error instanceof kq.Err)) {
                new kq.Err({
                    title: "系统提示",
                    type: vm?.$options ? "VUE" : "CODE",
                    message: `页面错误，请联系管理员或技术人员。`,
                    from: `Vue.${vm?.$options?.name}`,
                    current: error,
                    console: !this.consoleDisabled,
                });
            }
        }
    },
    installDisabled: true,
    /**
     * 安装方法
     * @param {object} Vue Vue对象
     * @param {object} [param={}] 入参对象
     * @param {[function]} [param.excludeList=[]] 被排序捕捉的错误方法合集
     * @param {boolean} [onerrorDisabled] window.onerror是否被禁用
     * @returns void
     */
    install(Vue, { excludeList = [], onerrorDisabled, consoleDisabled } = {}) {
        if (!_.isEmpty(excludeList)) {
            this.excludeList = [...excludeList, ...this.excludeList];
        }
        Vue.config.errorHandler = this.catchError.bind(this);
        this.consoleDisabled = consoleDisabled;
        if (!onerrorDisabled) {
            window.onerror = this.catchError.bind(this);
        }
        // 添加项目js脚本加载失败监测
        document.addEventListener(
            "error",
            (event) => {
                if (window._isTipScriptError) {
                    return false;
                }
                let target = event.target || event.srcElement;
                if (target instanceof HTMLScriptElement || target instanceof HTMLLinkElement) {
                    if (/static\/js\/[^.]+\.[^.]+.js$/.test($(target).attr("src"))) {
                        const isOk = confirm("服务端可能存在有更新，点击【确定】重新加载页面");
                        if (isOk) {
                            window._isTipScriptError = true;
                            window.location.reload();
                        }
                    }
                } else {
                    return false;
                }
            },
            true
        );
    },
};
