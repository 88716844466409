<script>
/**
 * @description 公共混入基类
 * @author tonny
 * @date 2021-05-17
 */
</script>

<script>
import $ from "jquery";
import _ from "lodash";
import Err from "../../Utils/Err";
import Vue from "vue";

export default {
    name: "kq-base",
    props: {
        // 挂载容器:将组件挂载到指定的容器中
        mountContainer: [String, Function, HTMLElement],
        // 父组件允许被挂载的最大容器数量
        maxMountContainer: {
            type: Number,
            default: 100,
        },
    },
    data() {
        return {
            errorVar__: "", // 错误信息
            errorInfo__: "", // 错误详细信息
            warn__: "", // 警告信息
            success__: "", // 成功信息
            info__: "", // 提示信息
            loading__: "", // 加载状态
            loadingMountContainer__: "body", // loading挂载容器
            mountSuccess__: null, // 是否挂载成功
            errorId_: null,
            warnId_: null,
            successId_: null,
            infoId_: null,
            loadingId_: false,
            ownParentDom: null,
            /** 预加载组件(uuid)列表(防止重复生成预加载组件) */
            preloadComponents_: {},
            /** 超类方法集合 */
            superMethods__: {},
            /** 本地挂载器(和$props.mountContainer进行sync双向绑定) */
            mountContainer_: null,
            errr: null,
        };
    },
    computed: {
        /**
         * query参数
         * @see this.$route
         * @see window.location
         * @returns {object} 参数对象{key: value}
         */
        query__() {
            return location.href.kqQuery();
        },
        /**
         * 错误变量
         * @desc 保证全局混入只被调用一次(使用watch会出现多次监听调用)
         * @desc 设置错误信息提示
         * @see this.errorVar__
         * @returns {any}
         */
        error__: {
            set(val) {
                this.errorVar__ = val;
                this.errHand__(val);
            },
            get() {
                return this.errorVar__;
            },
        },
    },
    watch: {
        /**
         * 观察挂载容器变量
         * @see 如果指定,则将当前组件节点挂载到指定dom容器
         * @returns void
         */
        mountContainer_: {
            handler(val) {
                if (!this.isEmpty__(val)) {
                    /** 容器dom */
                    let $dom;
                    this.isExist__(
                        () => {
                            if (_.isString(val)) {
                                $dom = $(val);
                            } else if (_.isElement(val)) {
                                $dom = $(val);
                            } else if (val instanceof $) {
                                $dom = val;
                            } else if (_.isFunction(val)) {
                                $dom = $(val());
                            }
                            return $dom.length;
                        },
                        () => {
                            if (
                                $dom.children(`.${this.$el.className}`).length <=
                                this.maxMountContainer
                            ) {
                                this.mountSuccess__ = true;
                                this.$emit("mountSuccess", $dom);
                                $(this.$el).appendTo($dom);
                            } else {
                                this.mountSuccess__ = false;
                            }
                        }
                    );
                } else {
                    $(this.$el).appendTo(this.ownParentDom);
                }
            },
            immediate: true,
        },
        errr: {
            handler() {
                console.log("watch errr...");
            },
            immediate: false,
        },
        /**
         * 警告观察
         * @returns void
         */
        warn__: {
            handler(val) {
                this.loading__ = false;
                this.warnHand__(val);
            },
            immediate: false,
        },
        /**
         * 信息观察
         * @returns void
         */
        info__: {
            handler(val) {
                this.loading__ = false;
                this.infoHand__(val);
            },
            immediate: false,
        },
        /**
         * 成功观察
         * @returns void
         */
        success__: {
            handler(val) {
                this.loading__ = false;
                this.successHand__(val);
            },
            immediate: false,
        },
        /**
         * 加载状态观察
         * @returns void
         */
        loading__: {
            handler(val) {
                this.loadingHand__(val);
            },
            immediate: false,
        },
    },
    created() {
        this.sync__("mountContainer");
    },
    mounted() {
        // 将当前的父元素记下来(用来恢复挂载状态)
        this.ownParentDom = $(this.$el).parent()[0];
    },
    methods: {
        /**
         * 变量是否全等
         * @param {*} args 多个变量
         * @returns {boolean} true:全等
         */
        isEqual__(...args) {
            return kq.Tools.isEqual(...args);
        },
        /**
         * 打开预渲染组件
         * @see 打开App.vue的预渲染组件
         * @param {object} props 组建的props属性
         * @param {string} props.component 组件的名称
         * @param {function} [props.update] 更新函数(每次变量被组件改变时都会调用,传递props对象与组件实例)
         * @returns void
         */
        openPreload__(props) {
            if (!this.$bus) {
                throw new Err({
                    title: "云库预渲染错误",
                    message: "全局广播对象(this.$bus)不存在,请先在main.js注册全局事件广播",
                    from: "kqBase.Preload__",
                });
            }
            if (!props || !props.component) {
                throw new Err({
                    title: "云库预渲染错误",
                    message: "openPreload__的参数不能为空",
                    from: "kqBase.Preload__",
                });
            }
            const item = this.preloadComponents_[props.component];
            if (!item) {
                props.update = this._preloadUpdate(props);
            } else {
                props.uuid = item.item.uuid;
            }
            this.$bus.$emit("kq-preload", props);
        },
        /**
         * 预加载更新函数
         * @see 为预加载组件预制更新机制,更新的同时追加记录
         * @see 防止重复生成预加载组件
         * @returns void
         */
        _preloadUpdate({ component, update }) {
            return (vm, item) => {
                let attr = this.preloadComponents_[component];
                if (!attr) {
                    attr = { vm, item };
                    this.$set(this.preloadComponents_, component, attr);
                }
                update && update(vm, item);
            };
        },
        /**
         * ref获取
         * @see ref变量获取成功,调用successCb回调函数,传递ref变量
         * @see 获取失败,提示警告,调用failCb回调函数
         * @param {string} ref this.$refs的key值
         * @param {function} successCb 成功回调函数
         * @param {function} failCb 失败回调函数
         * @param {number} maxCount 最大次数
         * @returns {Promise<object>} 失败将不执行返回,成功返回ref对象
         */
        refHand__(ref, successCb, failCb, maxCount = 600) {
            return new Promise((resolve, reject) => {
                if (this.$refs[ref] !== undefined) {
                    successCb && successCb.call(this, this.$refs[ref]);
                    resolve(this.$refs[ref]);
                    return;
                }
                let maxCounts = maxCount;
                let timer = setInterval(() => {
                    if (maxCounts < 0) {
                        console.warn(`[Base.refHand]渲染超时,请确认是否正确渲染:${ref}!`);
                        failCb && failCb.call(this);
                        clearInterval(timer);
                    } else if (this.$refs[ref] !== undefined) {
                        successCb && successCb.call(this, this.$refs[ref]);
                        clearInterval(timer);
                        resolve(this.$refs[ref]);
                    }
                    maxCount--;
                }, 50);
            });
        },
        _getResultObject(target, argList) {
            let result = target;
            let i;
            for (i = 0; i < argList.length; i++) {
                const arg = argList[i];
                if (result[arg]) {
                    result = result[arg];
                } else {
                    break;
                }
            }
            if (i === argList.length) {
                return result;
            }
        },
        isExist__(options, handler, failHandler) {
            return new Promise((resolve, reject) => {
                let { success, fail, arg, result, maxCount, interval } = this._isExistAnsys(
                    options,
                    handler,
                    failHandler
                );
                // 开始间隔判断
                const timer = setInterval(() => {
                    let isOk = result;
                    let resultArg = arg;
                    // 判断:结果为执行函数
                    if (_.isFunction(result)) {
                        isOk = result();
                        resultArg = arg();
                    }
                    if (isOk) {
                        success && success(resultArg);
                        clearInterval(timer);
                        resolve(resultArg);
                    } else if (maxCount - 1 > 0) {
                        maxCount--;
                        return;
                    } else {
                        clearInterval(timer);
                        fail && fail(resultArg);
                        // 没有传处理参数 || 处理参数就是函数(说明只需要处理正确结果) || 处理参数里面不包含成功函数和失败函数(说明只在处理函数中指定了固定配置参数)
                        // 以上情况则抛出错误
                        if (!handler || !_.isFunction(handler) || (!success && !fail)) {
                            reject();
                        }
                    }
                }, interval);
            });
        },
        _isExistAnsys(options, handler, failHandler, isDelay = true) {
            let result, arg;
            if (_.isString(options)) {
                // 获取ref(用函数结果是因为需要动态判断,如果一下写死可能会导致判断一个在此处的固定值)
                if (["refs.", "ref."].some((dot) => options.includes(dot))) {
                    const list = options.split(".");
                    const refIndex = list.findIndex((str) => str === "ref" || str === "refs");
                    arg = () => this._getResultObject(this, ["$refs", ...list.slice(refIndex + 1)]);
                    result = arg;
                }
                // 获取this实例对象
                else if (["this."].some((dot) => options.includes(dot))) {
                    arg = () => this._getResultObject(this, options.split(".").slice(1));
                    result = arg;
                }
                // dom性质
                else if ([".", "#"].some((dot) => options[0] === dot)) {
                    arg = () => $(options);
                    result = () => $(options).length;
                }
            }
            // 函数: 动态判断执行结果
            else if (_.isFunction(options)) {
                arg = () => options();
                result = arg;
            }
            // Element: 动态判断dom结果
            else if (_.isElement(options)) {
                arg = () => $(options);
                result = () => $(options).length;
            }
            // jquery对象
            else if (options instanceof $) {
                console.warn(
                    "[isExist__]传入jquery对象可能会导致传入时对象始终不存在的情况,建议使用工厂函数"
                );
                arg = () => options;
                result = () => options.length;
            } else if (_.isArray(options)) {
                if (handler && handler.every === true) {
                    arg = () =>
                        options.map((option) => this._isExistAnsys(option, null, null, false).arg);
                    result = () => {
                        options.every(
                            (option) => this._isExistAnsys(option, null, null, false).arg
                        );
                    };
                } else {
                    arg = () => {
                        let temp;
                        options.some((option) => {
                            const { arg: args, result: results } = this._isExistAnsys(
                                option,
                                null,
                                null,
                                false
                            );
                            if (results) {
                                temp = args;
                            }
                        });
                        return temp;
                    };
                    result = () =>
                        options.some(
                            (option) => this._isExistAnsys(option, null, null, false).result
                        );
                }
                result = arg;
            }
            // 直接返回
            else {
                arg = options;
                result = arg;
            }
            // 最大判断次数,成功处理回调,失败处理回调,间隔时间
            let maxCount, success, fail, interval;
            // 第二个参数为对象,说明需要详细配置
            if (_.isPlainObject(handler)) {
                maxCount = handler.maxCount || 1000;
                success = handler.success;
                fail = handler.fail;
                interval = handler.interval || 10;
            }
            // 第二个参数为函数,进行默认配置
            else if (_.isFunction(handler)) {
                success = handler;
                fail = failHandler;
                maxCount = 1000;
                interval = 10;
            }
            // 参数传递出错
            else if (handler) {
                console.error(
                    `isExist__函数参数错误:第二个参数必须为Object类型或Function类型,得到的类型:${typeof handler}`
                );
            }
            // 需要延时请求结果->返回可以被处理的对象
            if (isDelay) {
                return { maxCount, success, fail, interval, arg, result };
            }
            // 不需要延时请求结果,直接返回结果即可
            return {
                arg: _.isFunction(arg) ? arg() : arg,
                result: _.isFunction(result) ? result() : result,
            };
        },
        /**
         * 消息格式化
         * @see 对消息提示参数进行格式化
         * @returns {object} {title:标题, message: 消息内容}
         */
        _messageFormat({ arg1, arg2, title: t }) {
            if (!this.isEmpty__(arg1)) {
                let title,
                    message,
                    other = {}; // 其他配置
                if (_.isPlainObject(arg1)) {
                    title = arg1.title || t;
                    message = arg1.message || arg1.msg || "";
                    other = arg1;
                } else if (_.isString(arg1)) {
                    if (arg2 && _.isString(arg2)) {
                        title = arg1;
                        message = arg2;
                    } else {
                        title = t;
                        message = arg1;
                    }
                } else if (arg1 instanceof Error) {
                    title = arg1.title || "错误提示";
                    message = arg1._msg || arg1.message;
                    other.info = arg1._info || arg1.message;
                }
                return { ...other, title, message };
            }
        },
        /**
         * 错误处理
         * @see error__变化时调用
         * @see 错误提示
         * @param {string|boolean} value 传递的错误值
         * @returns void
         */
        errorHand__(arg1, arg2) {
            this.loading__ = false;
            if (!this.isEmpty__(arg1)) {
                this.errorId_ && this.$kq.notify.close(this.errorId_);
                const options = this._messageFormat({
                    arg1,
                    arg2,
                    title: "错误提示",
                });
                if (options.message.length > 100) {
                    options.info = options.info || options.message;
                    options.message = `报错了~点我复制详情上报吧!`;
                }
                this.errorId_ = this.$kq.notify({
                    ...options,
                    type: "error",
                    duration: options.duration === undefined ? 10000 : options.duration,
                    info: options.info || options.message,
                    onClose: () => {
                        this.error__ = "";
                    },
                });
                if (process.env.NODE_ENV === "development") {
                    console.error(options.message);
                }
            } else {
                this.$kq.notify.close(this.errorId_);
                this.errorId_ = null;
            }
        },
        /**
         * 错误处理函数
         * @see 关闭加载状态,提示错误
         * @param {Error|object|string} err 错误处理参数
         * @returns void
         */
        errHand__(err) {
            if (err._code == 401) {
                this.warn__ = {
                    title: "温馨提示",
                    message: "登录超时，请重新登录",
                };
                return;
            }
            this.loading__ = false;
            this._setTipDebounce__("prevError__", err, this.errorHand__);
            if (!this.isEmpty__(err)) {
                console.error(err);
            }
        },
        /**
         * 防抖设置提示
         * @desc 区别于普通函数,当高频率提示相同的内容时,不会重复提示,不同内容会立刻提示
         * @desc 原理为第一次判断临时变量是否与上一次相同,打开计时器关闭后第二次调用
         * @param {string} arg 临时变量名
         * @param {object} params 入参
         * @param {function} handler 处理函数
         * @returns void
         */
        _setTipDebounce__(arg, params, handler) {
            const tempInfo = JSON.stringify(params);
            if (!this.isEqual__(this[arg], tempInfo) || this.isEmpty__(params)) {
                handler(params);
                this[arg] = tempInfo;
            } else if (!this[`${arg}Timer`]) {
                handler(params);
            }
            clearTimeout(this[`${arg}Timer`]);
            this[`${arg}Timer`] = setTimeout(() => {
                this[`${arg}Timer`] = null;
                this[arg] = null;
            }, 100);
        },
        /**
         * 错误处理函数(为了保持和DataMixin组件错误处理一致,在此处定义这个方法避免在非DataMixin中调用errHand_出现报错的可能)
         * @param {Error|object|string} err 错误处理参数
         * @param {Function} request 请求的回调函数,用来恢复请求
         * @returns void
         */
        errHand_(err, request) {
            this.errHand__(err);
        },
        /**
         * 成功处理
         * @see success__变化时调用
         * @see 成功提示
         * @param {string|boolean} value 传递的成功值
         * @returns void
         */
        successHand__: _.debounce(function (arg1, arg2) {
            this.loading__ = false;
            if (!this.isEmpty__(arg1)) {
                this.successId_ && this.$kq.notify.close(this.successId_);
                this.successId_ = this.$kq.notify({
                    ...this._messageFormat({
                        arg1,
                        arg2,
                        title: "成功提示",
                    }),
                    type: "success",
                    onClose: () => {
                        this.success__ = "";
                    },
                });
            } else {
                this.$kq.notify.close(this.successId_);
                this.successId_ = null;
            }
        }, 100),
        /**
         * 加载处理
         * @see loading__变化时调用
         * @see 加载提示
         * @param {string|boolean} value 传递的加载状态值
         * @returns void
         */
        loadingHand__(arg1) {
            if (arg1) {
                this.loadingId_ && this.$kq.loading.close(this.loadingId_);
                let options;
                if (_.isPlainObject(arg1)) {
                    options = {
                        ...arg1,
                        label: arg1.label || arg1.message || "",
                    };
                } else if (arg1 === true) {
                    options = {
                        label: "",
                    };
                } else if (_.isString(arg1)) {
                    options = {
                        label: arg1,
                    };
                } else {
                    console.error("loading__传递的参数!");
                }
                this.loadingId_ = this.$kq.loading({
                    ...options,
                    onClose() {
                        this.loadingId_ = false;
                    },
                });
            } else {
                this.$kq.loading.close(this.loadingId_);
                this.loading__ = false;
            }
        },
        /**
         * 信息处理
         * @see info__变化时调用
         * @see 信息提示
         * @param {string|boolean} value 传递的信息值
         * @returns void
         */
        infoHand__: _.debounce(function (arg1, arg2) {
            this.loading__ = false;
            if (!this.isEmpty__(arg1)) {
                this.infoId_ && this.$kq.notify.close(this.infoId_);
                this.infoId_ = this.$kq.notify({
                    ...this._messageFormat({ arg1, arg2, title: "信息提示" }),
                    type: "info",
                    onClose: () => {
                        this.info__ = "";
                    },
                });
            } else {
                this.$kq.notify.close(this.infoId_);
                this.infoId_ = null;
            }
        }, 100),
        /**
         * 警告处理
         * @see warn__变化时调用
         * @see 警告提示
         * @param {string|boolean} value 传递的警告值
         * @returns void
         */
        warnHand__: _.debounce(function (arg1, arg2) {
            console.log("warn", arg1, arg2);
            this.loading__ = false;
            if (!this.isEmpty__(arg1)) {
                if (this.warnId_) {
                    this.$kq.notify.close(this.warnId_);
                }
                console.warn();
                this._warnId = this.$kq.notify({
                    ...this._messageFormat({ arg1, arg2, title: "警告提示" }),
                    type: "warn",
                    onClose: () => {
                        this.warn__ = "";
                    },
                });
                if (process.env.NODE_ENV === "development") {
                    console.warn(this._messageFormat({ arg1, arg2, title: "警告提示" }).message);
                }
            } else {
                this.$kq.notify.close(this.warnId_);
                this.warnId_ = null;
            }
        }, 100),
        /**
         * 长度格式化
         * @see 对长度进行转换成统一的css长度单位
         * @param {number|string} 长度字符串
         * @returns {string} css长度单位
         */
        formatLength__(length) {
            let num = Number(length);
            if (Number.isNaN(num)) {
                return length;
            } else {
                return num + "px";
            }
        },
        /**
         * 数据是否为空
         * @see 多个参数的关系为或的关系,如果需要且,请调用isEmpty__
         * @param {*} targets 多个参数
         * @returns {boolean}
         */
        isEmptyMaybe__(...targets) {
            return kq.Tools.isEmptyMaybe(...targets);
        },
        /**
         * 数据是否为空
         * @see 多个参数的关系为且的关系,如果需要或,请调用isEmptyMaybe__
         * @param {*} targets 多个值判断
         * @returns {boolean}
         */
        isEmpty__(...targets) {
            return kq.Tools.isEmpty(...targets);
        },
        /**
         * 是否为数子
         * @see 多个参数的关系为且的关系,如果需要或,请调用isNumnerMaybe__
         * @returns {boolean}
         */
        isNumber__(...args) {
            return kq.Tools.isNumber(...args);
        },
        /**
         * 是否为数字
         * @see 多个参数的关系为或的关系,如果需要且,请调用isNumner__
         * @returns {boolean}
         */
        isNumberMaybe__(...args) {
            return kq.Tools.isNumberMaybe(...args);
        },
        /**
         * 获取继承属性
         * @see 用在属性传递(不用v-bind的原因是可能会造成子组件与主组件的属性重复,且对于维护来说容易混淆props)
         * @param {string} name 属性名称
         * @returns {object}
         */
        getExtendProps__(name, attrs) {
            if (!name || !_.isString(name)) {
                throw new kq.Err({
                    message: "获取props属性的组件名称(name)必须为非空字符串",
                    current: name,
                    from: `${this.$options.name}.methods.getPropsByAttrs`,
                });
            }
            attrs = attrs || this.$attrs;
            const result = {};
            if (!this.isEmpty__(this.$attrs)) {
                _.forEach(this.$attrs, (value, key) => {
                    // 符合匹配条件
                    if (RegExp(`^${name}`).test(key)) {
                        // 值转换,去除前缀(xxx-的xxx-, xxxX的xxx)
                        key = key.replace(
                            RegExp(`^${name}-?(\\w)(\\w+)`),
                            ($, $1, $2) => $1.toLowerCase() + $2
                        );
                        result[key] = value;
                    }
                });
            }
            return result;
        },
        /**
         * sync双向绑定
         * @see 通过$watch api
         * @param {string} from 源(指的是props中的属性)
         * @param {string} [to] 目标(指的是data中的属性),不传则默认在from上加_
         * @returns void
         */
        sync__(from, to, isDeep, convert) {
            if (_.isPlainObject(from)) {
                from = from.from;
                to = from.to;
                isDeep = from.isDeep;
                convert = from.convert;
            }
            to = to || from + "_";
            this.$watch(
                from,
                (newValue, old) => {
                    if (newValue !== undefined) {
                        this[to] = _.isFunction(convert)
                            ? convert(this._setSyncTo(newValue, old))
                            : this._setSyncTo(newValue, old);
                    }
                },
                {
                    immediate: true,
                    deep: isDeep === true ? true : false,
                }
            );
            this.$watch(
                to,
                (newValue, old) => {
                    this.$emit(`update:${from}`, this._setSyncFrom(newValue, old));
                },
                {
                    deep: !!isDeep,
                }
            );
        },
        /**
         * 设置sync双向绑定本地值
         * @param {any} value 更新的值
         * @param {any} old 旧的值
         * @returns {any}
         */
        _setSyncTo(value, old) {
            return value;
        },
        /**
         * 设置sync双向绑定传入值
         * @param {any} value 更新的值
         * @param {any} old 旧的值
         * @returns {any}
         */
        _setSyncFrom(value, old) {
            return value;
        },
        /**
         * 子组件方法转发
         * @see 将子组件所有的方法抽出做当前的组件方法
         * @see 方法中this指向的是子组件
         * @param {object} param0 参数表
         * @param {string|Vue} param0.ref 子组件vue实例|子组件ref的名称
         * @param {[string]} [param0.includes=[]] 包含的方法列表
         * @param {[string]} [param0.excludes=[]] 排除的方法列表
         * @param {string} [param0.repeatSign=_] 重复方法的附加标记
         * @returns void
         */
        refMethodForward__({ ref, includes = [], excludes = [], repeatSign = "_" }) {
            if (ref instanceof Vue) {
                this._refMethodForward__({
                    vm: ref,
                    includes,
                    excludes,
                    repeatSign,
                });
            }
            this.refHand__(
                ref,
                (vm) => {
                    this._refMethodForward__({
                        vm,
                        includes,
                        excludes,
                        repeatSign,
                    });
                },
                () => {
                    console.warn(
                        "子组件加载失败,请确保子组件正确渲染,这有可能导致调用子组件转发的方法失败~"
                    );
                }
            );
        },
        /**
         * 子组件方法转发
         * @see 将子组件所有的方法抽出做当前的组件方法
         * @param {object} param 参数列表
         * @param {Vue} param.vm 子组件vue实例
         * @param {[string]} [param.includes=[]] 包含的方法列表
         * @param {[string]} [param.excludes=[]] 排除的方法列表
         * @param {string} [param.repeatSign=_] 重复方法的附加标记
         * @returns {void}
         */
        _refMethodForward__({ vm, includes, excludes, repeatSign = "_" }) {
            _.forEach(vm.$options.methods, (method, name) => {
                // 排除BaseMixin函数,私有函数,函数名排除
                if (
                    /__?$/.test(name) ||
                    /^_/.test(name) ||
                    (!this.isEmpty__(excludes) && excludes.includes(name)) ||
                    (!this.isEmpty__(includes) && !includes.includes(name))
                ) {
                    return;
                }
                if (this[name]) {
                    console.warn(
                        `ref组件转发方法重名,请注意使用${name}${repeatSign}来调用子组件方法`
                    );
                    name = name + repeatSign;
                }
                this[name] = method.bind(vm);
            });
        },
    },
    install(Vue) {
        Vue.mixin(this);
    },
};
</script>
