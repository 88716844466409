<script>
/**
 * @description 滚动更改型混入
 * @see 仅kooci-lib内部封装
 * @see 抽象型混入
 * @see ScrollTab与ScrollNav公共混入
 * @author tonny
 * @date 2021-10-05
 */
</script>

<script>
import vModel from "../vModel/vModel.vue";
import Nest from "../Nest/Nest.vue";
import $ from "jquery";
import _ from "lodash";

export default {
    mixins: [vModel, Nest],
    props: {
        // 监测滚动的scroll-dom
        scrollDom: null,
        // 是否为浏览器滚动区
        windowScroll: Boolean,
        // 偏移:作为跳转位置的偏移量
        // 比如更新current后,默认是跳到对应item距离滚动区顶部的位置,加上offset=20的话,item跳转时会向下偏移20
        // 比如滚动条滚动自动切换current时,默认是滚动区中间的位置判断,加上offset=20的话,会在中间位置向下20px时进行切换
        offset: {
            type: [String, Number],
            default: "start",
            validator(value) {
                if (_.isString(value)) {
                    if (
                        _.isNaN(Number(value)) && // 非数字
                        !value.includes("%") && // 非百分比
                        !["vw", "vh"].some((s) => value.includes(s)) &&
                        !["start", "center", "end"].includes(value)
                    ) {
                        let err = new Error(
                            `[Scroll-TabMixin.props]参数错误!滚动基线(offset)类型错误!为非数字字符串时只能为百分比(如10%)或center,top,bottom,当前:${value}`
                        );
                        console.error(err);
                        return false;
                    }
                }
                return true;
            },
        },
        // 方向:垂直|水平
        direction: {
            type: String,
            default: "vertical",
            validator(val) {
                if (!["vertical", "hortical"].includes(val)) {
                    const error = new Error(
                        `[Scroll-Tab.props]参数错误!方向(direction)只能为垂直(vertical)或水平(hortical)方向,当前:${JSON.stringify(
                            val
                        )}`
                    );
                    console.error(error);
                    return false;
                }
                return true;
            },
        },
        // 滚动区样式名
        scrollCss: {
            type: String,
            default: "",
        },
    },
    data() {
        return {
            scroll: null, // 滚动区dom(jquery)
            oldCurrent: 0, // 旧的current(当前只用于激活change传参)
            isClickScrolling: false, // 是否用户正在点击菜单滚动(调用go函数或直接设置v-model:value赋值)
            isScrollbarScrolling: false, // 是否滚动条正在滚动(拖动滚动条滚动状态,为了防止拖动滚动条时设置current导致同时执行了go函数致使的跳转冲突)
            isOverStartVisible: false, // 内容是否超过开始可视区
            isOverEndVisible: false, // 内容是否超过结束可视区
            prevScrollPos: 0, // 上一步的滚动位置(用来判断滚动方向)
            isStartToEndScroll: null, // 是否是遵从开始到结束位置滚动
        };
    },
    computed: {
        /**
         * 滚动样式
         * @see this.direction
         * @returns {object}
         */
        scrollStyle() {
            let style = {
                display: "flex",
                alignItems: "stretch",
            };
            if (this.direction === "vertical") {
                style = {
                    ...style,
                    "overflow-y": "auto",
                    flexDirection: "column",
                };
            } else {
                style = {
                    ...style,
                    "overflow-x": "auto",
                    flexDirection: "row",
                };
            }
            return style;
        },
    },
    watch: {
        /**
         * 观察当前值
         * @see 在确定不是滚动条滚动跳转时自动调用go执行滚动切换动画
         * @see 激活切换change事件(currentIndex<number>:当前索引,oldIndex<number>:旧的索引)
         * @see 激活点击切换clickChange事件(currentIndex<number>:当前索引,oldIndex<number>:旧的索引)
         * @see 激活滚动条切换事件scrollbarChange(currentIndex<number>:当前索引,oldIndex<number>:旧的索引)
         * @returns void
         */
        current(val, old) {
            this.oldCurrent = old;
            this.$emit("change", val, old);
            if (!this.isScrollbarScrolling) {
                this.$emit("clickChange", val, old);
                if (!val) {
                    this.go(0);
                } else {
                    this.go(val);
                }
            } else {
                this.$emit("scrollbarChange", this.current, this.oldCurrent);
            }
        },
        /**
         * 观察滚动条滚动态
         * @see 滚动渲染完成后自动关闭状态,和current切换滚动区分
         * @see 避免设置current调用go函数失效
         * @returns void
         */
        isScrollbarScrolling(val) {
            if (val) {
                this.$nextTick(() => {
                    this.isScrollbarScrolling = false;
                });
            }
        },
        /**
         * 是否超出可视区监听
         * @see 激活update:isOverStartVisible信号,传递值
         * @returns void
         */
        isOverStartVisible(val) {
            this.$emit("update:isOverStartVisible", val);
        },
        /**
         * 是否超出可视区监听
         * @see 激活update:isOverEndVisible信号,传递值
         * @returns void
         */
        isOverEndVisible(val) {
            this.$emit("update:isOverEndVisible", val);
        },
    },
    mounted() {
        // 参数验证
        this._paramValidator(($scroll) => {
            // 滚动事件
            $scroll.on("scroll", this._scrollEvent);
            this.$nextTick(() => {
                this._visibleJudge();
            });
        });
    },
    methods: {
        /**
         * 跳转指定位置
         * @see 该函数只会执行跳转动画,不会设置current切换
         * @see 需要完整跳转直接更改this.current或父组件的v-model值即可
         * @see 滚动动画结束后激活changed事件(currentIndex<number>:当前索引,oldIndex<number>:旧的索引)
         * @param {string|number} key string:表示key值, number:表示item索引
         * @param {function} afterHandler 执行动画完成后调用的函数
         * @returns {void|object} key存在itemList中, 则返回对应可以的item对象,否则返回undefined
         */
        go(key, afterHandler) {
            let item;
            if (_.isNumber(key)) {
                item = this.itemList[key];
            } else {
                this.itemList.find(({ key: itemKey }) => itemKey === key);
            }
            if (item) {
                this.isClickScrolling = true;
                // 滚动关键字, 位置关键字
                let scrollKey, positionKey;
                if (this.direction === "vertical") {
                    scrollKey = "scrollTop";
                    positionKey = "top";
                } else {
                    scrollKey = "scrollLeft";
                    positionKey = "left";
                }
                /** 滚动的容器 */
                let $scrollDom,
                    /** 对应元素距离父元素的距离 */
                    offset;
                if (this.windowScroll) {
                    // $(window)不支持scrollTop动画
                    $scrollDom = $("html,body");
                    // 距离浏览器顶部
                    offset =
                        item.component.$el.getBoundingClientRect()[positionKey];
                } else {
                    $scrollDom = this.scroll;
                    // 距离父容器
                    offset = $(item.component.$el).position()[positionKey];
                }
                $scrollDom.animate(
                    {
                        [scrollKey]:
                            this.scroll[scrollKey]() +
                            offset -
                            this._getOffsetSize(),
                    },
                    "fast",
                    () => {
                        this.isClickScrolling = false;
                        this.$emit("changed", this.current, this.oldCurrent);
                        afterHandler && afterHandler(this.current);
                    }
                );
                return item;
            }
        },
        /**
         * 滚动一整屏
         * @see 一整屏指的是一个滚动区长度
         * @see 仅仅滚动页面,不存在切换current
         * @returns void
         */
        goFullScreen(isStartToEnd = true) {
            let scrollKey, scrollSize, scrollPos;
            if (this.direction === "vertical") {
                scrollKey = "scrollTop";
                scrollSize = this.scroll.height();
                scrollPos = this.scroll.scrollTop();
            } else {
                scrollKey = "scrollLeft";
                scrollSize = this.scroll.width();
                scrollPos = this.scroll.scrollLeft();
            }
            this.scroll.animate(
                {
                    [scrollKey]: isStartToEnd
                        ? scrollPos - scrollSize
                        : scrollPos + scrollSize,
                },
                "fast"
            );
        },
        /**
         * 更新函数(重写)
         * @see Nest数据更新调用
         * @see 附加可视区判断
         * @returns void
         */
        update() {
            // 原逻辑
            Nest.methods.update.call(this);
            this._getScrollDom((scroll) => {
                this.scroll = $(scroll);
                // 可视区判断
                this._visibleJudge();
            });
        },
        /**
         * 获取滚动区的dom
         * @see 可以重新此函数自定义滚动区
         * @param {function} sucess 获取成功回调
         * @returns void
         */
        _getScrollDom(success, error) {
            if (this.windowScroll) {
                this.scroll = $(window);
                success(this.scroll);
            } else if (!this.scrollDom) {
                this.refHand__("scroll", (scroll) => {
                    this.scroll = $(scroll);
                    success(scroll, error);
                });
            } else {
                this.scroll = $(this.scrollDom);
                success(this.scroll);
            }
        },
        /**
         * 可视区判断
         * @see 根据内容是否超过滚动区来判断
         * @returns void
         */
        _visibleJudge() {
            this._getScrollDom(() => {
                if (!_.isEmpty(this.itemList)) {
                    // 滚动条范围尺寸,当前滚动条位置尺寸(scrollTop|scrollLeft)
                    let scrollRangeSize, scrollSize;
                    let k1, k2, k3;
                    let scrollDom = this.windowScroll
                        ? $("html,body")[0]
                        : this.scroll[0];
                    if (this.direction === "vertical") {
                        k1 = "scrollHeight";
                        k2 = "clientHeight";
                        k3 = "scrollTop";
                    } else {
                        k1 = "scrollWidth";
                        k2 = "clientWidth";
                        k3 = "scrollLeft";
                    }
                    scrollSize = scrollDom[k3]; // scrollDom.scrollTop
                    scrollRangeSize = scrollDom[k1] - scrollDom[k2]; // scrollDom.srcollHeight - scrollDom.clientHeight

                    this.isOverStartVisible = scrollSize > 0;
                    this.isOverEndVisible =
                        scrollRangeSize > 0 && scrollSize < scrollRangeSize;
                }
            });
        },
        /**
         * 参数验证
         * @see 验证用户在混入使用重写template是否加入了滚动区
         * @see 自动设置滚动区定位(如果用户没有指定)
         * @see 自动设置滚动区overflow方案(如果用户没有指定)
         * @param {function} success 验证成功回调函数
         * @returns void
         */
        _paramValidator(success) {
            this._getScrollDom(
                (dom) => {
                    const $scroll = $(dom);
                    if (!this.windowScroll) {
                        // 必要的定位属性添加
                        if (
                            !["absolute", "relative", "fixed"].includes(
                                $scroll.css("position")
                            )
                        ) {
                            $scroll.css("position", "relative");
                        }
                        // 必要的滚动属性添加
                        let overflowCss, heightCss;
                        if (this.direction === "vertical") {
                            overflowCss = "overflow-y";
                            heightCss = "max-height";
                        } else {
                            overflowCss = "overflow-x";
                            heightCss = "max-width";
                        }
                        if (
                            !["auto", "scroll"].includes(
                                $scroll.css(overflowCss)
                            )
                        ) {
                            $scroll.css(overflowCss, "auto");
                        }
                        // 必须的滚动设置
                        if (
                            !$scroll.css(heightCss) ||
                            $scroll.css(heightCss) === "none"
                        ) {
                            console.warn(
                                `[${this.$options.name}]样式错误!未检测到滚动区css属性${heightCss},这将导致滚动区异常!`
                            );
                        }
                    }
                    this.scroll = $scroll;
                    success && success($scroll);
                },
                () => {
                    const err = new Error(
                        `[Scroll->${this.$options.name}.ref]配置错误!未检测到this.$refs.scroll,这将会导致滚动监听失败~`
                    );
                    this.errHand__(err);
                },
                100
            );
        },
        /**
         * 滚动条事件
         * @see 滚动区滚动条滚动时调用
         * @returns void
         */
        _scrollEvent() {
            // 可视区判断
            this._visibleJudge();
            // 判断滚动方向
            this._scrollDirectionJudge();
        },
        /**
         * 判断滚动方向
         * @see 根据滚动的差值
         * @returns void
         */
        _scrollDirectionJudge() {
            const scrollPos =
                this.direction === "vertical"
                    ? this.scroll.scrollTop()
                    : this.scroll.scrollLeft();
            this.isStartToEndScroll = scrollPos > this.prevScrollPos;
            this.prevScrollPos = scrollPos;
        },
        /**
         * 获得实际偏移尺寸(px)
         * @see 根据this.direction和this.offset自动计算
         * @returns {number} 像素偏移尺寸
         */
        _getOffsetSize() {
            const width = this.scroll.width();
            const height = this.scroll.height();
            const size = this.direction === "vertical" ? height : width;
            if (!_.isNaN(Number(this.offset))) {
                return Number(this.offset);
            }
            // 百分比高度
            if (this.offset.includes("%")) {
                return size * Number(this.offset.slice(0, -1) * 0.01);
            }
            // vh高度
            else if (this.offset.includes("vh")) {
                return (
                    $(window).height() * Number(this.offset.slice(0, -2) * 0.01)
                );
            }
            // vw宽度
            else if (this.offset.includes("vw")) {
                return (
                    $(window).width() * Number(this.offset.slice(0, -2) * 0.01)
                );
            }
            // 开始(0)
            else if (this.offset === "start") {
                return 0;
            }
            // 中间(50%)
            else if (this.offset === "center") {
                return size * 0.5;
            }
            // 结束(100%)
            else {
                return size;
            }
        },
    },
};
</script>