<script>
/**
 * 自动滚动到旧的位置混入
 * @desc 该混入仅针对具有keep-alive属性的页面级组件
 * @author tonny
 * @date 2022-09-21
 */
</script>

<script>
export default {
    data() {
        return {
            /** 组件是否活跃(keep-alive有效) */
            isCmpActive: true,
            /** 旧的滚动条顶部距离(用来恢复) */
            oldScrollTop: 0,
            /** 滚动的Dom */
            oldScrollDom: window,
        };
    },
    activated() {
        this.recoveryScroll();
    },
    deactivated() {
        this.isCmpActive = false;
    },
    created() {
        $(this.oldScrollDom).on("scroll", () => {
            if (this.isCmpActive) {
                this.oldScrollTop = $(this.oldScrollDom).scrollTop();
            }
        });
    },
    methods: {
        /**
         * 恢复滚动
         * @returns void
         */
        recoveryScroll() {
            this.$nextTick(() => {
                const dom =
                    this.oldScrollDom === window
                        ? "html,body"
                        : this.oldScrollDom;
                $(dom).scrollTop(this.oldScrollTop);
                this.isCmpActive = true;
            });
        },
    },
};
</script>
