import Err from "../../../Utils/Err";
import _ from "lodash";

/**
 * 数据转换基类
 * @see 用来给派生类提供统一的处理
 * @see 可以直接被实例化来灵活的转换数据
 * @see 转换器只能对值类型转换(非字符串数字数组,对象,函数,undefined的类型) 比如:{a: 10, b: [1, 2, 3], c: {...}}, a和b都是值类型,c不是
 * @see 级联转换仅支持.取值或[]取值,不支持其他更复杂的取值方式 比如:"props.disabled", "datas[0].name"
 * @see 已知bug:当参照表中含有级联时,会以级联作为优先,比如: value: ["value.data", "value", "nodeValue"],会以value.data取值,没有才会往后找
 * @author tonny
 * @date 2021-10-15
 */

export default class Base {
    /**
     * 数据转换器
     * @see 传入Request请求中可以直接转换数据
     * @param {object} params
     * @param {object} [params.compares] 参照表({key<string>: value<string|[string]>}), key为转换后的值,支持级联操作(比如:props.disabled, datas[0].title)
     * @param {*} [params.data] 数据
     * @param {function} [params.convertKeyHook] 转换钩子
     * @param {boolean} [params.isStrict] 是否开始严格转换模式(严格:会将所有不在参照表中的数值省略,只保留参照表含有的数据, 不严格:只会替换参照表中有的数据,其它会原值保留)
     * @param {function} [params.convertValueHook] 转换值钩子函数(只有当value为值类型(非数组,对象,函数)调用)
     */
    constructor({
        compares,
        data,
        convertKeyHook,
        isStrict,
        convertValueHook,
    }) {
        this._contructorParamsValidator(arguments[0]);
        /** 原始对照表 */
        this.compares = compares || {};
        /** 转换后对照表 */
        this.formatCompares = this._comparesConvert(compares);
        /** 是否支持数字key */
        this._isSupportNumber = true;
        /** 数据 */
        this.data = data;
        /** 转换钩子函数 */
        this._convertKeyHook = convertKeyHook;
        /** 转换值钩子函数 */
        this._convertValueHook = convertValueHook;
        /** 严格模式 */
        this.isStrict = isStrict;
    }
    /**
     * 设置属性
     * @param {string|array} from 源属性
     * @param {string} to 转换后的属性
     * @returns void
     */
    set(from, to) {
        /** 错误标题 */
        const title = "参数错误",
            /** 错误来源 */
            errfrom = `Convert.${this.constructor.name}.set`;
        // key参数验证
        if (!_.isString(to) && to) {
            throw new Err({
                message: `转换后的属性(to)参数必须为非空字符串`,
                title,
                from: errfrom,
                current: to,
            });
        }
        // value参数验证
        this._comparesValidator({ key: to, value: from }, title, errfrom);
        // 单个字符串直接转换为数组
        if (_.isString(from)) {
            from = [from];
        }
        // 追加
        if (this.compares[to]) {
            if (!_.isArray(this.compares[to])) {
                this.compares[to] = [this.compares[to]];
            }
            this.compares[to].push(...from);
        } else {
            this.compares[to] = from;
        }
        // 重新转换规则
        this.formatCompares = this._comparesConvert(this.compares);
    }
    /**
     * 数据转换
     * @param {*} data 要转换的数据
     * @returns {*} 转换后的数据
     */
    convert(data) {
        data = data || this.data;
        if (_.isEmpty(data)) {
            return data;
        }
        if (_.isArray(data)) {
            return this._arrayConvert(data);
        }
        if (_.isPlainObject(data)) {
            return this._objectConvert(data);
        }
    }
    /**
     * 设置值钩子函数
     * @param {void|function} val 拦截value钩子
     */
    set convertValueHook(val) {
        this._hookValidator(
            val,
            "参数错误!",
            `Convert.${this.constructor.name}.set-convertValueHook`
        );
        this._convertValueHook = val;
    }
    /**
     * 设置key钩子参数
     * @param {void|function} val 拦截key钩子
     */
    set convertKeyHook(val) {
        this._hookValidator(
            val,
            "参数错误!",
            `Convert.${this.constructor.name}.setconvertKeyHook`
        );
        this._convertKeyHook = val;
    }
    /**
     * 构造函数参数验证
     * @param {object} param0
     * @throws 失败抛错
     */
    _contructorParamsValidator({ compares, convertKeyHook, convertValueHook }) {
        const title = "参数错误",
            from = `Convert.${this.constructor.name}.contructor`;
        // 对照表验证
        this._comparesValidator(compares, title, from);
        // 钩子验证
        this._hookValidator(convertKeyHook, title, from);
        this._hookValidator(convertValueHook, title, from);
    }
    _hookValidator(hook, title, from, allowEmpty = true) {
        if (!allowEmpty && !hook) {
            throw new Err({
                title,
                from,
                message: "钩子参数不允许为空",
            });
        }
        if (hook && !_.isFunction(hook)) {
            throw new Err({
                title,
                from,
                message: "转换钩子参数必须为函数",
                current: hook,
            });
        }
    }
    /**
     * 对照表参数验证
     * @param {object} compares 对照表
     * @param {string} title 错误标题
     * @param {string} from 错误源
     * @throws 输出错误抛出错误
     */
    _comparesValidator(compares, title, from) {
        if (!_.isPlainObject(compares)) {
            throw new Err({
                title,
                from,
                message: "对照表(compares)必须为对象",
                current: compares,
            });
        }
        if (
            _.some(
                compares,
                value =>
                    (!_.isNumber(value) && _.isEmpty(value)) ||
                    (!_.isString(value) &&
                        !_.isArray(value) &&
                        !_.isNumber(value)) ||
                    (_.isArray(value) && value.some(str => !_.isString(str)))
            )
        ) {
            throw new Err({
                title,
                from,
                message:
                    "对照表(compares)的每项value必须为非空字符串或字符串数据[string]",
                current: compares,
            });
        }
    }
    /**
     * 对照表转换
     * @see 将对照表转换成统一的格式
     * @param {object} compares 对照表
     * @returns {object} {key(被转换的key值): value<string>(转换后的值)}
     */
    _comparesConvert(compares) {
        let result = {};
        if (!_.isEmpty(compares)) {
            if (!_.isPlainObject(compares)) {
                throw new Err({
                    title: "参数错误",
                    message: "参数表只能为对象",
                    current: compares,
                    from: `Convert.${this.constructor.name}._comparesConvert`,
                });
            }
            const title = "参照表重复value值",
                message = `参照表出现重复value值,这样可能会导致转换出错误`,
                from = `Convert.${this.constructor.name}.compares`;
            _.forEach(compares, (value, key) => {
                if (_.isString(value)) {
                    if (result[value] !== undefined) {
                        console.warn(
                            new Err({
                                title,
                                from,
                                message,
                                console: false,
                                current: `${key} - ${result[value]} 重复: ${value}`,
                            }).message
                        );
                    }
                    result[value] = key;
                } else if (_.isArray(value)) {
                    value.forEach(val => {
                        if (result[val] !== undefined) {
                            console.warn(
                                new Err({
                                    title,
                                    from,
                                    message,
                                    console: false,
                                    current: `${key} - ${result[val]} 重复: ${val}`,
                                }).message
                            );
                        }
                        result[val] = key;
                    });
                } else {
                    this._isSupportNumber = true;
                }
            });
        }
        return result;
    }
    /**
     * 数组类型转换
     * @param {array} target 转换目标
     * @returns {array}
     */
    _arrayConvert(target) {
        let result = [];
        if (!_.isEmpty(target)) {
            result = target.map(item => {
                if (this._isValue(target)) {
                    return item;
                } else {
                    return this.convert(item);
                }
            });
        }
        return result;
    }
    /**
     * 对象类型转换
     * @param {object} target 转换目标
     * @returns {object}
     */
    _objectConvert(target) {
        let result = {};
        const repeats = {};
        // 遍历对象,对每个对象进行转换
        _.forEach(target, (value, key) => {
            // if(!Number.isNaN(Number(key))) {
            //     debugger;
            // }
            /** 拦截key */
            let convertKey = this.formatCompares[key];
            /** 钩子参数 */
            const hookParam = {
                key,
                value,
                from: key,
                to: convertKey,
                parent: target,
                result,
            };
            // 值拦截器钩子
            convertKey = this._setConvertKey(hookParam);
            hookParam.to = convertKey;
            // 级联转换(如果key被正真转换了,则跳过当前循环)
            if (this._cascadeConvert(key, value, target, result)) {
                return;
            }
            const isNumberMode = this._isSupportNumber && /^\d+$/.test(key);
            if (isNumberMode) {
                hookParam.from = key;
                hookParam.to = key;
                if (!convertKey) {
                    convertKey = key;
                }
            }
            // 严格模式判断
            if (this.isStrict && !isNumberMode) {
                if (!convertKey) {
                    return;
                }
            }
            // 值转换
            if (this._isValue(value)) {
                // 存在转换key
                if (convertKey || isNumberMode) {
                    // 一个对象有在参照表中有重复的值(比如目标对象:{a: 1, b: 2}在参照表{value: ["b", "a"]}就存在a, b重复了, 此时就取参照最后一个a座位最终值)
                    if (repeats[convertKey]) {
                        const compareValueList = this.compares[convertKey];
                        let lastKey, lastValue;
                        // 倒循环,找出最后一个不为undefined的target值作为最终值
                        for (let i = compareValueList.length - 1; i > 0; i--) {
                            lastKey = compareValueList[i];
                            lastValue = target[lastKey];
                            if (lastValue !== undefined) {
                                break;
                            }
                        }
                        hookParam.value = lastValue;
                        hookParam.key = lastKey;
                        hookParam.from = lastKey;
                    }
                    // 没有重复就正常赋值
                    else {
                        repeats[convertKey] = { key, value };
                    }
                    result[convertKey || key] = this._setConvertValue(
                        hookParam
                    );
                } else {
                    hookParam.to = "";
                    // 非严格模式只额外赋值未指定的key
                    if (_.isEmpty(this.compares[key])) {
                        result[key] = this._setConvertValue(hookParam);
                    }
                }
            }
            // 非值类型,继续转换
            else {
                let val = this.convert(value);
                if (val !== undefined) {
                    result[convertKey || key] = val;
                }
            }
        });
        return result;
    }
    /**
     * 判断是否为value值类型(非数组,对象,函数)
     * @param {*} target 目标
     * @returns {boolean}
     */
    _isValue(target) {
        const isValueArray =
            _.isArray(target) && target.every(str => this._isValue(str));
        return (!_.isObject(target) && target !== undefined) || isValueArray;
    }
    /**
     * 级联转换
     * @see 处理级联参照值,比如:props.disabled->readonly
     * @param {string} key 目标的key
     * @param {*} value 目标的值
     * @param {object} [result] 转换结果(用来存储)
     * @returns {boolean} key是否被真正转换, true:是
     */
    _cascadeConvert(key, value, parent, result = {}) {
        /** 是否进行了级联转换 */
        let isCascade = null;
        _.some(this.formatCompares, (convertValue, convertKey) => {
            // 转换数组类型的设置(test[0]->test.0)
            let formatConvertKey = convertKey.replace(
                /\[([^\[\]])\]/g,
                ($1, $2) => "." + $2
            );
            // 必须包含级联&级联不和原来的key相同&value不为值类型
            if (
                formatConvertKey.includes(".") &&
                formatConvertKey !== key &&
                !this._isValue(value)
            ) {
                // 按照点进行分割:用来循环取值
                const list = formatConvertKey.split(".");
                // key必须和级联的第一级一样,否则会出现匹配不准确
                if (key === list[0]) {
                    let temp = value;
                    // 循环从value中取到convertKey设定的值
                    list.slice(1).some((k, index) => {
                        if (!_.isEmpty(temp)) {
                            temp = temp[k];
                            // 当循环到list的最后一项的时候&最后一项为值类型
                            if (index === list.length - 2) {
                                if (this._isValue(temp)) {
                                    // 值拦截器
                                    temp = this._setConvertValue({
                                        key: k,
                                        value: temp,
                                        from: convertKey,
                                        to: convertValue,
                                        parent,
                                    });
                                } else {
                                    temp = this.convert(temp);
                                }
                                if (temp !== undefined) {
                                    result[convertValue] = temp;
                                    /** 转换值所处参照表对应key的索引 */
                                    const convertValueIndex = this.compares[
                                        convertValue
                                    ].indexOf(formatConvertKey);
                                    /** 参照表对应key的最后一位索引 */
                                    const comparesLastIndex =
                                        this.compares[convertValue].length - 1;
                                    // 判断是参照表的最后一个值才表示级联转换真正ok,需要屏蔽其他转换
                                    isCascade =
                                        _.isString(
                                            this.compares[convertValue]
                                        ) ||
                                        convertValueIndex === comparesLastIndex;
                                }
                                return true;
                            }
                        }
                    });
                }
            }
        });
        return isCascade;
    }
    /**
     * 设置转换值
     * @see 提供给派生类调用的钩子函数,避免和用户钩子干架
     * @see 优先级低于用户钩子,也就是说这边转换的值最终会以value参数传递给用户钩子
     * @param {object} params
     * @param {string} params.key 转换目标的key
     * @param {string|[string|number]|null|number} params.value 转换目标的值
     * @param {string} params.from 转换key的原值(比如:a->b,则from==a, to==b)
     * @param {string} params.to 转换key的终值
     * @param {object|array} params.parent 父级(数据中key所在的父级)
     * @returns {string|[string|number]|null|number} 数据的值
     */
    _setConvertValue(params) {
        let result = params.value;
        // 钩子转换
        if (this._convertValueHook) {
            result = this._convertValueHook(params);
        }
        return result;
    }
    /**
     * 设置转换值
     * @see 提供给派生类调用的钩子函数,避免和用户钩子干架
     * @see 优先级低于用户钩子,也就是说这边转换的值最终会以value参数传递给用户钩子
     * @param {object} params
     * @param {string} params.key 转换目标的key
     * @param {*} params.value 转换目标的值
     * @param {string} params.from 转换key的原值(比如:a->b,则from==a, to==b)
     * @param {string} params.to 转换key的终值
     * @param {object|array} params.parent 父级(数据中key所在的父级)
     * @returns {string|[string|number]|null|number} 转换key的终值
     */
    _setConvertKey(params) {
        let result = params.to;
        // 钩子转换
        if (this._convertKeyHook) {
            result = this._convertKeyHook(params);
        }
        return result;
    }
}
