var render = function render(){var _vm=this,_c=_vm._self._c;return _c('footer',{staticClass:"kq-bottom-toolbar",style:({
        height: _vm.formatLength__(_vm.height),
    })},[_c('transition',{attrs:{"enter-active-class":"animate__animated animate__fadeInUp","leave-active-class":"animate__animated animate__fadeOutDown"}},[_c('kq-layout',{directives:[{name:"show",rawName:"v-show",value:(_vm.current),expression:"current"}],ref:"layout",class:{
                'kq-bottom-toolbar-layout': true,
                'kq-bottom-toolbar-layout--fixed': _vm.fixed,
                'kq-bottom-toolbar-layout--float': _vm.isFloat,
                'kq-bottom-toolbar-layout--enable': _vm.toolbarIsBlur,
            },style:({
                zIndex: _vm.zIndex,
                height: _vm.formatLength__(_vm.height),
                bottom: _vm.bottom_,
            }),attrs:{"disabled":_vm.layoutDisabled}},[_c('div',{staticClass:"kq-bottom-toolbar-container"},[_c('div',{staticClass:"kq-bottom-toolbar-container-row",staticStyle:{"height":"60px"}},[_c('div',{staticClass:"kq-bottom-toolbar-container-start"},[_vm._t("start")],2),_c('div',{staticClass:"kq-bottom-toolbar-container-end"},[_vm._t("end",function(){return [_c('div',{staticClass:"kq-bottom-toolbar-container-end-btns"},_vm._l((_vm.btnList),function(btn){return _c('kq-sbutton',{key:btn.name,attrs:{"config":{
                                        ...btn,
                                        round: btn.round || true,
                                        type:
                                            (btn.props && btn.props.type) ||
                                            btn.type ||
                                            'primary',
                                    }}})}),1)]})],2)]),_c('div',{staticClass:"kq-bottom-toolbar-container-row"},[_vm._t("default")],2)])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }