/**
 * @description Tip-鼠标悬浮提示自定义指令
 * @see 依赖于element-ui的tool-tip组件
 * @author tonny
 * @date 2021-08-15
 */

import vTip from "./Tip.vue";
import _ from "lodash";
import $ from "jquery";

export default {
    install(Vue) {
        Vue.directive("kq-tip", {
            /**
             * 绑定钩子
             * @see 创建组件实例
             * @returns void
             */
            bind(el, { value, modifiers, arg }) {
                if (
                    !_.isString(value) &&
                    !_.isBoolean(value) &&
                    value !== undefined
                ) {
                    console.error(
                        `[DirectiveBind-tip]参数错误!value必须为tip的内容字符串或布尔型(用来开关tip)!当前:${JSON.stringify(
                            value
                        )}`
                    );
                    value = "";
                }
                el.kqTip = { arg, value };
                let effect, // 主题
                    enterable = true, // 是否允许鼠标进入tooltip
                    placement; // 位置
                _.forEach(modifiers, (on, name) => {
                    if (!on) {
                        return;
                    }
                    if (["dark", "light"].includes(name)) {
                        effect = name;
                    } else if (
                        [
                            "top",
                            "top-start",
                            "top-end",
                            "bottom",
                            "bottom-start",
                            "bottom-end",
                            "left",
                            "left-start",
                            "left-end",
                            "right",
                            "right-start",
                            "right-end",
                        ].includes(name)
                    ) {
                        placement = name;
                    } else if (["enterable-false"].includes(name)) {
                        enterable = false;
                    }
                });
                const Tip = Vue.extend(vTip);
                let options = {};
                if (_.isPlainObject(arg)) {
                    options = arg;
                }
                const vm = new Tip({
                    propsData: {
                        effect,
                        placement,
                        content: value,
                        disabled: !value,
                        enterable,
                        ...options,
                    },
                }).$mount();
                el.vm = vm;
            },
            /**
             * el被插入到父组件后的钩子
             * @see 将Tip的Dom插入到父组件中
             * @returns void
             */
            inserted(el) {
                const $el = $(el);
                // 当前提示组件dom
                const $toolTipDom = $(el.vm.$el);
                const $prev = $el.prev();
                const $next = $el.next();
                // 将提示组件$dom插入到el的原位置
                if ($prev.length) {
                    $prev.after($toolTipDom);
                } else if ($next.length) {
                    $next.before($toolTipDom);
                } else {
                    $toolTipDom.appendTo($el.parent());
                }
                $el.appendTo(el.vm.$refs.slot);
            },
            /**
             * 被绑定所在组件更新钩子
             * @see 更新Tip组件参数
             * @returns void
             */
            update({ kqTip, vm }, { value, arg }) {
                if (kqTip.arg !== arg || kqTip.value !== value) {
                    vm.disabled = !value;
                    value = value || "";
                    if (_.isString(value)) {
                        vm.content = value;
                    }
                    let options = vm.$props;
                    if (arg && _.isPlainObject(arg)) {
                        options = { ...options, ...arg };
                    }
                    _.forEach(options, (v, k) => {
                        vm[k] = v;
                    });
                }
            },
            /**
             * 解绑钩子
             * @see 销毁Tip组件
             * @returns void
             */
            unbind({ vm }) {
                vm.$destroy();
            },
        });
    },
};
