/**
 * @description 接口请求-应用相关模块
 * @author tonny
 * @date 2022-01-13
 */

import _ from "lodash";

let HOTAPPCONFIG = {
    HOTAPPLIST: {},
    isHot: true, // 是否是常用应用(若从接口中获取常用应用为空，则获取全部应用)
    collectionList: [],
    allList: [],
};
let ALLAPPLIST = [];
let COLLECTIONLIST = [];
export default class App {
    /**
     * 获取常用应用表
     * @param {string} [param0.device] 设备(all|void:全部(默认), mobile:手机, pc:电脑端)
     * @param {number} [param0.childSystemId] 子系统id
     * @param {number} [param0.showNum] 显示数量
     * @returns {[object]}
     */
    async getCommonAppList({ childSystemId, device = "pc", showNum = 5 } = {}) {
        let response = await this.request(
            {
                url: "/api/homepage/hotApp",
                timeout: 3000,
                timeoutConfig: {
                    reRequest: {
                        enabled: true,
                        maxCount: 3,
                    },
                },
            },
            arguments[0]
        );
        // 对结果进行转换,isJSON的作用是转换data为object,转换失败返回false(data可以是字符串类型的json)
        let result = kq.Tools.isJSON(response);
        if (result) {
            response = result;
            HOTAPPCONFIG.isHot = true;
        } else {
            console.warn("getCommonAppList接口获取的不是标准的JSON格式");
            HOTAPPCONFIG.isHot = false;
            let resultDataList = {};
            COLLECTIONLIST = await this.getCollectionAppList({
                childSystemId,
                device,
            }).catch((err) => {
                throw err;
            });
            let collectionCount = 0;
            if (COLLECTIONLIST.length > 0) {
                (COLLECTIONLIST || []).forEach((item) => {
                    if (collectionCount < showNum) {
                        resultDataList[item.appid] = 0;
                        HOTAPPCONFIG.collectionList.push(item.appid);
                        collectionCount++;
                    }
                });
            }
            if (collectionCount < showNum) {
                if (kq.Tools.isEmpty(COLLECTIONLIST)) {
                    ALLAPPLIST = await this.getAppList({
                        childSystemId,
                        device,
                    }).catch((err) => {
                        throw err;
                    });
                }
                /** 获取全部应用五条数据 */
                let count = 0;
                const haveAllCount = showNum - collectionCount;
                /** 并转换为统一的数据格式 */
                (ALLAPPLIST || []).kqEachDeep((item) => {
                    if (!resultDataList[item.appid]) {
                        if (count < haveAllCount) {
                            resultDataList[item.appid] = 0;
                            HOTAPPCONFIG.allList.push(item.appid);
                            count++;
                        } else {
                            return false;
                        }
                    }
                });
            }
            response = resultDataList || {};
        }
        HOTAPPCONFIG.HOTAPPLIST = response;
        return HOTAPPCONFIG;
    }
    /**
     * 更新常用应用
     * @param {number} param0.appid 应用号
     * * @param {number} [param0.childSystemId] 子系统id
     * @param {number} [param0.showNum] 显示数量
     * @returns {Promise<void>} 成功返回数据表,失败抛错
     */
    async setCommonAppList({
        childSystemId,
        appid = "",
        showNum = 5,
        device = "pc",
    } = {}) {
        if (kq.Tools.isEmpty(HOTAPPCONFIG.HOTAPPLIST)) {
            HOTAPPCONFIG = await this.getCommonAppList({
                childSystemId,
                showNum,
                device,
            }).catch((err) => {
                throw err;
            });
        }
        if (!appid) {
            throw new kq.Err({
                message: "应用号(appid)参数不能少",
                current: arguments[0],
                from: "kq.Request.App.setCommonAppList",
            });
        }
        const {HOTAPPLIST} = HOTAPPCONFIG;
        let filterHostForm = {};
        if (HOTAPPCONFIG.isHot) {
            filterHostForm = HOTAPPLIST;
        } else {
            for (const key in HOTAPPLIST) {
                if (Object.hasOwnProperty.call(HOTAPPLIST, key)) {
                    const value = HOTAPPLIST[key];
                    /** 过滤值为0的数据 */
                    if (!kq.Tools.isEqual(value, 0)) {
                        filterHostForm[key] = value;
                    }
                }
            }
        }
        if (filterHostForm[appid]) {
            if (filterHostForm[appid] > 0 && filterHostForm[appid] < 9999) {
                filterHostForm[appid] = ++filterHostForm[appid];
            } else if (filterHostForm[appid] <= -9999) {
                filterHostForm[appid] = "infinite";
            } else if (filterHostForm[appid] < 0) {
                filterHostForm[appid] = --filterHostForm[appid];
            } else if (filterHostForm[appid] === 9999) {
                filterHostForm[appid] = -1;
            }
        } else {
            filterHostForm[appid] = 1;
        }
        return this.request(
            {
                url: "/api/homepage/hotApp",
                method: "post",
                data: { ...filterHostForm },
                timeout: 3000,
                timeoutConfig: {
                    reRequest: {
                        enabled: true,
                        maxCount: 3,
                    },
                },
            },
            arguments[0]
        );
    }
    /**
     * 更新常用应用接口
     * @param {string} [param0.device] 设备(all|void:全部(默认), mobile:手机, pc:电脑端)
     * @param {number} [param0.childSystemId] 子系统id
     * @param {number} [param0.showNum] 显示数量
     * @returns {[object]}
     */
    async getFormatCommonAppList({
        childSystemId,
        device = "pc",
        showNum = 5,
    } = {}) {
        ALLAPPLIST = await this.getAppList({ childSystemId, device }).catch(
            (err) => {
                throw err;
            }
        );
        if (kq.Tools.isEmpty(HOTAPPCONFIG.HOTAPPLIST)) {
            HOTAPPCONFIG = await this.getCommonAppList({
                childSystemId,
                device,
                showNum,
            }).catch((err) => {
                throw err;
            });
        }
        const { HOTAPPLIST } = HOTAPPCONFIG;
        /** 过滤常用应用为0的值 */
        const FILTERHOTAPPLIST = {};
        for (const key in HOTAPPLIST) {
            if (Object.hasOwnProperty.call(HOTAPPLIST, key)) {
                const value = HOTAPPLIST[key];
                if (value !== 0) {
                    FILTERHOTAPPLIST[key] = value;
                }
            }
        }
        /** 获取常用的所有的appid */
        const hostAppidKeyList = Object.keys(FILTERHOTAPPLIST);
        /** 根据常用的appid查询对应的应用数据 */
        let filterHostList = (ALLAPPLIST || []).kqFilterDeep(
            ({ appid = "" }) => {
                return hostAppidKeyList.some((item) =>
                    kq.Tools.isEqual(item, appid)
                );
            }
        );
        /** 常用应用数据追加点击次数数据 */
        let addhotSortList = filterHostList.map((item) => {
            if (
                hostAppidKeyList.some((hot) =>
                    kq.Tools.isEqual(hot, item.appid)
                )
            ) {
                if (HOTAPPCONFIG.isHot) {
                    item.hotSort =
                        FILTERHOTAPPLIST[item.appid] === "infinite"
                            ? -Infinity
                            : FILTERHOTAPPLIST[item.appid];
                    item.tip = "common";
                } else {
                    /** 如果常用应用中掺杂了收藏应用，则加标识 */
                    if (
                        HOTAPPCONFIG.collectionList.some((collectionAppid) =>
                            kq.Tools.isEqual(collectionAppid, item.appid)
                        )
                    ) {
                        item.tip = "collection";
                    }
                    item.hotSort = 1;
                }
            }
            return item;
        });
        let resultHotList = [];
        /** 大于0的按照降序排序 */
        const gtZeroList = addhotSortList.filter(
            ({ hotSort = 0 }) => hotSort > 0
        );
        const gtZeroSortList = _.orderBy(gtZeroList, ["hotSort"], "desc");
        /** 小于0的按照升序排序 */
        const ltZeroList = addhotSortList.filter(
            ({ hotSort = 0 }) => hotSort < 0
        );
        const ltZeroSortList = _.orderBy(
            ltZeroList,
            ["hotSort", "name"],
            "esc"
        );
        resultHotList = [...ltZeroSortList, ...gtZeroSortList];
        let result = [];
        if (resultHotList.length >= showNum) {
            result = resultHotList.slice(0, showNum);
        } else {
            result = resultHotList;
            COLLECTIONLIST = await this.getCollectionAppList({
                childSystemId,
                device,
            }).catch((err) => {
                throw err;
            });
            /** 判断除了常用应用还需要插入几个应用 */
            let spliceNum = showNum - result.length;
            /** 如果有收藏应用则插入收藏应用，并去重 */
            if (COLLECTIONLIST.length > 0) {
                let collectionCount = 0;
                COLLECTIONLIST.forEach((item) => {
                    if (
                        result.every(
                            ({ appid: appidCommon = "" }) =>
                                !kq.Tools.isEqual(item.appid, appidCommon)
                        )
                    ) {
                        if (collectionCount < spliceNum) {
                            item.tip = "collection";
                            result.push(item);
                            collectionCount++;
                        }
                    }
                });
            }
            /** 如果常用应用+收藏应用仍小于需要显示的长度，则插入全部应用 */
            if (result.length < showNum) {
                /** 判断收藏应用插入后仍然不够显示的长度，则插入全部应用 */
                spliceNum = showNum - result.length;
                let count = 0;
                ALLAPPLIST.kqEachDeep((item) => {
                    if (
                        result.every(
                            ({ appid: appidCommon = "" }) =>
                                !kq.Tools.isEqual(item.appid, appidCommon)
                        )
                    ) {
                        if (count < spliceNum) {
                            result.push(item);
                            count++;
                        } else {
                            return false;
                        }
                    }
                });
            }
        }
        return result || [];
    }
    /**
     * 获取所有应用表
     * @param {object} param0 入参对象
     * @param {string} [param0.device] 设备(all|void:全部(默认), mobile:手机, pc:电脑端)
     * @param {number} [param0.childSystemId] 子系统id
     * @returns {[object]}
     */
    getAppList({ childSystemId, device = "pc" } = {}) {
        return this.request(
            {
                url: "/api/userRoles/queryAppsByUserCode",
                params: {
                    appSystem: childSystemId || "",
                    device: this._convertDevice(device),
                },
                convert: "AppList",
            },
            arguments[0]
        );
    }
    /**
     * 获取热门应用表
     * @param {object} param0 入参对象
     * @param {string} [param0.device] 设备(all|void:全部(默认), mobile:手机, pc:电脑端)
     * @param {number} [param0.childSystemId] 子系统id
     * @returns {[object]}
     */
    getHotAppList({ childSystemId, device = "pc" } = {}) {
        return this.request(
            {
                url: "/api/homepage/queryHotApp",
                params: {
                    appSystem: childSystemId || "",
                    device: this._convertDevice(device),
                },
                convert: "AppList",
            },
            arguments[0]
        );
    }
    /**
     * 获取收藏应用表
     * @param {object} param0 入参对象
     * @param {string} [param0.device] 设备(all|void:全部(默认), mobile:手机, pc:电脑端)
     * @param {number} [param0.childSystemId] 子系统id
     * @returns {[object]}
     */
    getCollectionAppList({ childSystemId, device = "pc" } = {}) {
        return this.request(
            {
                url: "/api/homepage/queryCollectList",
                params: {
                    appSystem: childSystemId || "",
                    device: this._convertDevice(device),
                },
                convert: "AppList",
                timeout: 3000,
                timeoutConfig: {
                    reRequest: {
                        enabled: true,
                        maxCount: 3,
                    },
                },
            },
            arguments[0]
        );
    }
    /**
     * 获取app号
     * @param {object} param 参数对象
     * @param {number} [param.fid] 功能点号
     * @param {number} [param.appid] app号
     * @returns {number} app号
     */
    async getAppid({ appid, fid }) {
        if (!appid) {
            const info = await this.getFunctionInfo({ fid }).catch((err) => {
                throw err;
            });
            appid = info.appid;
        }
        return appid;
    }
    /**
     * 查询功能信息
     * @param {object} param 参数对象
     * @param {number} [param.fid] 功能点号
     * @param {number} [param.appid] app号
     * @returns {object} {vid<number>:视图号, appid<number>:app号, ...}
     */
    async getFunctionInfo({ fid }) {
        if (!fid) {
            throw new kq.Err({
                message: "功能点号(fid)不能为空",
                current: arguments[0],
                from: "kq.Request.App.getFunctionInfo",
            });
        }
        const url = "/api/common/dict/queryViewsByFuncId";
        return this.request(
            {
                url,
                params: {
                    funcId: fid,
                },
                convert: url,
                timeout: 5000,
                timeoutConfig: {
                    reRequest: {
                        enabled: true,
                        maxCount: 3,
                    },
                },
            },
            arguments[0]
        );
    }
    /**
     * 获取应用详情信息
     * @param {object} param 参数对象
     * @param {number} param.appid 应用ID(不能与fid同时为空)
     * @param {number} param.fid 功能点号(不能与应用号同时为空)
     * @returns {Promise<object>} 成功返回应用信息对象,失败抛错
     */
    async getAppInfo({ appid, fid }) {
        if (!appid && !fid) {
            throw new kq.Err({
                message: "功能点号(fid)与app号(appid)不能同时为空",
                current: arguments[0],
                from: "kq.Request.App.getAppInfo",
            });
        }
        if (!appid) {
            appid = await this.getAppid({ fid }).catch((err) => {
                throw err;
            });
        }
        return this.request(
            {
                url: "/api/app",
                params: {
                    appId: appid,
                },
                convert: {
                    compares: {
                        name: "appName",
                        config: "config",
                        appid: "appId",
                        subname: "appMemo",
                        childSystemId: "appSystem",
                        childSystemName: "appSystemName",
                        icon: "icon",
                        version: "version",
                        background: "appBgColor",
                    },
                },
            },
            arguments[0]
        );
    }
    /**
     * 获取app全向详情信息
     * @param {object} param 参数对象
     * @param {number} [param.fid] 功能点号
     * @param {number} [param.appid] app号
     * @param {string} [param.device] 设备(all|void:全部(默认), mobile:手机, pc:电脑端)
     * @returns {Promise<object>} 失败抛错,成功返回app信息对象
     * @example {name<string>:app名称, icon<string>: app图标, color<string>:app颜色, authList<[object]>: 角色(权限)列表}
     * @example 权限表: [{name<string>: 名称, dataAuth<number>: 权限值, ...}]
     */
    async getAppAuthInfo({ appid, fid, device = "pc" }) {
        if (!appid && !fid) {
            throw new kq.Err({
                message: "功能点号(fid)与app号(appid)不能同时为空",
                current: arguments[0],
                from: "kq.Request.App.getAppAuthInfo",
            });
        }
        if (!appid) {
            appid = await this.getAppid({ fid }).catch((err) => {
                throw err;
            });
        }
        return this.request(
            {
                url: "/api/userRoles/appHomeRoles",
                params: { appId: appid, device: this._convertDevice(device) },
                convert: {
                    compares: {
                        name: ["appName", "authorityName"],
                        icon: "icon",
                        color: "appBgColor",
                        authList: "roles",
                        dataAuth: "authorityType",
                    },
                },
                timeout: 5000,
                timeoutConfig: {
                    reRequest: {
                        enabled: true,
                        maxCount: 3,
                    },
                },
            },
            arguments[0]
        );
    }
    /**
     * 获取应用导航列表
     * @param {object} param 参数对象
     * @param {number} [param.fid] 功能点号(appid与fid必传传一个)
     * @param {number} [param.appid] app号(appid与fid必须传一个)
     * @param {number} [param.dataAuth] 数据权限值(0:个人,1:二级,2:校级),不传则查询所有菜单信息,传则只查询对应数据权限的路由信息
     * @param {string} [param.device] 设备(all|void:全部(默认), mobile:手机, pc:电脑端)
     * @returns {Promise<[object]>} 失败抛错,成功返回路由表(参照接口文档)
     */
    async getAppNavList({ appid, fid, dataAuth, device = "pc" }) {
        if (!appid && !fid) {
            throw new kq.Err({
                message: "功能点号(fid)与app号(appid)不能同时为空",
                current: arguments[0],
                from: "kq.Request.App.getMenuList",
            });
        }
        if (!appid && fid) {
            appid = await this.getAppid(arguments[0]).catch((err) => {
                this.errHand__(err);
                throw err;
            });
        }
        const requestParams = {
            url: "/api/userRoles/appFuncs",
            params: { appId: appid, device: this._convertDevice(device) },
            convert: {
                compares: {
                    dataAuth: "dataAuthority",
                    ftype: ["funcTempType"],
                    help: "helpText",
                    fid: "id",
                    disabled: "isShow",
                    zlabel: "label",
                    name: ["name", "title"],
                    video: "videoUrl",
                    permissionId: "authorityId",
                    color: "iconColor",
                },
                convertValueHook({ to, value }) {
                    if (to === "disabled") {
                        return !Boolean(Number(value));
                    }
                    return value;
                },
            },
        };
        if (kq.Tools.isEmpty(dataAuth)) {
            requestParams.url = "/api/app/queryFuncByAppId";
        } else {
            requestParams.params.authorityType = dataAuth;
        }
        return this.request(requestParams, arguments[0]);
    }
    /**
     * 设备参数转换
     * @param {string} [device] 设备
     * @returns {string|void}
     */
    _convertDevice(device) {
        if (device === "mobile") {
            device = 1;
        } else if (device === "pc") {
            device = 0;
        } else {
            device = null;
        }
        return device;
    }
    /**
     * 获取功能点内数据数量
     * @param {object} param 参数表
     * @param {[number]} fidList 功能点号列表
     * @returns {Promise<[object]>} 成功返回数据表,失败抛错
     */
    async getFunctionDataCount({ fidList }) {
        if (_.isEmpty(fidList)) {
            throw new kq.Err({
                message: "功能点列表(fidList)参数不能少",
                current: arguments[0],
                from: "kq.Request.App.getFunctionCount",
            });
        }
        return this.request(
            {
                url: "/api/stat/getMenuDataTotal",
                method: "post",
                data: fidList,
            },
            arguments[0]
        );
    }
    /**
     * 添加热应用
     * @param {object} param 参数表
     * @param {number} param.appid 功能点号列表
     * @returns {Promise<void>} 成功返回数据表,失败抛错
     */
    async addHotApp({ appid }) {
        if (!appid) {
            throw new kq.Err({
                message: "应用号(appid)参数不能少",
                current: arguments[0],
                from: "kq.Request.App.setHotApp",
            });
        }

        return this.request(
            {
                url: "/api/homepage/addHot",
                method: "post",
                params: { appId: appid },
            },
            arguments[0]
        );
    }
    /**
     * 保存参数表
     * @param {object} param 参数对象
     * @param {number} param.appid 应用id
     * @param {object|string} [param.config] 参数表(必须为JSON串货JSON类型对象)
     * @returns {Promise<void>} 失败抛错
     */
    async saveConfig({ appid, config }) {
        if (!appid) {
            throw new kq.Err({
                message: "应用号(appid)参数不能少",
                current: arguments[0],
                from: "kq.Request.App.saveConfig",
            });
        }
        if (_.isPlainObject(config)) {
            // const strConfig = JSON.stringify(config);
            config = this.contentConvert(JSON.stringify(config), true);
            // config = strConfig.replace(/(\\r)?(\\n)/g, "bbrr");
        } else if (!_.isString(config)) {
            throw new kq.Err({
                message: "参数表(config)必须为JSON串或标准的JSON类型对象",
                current: config,
                from: "kq.Request.App.saveConfig",
            });
        }
        return this.request({
            url: "/api/app/setAppConfig",
            method: "post",
            data: {
                appId: appid,
                config: config || null,
            },
        });
    }
    /** 参数转换
     * @description 将获取的参数对应的字段值转换
     * @param {object} content 参数字段值
     * @param {boolean} isReverse 是否倒序转换
     */
    contentConvert(content, isReverse = false) {
        let result = content;
        [
            { from: ">", to: "mmmm" },
            { from: "<", to: "yyyy" },
            { from: "\\r\\n", regexp: "\\\\r\\\\n", to: "bbrn" },
            { from: "\\r", regexp: "\\\\r", to: "bbrr" },
            { from: "\\n", regexp: "\\\\n", to: "bbnn" },
            { from: "&", to: "kkkk" },
            { from: "?", regexp: "\\?", to: "hhhh" },
        ].forEach(({ from, fromRegexp, toRegexp, to, regexp }) => {
            if (isReverse) {
                result = result.replace(
                    RegExp(fromRegexp || regexp || from, "g"),
                    to
                );
            } else {
                result = result.replace(RegExp(toRegexp || to, "g"), from);
            }
        });
        return result;
    }
}
