<script>
/**
 * 流程混入
 * @author tonny
 * @date 2022-09-10
 */
</script>

<script>
const SUPER = "KQ_PROCESS_MIXIN";

export default {
    SUPER,
    data() {
        return {
            /** 流程请求对象 */
            processMixinRequest: kq.Request.mixin({
                vm: this,
                modules: ["process", "select"],
            }),
            /** 流程信息对象 */
            process: {
                /** 流程号 */
                pid: null,
                /** 当前审核节点字段号 */
                currentReviewZid: null,
                /** 下一个审核节点字段号 */
                nextReviewZid: null,
                /** 审核码表 */
                reviewSelectList: [],
                /** 查询表 */
                reviewSelects: {},
                /** 选择人员列表 */
                personList: [],
                /** 流程状态 */
                stat: null,
                /** 流程是否只读 */
                readonly: null,
                /** 审核历史记录 */
                historyList: [],
                /** 节点信息列表 */
                nodeList: [],
                /** 当前审核节点视图号 */
                vid: null,
                /** 审核模式(form:表单,pdf:pdf模式) */
                reviewMode: null,
                /** 签名必填的数据 */
                signatureConfig: null,
                /** 签名列隐藏的数据 */
                signatureHideConfig: null,
            },
        };
    },
    computed: {
        /**
         * 流程扭转列表
         * @desc 存储了流程号与经历号用来查询审核阶段历史记录
         * @see this.preProcessRid
         * @see this.prevProcessPid
         * @see this.process
         * @see this.rid
         * @returns {[object]}
         */
        processList() {
            const resultList = [];
            if (
                !this.isEmptyMaybe__(this.prevProcessRid, this.prevProcessPid) &&
                this.prevProcessPid != -1 &&
                this.prevProcessPid != 0 &&
                this.prevProcessRid != -1 &&
                this.prevProcessRid != 0
            ) {
                resultList.push({
                    pid: this.prevProcessPid,
                    rid: this.prevProcessRid,
                });
            }
            if (!this.isEmptyMaybe__(this.process.pid, this.rid)) {
                resultList.push({ rid: this.rid, pid: this.process.pid });
            }
            return resultList;
        },
        /**
         * 是否存在流程
         * @desc 根据是否有流程号来判断
         * @see this.process
         * @returns {boolean} true:存在
         */
        hasProcess() {
            return !this.isEmpty__(this.process.pid);
        },
    },
    methods: {
        /**
         * 清空操作(重写)
         * @desc 初始化审核数据表
         * @returns void
         */
        clearProcess() {
            this.process = {
                /** 流程号 */
                pid: null,
                /** 当前审核节点字段号 */
                currentReviewZid: null,
                /** 下一个审核节点字段号 */
                nextReviewZid: null,
                /** 审核码表 */
                reviewSelectList: [],
                /** 审核码表查询表 */
                reviewSelects: {},
                /** 选择人员列表 */
                personList: [],
                /** 流程状态 */
                stat: null,
                /** 流程是否只读 */
                readonly: null,
                /** 审核历史记录 */
                historyList: [],
                /** 节点信息列表 */
                nodeList: [],
                /** 签名必填的数据 */
                signatureConfig: null,
                /** 签名列隐藏的数据 */
                signatureHideConfig: null
            };
        },
        /**
         * 流程初始化
         * @returns {Promise<object>} 成功设置并返回审核信息, 失败抛错
         */
        async processInit() {
            // 请求审核信息
            const {
                pid,
                currentZid,
                nextZid,
                stat,
                readonly,
                vid,
                reviewMode,
                signatureConfig,
                signatureHideConfig
            } = await this.processMixinRequest
                .getProcessInfo(this._getProcessInfoParams())
                .catch((err) => {
                    this.errHand__(err);
                    throw err;
                });
            const process = this.process;
            process.readonly = readonly;
            process.pid = pid;
            process.currentReviewZid = currentZid;
            process.nextReviewZid = nextZid;
            process.stat = stat;
            process.vid = vid;
            if (!this.isEmpty__(signatureConfig)) {
                if(this.isEqual__(signatureConfig, "empty")) {
                    process.signatureConfig = false;
                } else {
                    try {
                        process.signatureConfig = JSON.parse(signatureConfig);
                    } catch (error) {
                        console.warn(`流程判断签字必填存在无法解析的数据:${signatureConfig}`);
                        process.signatureConfig = null;
                    }
                }
            } else {
                process.signatureConfig = null;
            }
            if (!this.isEmpty__(signatureHideConfig)) {
                try {
                    process.signatureHideConfig = JSON.parse(signatureHideConfig);
                } catch (error) {
                    console.warn(`流程判断签字列隐藏存在无法解析的数据:${signatureHideConfig}`);
                    process.signatureHideConfig = null;
                }
            } else {
                process.signatureHideConfig = null;
            }
            process.reviewMode = (reviewMode || "")
                .split(",")
                .filter((mode) => !this.isEmpty__(mode));
            // 请求审核字段码表
            if (!this.isEmpty__(currentZid) && !this.isEqual__(currentZid, -1)) {
                process.reviewSelectList = await this.processMixinRequest
                    .getSelectList({ zid: currentZid })
                    .catch((err) => {
                        this.errHand__(err);
                        throw err;
                    });
                /** 查询表 */
                const selects = {};
                process.reviewSelectList.forEach((item) => {
                    selects[item.zlabel] = item;
                    selects[item.value] = item;
                });
                process.reviewSelects = selects;
            }
            return this.process;
        },
        /**
         * 获取流程的参数
         * @virtucal
         * @returns {object}
         */
        _getProcessInfoParams() {
            return {
                fid: this.fid,
                rid: this.rid,
                fuid: this.uid,
                uid: this.$store.getters.uid,
                vid: this.vid,
            };
        },
        /**
         * 设置流程人员列表
         * @returns {Promise<[object]>} 成功设置并返回人员列表,失败抛错
         */
        async setProcessPersonList() {
            const personList = await this.processMixinRequest.getProcessUserList().catch((err) => {
                this.errHand__(err);
                throw err;
            });
            this.process.personList = personList.filter((person) => !this.isEmpty__(person));
            if (this.isEmpty__(this.process.personList)) {
                throw new kq.Err({
                    title: "配置错误",
                    type: "CONFIG",
                    message: "当前可能未配置审核人,请联系管理员~",
                    console: false,
                    from: "kq.ProcessMixin2.setProcessPersonList",
                });
            }
            return this.process.personList;
        },
        /**
         * 流程申请
         * @param {object} param0
         * @param {number} param0.nextUid 下一个人员号
         * @returns {Promise<object>} 成功初始化流程信息,失败抛错
         */
        async processApply({ nextUid }) {
            await this.processMixinRequest.commitProcess({ nextUid }).catch((err) => {
                this.errHand__(err);
                throw err;
            });
            return this.processInit(this.rid_);
        },
        /**
         * 审核流程提交
         * @param {object} param 入参对象
         * @param {number} [param.sid] 审核结果码值
         * @param {number} [param.value] 审核结果码值
         * @param {string} [param.svalue] 审核结果名称
         * @param {string} [param.label] 审核结果名称
         * @param {string} [param.zlabel] 审核结果码值别名
         * @param {string} [param.slabel] 审核结果码值别名
         * @param {string} [param.comment] 审核意见
         * @param {string} [param.signature] 审核签名
         * @param {number} [param.nextUid] 提交给下一个审核人(当下一个节点不为空,且结果为通过时,此参数不能为空)
         * @returns {Promise<void>} 失败抛错,成功提交流程
         */
        async processReview({
            sid,
            svalue,
            slabel,
            value,
            label,
            zlabel,
            comment,
            signature,
            nextUid,
        }) {
            let method;
            /** 兼容性查询码表对象 */
            const select = this.process.reviewSelectList.find(
                (select) =>
                    this.isEqual__(select.value, sid) ||
                    this.isEqual__(select.value, value) ||
                    this.isEqual__(select.zlabel, zlabel) ||
                    this.isEqual__(select.zlabel, slabel) ||
                    this.isEqual__(select.label, svalue) ||
                    this.isEqual__(select.label, label)
            );
            if (!this.isEmpty__(select)) {
                method = select.zlabel.kqMatch(/(pass)|(nopass)|(reject)/i, 0).toLowerCase();
                if (!this.isEmpty__(method)) {
                    if (
                        // 系统存在下一个节点
                        !this.isEqual__(this.process.nextReviewZid, -1) &&
                        !this.isEmpty__(this.process.nextReviewZid) &&
                        // 审核-通过
                        /_pass/i.test(zlabel || slabel) &&
                        // 下一个人员号为空
                        this.isEmpty__(nextUid)
                    ) {
                        throw new kq.Err({
                            message: `当前下一个审核节点不为空时,下一个人员id(nextUid)不能为空~`,
                            from: "kq.ProcessMixin.processCommit",
                            current: arguments[0],
                            console: false,
                        });
                    }
                    await this.processMixinRequest[method]({
                        signature,
                        comment,
                        sid: select.value,
                        uname: this.$store.getters.uname,
                        nextUid,
                        svalue: select.label,
                    }).catch((err) => {
                        this.errHand__(err);
                        throw err;
                    });
                    return;
                }
            }
            throw new kq.Err({
                title: "审核数据错误",
                message: "当前审核数据存在错误,未查询到正确的提交方法~请联系管理员~",
                from: "kq.ProcessMixin.processCommit",
                current: arguments[0],
            });
        },
        /**
         * 设置流程历史记录
         * @desc 设置历史记录列表
         * @desc 设置历史记录节点列表
         * @returns {Promise<void>} 失败抛错
         */
        async setProcessHistoryList() {
            const responseList = await Promise.all(
                this.processList.map(({ rid, pid }) => {
                    const requestList = [
                        this.processMixinRequest.getProcessZidInfo({
                            rid,
                            pid,
                        }),
                    ];
                    if (rid != 0) {
                        requestList.push(
                            this.processMixinRequest.getProcessHistoryList({
                                rid,
                            })
                        );
                    }
                    return Promise.all(requestList);
                })
            ).catch((err) => {
                this.errHand__(err);
                throw err;
            });
            this._ansysResponseList(responseList);
        },
        /**
         * 分析响应结果
         * @see 设置格式化this.nodeList_与this.historyList_
         * @param {[[]]} responseList 响应体
         * @returns void
         */
        _ansysResponseList(responseList) {
            let historyList2 = [];
            let nodeList = [];
            const pids = {},
                rids = {};
            responseList.forEach(([nodeInfo, historyList = []], index) => {
                const { pid, zidList = [], stat, currentZid } = nodeInfo;
                if (!pids[pid]) {
                    pids[pid] = true;
                    nodeList = nodeList.concat(
                        zidList.map((item) => ({
                            ...item,
                            pid,
                            stat,
                            currentZid,
                        }))
                    );
                }
                /** 查询时的经历号 */
                const rid = this.processList[index].rid;
                if (!rids[rid]) {
                    rids[rid] = true;
                    historyList2 = historyList2.concat(historyList);
                }
            });
            this.process.historyList = this.formatHistoryList(historyList2);
            this.process.nodeList = this.formatNodeList(nodeList, this.process.historyList);
        },
        /**
         * 历史信息格式化
         * @see 将历史信息中的审核信息解码
         * @see 给历史信息的流程名称添加颜色tag
         * @param {[object]} 历史信息表
         * @returns {[object]}
         */
        formatHistoryList(historyList) {
            const colorList = ["primary", "success", "warning", "danger", "info"];
            /** 流程名称列表(用来决定tag颜色) */
            const pnameList = [];
            return historyList.map(({ zid, zname, actionName, info, uname, date, pname, pid }) => {
                // 审核详情解码
                const { result, comment, signature } = kq.Request.Process.decodeReviewInfo(info);
                if (!pnameList.includes(pname)) {
                    pnameList.push(pname);
                }
                return {
                    pid,
                    id: zid,
                    name: actionName,
                    nodeName: zname,
                    uname,
                    date,
                    result,
                    comment,
                    signature,
                    tag: colorList[pnameList.length - 1],
                    pname,
                };
            });
        },
        /**
         * 格式化的节点列表
         * @see 添加颜色,状态,说明文本
         * @see 需要在formatHistoryList函数之后调用
         * @param {[object]} nodeList 节点列表
         * @param {[object]} historyList 历史信息表(用来自动带出节点表的流程)
         * @returns {[object]}
         */
        formatNodeList(nodeList, historyList) {
            /** 流程号信息表(用来判断是否为流程第一个节点) */
            const pidList = [];
            /** 是否再当前流程之前 */
            let beforeCurrent = true;
            /** 流程分组列表(用来判断是否进行流程扭转了,从而决定上一个流程全部已完成流程) */
            const groupList = _.groupBy(nodeList, "pid");
            /** 遍历的分组索引 */
            let groupIndex = 0;
            return nodeList.map(({ zid, zname, pid, currentZid, stat }, index) => {
                let color,
                    type,
                    label,
                    isCurrent = false;
                // 设置节点表的颜色与历史表的颜色一直
                // 可能存在因历史表的流程内容比节点表要少导致的颜色错误的bug
                const info = historyList.find(({ pid: pid2 }) => pid2 == pid);
                if (info) {
                    color = info.tag === "info" ? "qing" : info.tag;
                } else {
                    color = "purple";
                }
                /** 是否为流程的第一个节点 */
                let isFirst = false;
                if (!pidList.includes(pid)) {
                    pidList.push(pid);
                    isFirst = true;
                }
                // 上一个与当前的的不一致:分组+1
                if (nodeList[index - 1] && nodeList[index - 1].pid != pid) {
                    groupIndex++;
                }
                // 判断分组索引在最后一个流程之前:说明之前的流程全部完成处理
                if (groupIndex < groupList.length - 1) {
                    isCurrent = false;
                    type = "reviewed";
                    label = isFirst ? "已提交" : "已处理";
                }
                // 最后一个流程处理
                else {
                    // 当前节点未提交
                    if (stat === 0) {
                        currentZid = nodeList[0].zid;
                    }
                    if (zid == currentZid) {
                        beforeCurrent = false;
                        type = "reviewing";
                        if (stat === 3) {
                            label = "未通过";
                            type = "nopass";
                        } else if (stat === 2) {
                            label = "已完成";
                            type = "reviewed";
                        } else {
                            type = "reviewing";
                            label = isFirst ? "等待提交" : "处理中";
                        }
                    } else if (beforeCurrent) {
                        label = isFirst ? "已提交" : "已处理";
                        type = "reviewed";
                    } else {
                        label = "未处理";
                        type = "unreview";
                    }
                }
                return {
                    id: zid,
                    name: zname,
                    type,
                    label,
                    color,
                    isCurrent,
                };
            });
        },
    },
};
</script>
