import { rsaEncrypt } from "../../Utils/tools/encrypt";
import Tools from "../../Utils/tools";

export default class Login {
    /**
     * 登录
     * @param {object} param
     * @param {string} [param.openid] 微信openid参数
     * @param {string|number} [param.wid] 工号
     * @param {string} [param.password] 密码
     * @param {string} [param.imguuid] 图片验证码的uuid值
     * @param {string} [param.imgcode] 图片验证码
     * @param {string} [param.mobile] 手机号
     * @param {string} [param.adminCode] 管理员手机验证码
     * @param {string} [param.mobcode] 手机验证码
     * @param {string|number} [param.wxType=1] 公众号类型(0:酷奇公众号,1:学校公众号)
     * @returns {Promise<object>} 成功返回信息对象,失败抛错
     */
    async login({
        openid,
        wid,
        password,
        imguuid,
        imgcode,
        mobile = "",
        adminCode,
        mobcode,
        wxType = 1,
    }) {
        // 参数校验
        if (!openid && (!wid || !password)) {
            throw new Error(
                `[Request.login]参数错误!当openid为空时,工号(wid)与密码(password)必传!当前参数:${JSON.stringify(
                    arguments[0]
                )}`
            );
        }
        // 判断有openid->先用openid自动登录
        if (!Tools.isEmpty(openid) && Tools.isEmpty(password)) {
            const data = await this.request(
                {
                    url: "/api/weixin/getInfo",
                    params: { openId: openid },
                },
                arguments[0]
            ).catch((err) => {
                console.warn(err);
            });
            if (data) {
                return data;
            }
            return;
        }
        const params = {};
        if (openid) {
            params.url = "/api/weixin/wxBind";
            params.method = "post";
            params.data = {
                openId: openid,
                username: wid,
                password: rsaEncrypt(password),
                type: wxType,
            };
        } else {
            params.url = "/auth/login";
            params.method = "post";
            params.data = {
                username: wid,
                password: rsaEncrypt(password),
                code: imgcode,
                uuid: imguuid,
                isSAuth: mobcode ? 1 : 0,
                /** 加入adminCode是因为使用管理员验证码，不需要手机号，而若使用mobcode，isSAuth为1，则接口报错：手机号必填 */
                adminCode: mobcode || adminCode || null,
                mobile: mobile || null,
            };
        }
        return this.request(params, arguments[0]);
    }
    /**
     * 获取登录信息
     */
    getLoginInfo() {
        const url = "/auth/info";
        return this.request({ url, convert: url }, arguments[0]);
    }
    /**
     * 注销登录
     * @param {object} [param0={}] 参数对象
     * @param {number|boolean} [isSchool=true] 是否为校端公众号解绑(1|true:校端,0|false:酷奇)
     * @param {boolean} [isWx=false] 是否为微信
     * @returns Promise<void> 失败抛错
     */
    logout({ isSchool = true, isWx = false } = {}) {
        const requestList = [
            this.request({ url: "/auth/logout", method: "post" }, arguments[0]),
        ];
        if (isWx) {
            requestList.unshift(this.unbind({ isSchool }));
        }
        return Promise.all(requestList);
    }
    /**
     * 解绑微信
     * @param {object} [param0={}] 参数对象
     * @param {number|boolean} isSchool 是否为校端公众号解绑(1|true:校端,0|false:酷奇)
     * @returns {Promise<void>} 只会成功
     */
    unbind({ isSchool = true } = {}) {
        return this.request({
            url: "/api/weixin/wxUnbind",
            method: "post",
            data: {
                type: isSchool ? 1 : 0,
            },
        });
    }
    /**
     * 获取图片验证码
     * @returns {Promise<object>} 成功返回验证码对象,失败抛错
     * @example { url<string>: 验证码图片链接(base64), uuid<string>: 图片唯一编码, img<string>: 验证码图片链接(base64)}
     */
    async getImageCode() {
        const { img, uuid } = await this.request(
            "/auth/code",
            arguments[0]
        ).catch((err) => {
            throw err;
        });
        return { url: img, uuid, img };
    }
    /**
     * 重置密码
     * @param {object} param 参数表
     * @param {string} param.oldPassword 旧密码
     * @param {string} param.newPassword 新密码
     * @param {string} param.password 新密码
     * @returns {Promise<void>} 失败抛错
     */
    async resetPassword({ oldPassword, newPassword, password }) {
        if ((!password && !newPassword) || !oldPassword) {
            throw new kq.Err({
                message: "旧密码与新密码不能为空",
                current: arguments[0],
                from: "kq.Request.Login.resetPassword",
                type: false,
            });
        }
        return this.request(
            {
                url: "/auth/updatePwd",
                method: "post",
                params: {
                    oldPwd: rsaEncrypt(oldPassword),
                    newPwd: rsaEncrypt(newPassword || password),
                    confirmPwd: rsaEncrypt(newPassword || password),
                },
            },
            arguments[0]
        );
    }
    /**
     * 找回密码
     * @param {object} param 参数对象
     * @param {string} param.account 账号
     * @param {string} param.phone 手机号
     * @param {string} param.code 验证码
     * @returns {Promise<void>} 失败抛错
     */
    async findPassword({ account, phone, code }) {
        if (!account || !phone || !code) {
            throw new kq.Err({
                message:
                    "账号(account),手机号(phone),手机验证码(code)都不能为空",
                current: arguments[0],
                from: "kq.Reqeust.findPassword",
            });
        }
        return this.request(
            {
                url: "/auth/findPwd",
                params: {
                    id: account,
                    phone,
                    code,
                },
            },
            arguments[0]
        );
    }
    /**
     * 发送手机验证码
     * @param {object} param 参数对象
     * @param {string} param.phone 手机号
     * @param {string} [param.account] 账号(为空时为单独发送验证码,不验证查询账号的验证码)
     * @returns {Promise<void>} 失败抛错
     */
    async sendPhoneCode({ phone, account }) {
        if (!phone) {
            throw new kq.Err({
                message: "手机号(phone)不能为空",
                current: "",
                from: "kq.Request.sendPhoneCode",
            });
        }
        const param = { url: "", params: { phone } };
        // 查询账号
        if (account !== undefined) {
            param.url = "/auth/sendCode";
            param.params.id = account;
        }
        // 单纯发送验证码,比如注册账号
        else {
            param.url = "/auth/sendVerifyCode";
        }
        return await this.request(param, arguments[0]).catch((err) => {
            throw err;
        });
    }
    /**
     * 注册账号
     * @param {object} param 参数对象
     * @param {number|string} param.phoneCode 手机验证码
     * @param {string} param.password 密码
     * @param {object} otherField 其他字段信息
     * @param {Promise<object>} 成功返回用户信息对象,失败抛错
     */
    async register({ phoneCode, password, ...otherField }) {
        if (!phoneCode || !password) {
            throw new kq.Err({
                message:
                    "验证码(phoneCode)与密码(password)不能为空",
                current: arguments[0],
                from: "kq.Request.Login.register",
            });
        }
        const url = "/api/web/register";
        return await this.request(
            {
                url,
                method: "post",
                data: {
                    ...otherField,
                    password: rsaEncrypt(password),
                    verifyCode: phoneCode,
                },
                convert: url,
            },
            arguments[0]
        ).catch((err) => {
            throw err;
        });
    }
}
