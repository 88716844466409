/**
 * 请求类
 */

import _ from "lodash";
import Base from "./base";
import Tools from "../Utils/tools";

// 动态模块加载
const files = require.context("./modules", false, /\.js$/);
/** 模块对象 */
const mods = {};
/** 所有模块列表 */
const modList = [];
files.keys().forEach((file) => {
    const key = file.match(/(\w+).js$/)[1];
    const mod = files(file).default;
    mods[key] = mod;
    modList.push(mod);
});
/** 模块key对照表 */
const compares = ["all"].concat(
    _.map(mods, (val, key) => key.replace(/^\w/, ($) => $.toLowerCase()))
);

class Request extends Base {
    /**
     * 请求初始化
     * @param {object} param 参数
     * @param {function} param.loginOut 注销函数
     * @param {function} param.getToken 获取身份函数
     * @returns void
     */
    static use({ loginOut, getToken }) {
        if (_.isFunction(loginOut)) {
            Base.prototype.loginOut = loginOut;
        }
        if (_.isFunction(getToken)) {
            Base.prototype.getToken = getToken;
        }
    }
    /**
     * 请求模块混入(多继承)
     * @param {object} options 参数对象
     * @param {object} options.vm vue实例
     * @param {[string]|[object]} options.modules
     * @returns {object} 合并混入后的请求实例
     */
    static mixin(options) {
        const title = "参数错误",
            from = "kq.RequestMxin";
        let modules = [];
        let vm;
        if (options._isVue) {
            vm = options;
        }
        // 非对象错误
        else if (!_.isPlainObject(options)) {
            throw new kq.Err({
                title,
                from,
                message: "第一个参数必须为vue组件对象或plainObject对象",
                current: options,
            });
        } else {
            const { vm: vm2, modules: modules2 } = options;
            if (vm2 && !vm2._isVue) {
                throw new kq.Err({
                    title,
                    from,
                    message: "参数对象中vue组件(vm)类型不是vue组件类型",
                });
            }
            vm = vm2;
            if (!_.isEmpty(modules2)) {
                modules = modules.concat(modules2);
            }
            // 指定模块字符串错误
            if (
                modules.some(
                    (module) =>
                        _.isString(module) &&
                        !compares.includes(module.toLowerCase())
                )
            ) {
                throw new kq.Err({
                    title,
                    from,
                    message: `module参数为字符串时,必须包含在[${compares.join()}]范围内`,
                    current: modules,
                });
            }
        }
        if (modules.includes("all")) {
            modules = modList;
        }
        // 模块参数转换
        modules = modules.map((module) => {
            if (_.isString(module)) {
                return mods[module.replace(/^\w/, ($) => $.toUpperCase())];
            } else {
                return module;
            }
        });
        // 混入
        const Cls = Tools.mixin(Base, ...modules);
        const obj = new Cls({ ...options, vm });
        obj.getToken = Request.prototype.getToken || obj.getToken;
        obj.loginOut = Request.prototype.loginOut || obj.loginOut;
        return obj;
    }
    static install(Vue) {
        const request = new Request();
        Vue.prototype.$kqRequest = request.request.bind(request);
    }
}

// 注入直接模块对象(可以用过kq.Request.modulename访问对应子模块)
_.forEach(mods, (module, name) => {
    Request[name] = module;
});

export default Request;
