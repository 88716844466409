/**
 * console自定义
 * @see 附加快捷颜色函数
 * @author tonny
 * @date 2021-10-04
 */

import _ from "lodash";

// 颜色对照表
const colors = {
    red: "#e74c3c",
    orange: "#ffa026",
    yellow: "#f1c40f",
    purple: "#9b59b6",
    green: "#2ecc71",
    blue: "#199bfa",
    gray: "#95a5a6",
    qing: "#1abc9c",
};

export default {
    install() {
        _.forEach(colors, (color, name) => {
            if (window.console[name] === undefined) {
                window.console[name] = function() {
                    const args = Array.prototype.slice.call(arguments, 0);
                    console.log.apply(null, [
                        `%c${args[0]}`,
                        `color: ${color};`,
                        ...args.slice(1),
                    ]);
                };
            }
        });
    },
};
