/**
 * @desc 子系统相关仓库管理
 * @desc 只能酷奇新系统项目使用
 * @author tonny
 * @date 2022-08-17
 */

import Module from "../Module";
import Tools from "../../Utils/tools";

export default class ChildSystem extends Module {
    mapState() {
        /** 子系统缓存key */
        this._cacheKeySlabel = "KQLIB_STORE_CHILDSYSTEM_SLABEL";
        /** 请求对象 */
        this._request = kq.Request.mixin({ modules: ["select"] });
        return {
            /** 当前子系统id */
            sid: null,
            /** 当前子系统别名 */
            slabel: localStorage.getItem(this._cacheKeySlabel),
            /** 子系统配置码表 */
            selectList: [],
        };
    }
    /** 设置所属子系统码值 */
    mutationSetSid(state, sid) {
        state.sid = sid;
    }
    /** 设置所属于子系统码表 */
    mutationSetSelectList(state, selectList) {
        state.selectList = selectList;
    }
    /** 设置所属子系统别名 */
    mutationSetSlabel(state, slabel) {
        state.slabel = slabel;
        localStorage.setItem(this._cacheKeySlabel, slabel);
    }
    /** 获取所属子系统码值 */
    getterSid(state) {
        return state.sid;
    }
    /**
     * 获取所属子系统码表
     */
    getterSelectList(state) {
        return state.selectList;
    }
    /**
     * 获取子系统码值
     * @param {object} vuex vuex参数对象
     * @param {object} vuex.state 仓库存储对象
     * @param {function} vuex.commit 提交函数
     * @param {function} vuex.dispatch 异步请求函数
     * @param {string} [systemSlabel] 子系统别名(不传则返回系统配置的默认值)
     * @returns {Promise<number>} 成功返回子系统码值,失败抛错
     */
    actionGetSid({ state, commit, dispatch }, systemSlabel) {
        const error = {
            title: "配置错误",
            from: "kq.Store.ChildSystem.actionGetSid",
            type: "CONFIG",
            console: false,
        };
        return Tools.promise(async () => {
            let sid = state.sid;
            if (!sid) {
                /** 子系统码表 */
                let selectList = state.selectList;
                if (Tools.isEmpty(selectList)) {
                    selectList = await this._request
                        .getSelectList({ zlabel: "FN_SuoShuZiXiTong" })
                        .catch((err) => {
                            throw err;
                        });
                    if (Tools.isEmpty(selectList)) {
                        throw new kq.Err({
                            ...error,
                            current: selectList,
                            message:
                                "系统未配置所属子系统码表(FN_SuoShuZiXiTong)",
                        });
                    }
                    commit("setSelectList", selectList);
                }
                // 未传子系统别名:获取默认别名
                if (Tools.isEmpty(systemSlabel)) {
                    const configs = await dispatch(
                        "config/getSystem",
                        "CHILD_SYSTEM",
                        {
                            root: true,
                        }
                    ).catch((err) => {
                        throw err;
                    });
                    if (Tools.isEmpty(configs)) {
                        throw new kq.Err({
                            ...error,
                            message: "获取子系统参数为空,请联系管理员配置",
                        });
                    }
                    /** 默认子系统 */
                    const defaultSys = _.find(
                        configs,
                        ({ isDefault }) => isDefault && isDefault.value
                    );
                    systemSlabel =
                        _.get(defaultSys, "slabel.value") || "SV_RenShiXiTong";
                    commit("setSlabel", systemSlabel);
                }
                /** 当前子系统码项 */
                const currentSystemSelect = selectList.find(({ zlabel }) =>
                    Tools.isEqual(zlabel, systemSlabel)
                );
                if (currentSystemSelect) {
                    sid = currentSystemSelect.value;
                    commit("setSlabel", systemSlabel);
                    commit("setSid", sid);
                } else {
                    throw new kq.Err({
                        ...error,
                        message:
                            "系统未配置所属子系统码表不包含当前子系统,请确认",
                        current: systemSlabel,
                    });
                }
            }
            return sid;
        });
    }
    /**
     * 获取默认子系统别名
     * @param {object} vuex
     * @param {object} vuex.state 仓库存储对象
     * @param {function} vuex.dispatch 远程提交函数
     * @returns {Promise<string>} 成功返回别名,失败抛错
     */
    actionGetSlabel({ state, dispatch }) {
        return Tools.promise(async () => {
            let slabel = state.slabel;
            if (Tools.isEmpty(slabel)) {
                await dispatch("getSid").catch((err) => {
                    throw err;
                });
                slabel = state.slabel;
            }
            return slabel;
        });
    }
}
