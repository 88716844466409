<script>
/**
 * @description 更新字段混入
 * @see 用来监听表单更新,最后一次性保存使用
 * @author tonny
 * @date 2021-09-01
 */
</script>

<script>
import _ from "lodash";
import moment from "moment";

const SUPER = "KQ_FormChangeMixin";

export default {
    SUPER,
    data() {
        return {
            changeFieldList: [], // 需要更新的字段
            changeLoading: false, // 更改加载态(只有当beforeChange,change,afterChange为异步函数时有用)
        };
    },
    computed: {
        /**
         * 表单是否已经更改
         * @see this.changeFieldList
         * @returns {boolean} true:已更改
         */
        isChange() {
            return !this.isEmpty__(this.changeFieldList);
        },
    },
    methods: {
        /**
         * 撤销更改函数
         * @description 撤销针对字段的更改
         * @description 去除对应字段的changeFieldList数据
         * @param {object} field 字段对象
         * @returns void
         */
        revokeChangeItem(field) {
            this.$super(SUPER).revokeChangeItem(field);
            const currentFieldIndex = this.changeFieldList.findIndex(
                ({ id: zid }) => zid == field.zid
            );
            if (currentFieldIndex >= 0) {
                this.changeFieldList.splice(currentFieldIndex, 1);
            }
        },
        /**
         * 填充默认字段值
         * @description 将fiel.defaultValue填充进value值
         * @param {[object]} dataList 字段表
         * @returns void
         */
        fillDefaultFieldValue(dataList) {
            this.$super(SUPER).fillDefaultFieldValue(dataList);
            (dataList || []).kqEachDeep((field) => {
                if (
                    field.defaultValue &&
                    this.isEmpty__(field.value) &&
                    /^FR|P.*/.test(field.label || field.zlabel || "")
                ) {
                    let value = field.defaultValue;
                    if (field.type.includes("date")) {
                        value = moment(value).format("YYYY-MM-DD HH:mm:ss");
                    }
                    if (!this.isEmpty__(value)) {
                        // 多选码表
                        if (
                            field.type.includes("select-multi") ||
                            field.isRange
                        ) {
                            if (!_.isArray(value)) {
                                value = [value];
                            }
                            value = value
                                .map((sid) => {
                                    if (_.isString(sid)) {
                                        return sid;
                                    } else {
                                        return sid.kqLastItem();
                                    }
                                })
                                .join(",");
                        }
                        // 单选码表
                        else {
                            if (_.isArray(value)) {
                                value = value.kqLastItem();
                            }
                        }
                    }
                    this.$set(field, "value", value);
                    this.changeItem(field);
                }
            });
        },
        /**
         * 更改函数
         * @see 调用钩子beforeChange,afterChange
         * @returns void
         */
        changeItem(field) {
            this.$super(SUPER).changeItem(field);
            const beforeChangeRes = this.beforeChange(field);
            if (beforeChangeRes instanceof Promise) {
                this.changeLoading = true;
                beforeChangeRes
                    .then((res) => {
                        if (res !== false) {
                            this._changeItem(field);
                            const afterChangeRes = this.afterChange(field);
                            if (afterChangeRes instanceof Promise) {
                                afterChangeRes
                                    .then(() => {
                                        this.changeLoading = false;
                                    })
                                    .catch(() => {
                                        this.changeLoading = false;
                                    });
                            } else {
                                this.changeLoading = false;
                            }
                        }
                    })
                    .catch(() => {
                        this.changeLoading = false;
                    });
            } else if (beforeChangeRes !== false) {
                this._changeItem(field);
                const afterChangeRes = this.afterChange(field);
                if (afterChangeRes instanceof Promise) {
                    afterChangeRes
                        .then(() => {
                            this.changeLoading = false;
                        })
                        .catch(() => {
                            this.changeLoading = false;
                        });
                } else {
                    this.changeLoading = false;
                }
            }
        },
        /**
         * 更改核心函数
         * @see 设置this.updateFieldList
         * @returns void
         */
        _changeItem(field) {
            const id = this._getItemId(field);
            const value = this._getItemValue(field);
            const currentFieldIndex = this.changeFieldList.findIndex(
                ({ id: zid }) => zid == id
            );
            if (currentFieldIndex >= 0) {
                this.changeFieldList.splice(currentFieldIndex, 1);
            }
            this.changeFieldList.push({ ...field, id, value });
        },
        /**
         * 更改前钩子
         * @param {object} field 字段信息对象,必须包含id与value
         * @returns {boolean} false:终端更新函数(更新结果不会存储)
         */
        beforeChange(field) {},
        /**
         * 更改后钩子
         * @param {object} field 字段信息对象,必须包含id与value
         * @returns void
         */
        afterChange(field) {},
        /**
         * 删除某个更改
         * @param {string|number} id 字段号
         * @returns {[object]} 更改后的更新数据表
         */
        deleteChange(id) {
            const index = this.changeFieldList.findIndex(
                ({ id: zid }) => zid == id
            );
            if (index >= 0) {
                this.changeFieldList.splice(index, 1);
            }
            return this.changeFieldList;
        },
        /**
         * 清空更改
         * @returns {Promise<void>}
         */
        clearChange() {
            this.changeFieldList = [];
            return Promise.resolve();
        },
        /**
         * 获取用来存入changeFieldList中的字段号
         * @see 可以通过重写此函数定制获取特殊的字段号
         * @param {object} field 字段对象{id, id, value, mid, ...}
         * @returns {string}
         */
        _getItemId(field) {
            return field.zid;
        },
        /**
         * 获取用来存入changeFieldList中的字段值
         * @see 可以通过重写此函数定制获取特殊的值(比如码值号(mid))
         * @param {object} field 字段对象{id, zid, value, mid, ...}
         * @returns {string}
         */
        _getItemValue(field) {
            return field.value;
        },
    },
};
</script>
