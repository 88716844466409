// import "animate.css";
// import "kooci-lib/src/styles/index.less";
import { registerCmp } from "../Tool/tool.js";
import TipDirective from "./Directive/Tip/index.js";
import ConfirmDirective from "./Directive/Confirm/index.js";
import PopConfirm from "./Directive/Confirm/Confirm.vue";
import BaseDialog from "./Dialog/Base.vue";
import DialogNormal from "./Dialog/Normal.vue";
import Breadcrump from "./Breadcrump/Breadcrump.vue";
import SquareIconBtn from "./Button/SquareIconBtn/SquareIconBtn.vue";
import Screen from "./Screen/Screen.vue";
import ScreenOld from "./ScreenOld/Screen.vue";
import QrcodePopup from "./QRCode/Popup.vue";
import QrcodeMobileUpload from "./QRCode/MobileUpload.vue";
import FormTable from "./Form/Table/Table.vue";
import Form from "./Form/Layout/Form.vue";
import FormItem from "./Form/Layout/FormItem.vue";
import FormLayout from "./Form/Layout/Layout.vue";
import vModal from "vue-js-modal";
import Dialog from "./Dialog/Base2.vue";
import PopSelect from "./Pop/Select/Pop.vue";
import vClickOutSide from "v-click-outside";
import FixedToolbar from "./Toolbar/FixedToolbar.vue";
import RefreshBtn from "./Button/RefreshBtn/RefreshBtn.vue";
import BtnPublic from "./Button/Public.js";
import DetailMixin from "./Form/Detail/DetailMixin.vue";
import DetailMixin2 from "./Form/Input/Detail/DetailMixin.vue";
import ListMixin from "./Form/List/ListMixin.vue";
import SaveMixin from "./Form/Detail/SaveMixin.vue";
import CardNumber from "./Card/Number/Number.vue";
import ButtonComponent from "./Button/Button.vue";
import SpreadButton from "./Button/SpreadBtn/SpreadBtn.vue";
import CardInfoexpand from "./Card/InfoExpand/InfoExpand.vue";
import Lowcode from "./Lowcode/Lowcode.vue";
import CardAdd from "./Card/Add/Add.vue";
import LowcodeComponentMixin from "./Lowcode/Components/Mixin.vue";
import ScreenCard from "./Card/ScreenCard/ScreenCard.vue";
import Table from "./Table/Table.vue";
import ParamConfig from "./ParamConfig/KqParamConfig.vue";
import ParamConfigDialog from "./ParamConfig/ParamConfigDialog.vue";
import TableHeader from "./Form/Header/Header.vue";
import DialogMixin from "./Dialog/DialogMixin.vue";
import Layout from "./Layout/Layout.vue";
import FieldEditor from "./FieldEditor/FieldEditor.vue";
import TableConfig from "./Table/TableConfig.vue";

const components = {
    DialogMixin,
    ScreenCard,
    TipDirective,
    ConfirmDirective,
    BaseDialog,
    NormalDialog: DialogNormal,
    DialogNormal,
    Breadcrump,
    SquareIconBtn,
    Screen,
    ScreenOld,
    QrcodeMobileUpload,
    QrcodePopup,
    FormTable,
    Form,
    FormItem,
    FormLayout,
    Dialog,
    PopSelect,
    FixedToolbar,
    RefreshBtn,
    PopConfirm,
    BtnPublic,
    DetailMixin,
    DetailMixin2,
    ListMixin,
    SaveMixin,
    CardNumber,
    ButtonComponent,
    SpreadButton,
    CardInfoexpand,
    Lowcode,
    CardAdd,
    LowcodeComponentMixin,
    Table,
    TableHeader,
    ParamConfig,
    ParamConfigDialog,
    Layout,
    FieldEditor,
    TableConfig,
};

const asyncComponentList = [
    {
        name: "kq-card-type",
        component: () => import(/* webpackChunkName: "kqPcCardType" */ "./Card/Type/Type.vue"),
    },
    {
        name: "kq-card-tab",
        component: () => import(/* webpackChunkName: "kqPcCardTab" */ "./Card/Tab/Tab.vue"),
    },
    {
        name: "kq-card-tab-item",
        component: () =>
            import(/* webpackChunkName: "kqPubTab" */ "../PubComponents/Mixin/Tab/TabItem.vue"),
    },
    {
        name: ["kq-preview", "kqPreview"],
        component: () => import(/* webpackChunkName: "kqPcPreview" */ "./Preview/Preview.vue"),
    },
    {
        name: "kq-bottom-toolbar",
        component: () =>
            import(/* webpackChunkName: "kqPcBottomToolbar" */ "./Toolbar/BottomToolbar.vue"),
    },
    {
        name: ["kq-inputcell", "kq-inputcell2"],
        component: () =>
            import(/* webpackChunkName: "kqPcInputcell" */ "./Form/Input/Inputcell.vue"),
    },
    {
        name: "kq-inputcell-old",
        component: () =>
            import(/* webpackChunkName: "kqPcInputcellOld" */ "./Form/InputCell/InputCell.vue"),
    },
    {
        name: "kq-inputcell-group",
        component: () =>
            import(/* webpackChunkName: "kqPcInputcell" */ "./Form/Input/InputcellGroup.vue"),
    },
    {
        name: "kq-process-read-dialog",
        component: () => import(/* webpackChunkName: "kqPcProcess" */ "./Process/Dialog.vue"),
    },
    {
        name: "kq-process-read",
        component: () => import(/* webpackChunkName: "kqPcProcess" */ "./Process/Read.vue"),
    },
    {
        name: "kq-screen-field",
        component: () => import(/* webpackChunkName: "kqScreenField" */ "./Screen/KqScreen.vue"),
    },
    {
        name: "kq-sbutton",
        component: () => import(/* webpackChunkName: "kqPcSbutton" */ "./SuperButton/Button.vue"),
    },
    {
        name: "kq-chart-process",
        component: () =>
            import(/* webpackChunkName: "kqChartProcess" */ "./Chart/Process/ChartProcess.vue"),
    },
    {
        name: "kq-excel",
        component: () => import(/* webpackChunkName: "kqExcel" */ "./Excel/Excel.vue"),
    },
    {
        name: "kq-calendar",
        component: () => import(/* webpackChunkName: "kqCalendar" */ "./Calendar/KqCalendar.vue"),
    },
];

const kqPc = registerCmp("kqPc", components, (Vue) => {
    Vue.use(vModal);
    Vue.use(vClickOutSide);
    asyncComponentList.forEach(({ name, component }) => {
        if (!_.isObject(name)) {
            name = [name];
        }
        name.forEach((cmpName) => {
            Vue.component(cmpName, component);
        });
    });
});

export default kqPc;

export {
    TipDirective,
    ConfirmDirective,
    BaseDialog,
    Breadcrump,
    SquareIconBtn,
    Screen,
    TableConfig,
};
