/* eslint-disable */

import _ from "lodash";
import FormConfig from "kooci-lib/src/PubComponents/Form/config.js";
import Err from "kooci-lib/src/PubComponents/Utils/Err";

const FIELD_NAME_KEY = "zname";
const FIELD_ID_KEY = "zid";
const FIELD_LABEL_KEY = "zlabel";

const Config = {
    Convert: {
        name: "数据转换器",
        /** 方法 */
        methods: {
            getApiConvert(api) {
                if (!api || !_.isString(api)) {
                    throw new Err({
                        title: "参数错误",
                        message: "查询值(api)必须为非空字符串",
                        from: "Config.Convert.methods.getApiConvert",
                        current: api,
                    });
                }
                api = api.toUpperCase();
                /** 抽取数据的源 */
                let from;
                const result = Config.Convert.tables.apiConvertList.find(
                    ({ api: api2 }) => {
                        if (_.isArray(api2)) {
                            return api2.find((path) => {
                                // apiItem直接为链接
                                if (_.isString(path)) {
                                    return path.toUpperCase() === api;
                                }
                                // item存在更多信息,提取并判断
                                else if (_.isPlainObject(path)) {
                                    if (path.url.toUpperCase() === api) {
                                        from = path.from;
                                        return true;
                                    }
                                }
                            });
                        } else {
                            return api2.toUpperCase() === api;
                        }
                    }
                );
                if (result) {
                    return _.cloneDeep({ ...result, from });
                }
            },
        },
        /** 参数表 */
        tables: {
            apiConvertList: [
                {
                    name: "字段请求",
                    api: [
                        "Field",
                        "/api/temp/view",
                        "/api/view/DIY",
                        { url: "/api/view", from: "viewTableInfo" },
                        "/api/common/dict/queryFormDataByRootBillIdAndBillIdAndViewId",
                        "/api/common/dict/queryFormDataByViewIdAndRootBillId",
                    ],
                    compares: {
                        [FIELD_ID_KEY]: ["id", "nodeId", "field", "NodeID"], // 顺序不可变
                        [FIELD_NAME_KEY]: ["title", "name", "nodeName"], // 顺序不可变
                        readonly: [
                            "ReadOnly",
                            "readOnly",
                            "props.disabled",
                            "isLock",
                        ],
                        signatureDisabled: ["signatureDisabled"],
                        signatureRequired: ["signatureRequired"],
                        x: "x",
                        y: "y",
                        i: "i",
                        w: "w",
                        h: "h",
                        required: ["isRequire"],
                        [FIELD_LABEL_KEY]: ["label"],
                        dateFormat: ["dateFormat", "props.format", "dateType"],
                        selectList: ["options", "props.options"],
                        slabel: ["dictLabel"],
                        type: ["checkId", "nodeType", "NodeType"],
                        children: ["children"],
                        value: ["value.data", "nodeValueId", "value"], // 顺序不可变
                        newValue: "newValue", // 即将修改的值(确认字段)
                        tid: ["pid", "tempId"], // 顺序不可变
                        headerVid: ["subShowViewId"], // 表头vid
                        bodyVid: ["subEditViewId"], // 表内容vid
                        // dataList: ["value.data"],
                        headerList: ["value.title"],
                        rid: ["rowId"],
                        uid: ["rootBillId"],
                        status: ["billStatus"],
                        help: ["nodeNameNotice"], // 字段说明
                        isApprove: [""],
                        hide: ["hide"],
                        toNowEnabled: ["toNowEnabled"], // 是否启用至今
                        isReview: "isConfirm", // 字段是否需要审核
                        fid: ["funcId"],
                        isTitle: "isTitle",
                        ptid: "parentTempId",
                        svalue: ["auditStr1", "auditStr2", "valueLabel"], // 显示值
                        accept: "fileTypes", //文件类型校验
                        maxLength: "dataLen", //textarea最大长度
                        isReviewEnd: "type", // 是否终审字段
                        // reviewComment: "auditStr1",
                        // reviewSignature: "auditStr2",
                        // reviewDate: "auditStr2",
                    },
                    isStrict: false,
                    convertKeyHook(params) {
                        const { key, value, parent, to } = params;
                        // 字段的分类名称不转换label(用是否有children和是否不为码表综合判断)
                        if (
                            to === FIELD_LABEL_KEY &&
                            parent.children !== undefined &&
                            parent.level === undefined
                        ) {
                            return FIELD_NAME_KEY;
                        }
                        // 视图字段分类没有id
                        if (
                            to === FIELD_ID_KEY &&
                            parent.level === undefined &&
                            parent.children !== undefined
                        ) {
                            return "";
                        }
                        // 码表项不转换label
                        if (
                            to === FIELD_LABEL_KEY &&
                            (parent.level !== undefined ||
                                parent.dictLabel !== undefined)
                        ) {
                            return "label";
                        }
                        // 去除码表的类型
                        if (to === "type" && parent.level !== undefined) {
                            return;
                        }
                        // 子行集
                        // if (to === "value" && FormConfig.isRowset(parent)) {
                        //     return "headerList";
                        // }
                        return to;
                    },
                    convertValueHook(params) {
                        const { parent, from, to, value, result } = params;
                        // 类型组合转换
                        if (to === "type") {
                            let type = "text-text";
                            const checkId = parent.checkId;
                            const nodeType =
                                parent.nodeType === undefined
                                    ? parent.NodeType
                                    : parent.nodeType;
                            if (
                                checkId !== undefined &&
                                nodeType !== undefined
                            ) {
                                type = FormConfig.getType(nodeType, checkId);
                            } else {
                                console.log("未匹配", parent);
                            }
                            // 针对空value进行补值
                            if (
                                !type.includes("rowset") &&
                                parent.value === undefined
                            ) {
                                result.value = "";
                            }
                            return type;
                        }
                        // 布尔型转换
                        if (to === "required") {
                            return !Boolean(Number(value));
                        }
                        // 布尔型转换
                        if (
                            [
                                "readonly",
                                "hide",
                                "isReview",
                                "isTitle",
                                "toNowEnabled",
                                "signatureDisabled",
                                "signatureRequired"
                            ].includes(to)
                        ) {
                            return Boolean(Number(value));
                        }
                        if (to === "isReviewEnd" && value) {
                            return value === "end_audit" ? true : false;
                        }
                        // 日期格式化
                        if (to === "dateFormat" && value) {
                            return value
                                .replace("%Y", "YYYY")
                                .replace("%m", "MM")
                                .replace("%d", "DD")
                                .replace("%H", "HH")
                                .replace("%i", "mm")
                                .replace("%s", "ss");
                        }
                        // 审核型字段的值转换
                        if (
                            to === "svalue" &&
                            ("auditStr1" === from || "auditStr2" === from) &&
                            value &&
                            FormConfig.isReview(parent)
                        ) {
                            const [signature, time, uname] = (
                                parent.auditStr2 || ""
                            ).split(";");
                            // 额外注入审核值(兼容性)
                            result.rvalue = {
                                uname: uname || "",
                                comment: parent.auditStr1 || "",
                                datetime: time,
                                signature,
                            };
                            return result.rvalue;
                        }
                        // 码表型(多选,单选)值转换
                        if (
                            to === "value" &&
                            value &&
                            _.isArray(value) &&
                            FormConfig.isSelect(parent)
                        ) {
                            let type = FormConfig.getType(parent);
                            if (type.includes("multi")) {
                                return value.map((item) => {
                                    if (_.isArray(item)) {
                                        return item.kqLastItem();
                                    } else {
                                        return item;
                                    }
                                });
                            } else {
                                return value.kqLastItem();
                            }
                        }
                        // 多附件型转换
                        if (
                            to === "value" &&
                            value &&
                            FormConfig.isFile(parent)
                        ) {
                            let type = FormConfig.getType(parent);
                            if (type.includes("multi") && _.isString(value)) {
                                return value.split(";");
                            }
                        }
                        return value;
                    },
                },
                {
                    name: "字段信息转码表",
                    api: "FieldToSelect",
                    isStrict: true,
                    becareful:
                        "此转化器只能转换Field之后的结果,也就是把字段作为码表输出,字段内的码表信息将被去除",
                    compares: {
                        label: [FIELD_NAME_KEY, "label"],
                        children: ["children"],
                        value: ["value", FIELD_ID_KEY],
                        type: "type",
                    },
                },
                {
                    name: "查询样张号",
                    api: [
                        "GetTid",
                        "/api/process/queryDetail",
                        {
                            url: "/api/common/dict/queryViewsByFuncId",
                            from: "0",
                        },
                    ],
                    isStrict: false,
                    compares: {
                        tid: ["tempId"],
                        pid: ["billid"],
                        vid: ["viewID"],
                        // vname: ["viewName"],
                        appid: ["appId"],
                        dataAuth: "dataAuthority",
                        help: "helpText",
                        isHelp: "isHelpText",
                        isEdit: "isEdit",
                        video: "videoUrl",
                        authId: "authorityId",
                        appid: "appId",
                        // pname: "col1",
                        // childSystem: "appName",
                        // startName: "funcName",
                        // screenValue: "enter_rule",
                        // screenPreloadValue: "enter_rule_mark",
                        // hasNext: "next_flow",
                    },
                },
                {
                    name: "查询码表",
                    api: [
                        "selectList",
                        "/api/common/dict/getDict",
                        "/api/common/dict/labelDict",
                    ],
                    isStrict: true,
                    compares: {
                        value: "id",
                        label: "name",
                        innerCode: "innerCode",
                        children: "children",
                        zlabel: "label",
                        aliases: ["aliasOne", "aliasTwo", "alisaThree"],
                    },
                    convertValueHook({ to, value, parent }) {
                        // 空数据判断
                        if (to === "label") {
                            return parent.name || parent.aliasOne;
                        }
                        if (to === "aliases") {
                            return [
                                parent.aliasOne,
                                parent.aliasTwo,
                                parent.alisaThree,
                            ];
                        }
                        return value;
                    },
                },
                {
                    name: "查询普通(行集)列表",
                    api: [
                        "list",
                        "/api/common/dict/queryRowDataList",
                        "/api/common/dict/queryRowsDataByForm",
                        "/standalone/queryRowDataList",
                        "/api/commonService/queryRowDataList"
                    ],
                    isStrict: false,
                    compares: {
                        sum: "num",
                        dataList: "content",
                        rid: "rowId",
                        uid: "rootBillId",
                        pid: "flowId",
                        isApprove: "billStatus",
                        currentProcessZid: "functionId",
                        isLock: "isLock",
                    },
                    convertValueHook(params) {
                        const { to, value } = params;
                        // 转换锁定布尔值
                        if (to === "isLock") {
                            return Boolean(Number(value));
                        }
                        // 已归档布尔转换
                        if (to === "isApprove") {
                            return String(value).includes("已");
                        }
                        return value;
                    },
                },
                {
                    name: "查询字段数量",
                    api: "/api/common/dict/fieldsCount",
                    isStrict: false,
                    compares: {
                        nodeIds: "zidList",
                        tempId: "tid",
                    },
                    convertValueHook(params) {
                        const { to, value } = params;
                        return value;
                    },
                },
                {
                    name: "获取登录信息",
                    api: ["userInfo", "/auth/info", "/api/web/register"],
                    isStrict: true,
                    compares: {
                        permissionList: "roles",
                        uname: ["user.nickName", "user.user.nickName"],
                        uid: ["user.id", "user.user.id"],
                        wid: ["user.username", "user.user.username"],
                        did: ["user.deptId", "user.user.deptId"],
                        dname: ["user.dept.name", "user.user.dept.name"],
                        himg: ["user.avatarPath", "user.user.avatarPath"],
                        schoolId: ["user.sysCode", "user.user.sysCode"], // 学校代码
                        token: "token",
                        permissionList: "roles",
                        appid: "appId",
                        personalSignature: "signUrl", // 个人签名信息
                    },
                },
                {
                    name: "查询视图信息",
                    api: [
                        "viewInfo",
                        "/api/view",
                        "/api/view/DIY",
                        "/api/common/dict/queryViewsByFuncId",
                    ],
                    isStrict: false,
                    compares: {
                        ftype: "funcTempType", // 功能类型
                        fid: "funcId",
                        vname: "viewName",
                        vid: ["viewID", "viewId"],
                        zid: ["nodeId"],
                        zname: ["nodeName"],
                        tid: "tempId", // 样张号
                        help: ["helpText", "viewMemo"], // 视图说明,
                        video: "videoUrl", // 视频说明
                        helpEnable: "isHelpText", // 帮助是否可用用
                        authId: "authorityId",
                        dataAuth: "dataAuthority", // 数据权限
                        appid: "appId",
                        ptid: "parentTempId", // 直属行集样张号
                        lowcodeList: "lowcode", // 低码配置
                        linkageList: "lowCodeLayout", // 字段联动表
                    },
                    convertValueHook({ to, value }) {
                        if (["lowcodeList", "linkageList"].includes(to)) {
                            /** 转换后的值 */
                            let convertValue = "";
                            try {
                                convertValue = JSON.parse(decodeURI(value));
                            } catch (error) {
                                console.error(error);
                                new kq.Err({
                                    title: "JSON转换出错",
                                    typpe: "DATA",
                                    message:
                                        "当前配置数据不是标准的JSON格式,请联系管理员",
                                    from: "/api/app/funcView",
                                    current: decodeURI(value),
                                });
                            }
                            return convertValue;
                        }
                        return value;
                    },
                },
                {
                    name: "查询应用表",
                    isStrict: false,
                    api: [
                        "AppList",
                        "/api/userRoles/queryAppsByUserCode",
                        "/api/homepage/queryCollectList",
                        "/api/homepage/queryHotApp",
                    ],
                    compares: {
                        color: "appBgColor",
                        label: "appMemo",
                        icon: "icon",
                        appid: "id",
                        isCollect: "isCollect",
                        sort: "sort",
                        name: "name",
                        version: "version",
                        children: "children",
                        stat: "status",
                    },
                },
            ],
        },
    },
    Field: {
        tables: {
            /** 命名规范 */
            namespace: {
                FIELD_NAME_KEY: "zname",
                FIELD_ID_KEY: "zid",
                FIELD_LABEL_KEY: "zlabel",
            },
        },
    },
};

export default Config;
