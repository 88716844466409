import { render, staticRenderFns } from "./Normal.vue?vue&type=template&id=e8c06c24"
import script from "./Normal.vue?vue&type=script&lang=js"
export * from "./Normal.vue?vue&type=script&lang=js"
import style0 from "./Normal.vue?vue&type=style&index=0&id=e8c06c24&prod&lang=less"


/* normalize component */
import normalizer from "!../../../../kooci-saas-recruit/node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports