/**
 * @description 组件批量加载注册
 * @author tonny
 * @date 2022-06-02
 */

const configList = [];
const configFiles = require.context("./", true, /config\.js/);

configFiles.keys().forEach(path => {
    const lowcode = configFiles(path).default;
    const moduleName = path.split("/")[1];
    configList.push({
        lowcode,
        component: resolve =>
            require([
                `kooci-lib/src/PcComponents/Lowcode/Components/${moduleName}/${moduleName}.vue`,
            ], resolve),
    });
});

export default configList;
