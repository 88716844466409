<script>
/**
 * @description 数据错误混入组件
 * @see 抽象组件,不能被作为组件单独使用
 * @see 提供错误处理相关方法与逻辑
 * @author tonny
 * @date 2021-10-08
 */
</script>

<script>
import Notify from "../../Notify/Notify.vue";
import Tools from "../../Utils/tools/index.js";

export default {
    data() {
        return {
            /**  恢复错误请求函数列表(暂不用) */
            restoreRequestList_: [],
        };
    },
    methods: {
        /**
         * 错误处理函数
         * @param {Error} err 错误Error对象
         * @param {Function} request 错误请求(用来恢复|重复请求)
         * @returns void
         */
        errHand_(err, request) {
            Tools.asyncChain([
                () => this.beforeErrHand_(err, request),
                () => this._errHand_(err, request),
                () => this.afterErrHand_(err, request),
            ]);
        },
        /**
         * 处理错误前钩子
         * @see 处理错误之前调用
         * @returns void
         */
        beforeErrHand_(err, request) {},
        /**
         * 错误处理
         * @returns void
         */
        _errHand_(err, request) {
            // 非自定义的系统报错!
            if (err.message && err.stack && err.name === undefined) {
                const [, row, col] = err.stack
                    .split("\n")[1]
                    .match(/:(\d+):(\d+)/);
                err.message += `错误详情代码行==行:${row} 列:${col}`;
            }
            const msg = err.message || err.msg;
            if ((msg && msg.includes("401")) || err.code == "401") {
                err = { msg: "登录已失效" };
            }
            if (request) {
                this.restoreRequestList_.push(request);
            }
            if (this.$err && err.name == this.$err.ERR_CANCEL_AJAX.name) {
                // 统一取消请求调用
                this.loading__ = false;
                // 取消请求操作
                return true;
            }
            if (this.errHand__) {
                this.errHand__(err);
            } else {
                Notify.open({
                    type: "error",
                    title: "错误信息",
                    message: err.message || err.msg || err,
                    info: err.message || err.msg || err,
                });
            }
        },
        /**
         * 处理错误之后钩子
         * @see 处理错误后调用
         * @returns void
         */
        afterErrHand_(err, request) {},
    },
};
</script>