<script>
/**
 * 自动向导混入
 * @author tonny
 * @date 2023-03-01
 */
</script>

<script>
export default {
    inject: {
        /** 向导编辑是否可用 */
        getGuideEditEnabled: {
            default: () => {
                return () => false;
            },
        },
    },
    props: {
        /** 自动向导配置 */
        guideConfig: {
            type: Object,
            default: () => ({
                id: null,
                disabled: false,
            }),
        },
    },
    computed: {
        /**
         * 向导编辑是否可用
         * @returns {boolean} true:可用
         */
        guideEditEnabled2() {
            return !this.guideConfig.disabled && this.getGuideEditEnabled();
        },
    },
    updated() {
        setTimeout(() => {
            if (this.guideEditEnabled2) {
                if (!this.guideEditInitDebounce) {
                    this.guideEditInitDebounce = this.guideEditInit.kqDebounce(100);
                }
                this.guideEditInitDebounce();
            }
        }, 200);
    },
    watch: {
        guideEditEnabled2(enabled) {
            if (enabled) {
                if (!this.guideEditInitDebounce) {
                    this.guideEditInitDebounce = this.guideEditInit.kqDebounce(100);
                }
                this.guideEditInitDebounce();
            }
        },
    },
    methods: {
        /**
         * 向导编辑初始化
         * @returns void
         */
        guideEditInit() {
            const self = this;
            $(".kq-guide").each(function () {
                if ($(this).children(".kq-guide--btn").length === 0) {
                    const btn = $(
                        `<button draggable="true" ${
                            this.id ? "" : `disabled title="联系开发设置id"`
                        } class="v-green-bg-important v-green-dark-bg-hover-important kq-guide--btn kqiconfont kqicon-guide-full"></button>`
                    )
                        .on("click", self._guideOnClick)
                        .one("dragend", function ({ offsetX, offsetY }) {
                            $(this).css({
                                transform: `translate(${offsetX}px,${offsetY}px)`,
                            });
                        });
                    $(this).append(btn);
                }
                const positionStyle = $(this).css("position");
                if (positionStyle !== "relative" && positionStyle !== "absolute") {
                    $(this).css("position", "relative");
                }
            });
            this.$bus.$emit("guide-btn-updated");
        },
        /**
         * 点击事件
         */
        _guideOnClick(event) {
            event.stopPropagation();
            this.$bus.$emit("guide-click", {
                target: event.target,
                id: event.target.id,
            });
        },
    },
};
</script>

<style lang="less">
.kq-guide {
    box-sizing: border-box;
    .kq-guide--btn {
        width: 30px;
        height: 30px;
        border-radius: 100%;
        font-size: 15px;
        border: 2px solid rgba(255, 255, 255, 0.7);
        opacity: 0.6;
        color: white;
        transition: all 0.1s;
        outline: none;
        font-weight: bold;
        cursor: pointer;
        right: 0;
        top: 0;
        position: absolute;
        z-index: 1999;
        * {
            cursor: pointer;
        }
    }
    .kq-guide--btn[disabled] {
        cursor: no-drop;
    }
    .kq-guide--btn:hover {
        transform: scale(1.2);
        opacity: 1 !important;
        transform-origin: center center;
        z-index: 5001;
        border-color: white;
    }
}
.kq-guide--disabled {
    .kq-guide {
        .kq-guide--btn {
            display: none;
        }
    }
}
// 解决动画开启后fixed元素被遮挡的问题
div#driver-highlighted-element-stage,
div#driver-page-overlay {
    background: transparent !important;
    outline: 5000px solid rgba(0, 0, 0, 0.75);
}
</style>
