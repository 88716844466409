import ErrorStackParser from "error-stack-parser";
import sourceMap from "source-map";

let isInit = false;

/**
 * 创建sourceMap解析
 * @param {string} url sourceMap文件地址
 * @param {number} line 行号
 * @param {number} column 列号
 * @returns {Promise<{source:string,line:number,column:number}>} 解析结果
 */
function createSourceMap(url, line, column) {
    return new Promise(async (resolve, reject) => {
        // 请求映射文件
        const xhr = new XMLHttpRequest();
        xhr.open("GET", url);
        xhr.responseType = "blob";
        xhr.onload = () => {
            if (xhr.status === 200) {
                const reader = new FileReader();
                reader.readAsText(xhr.response, "utf-8");
                reader.onload = async function () {
                    let sourceMapInfo;
                    try {
                        sourceMapInfo = JSON.parse(reader.result);
                    } catch (error) {
                        reject(new Error("映射文件解析失败"));
                    }
                    if (!isInit) {
                        sourceMap.SourceMapConsumer.initialize({
                            "lib/mappings.wasm":
                                "https://kqlib.kooci.net/public/source-map/0.7.4/lib/mappings.wasm",
                        });
                        isInit = true;
                    }
                    // 解析映射源
                    sourceMap.SourceMapConsumer.with(sourceMapInfo, null, (consumer) => {
                        const info = consumer.originalPositionFor({
                            line,
                            column,
                        });
                        if (
                            info.source === undefined &&
                            info.line === undefined &&
                            info.column === undefined
                        ) {
                            reject(new Error("映射文件解析失败,解析结果为空"));
                        } else {
                            resolve(info);
                        }
                    });
                };
            } else {
                reject(new Error(`映射文件加载失败(${url})`));
            }
        };
        xhr.onerror = () => reject(new Error(`映射文件加载失败(${url})`));
        xhr.send();
    });
}

/**
 * 错误处理
 * @param {Error} error 错误信息Error对象
 * @returns {Promise<string>} 错误信息
 */
async function errorHandler(error, fileList) {
    let info = `\n错误输出:${error.toString()}`;
    const infoList = await Promise.all(
        fileList.map(
            ({ url, line, column }) =>
                new Promise((resolve) => {
                    createSourceMap(`${url}.map`, line, column)
                        .then(resolve)
                        .catch((err) => {
                            resolve({
                                source: `${url}:sourceMap解析失败,失败内容:${err.toString()}`,
                                line: line,
                                column: column,
                            });
                        });
                })
        )
    );
    infoList.forEach(({ source, line, column }) => {
        info += `\n错误文件:${source};行:${line};列:${column}`;
    });
    return info;
}

/**
 * 分析错误
 * @param {Error} error 错误Error对象
 * @returns {Promise<string>} 错误信息
 */
export default function analysisError(error) {
    let errorStackParse = ErrorStackParser.parse(error);
    const fileList = errorStackParse
        .map(({ fileName, lineNumber, columnNumber }) => ({
            url: fileName,
            line: lineNumber,
            column: columnNumber,
        }))
        .filter(({ url }) => !url.includes("kqlib.kooci.net"));
    return errorHandler(error, fileList);
}
