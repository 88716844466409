<script>
/**
 * @description 双向绑定混入
 * @see 只能绑定current变量
 * @author 张篮飞
 * @date 2020-10-29
 */
</script>

<script>
export default {
    props: ["value"],
    data() {
        return {
            current: undefined, // 双向绑定目前变量,需要子组件去定义初始化变量
        };
    },
    watch: {
        /**
         * 观察value变化
         * @see 更改current变量
         * @returns void
         */
        value: {
            handler(val, old) {
                if (val !== undefined) {
                    this.current = this._setCurrent(val, old);
                }
            },
            immediate: true,
            deep: true,
        },
        /**
         * 观察current变化
         * @see 与value值不同,激活input信号
         * @see 调用虚函数
         * @returns void
         */
        current: {
            handler(n, o) {
                if (!this.isEqual__(n, this.value)) {
                    this.$emit("input", this._setValue(n, o));
                }
            },
            deep: true,
        },
    },
    methods: {
        /**
         * 设置current值
         * @param {any} value 值
         * @param {any} oldValue 旧的值
         * @returns {any} 变化的值
         */
        _setCurrent(value, oldValue) {
            return value;
        },
        /**
         * 设置value值
         * @desc 通过激活事件设置的值得参数
         * @param {any} value 值
         * @param {any} oldValue 旧的值
         * @returns {any} 变化值
         */
        _setValue(value, oldValue) {
            return value;
        },
        /**
         * 设置vmodel值
         * @see 提供给外部用来更新值
         * @param {*} value 目标值
         * @returns void
         */
        vmodel(value) {
            console.log("调用了,,,", value);
            this.current = value;
        },
    },
};
</script>
