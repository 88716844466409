<script>
/**
 * @description InputCell-component公共混入
 * @see 比如Text,Review,Select等等公共混入
 * @author tonny
 * @date 2021-10-09
 */
</script>

<script>
export default {
    props: {
        /** 字段号 */
        zid: [String, Number],
        /** 字段名称 */
        zname: String,
        /** 字段类型 */
        type: String,
        /** 是否禁用 */
        disabled: Boolean,
        /** 是否只读 */
        readonly: Boolean,
        /** 是否必填 */
        required: Boolean,
        /** 视图号 */
        vid: [String, Number],
        /** 加载状态 */
        loading: Boolean,
        /** 是否更改 */
        isChange: Boolean,
        /** 提示文本 */
        placeholder: String,
        /** 是否为范围值 */
        isRange: Boolean,
        /** 码表列表 */
        selectList: {
            type: Array,
            validator: (val) =>
                !val.some(({ label }) => {
                    if (!label) {
                        new kqPub.Err({
                            title: "参数错误!",
                            from: "InputCell.props.selectList",
                            message: "selectList每一项都必须包含名称(label)",
                        });
                        return true;
                    }
                }),
        },
    },
    mixins: [kqPub.vModel],
    data() {
        return {
            /** 错误信息串 @see 用来作为inputcell错误显示 */
            errInfo: "",
            /** 是否获取焦点 */
            isFocus: false,
        };
    },
    computed: {
        /**
         * 显示的值
         * @see this.value
         * @see 作为el-input的v-model
         * @returns {String}
         */
        showValue: {
            set(value) {
                this.current = value;
            },
            get() {
                if (this.disabled || this.readonly) {
                    return this.value || "-";
                }
                return this.value || "";
            },
        },
    },
    watch: {
        /**
         * 判断当前值
         * @see 清空错误
         * @see 设置更改
         */
        current(value, old) {
            this.errInfo = "";
            this.onChange(value, old);
        },
    },
    methods: {
        /**
         * 表单验证
         * @returns {[string]|boolean} true:成功, Array:验证失败内容字符串数组
         */
        validate() {
            const validatorList = [this.requiredValidator()];
            if (validatorList.filter((res) => res !== true).length) {
                return validatorList;
            }
            this.errInfo = "";
            return true;
        },
        /**
         * 必填验证
         * @returns {boolean|string} true:成功, string:验证失败
         */
        requiredValidator() {
            if (this.required && !this.value) {
                const err = "带*号的为必填项";
                this.errHand(err);
                return err;
            }
            return true;
        },
        /**
         * 错误处理
         * @see 该错误将直接显示到表单上
         * @param {string|object} err 错误信息
         * @param {string} err.message 错误信息字符串
         * @returns void
         */
        errHand(err) {
            this.errInfo = err.message || err;
        },
        /**
         * 更改事件
         * @emits change {id<string|number>: 字段号, zid<string|number>:字段号, value<string>:新的值, old?<string>:旧的值}
         * @param {string} value 新的值
         * @param {string} old 旧的值
         * @returns void
         */
        onChange(value, old) {
            const id = this.zid;
            this.$emit("change", {
                id,
                zid: id,
                value,
                old,
            });
        },
        onFocus(event) {
            this.isFocus = true;
            const id = this.zid;
            this.$emit("focus", {
                id,
                zid: id,
                value: this.current,
                event,
            });
        },
        onBlur(event) {
            this.isFocus = false;
            const id = this.zid;
            this.$emit("blur", {
                id,
                zid: id,
                value: this.current,
                event,
            });
        },
    },
};
</script>