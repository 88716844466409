const baseInfo = {
    zid: "baseinfo",
    zname: "基础信息",
    subname: "写死的卡片基本信息",
    formList: [
        {
            zid: "icon",
            zname: "图标",
            type: "icon",
            required: true,
            excludeIconList: ["logo-full", "logo-line"],
        },
        {
            zid: "name",
            zname: "标题",
            type: "text-text",
            required: true,
        },
        {
            zid: "subname",
            zname: "描述信息",
            type: "text-text",
            required: true,
        },
        {
            zid: "color",
            zname: "主颜色",
            help: "看一看图标的背景色",
            type: "color",
            value: "#199bfa",
        },
        {
            zid: "lightColor",
            zname: "辅助色",
            help: "看一看演示数据背景色",
            type: "color",
            value: "#e9f6ff",
        },
        {
            zid: "actionType",
            zname: "穿透操作",
            type: "select-single-1",
            help: "注意:\n打开应用必须得保证每个数据项中包含应用号(appid)\n打开功能点必须得保证每个数据项中包含功能点号(fid)",
            selectList: [
                { label: "打开应用", value: "app", disabled: true },
                { label: "打开功能点", value: "function" },
                {
                    label: "打开功能点详情页",
                    value: "function-detail",
                    disabled: true,
                },
            ],
        },
    ],
};

export { baseInfo };

export default {
    component: "kq-lowcode-component-card-chart",
    type: "默认分类",
    name: "卡片统计项组件",
    device: "pc",
    demo: {
        name: "数据项名称",
        subname: "数据项描述",
        icon: "icon-docu-full",
        dataList: [
            {
                name: "数据名称",
                value: 123,
                suffix: "人",
            },
            {
                name: "数据名称2",
                value: "45",
                suffix: "次",
            },
        ],
    },
    configList: [
        baseInfo,
        {
            zid: "async",
            zname: "动态数据",
            type: "multi",
            /** 多个数据源可用的字段对象(当满足特定值时,可用多数据) */
            multiDisabledField: {
                zid: "type",
                value: ["all", ""],
                message:
                    "只有异步数据源[数据类型]都为单项数据时,才支持添加数据源",
            },
            /** 用来给tab命名的字段 */
            znameField: { zid: "dataName" },
            formList: [
                {
                    zid: "type",
                    zname: "数据类型",
                    type: "select-single-1",
                    required: true,
                    svalue: "",
                    value: "",
                    selectList: [
                        { label: "所有数据", value: "all" },
                        { label: "单项数据", value: "single" },
                    ],
                },
                {
                    zid: "from",
                    zname: "数据来源",
                    type: "select-single-1",
                    required: true,
                    value: "",
                    svalue: "",
                    selectList: [
                        { label: "接口", value: "api" },
                        { label: "功能点", value: "function" },
                    ],
                },
                {
                    zid: "dataName",
                    zname: "数据名称",
                    type: "text-text",
                    required: true,
                    value: "",
                },
                {
                    zid: "apiRoute",
                    zname: "接口地址",
                    type: "text-text",
                    required: true,
                    value: "",
                },
                {
                    zid: "apiConvert",
                    zname: "接口转换",
                    type: "code",
                    required: true,
                    value: "",
                    codeFormat: "javascript",
                },
                {
                    zid: "fid",
                    zname: "功能点",
                    type: "text-text",
                    required: true,
                    value: "",
                },
                {
                    zid: "valueType",
                    zname: "值类型",
                    type: "select-single-1",
                    required: true,
                    svalue: "",
                    value: "",
                    selectList: [{ label: "列表页总数", value: "list-total" }],
                },
            ],
            linkage: [
                {
                    name: "数据类型-数据来源联动",
                    subname: "所有数据的数据来源只能为api",
                    from: [
                        {
                            zid: "type",
                            value: ["all"],
                        },
                    ],
                    to: [
                        {
                            zid: "from",
                            action: "visible-select-disabled",
                            value: ["function"],
                        },
                    ],
                },
                {
                    name: "数据类型-数据名称联动",
                    subname: "数据类型:单项数据关联数据名称显示",
                    // 联动多字段关系
                    // relationship: "some",
                    from: [
                        {
                            zid: "type",
                            // 判断
                            value: ["single"],
                            // 判断条件(include:包含,exclude:不包含)
                            // condition: "include",
                            // 多个判断值之间的关系(some:或,every:且)
                            // relationship: "some",
                        },
                    ],
                    to: [
                        {
                            zid: "dataName",
                            // 操作类型
                            action: "visible-field",
                            // 操作值
                            value: true,
                            // 错误提示值
                            // message: "提示内容",
                        },
                    ],
                },
                {
                    name: "数据来源-字段联动",
                    subname: "数据来源码值:api,关联显示接口地址与接口转换",
                    type: "visible",
                    from: [
                        {
                            zid: "from",
                            value: ["api"],
                        },
                    ],
                    to: [
                        {
                            zid: "apiRoute",
                            action: "visible-field",
                            value: true,
                        },
                        {
                            zid: "apiConvert",
                            action: "visible-field",
                            value: true,
                        },
                    ],
                },
                {
                    name: "数据来源-字段联动",
                    subname: "数据来源码值:功能点,关联显示功能点号与值类型",
                    type: "visible",
                    from: [
                        {
                            zid: "from",
                            value: ["function"],
                        },
                    ],
                    to: [
                        {
                            zid: "fid",
                            action: "visible-field",
                            value: true,
                        },
                        {
                            zid: "valueType",
                            action: "visible-field",
                            value: true,
                        },
                    ],
                },
            ],
        },
    ],
};
