import Base from "./index.js";

/**
 * 兼容接口
 * @see 根据视图查询字段详情: /api/common/dict/queryFormDataByRootBillIdAndBillIdAndViewId
 * @see 根据样张号查询字段: /api/temp/view
 * @see 根据视图查询字段详情: /api/view
 */

import Config from "kooci-lib/src/PubComponents/config.js";
import Err from "kooci-lib/src/PubComponents/Utils/Err";
import _ from "lodash";

export default class Extend extends Base {
    /**
     * 数据转换器
     * @see 传入Request请求中可以直接转换数据
     * @param {object} params
     * @param {object} [params.compares] 参照表({key<string>: value<string|[string]>}), key为转换后的值,支持级联操作(比如:props.disabled, datas[0].title)
     * @param {*} [params.data] 数据
     * @param {function} [params.convertKeyHook] 转换钩子
     * @param {boolean} [params.isStrict] 是否开始严格转换模式(严格:会将所有不在参照表中的数值省略,只保留参照表含有的数据, 不严格:只会替换参照表中有的数据,其它会原值保留)
     * @param {function} [params.convertValueHook] 转换值钩子函数(只有当value为值类型(非数组,对象,函数)调用)
     */
    constructor(options) {
        if (!options) {
            throw new Err({
                title: "参数出错",
                message: "构造函数参数必传",
                from: `Convert.Extend.constructor`,
            });
        }
        let api, data, isStrict;
        if (_.isString(options)) {
            api = options;
        } else {
            api = options.api;
            isStrict = options.isStrict;
            data = options.data;
        }
        if (!api) {
            throw new Err({
                title: "参数出错",
                message: "接口参照(api)必传",
                from: `Convert.Extend.constructor`,
            });
        }
        const item = Config.Convert.methods.getApiConvert(api);
        if (!item) {
            throw new Err({
                title: "配置出错",
                message: "配置文件损坏!当前未查询到Field的相关数据转换器信息!",
                from: `Convert.Extend.constructor`,
            });
        }
        const { convertValueHook, convertKeyHook, compares } = item;
        super({
            data,
            isStrict: isStrict === undefined ? item.isStrict : isStrict,
            convertKeyHook,
            convertValueHook,
            compares,
        });
    }
}
