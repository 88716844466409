/* eslint-disable */

/**
 * 获取uuid
 * @returns {string} uuid字串
 */

import _ from "lodash";
import $ from "jquery";
import QRCode from "qrcode";
import JSEncrypt from "jsencrypt/bin/jsencrypt.min";

const uuid = function () {
    let d = _.now();
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
        let r = (d + _.random(16)) % 16 | 0;
        d = Math.floor(d / 16);
        return (c == "x" ? r : (r & 0x3) | 0x8).toString(16);
    });
};

/**
 * 是否为uuid串
 * @param {string} target 目标
 * @returns {boolean}
 */
uuid.isUuid = function (target) {
    const re = /^([a-f\d]{8}(-[a-f\d]{4}){3}-[a-f\d]{12}?)$/i;
    return re.test(target);
};
/**
 * 是否为uuid4串
 * @param {string} target 目标
 * @returns {boolean}
 */
uuid.isUuid4 = function (target) {
    const re = /^([a-f\d]{8}(-[a-f\d]{4}){3}-[a-f\d]{12}?)$/i;
    return re.test(target);
};

/**
 * 复制属性至目标中
 * @see 用来合并混入多继承
 * @param {object} target 合并后的目标
 * @param {object} source 合并源
 */
function copyProperties(target, source) {
    if (source) {
        for (let key of Reflect.ownKeys(source)) {
            if (
                ![
                    "constructor",
                    "prototype",
                    "name",
                    "arguments",
                    "caller",
                    "name",
                    "bind",
                    "call",
                    "apply",
                    "toString",
                    "length",
                ].includes(key)
            ) {
                let desc = Object.getOwnPropertyDescriptor(source, key);
                Object.defineProperty(target, key, desc);
            }
        }
    } else {
        console.error("source空....");
    }
}

export default {
    /**
     *
     * @param {function|string} target 目标
     * @param {function} handler 成功回调
     * @param {function} failHandler 失败回调
     * @param {number} maxSecond 最大秒数
     */
    isExist(target, handler, failHandler, maxSecond = 20) {
        return new Promise((resolve, reject) => {
            let maxCount = maxSecond * 10;
            const timer = setInterval(() => {
                if (maxCount < 0) {
                    failHandler && failHandler();
                    clearInterval(timer);
                    reject();
                } else {
                    if (_.isFunction(target)) {
                        if (target()) {
                            handler && handler();
                            clearInterval(timer);
                            resolve();
                            return;
                        }
                    } else if (_.isString(target)) {
                        if ($(target).length) {
                            handler && handler();
                            clearInterval(timer);
                            resolve();
                            return;
                        }
                    }
                    maxCount--;
                }
            }, 100);
        });
    },
    uuid,
    /**
     * 异步链式调用
     * @see 实现链式同步|异步调用
     * @see 默认队列中的函数返回值===false或抛出错误会直接中断链式调用
     * @param {Function|[Function]|[object]} handlerList 处理函数(列表),[{success<Function>:成功处理函数, err?<Function>:失败处理函数}]
     * @param {[any]} [paramList] 统一参数表(每次调用都会传此参数)
     * @returns void
     */
    asyncChain(handlerList, ...paramList) {
        if (_.isFunction(handlerList)) {
            handlerList = [handlerList];
        }
        // 首个调用,取出结果
        let handler, errHandler;
        const current = handlerList[0];
        const next = handlerList[1];
        if (_.isFunction(current)) {
            handler = current;
        } else if (_.isPlainObject(current)) {
            handler = current.success || current.handler;
            errHandler = current.error || current.failed || current.err || undefined;
        } else {
            const err = new Error(
                `[asyncChain]参数错误!回调列表中的每个对象必须为Object||Function, 当前:${current}`
            );
            console.error(err);
            throw err;
        }
        // 开始链式调用
        let res;
        try {
            res = handler(...paramList);
        } catch (error) {
            console.error(error);
            errHandler && errHandler(error);
            throw error;
        }
        // 异步
        if (res instanceof Promise) {
            res.then((result) => {
                if (result !== false && next) {
                    this.asyncChain(handlerList.slice(1), ...paramList);
                }
            }).catch((err) => {
                console.error(err);
                errHandler && errHandler(err);
            });
        }
        // 同步->继续调用下一个
        else if (res !== false && next) {
            return this.asyncChain(handlerList.slice(1), ...paramList);
        }
        return res;
    },
    /**
     * 判断字符创是否是JSON
     * @param {string} target 目标字符串
     * @returns {boolean|Object} 是json放回object对象,不是返回false
     */
    isJSON(target) {
        let result = target;
        if (_.isString(result)) {
            try {
                const obj = JSON.parse(target);
                if (!_.isObject(obj)) {
                    result = false;
                } else {
                    result = obj;
                }
            } catch (e) {
                result = false;
            }
        } else if (_.isPlainObject(target)) {
            return target;
        }
        return result;
    },
    /**
     * 多继承
     * @param  {...any} mixins 多个合并类
     * @returns {function}
     */
    mixin(...mixins) {
        class Mix {
            constructor(...args) {
                for (let mixin of mixins) {
                    copyProperties(this, new mixin(...args)); // 拷贝实例属性
                }
            }
        }
        for (let mixin of mixins) {
            let name = "cur";
            let props = mixin;
            while (name) {
                // 拷贝静态属性
                copyProperties(Mix, props);
                // 拷贝原型属性
                copyProperties(Mix.prototype, props.prototype);
                // 向上递归
                props = props.__proto__;
                name = props.name;
            }
        }

        return Mix;
    },
    /**
     * 文件下载
     * @see https://www.npmjs.com/package/downloadjs
     * @param {string} url 链接|base64编码
     * @param {string} name 文件名
     * @returns {boolean} true:成功
     */
    download(url, name, isOpenMode, other) {
        kq.File.download({ url, name, isOpenMode });
    },
    /**
     *
     * @param {string} content 二维码内容
     * @param {object} options 配置参数
     * @returns {Promise<string>} 成功返回base64编码,失败抛错
     */
    async qrcode(content, options) {
        const res = await QRCode.toDataURL(content, {
            width: 1000,
            margin: 2,
            ...options,
        }).catch((err) => {
            throw err;
        });
        return res;
    },
    /**
     * 根据oss的链接获取文件名
     * @param {string} url oss链接
     * @param {boolean} removePrefix 是否去除名称前缀
     * @param {boolean} isDecode 是否解码
     * @returns {string}
     */
    getNameByOssUrl(url, removePrefix = true, isDecode = true) {
        if (!url) {
            return "";
        }
        let name = url.kqMatch(/\/?([^\/]+)$/, 1);
        if (removePrefix) {
            let suffix = name.kqMatch(/\.(\w+)$/, 1);
            name = name.kqMatch(`(${suffix}\\d+_)?(.*)`, 2);
        }
        return isDecode ? unescape(name) : name;
    },
    /**
     * 获取文件名后缀
     * @param {string} name 文件名
     * @returns {string}
     */
    getSuffixByFileName(name) {
        if (!name) {
            return "";
        }
        return name.kqMatch(/\.(\w+)$/, 1);
    },
    /**
     * 是否为图片
     * @param {string} name 文件名
     * @returns {boolean}
     */
    isImage(name) {
        if (/[^.]+$/.test(name)) {
            name = "." + name;
        }
        return /\.(png)|(jpg)|(jpeg)|(bmp)|(webp)|(svg)|(gif)$/.test(name.toLowerCase());
    },
    /**
     * 判断多个变量是否相等
     * @see 数组和对象对比里面的每一项,只有key数目相同且值相同才全等
     * @see 参数表包含:字符串和数字会同时转换为数字后进行比较,所以如果区分数字和字符串,不适用此函数
     * @param  {...any} args 参数表
     * @returns {boolean} true:全等
     */
    isEqual(...args) {
        let isEqual = false;
        let temp = args[0];
        for (let i = 1; i < args.length; i++) {
            // debugger;
            const current = args[i];
            if (_.isArray(temp) && _.isArray(current)) {
                isEqual =
                    temp.length === current.length &&
                    temp.every((item, index) => this.isEqual(item, current[index]));
            } else if (_.isString(temp) && _.isString(current)) {
                isEqual = temp === current;
            } else if (
                (_.isString(temp) && temp && _.isNumber(current)) ||
                (_.isNumber(temp) && _.isString(current) && current) ||
                (_.isNumber(temp) && _.isNumber(current))
            ) {
                isEqual = Number(temp) === Number(current);
            } else if (_.isPlainObject(temp) && _.isPlainObject(current)) {
                isEqual =
                    Object.keys(temp).length === Object.keys(current).length &&
                    _.every(temp, (value, key) => this.isEqual(value, current[key]));
            } else if (_.isFunction(temp) && _.isFunction(temp)) {
                isEqual = temp.toString() === current.toString();
            } else {
                isEqual = temp === current;
            }
            if (isEqual === false) {
                break;
            }
            temp = current;
        }
        return isEqual;
    },
    isEmpty(...args) {
        return args.every((arg) => {
            if (_.isArrayBuffer(arg)) {
                return false;
            }
            if (arg instanceof Error) {
                return false;
            }
            if (arg instanceof Promise) {
                return false;
            }
            if (_.isFunction(arg)) {
                return false;
            }
            if (_.isObject(arg)) {
                return _.isEmpty(arg);
            }
            return [null, undefined, ""].includes(arg);
        });
    },
    isEmptyMaybe(...args) {
        return args.some((arg) => this.isEmpty(arg));
    },
    /**
     * 是否为数字
     * @see 可以判断字符串数字
     * @see 多个参数为且的关系
     * @param  {...any} args
     * @returns {boolean} true:是
     */
    isNumber(...args) {
        return args.every((arg) => {
            if (this.isEmpty(arg)) {
                return false;
            }
            return !Number.isNaN(Number(arg));
        });
    },
    isNumberMaybe(...args) {
        return args.some((arg) => this.isNumber(arg));
    },
    /**
     * 深度遍历
     * @description 只有在遍历到boolean,number,string,function,null,undefined时才会调用回调函数
     * @param {*} target 遍历目标
     * @param {function} callback 回调函数(target<*>:目标值,parent<any>: 父级属性 )
     * @returns {void}
     */
    forEachDeep(target, callback, parent) {
        if (_.isPlainObject(target)) {
            return !_.some(target, (value) => {
                const res = this.forEachDeep(value, callback, target);
                if (res === false) {
                    return true;
                }
            });
        } else if (_.isArray(target)) {
            return !target.some((item) => {
                const res = this.forEachDeep(item, callback, target);
                if (res === false) {
                    return true;
                }
            });
        } else {
            return callback(target, parent);
        }
    },
    /**
     * RSA加密
     * @param {string} publicKey 公钥
     * @param {string} txt 加密内容
     * @returns void
     */
    rsaEncrypt(publicKey, txt) {
        if (!publicKey) {
            console.error("公钥不能为空!");
            return "";
        }
        const encryptor = new JSEncrypt();
        encryptor.setPublicKey(publicKey); // 设置公钥
        return encryptor.encrypt(txt); // 对需要加密的数据进行加密
    },
    /**
     * 对象的深度合并
     * @see 对象:覆盖累加合并(有的覆盖,没有的累加)
     * @see 数组:覆盖合并(直接覆盖)
     * @see 其他:覆盖合并
     * @param {object} target 目标对象
     * @param  {...any} arg 其他对象
     * @returns {object} 深度合并之后的对象
     */
    merge(target, src) {
        let parent;
        function _merge(p1, p2) {
            if (_.isPlainObject(p1) && _.isPlainObject(p2)) {
                parent = p1;
                return _.mergeWith(p1, p2, _merge);
            } else if (_.isFunction(p1) && _.isFunction(p2)) {
                parent["_" + arguments[0].name] = p1;
                return p2;
            } else if (p1 !== undefined && !_.isFunction(p1) && _.isFunction(p2)) {
                return p2(p1);
            }
            return p2;
        }
        return _merge(target, src);
    },
    encode(target, type = "base64") {
        switch (type) {
            case "base64":
                return btoa(encodeURIComponent(target));
            default:
                break;
        }
    },
    decode(target, type = "base64") {
        switch (type) {
            case "base64":
                return decodeURIComponent(atob(target));
            default:
                break;
        }
    },
    /**
     * 合并转换个性化数据
     * @param {function} files 批量加载的文件函数
     * @returns {object} 最终模块对象
     */
    mergePersonal(files) {
        const modules = {};
        files.keys().forEach((path) => {
            /** path参数表 */
            const paramList = path.split(".")[1].split("/").slice(1);
            // 功能模块
            if (paramList[0] === "Function") {
                /** 功能模块 */
                let functionModule = modules.Function;
                // 注入功能模块
                if (!functionModule) {
                    functionModule = {};
                    modules.Function = functionModule;
                }
                const [, personal, module] = paramList;
                /** 个性化模块 */
                let personalModule = functionModule[personal];
                // 注入个性化模块
                if (!personalModule) {
                    personalModule = {};
                    functionModule[personal] = personalModule;
                }
                personalModule[module] = files(path).default;
            }
            // 公用模块
            else {
                /** personal: 个性化模块名, type: 类型模块名 */
                const [personal, type] = paramList;
                /** 个性化模块 */
                let personalModule = modules[personal];
                // 注入个性化模块
                if (!personalModule) {
                    personalModule = {};
                    modules[personal] = personalModule;
                }
                /** 类型模块 */
                let typeModule = personalModule[type];
                // 注入类型模块
                if (!typeModule) {
                    typeModule = {};
                    personalModule[type] = typeModule;
                }
                // 公共模块
                if (type === "Public") {
                    const [, , module] = paramList;
                    typeModule[module] = files(path).default;
                }
                // 模板模块
                else {
                    const [, , template, module] = paramList;
                    /** 模板模块 */
                    let templateModule = typeModule[template];
                    // 注入模板模块
                    if (!templateModule) {
                        templateModule = {};
                        typeModule[template] = templateModule;
                    }
                    templateModule[module] = files(path).default;
                }
            }
        });
        return modules;
    },
    /*
     * 异步执行函数
     * @desc 用来进行异步操作
     * @desc 封装的目的是在无法使用async/await的情况下使用Promise操作
     * @param {function} handler 处理函数
     * @returns {Promise<any>}
     * @example kq.Tools.promise(async () => {...})
     */
    promise(handler) {
        if (_.isFunction(handler)) {
            return new Promise((resolve, reject) => {
                const res = handler(resolve, reject);
                if (res instanceof Promise) {
                    res.then(resolve).catch(reject);
                } else {
                    resolve();
                }
            });
        }
        return Promise.resolve();
    },
    /**
     * 格式化长度
     * @desc 数字:转换为px,其他原封不动返回
     * @param {string|number} length 长度单位
     * @returns {string}
     */
    formatLength(length) {
        const num = Number(length);
        if (Number.isNaN(num)) {
            return length;
        } else {
            return num + "px";
        }
    },
    /**
     * 延迟函数
     * @param {number} duration 持续时间,不传表示立刻执行
     * @param {Primise<number>} 完成返回计时器id
     */
    delay(duration) {
        return new Promise((resolve) => {
            if (!duration) {
                resolve();
            } else {
                const timer = setTimeout(() => {
                    resolve(timer);
                }, duration);
            }
        });
    },

    /**
     * 将目标转换为数组
     * @param {*} target 目标值
     * @returns {Array} 转换后的数组
     */
    toArray(target) {
        if (this.isEmpty(target)) {
            return [];
        }
        if (_.isArray(target)) {
            return target;
        }
        if (!_.isArray(target)) {
            return [target];
        }
    },
    /**
     * 展开级联码表为查询表
     * @param {[object]} selectList 码表列表
     * @param {object} parent 码表父级
     * @param {object} [result] 上一层结果
     * @returns {object} 码表查询对象
     */
    spreadSelectList(selectList, parent, result = {}) {
        selectList.forEach((select) => {
            if (select.children === undefined) {
                const okSelect = { ...select, parent, from: select.from || select };
                result[select.value] = okSelect;
                result[select.label] = okSelect;
                const keyList = [];
                let item = result[select.value];
                while (item) {
                    keyList.unshift(item.label);
                    item = item.parent;
                }
                const fullPath = keyList.join("/");
                okSelect.fullPath = fullPath;
                result[fullPath] = okSelect;
            } else {
                this.spreadSelectList(select.children, { ...select, parent }, result);
            }
        });
        return result;
    },
    /**
     * 字段别名是否相等
     * @param {string} target 判断目标
     * @param {string} source 判断源
     * @returns {boolen} true:相等
     */
    isEqualZlabel(target, source) {
        return (target || "").kqIsEqualZlabel(source);
    },
};
