const baseInfo = {
    zid: "baseinfo",
    zname: "基础信息",
    subname: "写死的卡片基本信息",
    formList: [
        {
            zid: "icon",
            zname: "图标",
            type: "icon",
            required: true,
            excludeIconList: ["logo-full", "logo-line"],
        },
        {
            zid: "name",
            zname: "标题",
            type: "text-text",
            required: true,
        },
        {
            zid: "subname",
            zname: "描述信息",
            type: "text-text",
            required: true,
        },
        {
            zid: "color",
            zname: "主颜色",
            help: "看一看图标的背景色",
            type: "color",
            value: "#199bfa",
        },
    ],
};

export { baseInfo };
import { _getLinkageTypeFunc } from "../PieChart/config.js";

export default {
    component: "kq-lowcode-component-table-list",
    type: "默认分类",
    name: "表格组件",
    demo: {
        name: "表格名称",
        subname: "表格描述",
        icon: "icon-docu-full",

        headerList: [
            {
                name: "姓名",
                zid: 1,
                zname: "姓名",
                fixed: "left",
                isTitle: 1,
                width: ""
            },
            {
                name: "工号",
                zid: 2,
                zname: "工号",
                fixed: "left",
                isTitle: 1,
                width: ""
            },
            {
                name: "部门",
                zid: 223,
                zname: "部门",
                isTitle: 1,
                width: ""
            },
            {
                name: "日期",
                zid: 4,
                zname: "日期",
                isTitle: 1,
                width: ""
            },
            {
                name: "项目",
                zid: 5,
                zname: "项目",
                isTitle: 1,
                width: ""
            },
        ],
        dataList: [
            {
                1: "张三",
                2: "2",
                223: "开发部",
                4: "2022-07-04",
                5: "人才安居"
            },
            {
                1: "李四",
                2: "2",
                223: "产品部",
                4: "2022-07-04",
                5: "人才项目2"
            },
            {
                1: "王五",
                2: "2",
                223: "项目部",
                4: "2022-07-04",
                5: "人才项目3"
            },
            {
                1: "赵丽颖",
                2: "4",
                223: "项目部",
                4: "2022-07-04",
                5: "人才项目4"
            }
        ]

    },
    configList: [
        baseInfo,
        {
            zid: "async",
            zname: "动态数据",
            type: "single",
            /** 多个数据源可用的字段对象(当满足特定值时,可用多数据) */
            multiDisabledField: {
                zid: "type",
                value: ["all", ""],
                message:
                    "只有异步数据源[数据类型]都为单项数据时,才支持添加数据源",
            },
            formList: [
                {
                    zid: "type",
                    zname: "数据类型",
                    type: "select-single-1",
                    value: "",
                    svalue: "",
                    required: true,
                    selectList: [
                        { label: "所有数据", value: "all" },
                        { label: "单项数据", value: "single" },
                    ],
                },
                {
                    zid: "from",
                    zname: "数据来源",
                    type: "select-single-1",
                    required: true,
                    value: "",
                    svalue: "",
                    selectList: [
                        { label: "接口", value: "api" },
                        { label: "功能点", value: "function" },
                    ],
                },
                {
                    zid: "apiRoute",
                    zname: "接口地址",
                    type: "text-text",
                    required: true,
                    value: "",
                },
                {
                    zid: "apiConvert",
                    zname: "接口转换",
                    type: "code",
                    required: true,
                    value: "",
                    codeFormat: "javascript",
                },
                {
                    zid: "fid",
                    zname: "功能点",
                    type: "text-text",
                    required: true,
                    value: "",
                },
                {
                    zid: "valueType",
                    zname: "值类型",
                    type: "select-single-1",
                    required: true,
                    svalue: "",
                    value: "",
                    selectList: [{ label: "列表页信息", value: "list-info" }],
                },
            ],
            linkage: [
                {
                    name: "数据类型-数据源联动",
                    subname: "选择数据类型后,联动数据源字段显示",
                    from: [
                        {
                            zid: "type",
                            value: ["all"],
                        },
                    ],
                    to: [
                        {
                            zid: "from",
                            action: "visible-select-disabled",
                            value: ["function"],
                        },
                        {
                            zid: "from",
                            action: "code",
                            value: [_getLinkageTypeFunc()],
                        },
                    ],
                },
                {
                    name: "数据类型-数据源联动",
                    subname: "选择数据类型后,联动数据源字段显示",
                    from: [
                        {
                            zid: "type",
                            value: ["single"],
                        },
                    ],
                    to: [
                        {
                            zid: "from",
                            action: "visible-select-disabled",
                            value: ["api"],
                        },
                        {
                            zid: "from",
                            action: "code",
                            value: [_getLinkageTypeFunc()],
                        },
                    ],
                },
                {
                    name: "数据类型-数据名称联动",
                    subname: "数据类型:单项数据关联数据名称显示",
                    from: [
                        {
                            zid: "type",
                            value: ["all"],
                        },
                    ],
                    to: [
                        {
                            zid: "from",
                            action: "visible-select-disabled",
                            value: ["function"],
                        },
                    ],
                },
                {
                    name: "数据来源联动",
                    subname: "数据来源-api显示对应api的相关",
                    from: [
                        {
                            zid: "type",
                            value: ["all"],
                        },
                    ],
                    to: [
                        {
                            zid: "apiRoute",
                            action: "visible-field",
                            value: true,
                        },
                        {
                            zid: "apiConvert",
                            action: "visible-field",
                            value: true,
                        },
                    ],
                },
                {
                    name: "数据来源-字段联动",
                    subname: "数据来源码值:功能点,关联显示功能点号与值类型",
                    type: "visible",
                    from: [
                        {
                            zid: "type",
                            value: ["single"],
                        },
                    ],
                    to: [
                        {
                            zid: "fid",
                            action: "visible-field",
                            value: true,
                        },
                        {
                            zid: "valueType",
                            action: "visible-field",
                            value: true,
                        },
                    ],
                },
            ],
        },
    ],
};