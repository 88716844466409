import Tools from "kooci-lib/src/PubComponents/Utils/tools";
import Field from "./Field.js";

export default class Param extends Field {
    /**
     * 获取云端列表页
     * @desc 参数表集中管理专用
     * @param {object} param0 入参对象
     * @param {string} param0.schoolId 学校编码
     * @param {string} param0.versionType 版本类型(personal:个性化,param:参数表)
     * @param {[object]} [param.filterList] 筛选表
     * @param {string} [param.fuzzyQuery] 模糊查询参数
     * @param {number} [param.currentPage] 当前页码(从1开始)
     * @param {number} [param.pageSize] 一页的个数
     * @param {[object]} [param.sortList] 排序详情表
     * @returns {Promise<[object]>} 成功返回字段表,失败抛错
     */
    async getAppstoreList({
        schoolId,
        versionType,
        filterList = [],
        fuzzyQuery = "",
        currentPage = 1,
        pageSize = 20,
        sortList = [],
    }) {
        if (!versionType) {
            throw new kq.Err({
                message: "版本类型(versionType)不能少",
                current: arguments[0],
                from: "kq.Request.Field.getAppstoreList",
                console: false,
            });
        }
        // 筛选结果格式化
        const { conditions, conditionsMapNotEq, conditionsMapIsNull } =
            this._filterFormat(filterList);
        return this.request(
            {
                url: "/appstore/queryRowDataList",
                method: "post",
                convert: "list",
                data: {
                    schoolCode: schoolId,
                    paramLabel: versionType,
                    defaultQueryValue: fuzzyQuery,
                    conditions,
                    conditionsMapNotEq,
                    conditionsMapIsNull,
                    orders: this._sortFormat(sortList),
                    pageNum: currentPage,
                    pageSize,
                },
            },
            arguments[0]
        );
    }
    /**
     * 获取云端字段列表
     * @desc 参数表集中管理专用
     * @param {object} param0 入参对象
     * @param {string} param0.schoolId 学校编码
     * @param {string} param0.versionType 版本类型(personal:个性化,param:参数表)
     * @returns {Promise<[object]>} 成功返回字段表,失败抛错
     */
    async getAppstoreFieldList({ schoolId, versionType }) {
        if (!versionType) {
            throw new kq.Err({
                message: "版本类型(versionType)不能少",
                current: arguments[0],
                from: "kq.Request.Field.getAppstoreList",
                console: false,
            });
        }
        return this.request(
            {
                url: "/appstore/queryRowDataTitle",
                method: "post",
                convert: "Field",
                data: {
                    schoolCode: schoolId,
                    paramLabel: versionType,
                },
            },
            arguments[0]
        );
    }
    /**
     * 保存云端配置数据
     * @param {object} param0 入参对象
     * @param {[object]} param0.fieldList 字段列表
     * @param {string} param0.versionType 版本类型(param:参数表,personal:个性化)
     * @returns {Promise<string>} 成功返回经历号,失败抛错
     */
    async saveAppstoreField({ fieldList, versionType }) {
        if (Tools.isEmptyMaybe(fieldList, versionType)) {
            throw new kq.Err({
                message: "版本类型(versionType)与字段列表(fieldList)不能少",
                current: arguments[0],
                from: "kq.Request.Field.getAppstoreList",
                console: false,
            });
        }
        return this.request(
            {
                url: "/appstore/createListFormData",
                method: "post",
                data: {
                    paramLabel: versionType,
                    items: fieldList.map(
                        ({ zid, label, zlabel, value, nodeValue }) => ({
                            zid,
                            label: zlabel || label,
                            nodeValue: value || nodeValue || null,
                        })
                    ),
                },
            },
            arguments[0]
        );
    }
}
