var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"kq-color",rawName:"v-kq-color.white.bg",modifiers:{"white":true,"bg":true}}],staticClass:"v-form-login",style:({ width: _vm.formatLength__(_vm.width) })},[_c('ul',{staticClass:"v-form-login-form"},[_vm._l((_vm.formatDataList),function(data,index){return [(!data.disabled)?_c('li',{key:data.label},[_c('div',{class:{
                        'vflf-container': true,
                        'v-primary-border-color-important':
                            _vm.formDataList[index].isFocus,
                    }},[_c('div',{staticClass:"vflf-container-input"},[_c('div',{staticClass:"vflf-container-input-label"},[_c('label',{class:data.titleColor},[_vm._v(_vm._s(data.label))]),_c('i',{directives:[{name:"kq-icon",rawName:"v-kq-icon:required.line",arg:"required",modifiers:{"line":true}},{name:"kq-color",rawName:"v-kq-color.error",modifiers:{"error":true}}]})]),((_vm.formDataList[index].inputType ||
                                data.inputType)==='checkbox')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.dataList[index].value),expression:"dataList[index].value"},{name:"kq-color",rawName:"v-kq-color.title",modifiers:{"title":true}}],ref:"inputs",refInFor:true,staticClass:"vflf-container-input-input v-input-clear",attrs:{"tabindex":index + 1,"autocomplete":data.autocomplete || 'off',"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.dataList[index].value)?_vm._i(_vm.dataList[index].value,null)>-1:(_vm.dataList[index].value)},on:{"focus":function($event){return _vm.onFocus(data, index, $event)},"blur":function($event){return _vm.onBlur(data, index, $event)},"input":function($event){return _vm.onChange(data, index, $event)},"keypress":function($event){return _vm.onKeyPress(data, index, $event)},"change":function($event){var $$a=_vm.dataList[index].value,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.dataList[index], "value", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.dataList[index], "value", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.dataList[index], "value", $$c)}}}}):((_vm.formDataList[index].inputType ||
                                data.inputType)==='radio')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.dataList[index].value),expression:"dataList[index].value"},{name:"kq-color",rawName:"v-kq-color.title",modifiers:{"title":true}}],ref:"inputs",refInFor:true,staticClass:"vflf-container-input-input v-input-clear",attrs:{"tabindex":index + 1,"autocomplete":data.autocomplete || 'off',"type":"radio"},domProps:{"checked":_vm._q(_vm.dataList[index].value,null)},on:{"focus":function($event){return _vm.onFocus(data, index, $event)},"blur":function($event){return _vm.onBlur(data, index, $event)},"input":function($event){return _vm.onChange(data, index, $event)},"keypress":function($event){return _vm.onKeyPress(data, index, $event)},"change":function($event){return _vm.$set(_vm.dataList[index], "value", null)}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.dataList[index].value),expression:"dataList[index].value"},{name:"kq-color",rawName:"v-kq-color.title",modifiers:{"title":true}}],ref:"inputs",refInFor:true,staticClass:"vflf-container-input-input v-input-clear",attrs:{"tabindex":index + 1,"autocomplete":data.autocomplete || 'off',"type":_vm.formDataList[index].inputType ||
                                data.inputType},domProps:{"value":(_vm.dataList[index].value)},on:{"focus":function($event){return _vm.onFocus(data, index, $event)},"blur":function($event){return _vm.onBlur(data, index, $event)},"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.dataList[index], "value", $event.target.value)},function($event){return _vm.onChange(data, index, $event)}],"keypress":function($event){return _vm.onKeyPress(data, index, $event)}}})]),_c('div',{staticClass:"vflf-container-tool"},[(data.type === 'imgcode')?_c('img',{attrs:{"src":data.codeUrl,"alt":"验证码加载中"},on:{"click":function($event){data.updateCode && data.updateCode()}}}):_vm._e(),(
                                data.type === 'password' &&
                                _vm.isNeedPasswordShowTool
                            )?_c('i',{directives:[{name:"kq-icon",rawName:"v-kq-icon",value:(
                                _vm.formDataList[index].isHide ? 'hide' : 'show'
                            ),expression:"\n                                formDataList[index].isHide ? 'hide' : 'show'\n                            "},{name:"kq-tip",rawName:"v-kq-tip",value:('点击展示/隐藏密码'),expression:"'点击展示/隐藏密码'"}],class:{
                                'vflf-container-tool-eye': true,
                                'v-primary-color v-primary-dark-color-hover':
                                    !_vm.formDataList[index].isHide,
                                'v-label-color v-primary-color-hover':
                                    _vm.formDataList[index].isHide,
                            },on:{"click":function($event){return _vm.onEyeClick(index)}}}):_vm._e(),(data.type === 'phonecode')?_c('el-button',{attrs:{"round":"","size":"small","type":"null","disabled":!!_vm.sendPhonecodeCount},on:{"click":function($event){return _vm.onSendPhonecodeClick(data)}}},[_vm._v(_vm._s(_vm.sendPhonecodeCount ? `${_vm.sendPhonecodeCount}秒后发送` : "发送验证码"))]):_vm._e()],1)]),(data.ruleList && data.ruleList.length)?_c('ol',{class:{
                        'vflf-validator': true,
                        'vflf-validator--active':
                            _vm.formDataList[index].isFocus &&
                            !_vm.formDataList[index].isCorrect,
                    }},_vm._l((data.ruleList),function(rule,j){return _c('li',{key:j},[_c('div',{directives:[{name:"kq-icon",rawName:"v-kq-icon.line",value:(
                                _vm.formDataList[index].ruleList[j].isCorrect
                                    ? 'yes'
                                    : 'close'
                            ),expression:"\n                                formDataList[index].ruleList[j].isCorrect\n                                    ? 'yes'\n                                    : 'close'\n                            ",modifiers:{"line":true}},{name:"kq-loading",rawName:"v-kq-loading.circle.opacity-false.mini",value:(
                                _vm.formDataList[index].ruleList[j].loading
                            ),expression:"\n                                formDataList[index].ruleList[j].loading\n                            ",modifiers:{"circle":true,"opacity-false":true,"mini":true}}],staticClass:"vflf-validator-loading"}),_c('span',[_vm._v(_vm._s(rule.tip))])])}),0):_vm._e()]):_vm._e()]})],2),_c('footer',_vm._l((_vm.btnList),function(btn,index){return _c('el-button',{key:btn.name,attrs:{"type":btn.color,"plain":btn.isPlain,"disabled":_vm.formBtnList[index].disabled,"round":"","size":btn.size || _vm.btnSize,"loading":!!btn.loading},on:{"click":function($event){return _vm.onBtnClick(btn)}}},[_vm._v(_vm._s(btn.loading ? btn.loading === true ? btn.name : btn.loading : btn.name))])}),1),_c('v-easy-tip',{attrs:{"label":_vm.error_,"theme-color":"error","duration":0,"icon":"iconfont icon-tip-full","show-placement":"top","animate-placement":"top"},model:{value:(_vm.errTipShow),callback:function ($$v) {_vm.errTipShow=$$v},expression:"errTipShow"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }