/** 模板类型参照枚举 */
const FtypeCompares = {
    /** (普通)本人维护 */
    N_MAINTAIN: 0,
    /** (普通)人员列表 */
    N_PERSONS: 1,
    /** (行集)个人申请(已废弃) */
    R_APPLY_PERSONAL: 2,
    /** (行集)申请-原:行集-个人查看 */
    R_APPLY: 3,
    /** (行集)经历审核 */
    R_REVIEW: 4,
    /** (行集)经历终审(审批):所有的需要终审的记录 */
    R_APPROVE: 5,
    /** (行集)经历汇总:所有的审核记录 */
    R_SUMMARY: 6,
    /** (行集)转单进度功能 */
    R_TRANSFER: 13,
    /** (行集)管理员申请 */
    R_ADMIN_APPLY: 14,
    /** 审核转单已阅 */
    REVIEW_TRANSFER_READED: -1,
    /** 页面-审核页 */
    P_REVIEW: -2,
    /** 门户-菜单管理 */
    GATEWAY_MENU: 15,
    /** 门户-菜单管理(手机端) */
    GATEWAY_MENU_MOBILE: 16,
    /** (报表)单级码表分析 */
    T_SELECT_SINGLE: 7,
    /** (报表)多级码表分析 */
    T_SELECT_MULTI: 8,
    /** (报表)统计表:支持多维统计表 二维报表 */
    T_DIMENSION_TWO: 9,
    /** (报表)统计表:支持多维统计表 多维报表 */
    T_DIMENSION_MULTI: 12,
    /** (报表)经历分析 */
    T_ROWSET_ANALYSIS: 10,
    /** (数据)数据导入 */
    D_ImportData: 11,
};
/** 数据权限参照枚举 */
const DataAuth = {
    /** 个人 */
    PERSON: 0,
    /** 本部门 */
    DEPART: 1,
    /** 全校 */
    SCHOOL: 2,
};

/**
 * 是否为功能视图列表详情页
 * @param {number} ftype 功能类型
 * @returns {boolean}
 */
function isFunctionviewListDetail(ftype) {
    return [
        FtypeCompares.N_MAINTAIN,
        FtypeCompares.N_PERSONS,
        FtypeCompares.R_APPLY,
        FtypeCompares.R_REVIEW,
        FtypeCompares.R_APPROVE,
        FtypeCompares.R_SUMMARY,
        FtypeCompares.R_ADMIN_APPLY,
        FtypeCompares.REVIEW_TRANSFER_READED,
        FtypeCompares.P_REVIEW,
    ].some((ftype2) => ftype == ftype2);
}

export { FtypeCompares, DataAuth, isFunctionviewListDetail };

// 加密秘钥
export const ENCRYPT_KEY = "hduawidhjkah";
