/**
 * @description 视图模块
 * @author tonny
 * @date 2022-08-13
 */

import _ from "lodash";
import Vue from "vue";
import Module from "../Module";
import Tools from "../../Utils/tools";

export default class View extends Module {
    constructor(...params) {
        super(...params);
        /** 请求对象 */
        this._request = kq.Request.mixin({ modules: ["view", "function"] });
    }
    mapState() {
        return {
            /** 视图信息 */
            views: {},
        };
    }
    /**
     * 设置信息对象
     * @param {object} state 当前仓库对象
     * @param {object} vinfo 信息表
     * @returns void
     */
    mutationSet(state, vinfo) {
        if (vinfo) {
            const { vid, fid } = vinfo;
            state.views[vid] = vinfo;
            if (fid) {
                state.views[fid] = vinfo;
            }
        }
    }
    /**
     * 更新视图信息
     * @param {object} state 当前仓库对象
     * @param {object} vinfo 信息表
     * @returns void
     */
    mutationUpdate(state, vinfo) {
        if (vinfo && (vinfo.fid || vinfo.vid)) {
            const { fid, vid } = vinfo;
            let info;
            if (fid) {
                info = state[fid];
            } else {
                info = state[vid];
            }
            _.forEach(vinfo, (value, key) => {
                if (!["fid", "vid"].includes(key)) {
                    Vue.set(info, key, value);
                }
            });
        }
    }
    /**
     * 设置更新低码参数
     * @param {object} state 当前残酷对象
     * @param {object} param 参数对象
     * @param {number} param.fid 功能点号
     * @param {[object]} param.lowcodeList 低码列表
     */
    mutationSetLowcode(state, { fid, lowcodeList }) {
        const vinfo = state.views[fid];
        if (vinfo) {
            vinfo.lowcodeList = lowcodeList;
        }
    }
    /**
     * 设置更新字段联动
     * @param {object} state 当前残酷对象
     * @param {object} param 参数对象
     * @param {number} param.fid 功能点号
     * @param {[object]} param.LinkageList 字段联动列表
     */
    mutationSetLinkage(state, { fid, LinkageList }) {
        const vinfo = state.views[fid];
        if (vinfo) {
            vinfo.linkageList = LinkageList;
        }
    }
    /**
     * 清空所有视图信息
     * @param {object} state 仓库存储对象
     * @returns void
     */
    mutationClear(state) {
        state.views = {};
    }
    /**
     * 获取视图信息
     * @param {object} param actions第一参数对象
     * @param {object} payloads 查询参数对象
     * @param {number} [payloads.vid] 视图号(vid,fid,vlabel不能同时为空)
     * @param {number} [payloads.fid] 功能点号(vid,fid,vlabel不能同时为空)
     * @param {string} [payloads.vlabel] 视图别名(vid,fid,vlabel不能同时为空)
     * @param {boolean} [payloads.isRefresh] 是否强制刷新(true:不会读取缓存)
     * @returns {Promise<object>} 成功返回信息对象,失败抛错
     */
    actionGetVinfo({ state, commit }, payloads) {
        return Tools.promise(async () => {
            const vinfo = state.views[payloads.fid || payloads.vid];
            if (!vinfo || payloads.isRefresh) {
                // 获取功能视图的基本信息
                const viewInfo = await this._request
                    .getViewInfo(payloads)
                    .catch((err) => {
                        throw err;
                    });
                /** 根据功能点查询的视图信息 */
                let finfo1 = {};
                /** 根据功能点查询的功能信息 */
                let finfo2 = {};
                // 2.获取视图单独的信息
                if (!payloads.vid && viewInfo.vid) {
                    finfo1 = await this._request
                        .getViewInfo({
                            vid: viewInfo.vid,
                        })
                        .catch((err) => {
                            throw err;
                        });
                }
                // 3.获取功能点相关信息
                finfo2 = await this._request.getFinfo(payloads).catch((err) => {
                    throw err;
                });
                let result = {};
                // 深度合并(合并非空变量)
                [finfo1, finfo2, viewInfo].forEach((info) => {
                    result = _.mergeWith(result, info, (prev, next) => {
                        if (Tools.isEmpty(prev) && !Tools.isEmpty(next)) {
                            return next;
                        }
                        if (!Tools.isEmpty(prev) && Tools.isEmpty(next)) {
                            return prev;
                        }
                    });
                });
                commit("set", result);
                return result;
            }
            return vinfo;
        });
    }
    actionGetVid({ dispatch }, payloads) {
        return new Promise((resolve, reject) => {
            dispatch("getVinfo", payloads)
                .then((vinfo) => {
                    resolve(vinfo.vid);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }
    actionGetFid({ dispatch }, payloads) {
        return new Promise((resolve, reject) => {
            dispatch("getVinfo", payloads)
                .then((vinfo) => {
                    resolve(vinfo.fid);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }
    /**
     * 获取视图对象
     * @param {object} state 仓库存储对象
     * @returns {object}
     */
    getterViews(state) {
        return state.views;
    }
}
