/**
 * @description 字符串工具判断类型方法集合
 * @author tonny
 * @date 2021-12-19
 */

import moment from "moment";

/**
 * 是否为日期类型字符串
 * @returns {boolean} true:是
 */
export function isDate() {
    if (!Number.isNaN(Number(this))) {
        return false;
    }
    return moment(this)._isValid;
}

/**
 * 是否为链接
 * @returns {boolean} true:是
 */
export function isUrl() {
    return /(https?|ftp|file|data):\/\/[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|]/.test(
        this
    );
}
/**
 * 是否为数字
 * @returns {boolean} true:是
 */
export function isNumber() {
    return this !== "" && !Number.isNaN(Number(this));
}

/**
 * 是否为图片
 * @returns {boolean} true:是
 */
export function isImage() {
    const suffix = this.match(/\w+$/);
    if (suffix && suffix[0]) {
        return ["jpg", "jpeg", "png", "webp", "gif", "bmp", "svg"].includes(
            suffix[0].toLowerCase()
        );
    }
    return /^data:image/.test(this);
}

/**
 * 是否为微软文件
 * @returns {boolean} true:是
 */
export function isMicrosoftFile() {
    const suffix = this.match(/\w+$/);
    if (suffix && suffix[0]) {
        return ["docx", "doc", "xls", "xlsx", "ppt", "pptx"].includes(suffix[0].toLowerCase());
    }
    return false;
}

/**
 * 字段zlabel是否相等
 * @param {string|object} target 目标字符串或对象
 * @returns {boolean} true:相等
 */
export function isEqualZlabel(target) {
    if (!target || !_.isString(target)) {
        return false;
    }
    if (_.isPlainObject(target)) {
        target = target.zlabel || "";
    } else if (!_.isString(target)) {
        return false;
    }
    let fromStr = this;
    const replaceRegExp = /^(F[A-Z])(_(X|Y))?_(\w+)$/;
    const hasPrefixRegExp = /^F[A-Z](_(X|Y))?_/;
    const concatFunc = ($, $1, $2, $3, $4) => `${$1}_${$4}`;
    const splitFunc = ($, $1, $2, $3, $4) => $4;
    if (hasPrefixRegExp.test(target)) {
        target = target.replace(replaceRegExp, concatFunc);
    } else {
        fromStr = fromStr.replace(replaceRegExp, splitFunc);
    }
    if (hasPrefixRegExp.test(fromStr)) {
        fromStr = fromStr.replace(replaceRegExp, concatFunc);
    } else {
        target = target.replace(replaceRegExp, splitFunc);
    }
    return fromStr.toString() === target.toString();
}
