<script>
/**
 * @description 判断新增按钮是否在视图配置新增时间内混入
 * @description 获取视图信息是否在添加时间内（不设置默认true）
 * @description 只有开始时间判断时候在开始时间之前，结束时间判断是否在结束时间之后
 * @description 两个时间都在时间范围内才能添加
 * @author fyd
 * @date 2023-05
 */
</script>


<script>
import _ from "lodash"
import moment from "moment";
export default {
    data() {
        return {
            /** 混入请求对象 */
            addMixinRequest: kq.Request.mixin({
                vm: this,
                modules: ["system"],
            }),
            /** 是否在新增时间范围内 */
            isOnAddTimeRange: true,
        };
    },
    methods: {
        /**
         * @description 获取视图信息是否在添加时间内（不设置默认true）
         * @description 只有开始时间判断时候在开始时间之前，结束时间判断是否在结束时间之后
         * @description 两个时间都在时间范围内才能添加
         * @returns {boolean} true:在添加时间内
         */
         async getVinfoConformAddTime({vid,fid}) {
            // 新增开始时间 新增结束时间
            const { startAddTime = "", endAddTime = "" } = await this.$store
                .dispatch("view/getVinfo", { vid,fid })
                .catch((err) => {
                    this.errHand__(err);
                    throw err;
                });
            let isConformAddTime = true;
            // 当前时间
            const nowTime = await this.addMixinRequest.getTime();
            if (startAddTime && endAddTime) {
                isConformAddTime = moment(nowTime).isBetween(startAddTime, endAddTime);
            } else if (startAddTime && !endAddTime) {
                isConformAddTime = moment(nowTime).isAfter(startAddTime);
            } else if (!startAddTime && endAddTime) {
                isConformAddTime = moment(nowTime).isBefore(endAddTime);
            } else {
                isConformAddTime = true;
            }
            return isConformAddTime;
        },
    },
}
</script>