import { render, staticRenderFns } from "./SquareIconBtn.vue?vue&type=template&id=62a50de3&scoped=true"
import script from "./SquareIconBtn.vue?vue&type=script&lang=js"
export * from "./SquareIconBtn.vue?vue&type=script&lang=js"
import style0 from "./SquareIconBtn.vue?vue&type=style&index=0&id=62a50de3&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../kooci-saas-recruit/node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "62a50de3",
  null
  
)

export default component.exports