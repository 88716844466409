import Base from "../base";

export default class ProcessManage extends Base {
    /**
     * 获取流程审核信息
     * @param {object} param 入参
     * @param {number} [param.pid] 流程号
     * @param {number} [param.fid] 功能点号
     * @returns {Promise<object>} 失败抛错,成功返回流程审核信息
     * @example
     * {
     *    all: [ // 所有审核字段
     *      {
     *          zid:123, // 审核字段号
     *          zname: "审核字段名称"
     *      }
     *   ],
     *   saved: [ // 已经保存的审核字段(当pid为空则为空)
     *      {
     *          zid: 123,
     *           zname: "部门审核",
     *           alias: "审核别名",  ---前端+
     *           applyPersonZid: 12345, // 申请人字段号
     *           reviewPersonZid: 12345,  // 审核人字段号
     *           condition: "1,!~,,and;", // 审核人过滤条件
     *           conditionMark: "姓名-不为空", // 审核人条件备注
     *           role: "person", // 指定审核角色 ---getUserList +
     *           uids: [3, 1234578], // 指定审核人员号列表
     *           vid: 124, // 独立视图号(用来指定可编辑的字段)
     *           messageRole: "person", // 抄送人角色
     *           messageUids: [3, 12222], // 抄送人指定人员号列表
     *           messageCondition: "1,!~,,and;", // 抄送人过滤条件
     *           messageConditionMark: "姓名-不为空", // 抄送人条件备注
     *       }
     *     ]
     *   }
     */
    async getProcessReviewInfo({ pid, fid }) {
        if (!pid && !fid) {
            throw new kq.Err({
                message: "流程号(pid)与功能点号(fid)不能同时为空",
                from: "kqRequest.Process.getProcessReviewInfo",
                current: arguments[0],
                console: false,
            });
        }
        return this.request({
            url: "/api/process/processQueryJson",
            params: { pid, fid },
        });
    }
    /**
     * 保存流程
     * @param {object} param 入参对象
     * @param {number} [param.pid] 流程号(为空时则会先创建一个流程)
     */
    async save({ pid, processInfo = {}, fieldList = [] }) {
        if (Tools.isEmpty(fieldList, processInfo)) {
            throw new kq.Err({
                message:
                    "要保存的审核字段列表(fieldList)与流程信息(processInfo)不能同时为空",
                from: "kqRequest.Process.saveProcessReviewInfo",
                current: arguments[0],
                console: false,
            });
        }
        if (!Tools.isEmpty(processInfo)) {
            await this.saveProcessInfo({ pid, ...processInfo }).catch((err) => {
                throw err;
            });
        }
        if (!this.isEmpty(fieldList)) {
            await this.saveProcessReview({ pid, zids: fieldList }).catch(
                (err) => {
                    throw err;
                }
            );
        }
    }
    /**
     * 保存流程信息
     * @param {object} param 参数对象
     * @param {number} [param.pid] 流程号
     * @param {number} param.sort 排序号
     * @param {string} [param.explain] 说明
     * @param {string} param.name 流程名称
     * @param {number|string} [param.pusher] 流程推送人(1|person:个人,2|apply:申请人)
     * @param {number|string} [param.reverseType=1] 流程扭转类型(1|order:顺发,2|parallel:并发)
     * @param {number|string} param.rejectType 驳回类型(2|person:个人,3|apply:申请人)
     * @param {number} [param.nextFid] 下一个流程发起点
     * @param {string} [param.condition] 审核条件
     * @param {string} [param.conditionMark] 审核条件备注
     * @returns {Promise<void>} 失败抛错
     */
    async saveProcessInfo({
        pid,
        fid,
        sort,
        explain,
        name,
        pusher,
        reverseType = 1,
        rejectType,
        nextFid,
        condition,
        conditionMark,
    }) {
        if (this.isEmptyMaybe(name, fid, sort, rejectType)) {
            throw new kq.Err({
                message:
                    "流程名称(name)与发起点功能点号(fid)与排序(sort)与驳回类型(rejectType)不能为空",
                from: "kqRequest.Process.saveProcessInfo",
                current: arguments[0],
                console: false,
            });
        }
        return this.request({
            url: "/api/process/upsertFlow",
            method: "post",
            data: {
                nodeID: pid,
                nodeValue1: fid,
                nodeName: name,
                sortID: sort,
                strValue4: explain,
                next_flow: nextFid ? 1 : 0,
                next_flow_funcid: nextFid,
                next_apply_type: pusher,
                nodeValue2: reverseType,
                nodeValue3: rejectType,
                enter_rule: condition,
                enter_rule_mark: conditionMark,
            },
        });
    }
}
