/**
 * 判断相关
 */

/**
 * 数组是否为空
 * @returns {boolean}
 */
export function isEmpty() {
    return !this.length;
}
