const getters = {
    isLogin: state => state.user.isLogin,
    pageName: state => state.system.pageName,
    systemName: state => state.system.systemName,
    processAliasList: state => state.process.processMbAliasList,
    menuTree: state => state.system.menuTree,
    defaultNav: state => state.system.defaultNav,
	iconLink: state => state.system.systemConfig?.iconLink || "",
	schoolName: state => state.system.systemConfig?.schoolName || "",
	schoolCode: state => state.system.systemConfig?.schoolCode || "",
	logoUrl: state => state.system.systemConfig?.logoUrl || "",
	recruitTitle: state => state.system.systemConfig?.recruitTitle || "",
	recruitTitleDescribe: state => state.system.systemConfig?.recruitTitleDescribe || "",
	navList: state => state.system.menuTree,
};
export default getters