const zlabels = {
    guideType: "FP_XiangDaoLeiXing",
    guideId: "FP_XiangDaoID",
    guideTitle: "FP_XiangDaoMingChen",
    guideDescription: "FP_XiangDaoShuoMing",
    guideEmit: "FP_XiangDaoJiHuoTiaoJian",
    guideFid: "FP_GuanLianGongNenDianHao",
    guideRule: "FP_BuZhouGuiZe",
    guideSkip: "FP_ShiFuYunXuTiaoGuo",
    guideSort: "FP_XiangDaoPaiXu",
    stepId: "FP_BuZhouID",
    stepSort: "FP_BuZhouPaiXu",
    stepTitle: "FP_BuZhouBiaoTi",
    stepDescription: "FP_BuZhouMiaoShu",
    stepPosition: "FP_BuZhouXianShiWeiZhi",
};

/** 向导视图必填字段label */
const guideRequiredZlabels = {
    [zlabels.guideType]: true,
    [zlabels.guideId]: true,
    [zlabels.guideTitle]: true,
    [zlabels.guideDescription]: true,
    [zlabels.guideEmit]: true,
    [zlabels.guideFid]: false,
    [zlabels.guideRule]: false,
    [zlabels.guideSkip]: false,
};

/** 步骤视图必填字段label */
const stepRequiredZlabels = {
    [zlabels.stepId]: true,
    [zlabels.stepSort]: true,
    [zlabels.stepTitle]: true,
    [zlabels.stepDescription]: true,
    [zlabels.stepPosition]: true,
};

/** 向导对照表 */
const guideCompares = {
    id: zlabels.guideId,
    title: zlabels.guideTitle,
    description: zlabels.guideDescription,
    rule: zlabels.guideRule,
    skip: zlabels.guideSkip,
    type: zlabels.guideType,
    emit: zlabels.guideEmit,
};

/** 步骤key对照表 */
const stepCompares = {
    id: zlabels.stepId,
    title: zlabels.stepTitle,
    description: zlabels.stepDescription,
    position: zlabels.stepPosition,
    sort: zlabels.stepSort,
    guideId: zlabels.guideId,
};

/** 向导字段信息表 */
const guideFields = {
    type: {
        zid: "type",
        zname: "向导类型",
        required: true,
        type: "select-single-1",
        value: "",
        zlabel: zlabels.guideType,
        sort: 1,
        selectList: [
            {
                label: "公共",
                value: "public",
            },
            {
                label: "私有",
                value: "private",
                disabled: "暂未开放",
            },
        ],
    },
    emit: {
        zid: "emit",
        zname: "触发类型",
        required: true,
        type: "select-single-1",
        value: "",
        help: "什么样的情况下触发自动向导",
        zlabel: zlabels.guideEmit,
        sort: 2,
        selectList: [
            {
                label: "页面载入后自动触发",
                value: "loadauto",
            },
            {
                label: "点击按钮后触发",
                value: "click",
                disabled: "暂未开放",
            },
        ],
    },
    title: {
        zid: "title",
        zname: "向导名称",
        required: true,
        type: "text-text",
        value: "",
        sort: 3,
        zlabel: zlabels.guideId,
    },
    description: {
        zid: "description",
        zname: "向导描述",
        required: false,
        type: "text-big",
        value: "",
        sort: 4,
        zlabel: zlabels.guideDescription,
    },
    stepRule: {
        zid: "rule",
        zname: "步骤规则",
        help: "执行下一步的操作方式",
        type: "select-single-1",
        value: "nav",
        zlabel: zlabels.guideRule,
        sort: 5,
        required: true,
        selectList: [
            {
                label: "导航按钮",
                value: "nav",
            },
            {
                label: "点击目标",
                value: "click",
                disabled: "暂未开放",
            },
        ],
    },
    allowSkip: {
        zid: "skip",
        zname: "是否允许跳过",
        help: "是否允许提前结束向导",
        type: "select-single-1",
        value: "1",
        sort: 6,
        required: true,
        zlabel: zlabels.guideSkip,
        selectList: [
            {
                label: "是",
                value: 1,
            },
            {
                label: "否",
                value: 0,
            },
        ],
    },
};
/** 步骤字段总表 */
const stepFields = {
    id: {
        zid: "id",
        zname: "ID",
        type: "text-text",
        value: "",
        sort: 0,
        zlabel: zlabels.stepId,
        readonly: true,
    },
    sort: {
        zid: "sort",
        zname: "排序",
        type: "text-number",
        value: "",
        sort: 1,
        zlabel: zlabels.stepSort,
    },
    title: {
        zid: "title",
        zname: "标题",
        placeholder: "请输入步骤标题",
        type: "text-text",
        value: "",
        sort: 2,
        zlabel: zlabels.stepTitle,
    },
    description: {
        zid: "description",
        zname: "描述",
        placeholder: "请输入步骤描述",
        type: "text-big",
        value: "",
        sort: 3,
        zlabel: zlabels.stepDescription,
    },
    position: {
        zid: "position",
        zname: "显示方向",
        placeholder: "请输入步骤显示方向",
        value: "",
        type: "select-single-1",
        zlabel: zlabels.stepPosition,
        selectList: [
            {
                label: "上",
                value: "top",
            },
            {
                label: "右",
                value: "right",
            },
            {
                label: "下",
                value: "bottom",
            },
            {
                label: "左",
                value: "left",
            },
        ],
    },
};

export {
    zlabels,
    guideCompares,
    stepCompares,
    guideRequiredZlabels,
    stepRequiredZlabels,
    guideFields,
    stepFields,
};
