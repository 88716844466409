/**
 * 万能评审相关
 * @author fyd
 * @date 2023-07-05
 */
import Base from "../base";
import Tools from "../../Utils/tools";
export default class Universal extends Base {
    /**
     * 万能评审登录
     * @param {object} param0
     * @param {number} param0.account 评审码
     * @param {number} param0.imgCode 图片验证码
     * @param {number} param0.phoneCode 手机验证码
     * @param {number} param0.phone 手机号
     * @param {number} param0.loginType 登录类型 1:评审码登录，2:手机验证码登录
     * @returns {Promise<object>} 成功返回信息对象,失败抛错
     * @example
     * new kq.Request.Universal().login({account: 1, imgCode: 2, type: review})
     */
    async universalLogin({ account, imgCode, uuid, phoneCode, phone, loginType,serialNo }) {
        if (Tools.isEmptyMaybe(account, imgCode) && Tools.isEqual(loginType, 1)) {
            throw new kq.Err({
                message: "评审码或图片验证码不能为空",
                current: arguments[0],
                from: "kq.Request.login",
            });
        }
        if (Tools.isEmptyMaybe(phoneCode, phone) && Tools.isEqual(loginType, 2)) {
            throw new kq.Err({
                message: "手机号与验证码不能为空",
                current: arguments[0],
                from: "kq.Request.login",
            });
        }
        let params = {
            url: "/api/universal/login",
            method: "post",
            data: {
                account,
                imgCode,
                phoneCode,
                phone,
                uuid,
                serialNo
            },
        };
        return this.request(params, arguments[0]);
    }
    /**
     * 万能评审退出登录
     */
    async universalLogout() {
        const params = {
            url: "/api/universal/logout",
        };
        return this.request(params, arguments[0]);
    }
}
