import Convert from "../Convert/Convert.js";
import moment from "moment";

export default class ConvertDetail extends Convert {
    inModel(value) {
        if (this._type.includes("file")) {
            return this._inModelFile(value);
        }
        return super.inModel(value);
    }

    outModel(value) {
        if (this._type.includes("file")) {
            return this._outModelFile(value);
        }
        return super.outModel(value);
    }
    outChange(field) {
        if (this._type.includes("file")) {
            return this._outChangeFile(field);
        }
        if (this._type.includes("review")) {
            return this._outChangeReview(field);
        }
        if (this._type.includes("select")) {
            return this._outChangeSelect(field);
        }
        return super.outChange(field);
    }
    /**
     * 码表类型change转换
     * @description 多选类型转换
     * @param {object} field 字段对象
     * @returns {object}
     */
    _outChangeSelect(field) {
        if (this._type.includes("multi")) {
            let value = field.value;
            if (_.isArray(value)) {
                value = value.join(",");
            }
            return {
                ...field,
                value,
            };
        }
        return field;
    }
    /**
     * 文件类型输入转换
     * @description 多选值将附件转换为数组
     * @param {*} value 输入值
     * @returns {*}
     */
    _inModelFile(value) {
        if (this._type.includes("multi") || this._vm.$props.isChange) {
            if (_.isString(value)) {
                return value.split(";").filter((val) => val !== "");
            }
        }
        return value;
    }
    /**
     * 文件类型输出转换
     * @description 将附件转换为字符串
     * @param {*} value 输出值
     * @returns {*}
     */
    _outModelFile(value) {
        if (_.isArray(value)) {
            return (value || []).join(";");
        }
        return value;
    }
    /**
     * 文件类型change转换
     * @param {object} field 字段对象
     * @returns {object}
     */
    _outChangeFile(field) {
        return { ...field, value: this._outModelFile(field.value) };
    }
    /**
     * 审核类型change转换
     * @param {object} field 字段对象
     * @returns {object}
     */
    _outChangeReview(field) {
        const { uname, comment, datetime, signature } = this._vm.svalue_ || {};
        return {
            ...field,
            rvalue: !kq.Tools.isEmpty(field.value)
                ? [comment, signature, datetime, uname].join(";")
                : "",
        };
    }
}
