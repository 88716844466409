/**
 * @description dom尺寸更改监听指令
 * @author tonny
 * @date 2022-08-13
 */

import ResizeObserver from "resize-observer-polyfill";
import _ from "lodash";
import $ from "jquery";

export default {
    install(Vue) {
        Vue.directive("kq-resize", {
            bind(el, { modifiers, value }) {
                if (_.isFunction(value)) {
                    /** 节流间隔 */
                    let interval = 100;
                    _.some(modifiers, (val, key) => {
                        if (kq.Tools.isNumber(key) && val) {
                            interval = Number(modifiers[key]);
                            el._interval = interval;
                            return true;
                        }
                    });
                    // 缓存读取
                    let robserver = el._robserver;
                    if (!robserver) {
                        robserver = new ResizeObserver(
                            _.debounce(function () {
                                value({
                                    width: $(el).width(),
                                    height: $(el).height(),
                                    $el: $(el),
                                    target: el,
                                });
                            }, interval)
                        );
                        robserver.observe(el);
                        el._robserver = robserver;
                    }
                } else {
                    console.warn("v-kq-error指令的值必须是一个函数");
                }
            },
            /**
             *
             * @param {Element} el 目标dom
             * @param {object} param 指令参数对象
             * @param {object} param.modifiers 指令修饰符镀锡
             * @param {function} value 指令执行操作函数
             */
            inserted(el, { value, modifiers }) {
                if (modifiers.immediate && _.isFunction(value)) {
                    setTimeout(() => {
                        value({
                            $el: $(el),
                            width: $(el).width(),
                            height: $(el).height(),
                            target: el,
                        });
                    }, el._interval || 100);
                }
            },
            unbind(el) {
                if (el._robserver) {
                    el._robserver.disconnect();
                    el._robserver.unobserve(el);
                    el._robserver = null;
                }
            },
        });
    },
};
