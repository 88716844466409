<script>
/**
 * @description 字段转换混入组件
 * @see 对系统内不同的接口返回值进行属性转换,使外界可以直接通过v-bind=field来绑定字段
 * @author tonny
 * @date 2021-10-09
 */
</script>

<script>
import Mixin from "./Mixin.vue";

const Config = kqPub.FormConfig;

export default {
    mixins: [Mixin],
    props: {
        /** 字段名称 @see 视图查询(/api/view) */
        nodeName: String,
        name: String,
        /** 字段名称 @see 详情查询(/api/common/dict/queryFormDataByViewIdAndRootBillI) */
        title: String,
        /** 字段值 */
        value: [String, Number],
        /** 字段类型1 */
        nodeType: {
            type: [String, Number],
            validator(val) {
                if (Number.isNaN(Number(val))) {
                    new kqPub.Err({
                        from: "InputCellConvert.props",
                        title: "参数错误!",
                        message: "字段类型1(nodeType)只能为数字型字符串或数字!",
                        current: val,
                    });
                    return false;
                }
                return true;
            },
        },
        /** 字段类型2 */
        checkId: {
            type: [String, Number],
            validator(val) {
                if (Number.isNaN(Number(val))) {
                    new kqPub.Err({
                        from: "InputCellConvert.props",
                        title: "参数错误!",
                        message: "字段类型2(checkId)只能为数字型字符串或数字!",
                        current: val,
                    });
                    return false;
                }
                return true;
            },
        },
        /** 是否只读 */
        readOnly: [Number, Boolean],
        /** 额外属性(包含时间format与是否禁用) */
        props: {
            type: Object,
            default: () => ({}),
        },
        /** 字段号 @see 详情查询 */
        id: [Number, String],
        /** 字段号 @see 视图查询 */
        nodeId: [Number, String],
        /** 别名 */
        label: String,
        /** 是否必填 */
        isRequire: [Number, Boolean],
        /** 视图号 */
        viewId: [Number, String],
        /** 日期类型字符串 */
        dateType: String,
        /** 码表列表 */
        options: Array,
    },
    computed: {
        /**
         * 字段类型
         * @see this.nodeType
         * @see this.checkId
         * @returns {string}
         */
        formatType() {
            if (this.nodeType !== undefined && this.checkId !== undefined) {
                return Config.getType(this.nodeType, this.checkId);
            } else {
                const err = new kqPub.Err({
                    title: "参数警告!",
                    from: `${this.$options.name}.computed.formatType`,
                    message: `未查询到相关的字段类型,这可能会导致单元格错误~,字段:${this.name}`,
                    console: false,
                });
                console.warn(err.message);
            }
        },
        /**
         * 组件对象
         * @see 提供给个类型InputCell组件的props
         * @returns {object}
         */
        component() {
            /** 字段类型(本组件props.type优先) */
            const zname =
                this.zname || this.name || this.title || this.nodeName || "";
            /** 字段类型 */
            let type = zname.includes("签名")
                ? "signature"
                : this.type || this.formatType || "text-text";
            /** 日期格式化字符串 */
            let dateFormat =
                this.dateFormat ||
                (this.props && this.props.format) ||
                this.dateType ||
                "";
            return {
                /** 字段名 */
                zname,
                /** 字段类型 */
                type,
                /** 是否只读 */
                readonly:
                    this.readonly ||
                    this.props.disabled ||
                    Boolean(Number(this.readOnly)),
                /** 是否禁用 */
                disabled: this.disabled,
                /** 是否必填 */
                required: this.required || Boolean(Number(this.isRequire)),
                /** 视图号 */
                vid: this.vid || this.viewId,
                /** 字段号 */
                zid: this.zid || this.id || this.nodeId,
                /** 提示文本 */
                placeholder: this.placeholder,
                /** 是否变化了 */
                isChange: this.isChange,
                /** 点击拦截器 */
                clickInterceptor: this.clickInterceptor,
                /** 日期格式化串 @see %Y年%m月%d日 %H时%i分%s秒 */
                dateFormat: dateFormat
                    .replace("%Y", "YYYY")
                    .replace("%m", "MM")
                    .replace("%d", "DD")
                    .replace("%H", "HH")
                    .replace("%i", "mm")
                    .replace("%s", "ss"),
                /** 范围值 */
                isRange: this.isRange,
                /** 码表列表 */
                selectList:
                    this.selectList ||
                    (this.options && this.options.map(this._selectConvert)),
            };
        },
    },
    methods: {
        /**
         * option转换
         * @see computed.formatOptions核心函数
         * @see 递归调用将option中的name和label合并
         * @param {object} option 每一个选项
         * @returns {object}
         */
        _selectConvert(select) {
            return {
                ...select,
                label: select.name || select.label,
                value: select.id || select.value,
                children: _.isEmpty(select.children)
                    ? undefined
                    : select.children.map(this._selectConvert),
            };
        },
    },
};
</script>