/**
 * @description cookie相关操作
 * @author maxi
 * @date 2023-02-09
 */

import Cookies from "js-cookie";
import Vue from "vue";

const TokenKey = "token";

export function getToken() {
    return (Vue.prototype.$utils && Vue.prototype.$utils.getToken()) || Cookies.get(TokenKey)
}

export function setToken(token) {
    return (Vue.prototype.$utils && Vue.prototype.$utils.setToken(token)) || Cookies.set(TokenKey, token)
}

export function removeToken() {
    return Cookies.remove(TokenKey);
}