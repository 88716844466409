<template>
    <div id="app">
        <router-view />
    </div>
</template>

<script>
export default {
    data() {
        return {
            
        };
    },
    computed: {
        /** 
         * 获取当前视口尺寸
         */
        clientWidthData() {
            return document.documentElement.clientWidth
        }
    },
    watch: {
        clientWidthData: {
            handler(newValue) {
                if (newValue >= 1920) {
                    document.documentElement.style.fontSize = '18px';
                }
            },
            immediate: true,
            deep: true
        }
    },
    mounted() {
        
    },

    methods: {
        
    },
};
</script>
