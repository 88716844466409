/**
 * @description 全局更改数据仓库
 * @description 需要配合vuex使用
 * @description 该封装适用跨路由之间的数据变更后需要刷新的机制,比如:更改详情页后,回到列表页需要重新初始化列表页数据
 * @example export default new Vuex({modules: kq.StoreChange})
 * @example 设置: this.$store.commit("change/set", {id: this.fid});
 * @example 获取: this.$store.getters["change/get"][123];
 * @example 删除: this.$store.commit("change/delete", {id: this.fid});
 * @author tonny
 * @date 2022-07-20
 */

import Module from "../Module";
import Tools from "../../Utils/tools";
import Vue from "vue";

export default class Change extends Module {
    /**
     * 仓库存储对象(重写)
     * @returns {object}
     */
    mapState() {
        return {
            /** 更新对象 */
            changes: {},
        };
    }
    /**
     * 设置一个值
     * @param {object} param vuex参数对象
     * @param {object} param.state vuex仓库对象
     * @param {object|string|number} payload 设置目标参数对象
     * @param {string|number} payload.id 存储更改的id
     * @returns void
     */
    mutationSet(state, id = {}) {
        if (_.isObject(id)) {
            id = id.id;
        }
        if (!Tools.isEmpty(id)) {
            state.changes[id] = true;
        }
    }
    /**
     * 删除一个值
     * @param {object} param vuex参数对象
     * @param {object} param.state vuex仓库对象
     * @param {object|string|number} payload 设置目标参数对象
     * @param {string|number} payload.id 存储更改的id
     * @returns void
     */
    mutationDelete(state, id = {}) {
        if (_.isObject(id)) {
            id = id.id;
        }
        if (!Tools.isEmpty(id)) {
            Vue.delete(state.changes, id);
        }
    }
    /**
     * 清空更改值
     * @param {object} state 仓库存储对象
     * @returns void
     */
    mutationClear(state) {
        state.changes = {};
    }
    /**
     * 获取更改对象
     * @param {object} param vuex参数对象
     * @param {object} param.state vuex仓库对象
     * @returns {object}
     */
    getterGet(state) {
        return state.changes || {};
    }
}
