<script>
/**
 * @description 保存按钮公用混入
 * @author tonny
 * @date 2022-01-05
 */
</script>

<script>
import _ from "lodash";
import moment from "moment";

const SUPER = "KQ_SAVE_MIXIN";

export default {
    SUPER,
    props: {
        /** 是否只读(sync双向绑定) */
        readonly: Boolean,
        /** 内部号 */
        uid: [String, Number],
    },
    data() {
        return {
            /** 是否只读 */
            readonly_: false,
            /** 保存按钮(需要子混入重写) */
            saveBtn: {
                name: "保存",
                disabled: false,
            },
            /** 内部号(双向) */
            uid_: null,
            /** 保存关闭行集详情弹层 */
            isSaveCloseRowsetDetailDialog: true,
        };
    },
    watch: {
        /**
         * @see 1.只读:隐藏保存按钮
         */
        readonly_: {
            handler(readonly) {
                this.saveBtn.disabled = readonly;
            },
            immediate: true,
        },
    },
    created() {
        this.editBarBtnList.push(this.saveBtn);
        this.sync__({ from: "uid", convert: (val) => Number(val) });
    },
    methods: {
        /**
         * 验证函数
         * @virtucal
         * @returns {Promise<void>} 失败抛错
         */
        async validate() {
            return this.$super(SUPER).validate();
        },
        /**
         * 处理需要保存事件
         * @see 处理子行集保存之前行集需要先保存的情况
         * @param {function} after 保存后执行的操作
         * @returns void
         */
        handlerNeedSave(after) {
            // 临时取消子行集保存后关闭
            this.isSaveCloseRowsetDetailDialog = false;
            this.onSave({ isCommit: false }).then(() => {
                after();
                this.isSaveCloseRowsetDetailDialog = true;
            });
        },
        /**
         * 保存按钮得prop是否禁用
         * @description 1.当前是否更改字段
         * @returns {boolean} true:禁用
         */
        _saveBtnPropsDisabled() {
            if (this.isEmpty__(this.changeFieldList)) {
                return "未修改任何字段";
            }
        },
        /**
         * 保存事件
         * @see this.saveBtn按钮点击调用
         * @param {boolean} isCommit 是否需要提交
         * @param {boolean} [processDisabled=false] 是否禁止流程提交
         * @param {boolean} [validateDisabled=false] 是否禁止表单校验
         * @returns void
         */
        async onSave({
            isCommit,
            processDisabled = false,
            validateDisabled = false,
        } = {}) {
            return new Promise((resolve, reject) => {
                setTimeout(async () => {
                    this.saveBtn.loading = "保存中";
                    if (!validateDisabled) {
                        // 校验审核字段中的子字段
                        const isReviewValidateOk = this.validateReviewField();
                        // 表单验证
                        let commonValidateErr = null;
                        await this.validate().catch((err) => {
                            commonValidateErr = err;
                        });
                        if (!isReviewValidateOk || commonValidateErr) {
                            if (commonValidateErr) {
                                console.error(commonValidateErr);
                            }
                            const tipKey = isCommit ? "提交" : "保存";
                            this.error__ = {
                                title: `${tipKey}失败`,
                                message: `请根据红色错误提示重新修改数据后重新${tipKey}~`,
                                duration: 5000,
                            };
                            this.saveBtn.loading = false;
                            // 验证未通过
                            commonValidateErr = new Error("验证未通过");
                            reject(commonValidateErr);
                            throw commonValidateErr;
                        }
                    }
                    // 空数据不保存(不提交的情况下)
                    if (!this.isChange && !isCommit) {
                        this.saveBtn.loading = false;
                        resolve();
                        return;
                    }
                    await this.save(isCommit).catch((err) => {
                        this.saveBtn.loading = false;
                        reject(err);
                        throw err;
                    });
                    this.saveBtn.loading = false;
                    this.$emit(
                        "saved",
                        this._emitDataConvert(this.changeFieldList),
                        this.isSaveCloseRowsetDetailDialog
                    );
                    // 清空查询项
                    this.clear(true);
                    this.success__ = "保存成功~";
                    resolve();
                }, 200);
            });
        },
        /**
         * 保存逻辑
         * @see 表单验证
         * @returns {Promise<void>} false:未保存, 失败抛错
         */
        async save() {},
        /**
         * 激活saved事件参数
         * @returns {object}
         */
        _emitDataConvert(changeFieldList) {
            const fieldList = [];
            this.formatDataList.kqEachDeep((field) => {
                let { type, value, selectList, dateFormat } = field;
                const changedField = changeFieldList.find(({ zid }) =>
                    this.isEqual__(zid, field.zid)
                );
                if (changedField) {
                    value = changedField.svalue || changedField.value;
                } else if (
                    value &&
                    type.includes("select") &&
                    !this.isEmpty__(selectList)
                ) {
                    // 数组转换
                    value = String(value).split(",");
                    value = value
                        .map(
                            (sid) =>
                                selectList.kqFindDeep((select) =>
                                    this.isEqual__(select.value, sid)
                                )?.label
                        )
                        .join(",");
                } else if (value && type.includes("date") && dateFormat) {
                    value = moment(value).format(dateFormat);
                }
                fieldList.push({ ...field, value });
            });
            return {
                rid: this.rid_,
                uid: this.uid_,
                fieldList,
            };
        },
    },
};
</script>
