<script>
/**
 * 工具栏按钮混入
 * @desc 提供了按钮排序,按钮合并的方法
 * @author tonny
 * @date 2022-08-31
 */
</script>

<script>
export default {
    data() {
        return {
            /* 
            demoBtn: {
                name: "按钮名称",
                click: this.demoClick,
                custom: {
                    enable: {
                        //是否参照只读(只读则不存在该按钮)
                        readonly: true,
                        // 只读的方法
                        method: () => true,
                    },
                    // 是否禁用<boolean|function>
                    disabled: () => false,
                },
            }
            */
        };
    },
    computed: {
        /**
         * 是否只读
         * @returns {boolean} true:只读
         */
        formatReadonly() {
            return false;
        },
    },
    methods: {
        /**
         * 合并按钮
         * @see 按钮转换
         * @param {[object]} btnList 按钮数组
         * @returns {[object]}
         */
        _mergeBtn(btnList) {
            btnList = kq.Button.sort(btnList.filter(this._filterBtn));
            return (btnList || []).map((btn) => new kq.Button(btn));
        },
        /**
         * 按钮筛选规则
         * @param {object} btn 按钮对象
         * @param {object|boolean} [btn.enable] 按钮是否可用
         * @param {boolean} [btn.disabled=false] 按钮是否禁用
         * @returns {boolean} true:可用通过
         */
        _filterBtn({ custom: { enable = true, disabled = false } = {} } = {}) {
            let methodOk = true;
            if (_.isPlainObject(enable)) {
                const { method, readonly } = enable || {};
                // 判断只读筛选
                if (readonly && this.formatReadonly) {
                    return false;
                }
                /** 方法判断是否通过 */
                methodOk = _.isFunction(method) ? method() : true;
            }
            if (_.isFunction(disabled)) {
                disabled = disabled();
            }
            return methodOk && enable && !disabled;
        },
    },
};
</script>
