import moment from "moment";
import xss from "xss";

/**
 * 日期格式化
 * @param {string} format 格式串(参考moment)
 * @returns {string}
 */
export function dateFormat(format) {
    return moment(this).format(format);
}
// 设置白名单
const xssOptions = {
    // 白名单
    whiteList: {
        a: ["style", "href", "title", "target","class"],
        p: ["style","class"],
        section: ["style","class"],
        strong: ["style","class"],
        abbr: ["title", "style","class"],
        address: ["style","class"],
        area: ["style", "shape", "coords", "href", "alt","class"],
        article: ["style","class"],
        aside: ["style","class"],
        audio: ["style", "autoplay", "controls", "loop", "preload", "src","class"],
        b: ["style","class"],
        bdi: ["style", "dir","class"],
        bdo: ["style", "dir","class"],
        big: ["style","class"],
        blockquote: ["style", "cite","class"],
        br: ["style","class"],
        caption: ["style","class"],
        center: ["style","class"],
        cite: ["style","class"],
        code: ["style","class"],
        col: ["style", "align", "valign", "span", "width","class"],
        colgroup: ["style", "align", "valign", "span", "width","class"],
        dd: ["style","class"],
        del: ["style", "datetime","class"],
        details: ["style", "open","class"],
        div: ["style", "style","class"],
        dl: ["style","class"],
        dt: ["style","class"],
        em: ["style","class"],
        font: ["style", "color", "size", "face","class"],
        footer: ["style","class"],
        h1: ["style","class"],
        h2: ["style","class"],
        h3: ["style","class"],
        h4: ["style","class"],
        h5: ["style","class"],
        h6: ["style","class"],
        header: ["style","class"],
        hr: ["style","class"],
        i: ["style","class"],
        img: ["style", "src", "alt", "title", "width", "height","class"],
        ins: ["style", "datetime","class"],
        li: ["style","class"],
        mark: ["style","class"],
        nav: ["style","class"],
        ol: ["style","class"],
        pre: ["style","class"],
        s: ["style","class"],
        small: ["style","class"],
        span: ["style","class"],
        sub: ["style","class"],
        sup: ["style","class"],
        table: ["width", "border", "align", "valign", "style","class"],
        tbody: ["style", "align", "valign","class"],
        td: ["width", "rowspan", "colspan", "align", "valign", "style","class"],
        tfoot: ["style", "align", "valign","class"],
        th: ["style", "width", "rowspan", "colspan", "align", "valign","class"],
        thead: ["style", "align", "valign","class"],
        tr: ["style", "rowspan", "align", "valign","class"],
        tt: ["style","class"],
        u: ["style","class"],
        ul: ["style","class"],
        video: ["style", "autoplay", "controls", "loop", "preload", "src", "height", "width","class"],
        style: ["style","class"], //新添
    },
    // 去掉不在白名单上的标签
    // stripIgnoreTag: true,
    // 去掉HTML备注
    allowCommentTag: false,
    // 彻底去除script标签
    stripIgnoreTagBody: ["script", "noscript"],
};
/**
 * 清除脚本
 * @returns {string}
 */
export function clearScript() {
    return xss(unescape(this.replace(/&#xA0;/g, "&nbsp;").replace(/&#x(.*?);/g, "%u$1")),xssOptions);
}

/**
 * 清除html标签
 * @returns {string}
 */
export function clearHtml() {
    return this.replace(/<br ?\/>/gi, "\n")
        .replace(/(<[^>]*>)/gi, "")
        .replace(/&nbsp;/gi, " ");
}

/**
 * 将字符串转换为html
 * @see 替换\n空格与h标题
 * @returns {string}
 */
export function toHtml() {
    let result = this;
    // 本身不是html
    if (!/<.+>/.test(result)) {
        result = result
            .replace(/#([1-9]) ([^\n]+)\n/g, ($, $1, $2) => `<h${$1}>${$2}</h${$1}>`)
            .replace(/ /g, "&nbsp;");
    }
    result = result.replace(/\n/g, "<br/>");
    return result;
}

/**
 * 获取query参数
 * @returns {object}
 */
export function query() {
    const result = {};
    let str = this;
    if (str.includes("%u")) {
        str = decodeURI(unescape(str));
    } else {
        str = unescape(decodeURI(str));
    }
    const querys = str.match(/[?&]{1}[^=]+=[^?&#]+/g);
    if (querys) {
        querys.forEach((queryStr) => {
            const [key, value] = queryStr.split("=");
            result[key.slice(1)] = value;
        });
    }
    return result;
}

/**
 * 添加query参数
 * @param {string} key query参数的key
 * @param {string|number} value query参数的value
 * @returns {string}
 */
export function addQuery(key, value) {
    return this.replace(
        /((\?|&)([^&#\/]+=[^&#\/]+))?$/,
        ($, $1, $2, $3) => `${$}${$2 === "?" || "&" ? "&" : "?"}${key}=${value}`
    );
}
