<script>
/**
 * @description 数据基础模块混入
 * @description 进入路由加载refresh以及钩子
 * @description 支持路由参数变化时重新加载refresh(需要配置refreshVars变量)
 * @author tonny
 * @date 2021-09-30
 */
</script>

<script>
import Utils from "../../Utils/tools";
import DataErr from "./DataErr.vue";

const SUPER = "DataMixin";

export default {
    SUPER,
    mixins: [DataErr],
    data() {
        return {
            configEnterRouteEveryRefresh: false, // 配置-是否每次进入路由都刷新
            isFirstEnterRoute: true, // 是否第一次进入路由
            isRefresh: false, // 是否已经初始化页面
            needEnterRouteRefresh: true, // 进入路由加载页面
            /** 路由是否活跃 */
            isRouteActived: true,
            /**
             * 重载页面刷新的参考变量(params或query内对应的参数都可以)
             * @desc 如果为对象:则指定刷新的路由参数
             * @desc 如果为"all":则指定所有的parmas与query参数(路由只要一变化就刷新)
             */
            refreshVars: {},
            /** 刷新加载状态 */
            refreshLoading: false,
            /** 刷新的节流时间(毫秒) */
            refreshThrottleTimeout: 200,
        };
    },
    watch: {
        /**
         * @see 1.设置加载路由参数,判断参数变化重载页面数据
         */
        $route: {
            handler({ params, query }) {
                if (!this.isEmpty__(this.refreshVars)) {
                    params = { ...params, ...query };
                    // 延时执行(为了保证在enterRouter与actived钩子之后判断)
                    setTimeout(() => {
                        if (this.refreshVars === "all") {
                            this.refresh("router-change");
                        } else {
                            if (this.isRouteActived) {
                                let isNotSame = false;
                                _.forEach(this.refreshVars, (val, key) => {
                                    if (
                                        params[key] !== undefined &&
                                        !this.isEqual__(params[key], val)
                                    ) {
                                        this.refreshVars[key] = params[key];
                                        isNotSame = true;
                                    }
                                });
                                // 参数不同且当前组件活跃态(排除组件退出时会进行判断)
                                if (isNotSame) {
                                    this.hasRefreshByRefreshVars = true;
                                    this.refresh("router-change");
                                }
                            }
                        }
                    }, 1);
                }
            },
            immediate: true,
        },
    },
    created() {
        this.refreshMethod = this.refresh.kqThrottle(200);
    },
    // 进入路由钩子
    beforeRouteEnter(to, from, next) {
        next((_this) => {
            if (_this.needEnterRouteRefresh && !_this._enterRefreshDisabled()) {
                _this._init_(to, from, next);
            }
            _this.isRouteActived = true;
        });
    },
    activated() {
        this.isRouteActived = true;
    },
    deactivated() {
        this.isRouteActived = false;
    },
    methods: {
        /**
         * 进入刷新是否禁用
         * @desc 避免beforeRouterEnter与refreshVars同时刷新(以refreshVars为准)
         * @treurns {boolean} true:进入路由刷新禁用
         */
        _enterRefreshDisabled() {
            return (
                !this.isEmpty__(this.refreshVars) &&
                !this.hasRefreshByRefreshVars
            );
        },
        /**
         * 刷新函数(立刻)
         * @see 此函数会同时调用init相关钩子
         * @returns {Promise<void>} 失败抛错
         */
        _refresh() {
            return new Promise((resolve, reject) => {
                this.clear();
                this.refreshLoading = true;
                this.isRefresh = false;
                this.asyncChain([
                    { handler: this.beforeInit, error: reject },
                    { handler: this.init, error: reject },
                    { handler: this.afterInit, error: reject },
                    {
                        handler: () => {
                            this.refreshLoading = false;
                            this.isRefresh = true;
                            this.isFirstEnterRoute = false;
                            resolve();
                        },
                    },
                ]);
            });
        },
        /**
         * 刷新页面
         * @desc 自动添加防抖机制
         * @desc 不要重写,如果重写请重写_refresh
         * @see 刷新页面调用
         * @see 此函数会同时调用init相关钩子
         * @returns {Promise<void>} 失败抛错
         */
        refresh() {
            return new Promise((resolve, reject) => {
                if (!this.debounceTimer_) {
                    this._refresh().then(resolve).catch(reject);
                    this._debounceIsCallRefresh = true;
                }
                if (!this.debounceTimer_) {
                    this.debounceTimer_ = setTimeout(() => {
                        /** 调用结果 */
                        let callRes;
                        if (!this._debounceIsCallRefresh) {
                            callRes = this._refresh();
                        }
                        clearTimeout(this.debounceTimer_);
                        this.debounceTimer_ = null;
                        this._debounceIsCallRefresh = false;
                        // 延迟判断调用结果进行结果返回(避免提前调用resolve导致函数提前结束)
                        if (callRes) {
                            callRes.then(resolve).catch(reject);
                        }
                    }, this.refreshThrottleTimeout);
                }
            });
        },
        /**
         * 清空数据状态
         * @returns void
         */
        clear(...params) {
            this.$super(SUPER).clear(...params);
        },
        /**
         * 初始化前钩子
         * @see this.refresh调用
         * @see 提供给个性化重写
         * @returns {Promise|*} 如果返回值,则该值会被作为第一个参数传入到下一个钩子(init)函数
         */
        async beforeInit() {
            return this.$super(SUPER).beforeInit();
        },
        /**
         * 初始化钩子
         * @see 一般重写用来通用功能的请求数据
         * @see this.refresh调用
         * @see 提供给个性化重写
         * @returns {Promise|*} 如果返回值,则该值会被作为第一个参数传入到下一个钩子(afterInit)函数
         */
        async init() {
            return this.$super(SUPER).init();
        },
        /**
         * 初始化前钩子
         * @see this.refresh调用
         * @see 提供给个性化重写
         * @returns {Promise|*} void
         */
        async afterInit() {
            return this.$super(SUPER).afterInit();
        },
        /**
         * 异步链式调用
         * @see 实现链式同步|异步调用
         * @param {Function|[Function]|[object]} handlerList 处理函数(列表),[{success<Function>:成功处理函数, err?<Function>:失败处理函数}]
         * @param {*} param 绑定的参数
         * @returns void
         */
        asyncChain(handlerList, params) {
            Utils.asyncChain(handlerList, params);
        },
        /**
         * 进入路由自定义钩子
         * @see 进入路由钩子被调用
         * @param {object} to 要进入的路由(当前路由)
         * @param {object} from 上一个路由信息对象
         * @returns {boolean|undefined} false:该返回结果将阻止this.refresh被调用
         */
        routeEnter() {},
        /**
         * 初始化
         * @see 私有函数
         * @see beforeRouteEnter钩子调用
         * @see 进入路由默认操作
         * @param {object} to 跳转的当前路由信息
         * @param {object} from 来自的路由信息
         * @param {function} next 路由跳转函数(只有this.routeEnter使用)
         * @returns void
         */
        _init_(to, from, next) {
            // 默认置顶
            // $("html,body").scrollTop(0);
            // 调用子进入路由逻辑
            if (this.routeEnter(to, from, next) !== false) {
                // 首次进入路由 || 每次进入路由都刷新的配置开始 -> 刷新页面
                if (
                    this.isFirstEnterRoute ||
                    this.configEnterRouteEveryRefresh
                ) {
                    const res = this.refresh("router-enter");
                    if (res instanceof Promise) {
                        res.then(() => {
                            this.isFirstEnterRoute = false;
                        });
                    } else {
                        this.isFirstEnterRoute = false;
                    }
                }
            }
        },
    },
};
</script>
