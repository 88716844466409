<script>
/**
 * @description 公共页面请求混入
 * @see 抽象混入组件(不能作为组件单独使用)
 * @see 组件继承DataMixin机制
 * @see 根据应用id与视图号自动拉取相关数据
 * @see 封装基本的本地增删改查接口便于通过this直接调用
 * @author tonny
 * @date 2021-10-08
 */
</script>

<script>
import DataMixin from "./Data.vue";
import _ from "lodash";
import Request from "../../Request/index.js";

export default {
    mixins: [DataMixin],
    data() {
        return {
            /** 视图信息列表 */
            fieldInfoList_: [],
            /** 视图号 */
            vid_: "",
            /** 功能点号 */
            fid_: "",
            /** 当前登录人内部号 */
            uid_: "",
            /** 当前登录人工号 */
            wid_: "",
            /** 当前登录人姓名 */
            uname_: "",
            /** 部门名称 */
            dname_: "",
            /** 部门id */
            did_: "",
            /** 当前经历号 */
            rid_: "",
            permission_: 0, // 权限(0:个人,1:二级秘书,2:校级)
            restoreRequestList_: [], // 恢复错误请求函数列表
            autoSearchChildRowViewInfo_: true, // 自动查询子行级视图信息
            isEmpty_: false, // 是否为空
            isNeedSubmit_: false, //是否需要提交按钮
            /** 数据请求对象 */
            data_: new Request.mixin({
                vm: this,
                modules: ["field", "Select"],
            }),
        };
    },
    computed: {
        /**
         * 是否为行级详情模式
         * @returns {boolean} true: 是
         */
        isRowMod_() {
            return !!this.rid_;
        },
        /**
         * 字段值对象
         * @see 将viewInfoList信息中的值映射到此对象中
         * @see 可以使用此对象进行动态渲染
         * @see 对象中的value为字符型值(如果字段为码表型,则会对码值转换为码表选项名称)
         * @returns {object} {label1: "value1"}
         */
        value_() {
            let resultList = {};
            if (!_.isEmpty(this.fieldInfoList_)) {
                this._eachNormalField(({ value, zlabel, zid }) => {
                    if (zlabel) {
                        resultList[zlabel] = value;
                    }
                    resultList[zid] = value;
                }, this.fieldInfoList_);
            }
            return resultList;
        },
        /**
         * 字段信息对象
         * @see this.fieldInfoList_
         * @see 将fieldInfoList_信息中的值映射到此对象中
         * @see 可以使用此对象进行动态渲染
         * @see 对此对象中的值进行更改将不会影响到this.fieldInfoList_中的值(避免无限循环)
         * @returns {object} {label1: {...对象信息}}
         */
        info_() {
            let resultList = {};
            try {
                if (!_.isEmpty(this.fieldInfoList_)) {
                    this._eachNormalField((field) => {
                        const { zlabel, zid, value, selectList } = field;
                        let zvalue = this._getZvalue(value, selectList);
                        field = { ...field, zvalue };
                        if (zlabel) {
                            resultList[zlabel] = field;
                        }
                        resultList[zid] = field;
                    }, this.fieldInfoList_);
                }
            } catch (error) {
                console.error(error);
            }
            return resultList;
        },
        /**
         * 码表对象
         */
        select_() {
            let resultList = {};
            if (!_.isEmpty(this.fieldInfoList_)) {
                this._eachNormalField(({ selectList, zlabel, zid }) => {
                    if (!_.isEmpty(selectList)) {
                        resultList[zlabel] = selectList;
                        resultList[zid] = selectList;
                    }
                }, this.fieldInfoList_);
            }
            return resultList;
        },
    },
    methods: {
        /**
         * 初始化函数(重写)
         * @see 添加加载视图信息表
         * @returns {Promise<void>} 失败抛错
         */
        async init_() {
            await this.loadFieldInfoList_().catch((err) => {
                throw err;
            });
        },
        async loadFieldInfoList_() {
            const { fid_: fid, vid_: vid, rid_: rid } = this;
            this.fieldInfoList_ = await this.data_
                .getFieldList({ fid, vid, rid })
                .catch((err) => {
                    this.errHand_(err);
                    throw err;
                });
        },
        get_({ zid, zlabel }) {},
        set_() {},
        async add_() {},
        async delete_() {},
        async save_() {},
        async search_() {},
        /**
         * 遍历每一个普通字段
         * @see 根据给定的字段列表来遍历每一个普通(不是分类)字段
         * @param {function} handler 遍历到字段后的回调函数
         * @param {[object]} fieldList 字段列表
         * @returns vod
         */
        _eachNormalField(handler, fieldList) {
            fieldList.forEach((field) => {
                const { children, type } = field;
                if (_.isEmpty(children) && type && !type.includes("rowset")) {
                    handler(field);
                } else if (!_.isEmpty(children)) {
                    this._eachNormalField(handler, children);
                }
            });
        },
        /**
         * 查询字段的码值名称
         * @param {*} value 字段值
         * @param {[object]} selectList 码表
         * @returns {string} 返回字段值
         */
        _getZvalue(value, selectList) {
            if (_.isArray(selectList)) {
                const item = selectList.kqDeepFind(
                    ({ value: svalue }) => svalue == value
                );
                if (item) {
                    return item.label;
                }
            }
            return value;
        },
        /**
         * 进入路由钩子(重写)
         * @see 参数初始化
         * @returns void
         */
        routerEnter_() {
            this.fid_ =
                this.fid_ ||
                this.query__.id ||
                this.query__.fid ||
                this.$route.params.fid;
            this.vid_ = this.vid_ || this.query__.vid || this.$route.params.vid;
            this.uid_ =
                this.uid_ ||
                this.query__.uid ||
                this.$route.params.uid ||
                this.$store.getters.uid;
            this.rid_ = this.rid_ || this.query__.rid || this.$route.params.rid;
            this.fid_ == -1 && (this.fid_ = "");
            this.vid_ == -1 && (this.vid_ = "");
            this.uid_ == -1 && (this.uid_ = "");
            this.rid_ == -1 && (this.rid_ = "");
            return DataMixn.methods.routerEnter_.call(this);
        },
    },
};
</script>