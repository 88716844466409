import Mock from "mockjs2";

Mock.setup({
    timeout: "100-500",
});
Mock.mock("http://127.0.0.1:8888/mock/getNavList", {
    code: 0,
    data: [
        {
            name: "首页",
            subname: "INDEX",
            route: "standard-index",
        },
        {
            name: "招聘岗位",
            subname: "POSITIONS",
            route: "recruit-positions",
        },
        {
            name: "学校简介",
            subname: "INTRODUCE",
            route: "school-introduce",
        },
        {
            name: "操作流程",
            subname: "PROCESS",
            route: "action-process",
        },
    ],
    msg: null,
});

Mock.mock("http://127.0.0.1:8888/api/api/contact", {
    code: 0,
    data: [
        {
            name: "人才招聘",
            subname: "PERSONNEL",
            "children|2-5": [
                {
                    name: "@cword(2, 7)",
                    value: "@natural(02521514524, 051478512145)",
                },
            ],
        },
        {
            name: "联系技术",
            subname: "TECHNOLOGY",
            "children|2-5": [
                {
                    name: "@cword(2, 7)",
                    value: "@natural(02521514524, 051478512145)",
                },
            ],
        },
        {
            name: "关注",
            subname: "FOLLOW",
            children: [
                {
                    name: "联系管家",
                    value: "https://kq-lib.oss-cn-hangzhou.aliyuncs.com/kefu1.png",
                },
                {
                    name: "关注公众号",
                    value: "https://kq-lib.oss-cn-hangzhou.aliyuncs.com/kefu1.png",
                },
            ],
        },
    ],
});

Mock.mock("http://127.0.0.1:8888/api/api/carousels", {
    code: 0,
    data: [
        "https://kq-lib.oss-cn-hangzhou.aliyuncs.com/index/index1_1.webp",
        "https://kq-lib.oss-cn-hangzhou.aliyuncs.com/index/index1_2.webp",
        "https://kq-lib.oss-cn-hangzhou.aliyuncs.com/index/index1_3.webp",
    ],
    msg: null,
});

Mock.mock("http://127.0.0.1:8888/api/api/posts", {
    code: 0,
    "data|3-9": [
        {
            name: "@cword(4,10)",
            subname: "@word(8, 25)",
            "icon|1": [
                "icon-huaxue-full",
                "icon-zheng-full",
                "icon-guanli-full",
                "icon-bao-full",
                "icon-boshi-full",
                "icon-jiaoxue-full",
            ],
        },
    ],
});

Mock.mock("http://127.0.0.1:8888/api/api/notices", {
    code: 0,
    "data|3-9": [
        {
            name: "@cword(4,10)",
            date: "@date",
            content: "@cword(100, 500)",
        },
    ],
    msg: null,
});

Mock.mock("http://127.0.0.1:8888/api/api/introduces", {
    code: 0,
    "data|1-3": [
        {
            name: "@cword(4,10)",
            content: "@cword(100, 500)",
            "img|1": [
                "https://kq-lib.oss-cn-hangzhou.aliyuncs.com/index/index1_1.webp",
                "https://kq-lib.oss-cn-hangzhou.aliyuncs.com/index/index1_2.webp",
                "https://kq-lib.oss-cn-hangzhou.aliyuncs.com/index/index1_3.webp",
            ],
        },
    ],
    msg: null,
});

Mock.mock("http://127.0.0.1:8888/mock/screens", {
    code: 0,
    "data|2-5": [
        {
            name: "@cword(2, 10)",
            "selectList|4-10": [
                {
                    label: "@cword(2, 10)",
                    "value|+1": 100001,
                },
            ],
        },
    ],
});

// 获取所有岗位信息
Mock.mock("http://127.0.0.1:8888/mock/allposts", {
    code: 0,
    "data|2-5": [
        {
            name: "@cword(2, 10)",
            subname: "@date",
            "children|3-10": [
                {
                    name: "@cword(2,10)",
                    value: "@cword(3,300)",
                },
            ],
        },
    ],
});
