import _ from "lodash";
import store from "kooci-lib/src/store";

/**
 * 加载脚本文件
 * @param {object} scriptObject 加载脚本文件
 * @returns void
 */
export function loadScript(scriptObject) {
    if (typeof scriptObject !== "object") {
        throw new Error("obj参数必须是对象");
    }
    const $scriptList = document.getElementsByTagName("script");
    Object.keys(scriptObject).forEach(name => {
        if (
            Array.prototype.some.call($scriptList, $dom => {
                $dom.getAttribute("src") &&
                    $dom.getAttribute("src").includes(name);
            })
        ) {
            document.write(`<script src="${url}"></script>`);
            setTimeout(() => {
                let count = 0;
                Array.prototype.forEach.call(
                    document.getElementsByTagName("script"),
                    $dom2 => {
                        if (count > 1) {
                            console.warn(
                                `[脚本重复加载错误]目前文件检测出现两个${name}脚本`
                            );
                            return false;
                        }
                        if (
                            $dom2.getAttribute("src") &&
                            $dom2.getAttribute("src").includes(libs)
                        ) {
                            count++;
                        }
                    }
                );
            }, 20000);
        }
    });
}

/**
 * 获得全局对象
 * @param {string} module 模块名
 * @returns {object} 模块对象
 */
export function getGloalObject(module) {
    if (window.kq === undefined) {
        window.kq = { global: {} };
    } else if (window.kq.global === undefined) {
        window.kq.global = {};
    }
    if (window.kq.global[module] === undefined) {
        window.kq.global[module] = {};
    }
    return window.kq.global[module];
}

/**
 * 设置全局变量
 * @see 如果传入的key已经存在,则会返回key在全局变量中存储的值,不会返回指定的值
 * @param {string} module 模块名称
 * @param {string} key 变量名称
 * @param {*} value 变量值
 * @returns {*} key对应的值(不一定是传入的值)
 */
export function setGlobalArgument(module, key, value) {
    const global = getGloalObject(module);
    if (global[key] === undefined) {
        global[key] = value;
    }
    return global[key];
}

/**
 * 全局属性注入
 * @param {function} files 通过reuireContext加载的模块返回值
 * @param {Object} target 注入函数的目标(Object,String,Function,Array,...)
 * @returns void
 */
export function prototypeMixin(files, target) {
    files.keys().forEach(file => {
        const module = files(file);
        _.forEach(module, (func, key) => {
            if (key !== "default") {
                const name = `kq${key.replace(/^\w/, $1 => $1.toUpperCase())}`;
                target.prototype[name] = func;
            }
        });
    });
}

import Request from "../PubComponents/Request/index.js";

/**
 * 快速登录(测试专用)
 * @param {*} vm vm实例
 * @returns {Promise<object>}
 */
export function login(vm) {
    const data = new Request.mixin({
        vm,
        modules: ["login"],
    });
    data.login({ openid: 9526, name: "微信登录..." })
        .then(res => {
            const {
                token,
                user: {
                    user: { id: uid },
                },
            } = res;
            localStorage.setItem("token", token);
            store.state.user.uid = uid;
        })
        .catch(err => {
            console.error(err);
            throw err;
        });
}
