/**
 * 路由模块类
 * @description 
 */

import _ from "lodash";

/**
 * 路由模块基类
 * @description 提供统一获取路由表的公共属性
 * @author tonny
 * @date 2022-03-12
 */
export default class Module {
    /**
     * 路由模块基类-构造函数
     * @constructor
     * @param {object} options 配置对象
     * @param {function} options.resetRouter 重置路由方法(依赖Router类的resetRouter实例方法)
     */
    constructor({ resetRouter }) {
        if (!_.isFunction(resetRouter)) {
            throw new Error(
                "设置路由后的处理函数(resetRouter)不能为空,这将会导致动态设置路由无效"
            );
        }
        /** 路由表 */
        this._routeList = this._getRouteList();
        /** 重置路由变量(不要直接调用) */
        this._resetRouter = resetRouter;
    }
    /**
     * 实例名称
     * @returns {string}
     */
    get name() {
        console.error(
            "路由模块错误:需要重写获取名称的函数(不要使用constructor.name打包后为动态)!"
        );
        return "";
    }
    /**
     * 路由表
     * @returns {[object]}
     */
    get routeList() {
        return this._routeList;
    }
    /**
     * 重置路由
     * @description 重置当前路由操作
     * @returns void
     */
    resetRouter() {
        const resetRouter = this._resetRouter;
        resetRouter && resetRouter();
    }
    /**
     * 设置一个路由
     * @public
     * @description 调用该函数用来动态更改路由
     * @param {string} name 路由名称
     * @param {object} attrs 需要设置的属性对象
     * @returns {object} 设置完成后路由对象
     */
    setRoute(name, attrs) {
        const targetRoute = this._routeList.find(
            (route) => route.name === name
        );
        if (targetRoute) {
            _.forEach(attrs, (attr, key) => {
                this._routeList[key] = attr;
            });
        }
        this.resetRouter();
        return targetRoute;
    }
    /**
     * (纯虚函数)获取路由表
     * @virtual
     * @protected
     * @description 需要子类重写该函数以获取路由表
     * @returns {[object]}
     */
    _getRouteList() {
        console.warn(`${this.constructor.name}引入了一个空路由表`);
        return [];
    }
}
