import Vuex from "vuex";
import StoreModule from "./Module";
import Tools from "../Utils/tools";

/**
 * 仓库模块(重写)
 * @desc 加入了子模块的校验与全局配置合并
 * @param {object} options 初始化配置对象
 * @class
 */
export default class Store extends Vuex.Store {
    static install(...params) {
        Vuex.install(...params);
        params[0].mixin({
            data() {
                return {
                    STORE_STATES: params[1].state,
                };
            },
        });
    }
    constructor(options = {}) {
        const { modules } = options;
        if (!Tools.isEmpty(modules)) {
            const mergeOptions = Store.mergeModules(options);
            super(mergeOptions);
            // 注入配置项
            _.forEach(modules, (module, name) => {
                module.injectOptions({ store: this, modules, name });
            });
            /** 转换的多模块对象 */
            this._convertModules = mergeOptions.modules;
            /** 传入的多模块对象 */
            this._modules = modules;
        } else {
            super(options);
        }
    }
    /**
     * 传入的模块列表
     * @returns {object}
     */
    get modules() {
        return this._modules;
    }
    /**
     * 转换后的模块列表
     * @desc 仅包含state,mutations,action,getters,namespaced
     * @return {object}
     */
    get convertModules() {
        return this._convertModules;
    }
    /**
     * 合并子模块
     * @desc 将子模块按照规则进行验证与合并
     * @desc 合并子模块的全局对象
     * @param {object} options 配置对象
     * @returns {object}
     */
    static mergeModules(options) {
        let {
            modules,
            getters = {},
            mutations = {},
            actions = {},
        } = options || {};
        const formatModules = {};
        // 子模块验证
        _.forEach(modules, (module, moduleName) => {
            if (!module instanceof StoreModule) {
                throw new kq.Err({
                    message: "仓库的每一个模块必须为kq.Module派生的类实例",
                    current: Module,
                    from: "kq.Store.mergeModules",
                });
            }
            getters = this._mergeGlobalOption(getters, module, "Getters");
            mutations = this._mergeGlobalOption(mutations, module, "Mutations");
            actions = this._mergeGlobalOption(actions, module, "Actions");
            formatModules[moduleName] = {
                state: module.state,
                mutations: module.mutations,
                actions: module.actions,
                getters: module.getters,
                namespaced: module.namespaced,
            };
        });
        return {
            ...options,
            modules: formatModules,
            getters,
            mutations,
            actions,
        };
    }
    /**
     * 合并全局配置
     * @desc 将每一个子模块实例中的全局配置与当前全局配置合并
     * @param {object} option 仓库配置对象
     * @param {object} module 每一个模块实例
     * @param {string} optionName 配置名称(用来从模块实例中抽取对应全局配置与log输出)
     * @private
     * @returns {object}
     */
    static _mergeGlobalOption(option, module, optionName) {
        /** 模块的全局配置对象 */
        const moduleOptions =
            module[
                `global${optionName.replace(/^\w/, ($) => $.toUpperCase())}`
            ];
        // 循环每一项的全局模块配置,一次追加到全局配置中
        _.forEach(moduleOptions, (moduleOption, name) => {
            if (Tools.isEmpty(option[name])) {
                option[name] = moduleOption.bind(module);
            } else {
                console.warn(
                    new kq.Err({
                        message: `仓库重复的全局${optionName},这可能会导致某些地方全局调用失效`,
                        current: name,
                        from: "kq.Router.mergeModules",
                        console: false,
                        type: false,
                    })
                );
            }
        });
        return option;
    }
    /**
     * 禁止自动安装(云库)
     */
    static get installDisabled() {
        return true;
    }
}
