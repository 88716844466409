/**
 * @desc app相关仓库
 * @author tonny
 * @date 2022-08-13
 */

import Tools from "../../Utils/tools";
import _ from "lodash";
import Request from "../../Request";
import Module from "../Module";

export default class App extends Module {
    constructor(...params) {
        super(...params);
        /** 请求对象 */
        this._request = Request.mixin({ modules: ["app"] });
    }
    /**
     * 仓库存储对象(重写)
     * @returns {object}
     */
    mapState() {
        return {
            /**
             * 应用信息表
             * @desc 存储每一个app的基础信息
             * @example {[appid<number>(应用号)]: {...应用信息}}
             */
            baseInfo: {},
            /**
             * 应用路由信息表
             * @desc 存储这每个数据权限下的所有功能路由信息
             * @example { [appid<string>(应用号)]: {[dataAuth<number>(数据权限值,0个人,1二级,2校级)]: [{...路由信息对象}]}}
             */
            routeInfo: {},
            /** 数据权限信息 */
            authInfo: {},
        };
    }
    /**
     * 设置应用基础信息
     * @param {object} state 仓库存储对象
     * @param {object} payload 入参对象
     * @param {number} payload.appid 应用号
     * @param {object} payload.info 应用信息对象
     * @param {number} [payload.fid] 功能点号(如果不为空,则会以功能点号为key存储方便快速查询)
     * @returns void
     */
    mutationSetBaseInfo(state, { appid, fid, info }) {
        if (Tools.isEmpty(appid)) {
            console.warn(
                new kq.Err({
                    message: `应用号(appid)不能为空`,
                    current: arguments[1],
                    type: false,
                    console: false,
                    from: "Vuex.kqApp.commit.setAppBaseInfo",
                }).message
            );
            return;
        }
        state.baseInfo[appid] = info;
        if (!Tools.isEmpty(fid)) {
            state.baseInfo[fid] = info;
        }
    }
    /**
     * 设置app路由信息
     * @param {object} state 仓库存储对象
     * @param {object} payload 入参对象
     * @param {number} payload.appid 应用号
     * @param {number} [payload.dataAuth] 数据权限(0:个人,1:二级,2:校级)
     * @desc 如果不传dataAuth则视为存储整个应用权限组的信息
     * @param {number} [payload.fid] 功能点号(传值则会以功能点号为key存储和对应应用号相同的数据)
     * @param {[object]|object} [payload.info] 路由信息表
     * @returns void
     */
    mutationSetRouteInfo(state, { dataAuth, appid, fid, info }) {
        if (Tools.isEmpty(appid)) {
            console.warn(
                new kq.Err({
                    message: "应用号(appid)不能为空",
                    current: arguments[1],
                    type: false,
                    console: false,
                    from: "Vuex.kqApp.commit.setAppRouteInfo",
                })
            ).message;
            return;
        }
        if (Tools.isEmpty(dataAuth)) {
            if (!_.isPlainObject(info)) {
                new kq.Err({
                    message:
                        "当权限值(dataAuth)入参为空时,信息(info)必须为包含了3个权限组件的对象",
                    current: info,
                    from: "Vuex.kqApp.commit.routeInfo",
                });
                return;
            }
            state.routeInfo[appid] = info;
            !Tools.isEmpty(fid) && (state.routeInfo[fid] = info);
        } else {
            _.set(state.routeInfo, [appid, dataAuth], info);
            !Tools.isEmpty(fid) &&
                (state.routeInfo[fid] = state.routeInfo[appid]);
        }
    }
    /**
     * 清空存储
     * @param {object} state 仓库存储对象
     * @returns void
     */
    mutationClear(state) {
        state.baseInfo = {};
        state.routeInfo = {};
        state.authInfo = {};
    }
    /**
     * 设置权限表
     * @param {object} state 仓库存储对象
     * @param {object} param1 入参
     * @param {number} param1.appid 应用号
     * @param {[object]} param1.authList 数据权限表
     * @returns void
     */
    mutationSetAuthList(state, { appid, authList }) {
        if (Tools.isEmpty(authList)) {
            delete state.authInfo[appid];
        } else {
            state.authInfo[appid] = authList;
        }
    }
    /**
     * 获取应用基础信息
     * @param {object} param vuex.mutations参数对象
     * @param {object} param.state 仓库存储对象
     * @param {object} payload 入参
     * @param {number} [payload.appid] 应用号
     * @param {number} [payload.fid] 功能点号(不能与应用号同时为空)
     * @returns {Promise<object>} 成功返回应用信息对象,失败抛错
     */
    actionGetBaseInfo({ state, commit }, { appid, fid, device }) {
        return Tools.promise(async () => {
            let info = state[appid] || state[fid];
            appid = await this._request
                .getAppid({ fid, appid })
                .catch((err) => {
                    throw err;
                });
            if (Tools.isEmpty(info)) {
                const [info1, info2] = await Promise.all([
                    this._request.getAppInfo({ appid }),
                    this._request.getAppAuthInfo({ appid, device }),
                ]).catch((err) => {
                    throw err;
                });
                // 无覆盖合并(防止a.value === 1;b.value === null, b把a覆盖了)
                info = _.mergeWith(info1, info2, (prev, next) => {
                    if (kq.Tools.isEmpty(prev) && !kq.Tools.isEmpty(next)) {
                        return next;
                    }
                    if (!kq.Tools.isEmpty(prev) && kq.Tools.isEmpty(next)) {
                        return prev;
                    }
                });
                commit("setBaseInfo", { appid, fid, info });
            }
            return info;
        });
    }
    /**
     * 获取路由信息
     * @param {object} param vuex.actions参数对象
     * @param {object} param.state 仓库存储对象
     * @param {function} param.commit 仓库提交函数
     * @param {object} payload 入参对象
     * @param {number} [payload.appid] 应用号(与功能点号不能同时为空)
     * @param {number} [payload.fid] 功能点号(与应用号不能同时为空)
     * @param {number} [payload.dataAuth] 数据权限(0:个人,1:二级,2:校级)
     * @param {string} [payload.device] 设备
     * @param {boolean} [payload.isFilter] 是否需要过滤禁用的功能(只影响函数输出值,不影响仓库存储值)
     * @returns {Promise<[object]|object>} 传入dataAuth时,返回的是对应的路由信息表,不传则返回所有权限下的路由信息表
     */
    actionGetRouteInfo(
        { state, commit },
        { appid, fid, dataAuth, device, isFilter }
    ) {
        return Tools.promise(async () => {
            appid = await this._request
                .getAppid({ fid, appid })
                .catch((err) => {
                    throw err;
                });
            /** 查询的key列表 */
            const queryKeyList = ["routeInfo", appid || fid];
            // 数据权限不为空:代表需要查询权限信息
            if (!Tools.isEmpty(dataAuth)) {
                queryKeyList.push(dataAuth);
            }
            /** 结果信息 */
            let info = _.get(state, queryKeyList);
            // 信息为空,说明未存储,接口请求
            if (Tools.isEmpty(info)) {
                let dataAuthList = [dataAuth];
                if (Tools.isEmpty(dataAuth)) {
                    dataAuthList = [0, 1, 2];
                }
                const infoList = await Promise.all(
                    dataAuthList.map((auth) =>
                        this._request.getAppNavList({
                            ...arguments[1],
                            appid,
                            dataAuth: auth,
                            device,
                        })
                    )
                ).catch((err) => {
                    throw err;
                });
                if (dataAuthList.length > 1) {
                    info = {};
                    infoList.forEach((auths, index) => {
                        if (!Tools.isEmpty(auths)) {
                            info[dataAuthList[index]] = auths;
                        }
                    });
                } else {
                    info = infoList[0];
                }
                commit("setRouteInfo", { fid, appid, info, dataAuth });
            }
            if (isFilter) {
                return this._filterRouteInfo(info);
            }
            return info;
        });
    }
    /**
     * 过滤路由信息
     * @desc 将禁用的功能点或者目录从结果中去除
     * @param {[object]|object} info 路由信息
     * @returns {[object]|object} 过滤后的信息对象|数组
     */
    _filterRouteInfo(info) {
        if (_.isArray(info)) {
            return info.filter((item, index) => {
                if (_.isArray(item)) {
                    info[index] = this._filterRouteInfo(item);
                    return info[index].length;
                }
                if (item.fid !== undefined) {
                    if (_.isArray(item.children)) {
                        item.children = item.children.filter(
                            this._filterRouteInfo
                        );
                        return !item.disabled && !Tools.isEmpty(item.children);
                    } else {
                        return !item.disabled;
                    }
                }
                return true;
            });
        } else if (_.isPlainObject(info)) {
            let results = {};
            _.forEach(info, (value, key2) => {
                results[key2] = this._filterRouteInfo(value);
            });
            return results;
        }
        return info;
    }
    /**
     * 获取应用权限列表信息
     * @param {object} param0 vuex参数对象
     * @param {object} param0.state 仓库存储对象
     * @param {object} param1 入参
     * @param {number} [param1.appid] 应用号(必须和功能点号有一个不为空)
     * @param {number} [param1.fid] 功能点号(必须和应用号有一个不能为空)
     * @returns {Promise<[object]>} 权限表
     */
    actionGetAuthList({ state, commit }, { appid, fid, device }) {
        return Tools.promise(async () => {
            let authList = state.authInfo[appid];
            if (Tools.isEmpty(authList)) {
                appid = await this._request
                    .getAppid({ appid, fid })
                    .catch((err) => {
                        throw err;
                    });
                /** 应用信息 */
                const finfo = await this._request
                    .getAppAuthInfo({ ...arguments[1], appid, device })
                    .catch((err) => {
                        throw err;
                    });
                authList = finfo.authList;
                commit("setAuthList", { appid, authList });
            }
            return authList;
        });
    }
}
