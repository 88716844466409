/**
 * 组件注册
 * @see 每个模块的入口文件调用
 * @see 用来注册kq全局变量和导出install函数
 * @param {string} modeName 模块名称
 * @param {object} components 组件对象{key:用来通过kq直接使用的组件名称,value:组件对象}
 * @param {function} installFunc 整模块安装方法
 * @returns {object} {install: 模块组件的install函数, ...所有模块}
 */
import _ from "lodash";

export function registerCmp(modName, components, installFunc) {
    if (window[modName] === undefined) {
        window[modName] = {};
    }
    if (process.env.NODE_ENV === "development") {
        console.log(`%c酷奇云库:${modName}已载入!`, `color: #199bfa`);
    }
    let modModule = window[modName];
    // 组件遍历放入全局变量
    _.forEach(components, (cmp, name) => {
        // kq全局对象
        if (window.kq === undefined) {
            window.kq = {};
        }
        // kq.mod全局对象(通过kq.modName.xxx来直接调用)
        if (window.kq[modName] === undefined) {
            window.kq[modName] = modModule;
        }
        // kq模块全局分解对象(通过kq.xxx来直接调用)
        if (window.kq[name] !== undefined) {
            throw new Error(`kq对象存在重名组件!,重名组件名称:${name}`);
        }
        window.kq[name] = cmp;
        // mod模块全局对象(通过modName.xxx来直接调用)
        if (modModule[name] !== undefined) {
            throw new Error(`${modName}对象存在重名组件!`);
        }
        modModule[name] = cmp;
    });
    const install = function (Vue, params) {
        installFunc && installFunc(Vue, params);
        _.forEach(components, (cmp) => {
            if (cmp.install && !cmp.installDisabled) {
                cmp.install(Vue, params);
            }
        });
    };
    // 可以直接通过全局变量安装(Vue.use(modName))
    modModule.install = install;
    // 可以通过kq全局安装(Vue.use(kq));
    const kqInstall = window.kq.install;
    window.kq.install = function (Vue, params) {
        kqInstall && kqInstall(Vue, params);
        install(Vue, params);
    };
    return { ...modModule, install };
}
