/**
 * 是否大于
 * @param  {...any} targets 多参数表(比每个都大)
 * @returns {boolean}
 */
export function isGreaterThan(...targets) {
    return targets.every((num) => this > _.toNumber(num));
}
export function isAfter(...targets) {
    return isGreaterThan.call(this, ...targets);
}
/**
 * 是否大于等于
 * @param  {...any} targets 多参数表(比每个都大与等于)
 * @returns {boolean}
 */
export function isGreaterThanOrEqual(...targets) {
    return targets.every((num) => this >= _.toNumber(num));
}
export function isSameOrAfter(...targets) {
    return isGreaterThanOrEqual.call(this, ...targets);
}
/**
 * 是否小于
 * @param  {...any} targets 多参数表(比每个都小)
 * @returns {boolean}
 */
export function isLessThan(...targets) {
    return targets.every((num) => this < _.toNumber(num));
}
export function isBefore(...targets) {
    return isLessThan.call(this, ...targets);
}
/**
 * 是否小于等于
 * @param  {...any} targets 多参数表(比每个都小于等于)
 * @returns {boolean}
 */
export function isLessThanOrEqual(...targets) {
    return targets.every((num) => this <= _.toNumber(num));
}
export function isSameOrBefore(...targets) {
    return isLessThanOrEqual.call(this, ...targets);
}
/**
 * 是否相等
 * @param  {string|number} target 对比对象
 * @returns {boolean}
 */
export function isEqual(target) {
    return kq.Tools.isEqual(this, target);
}
export function isSame(...targets) {
    return isEqual.call(this, ...targets);
}
/**
 * 是否在范围内(不包含边界)
 * @param  {...any} targets 范围
 * @returns {boolean}
 */
export function isBetween(...targets) {
    if (targets.length < 2) {
        return true;
    }
    return this > _.toNumber(targets[0]) && this < _.toNumber(targets[1]);
}
