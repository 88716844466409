/**
 * Form转换器
 * @description 只转换Form表单数据
 * @see in 转入:转换props数据
 * @see out 转出:转换change事件参数
 * @see 对props参数进行转换
 */

import Vue from "vue";
import _ from "lodash";

export default class Convert {
    constructor(vm) {
        if (!vm || !vm instanceof Vue) {
            throw new kq.Err({
                message: `构造函数第一个参数组件实例(vm)必须为非空vue组件实例对象`,
                current: vm,
                from: "FormConvert.contructor",
            });
        }
        this.vm = vm;
        /** 转换器对象 */
        this._convert = null;
    }
    /**
     * 入口转换函数
     * @see 将入口的props转换
     * @see 默认转换vm.$attrs
     * @param {object} [props] 指定转换的props对象(不传则使用vm.$attrs)
     * @returns {object}
     */
    inProps(props) {
        if (!this._convert) {
            this._convert = new kq.Convert(this.convertOption);
        }
        this._inProps = this._convert.convert(props || this.vm.$attrs);
        return this._inProps;
    }
    /**
     * 出口转换函数
     * @see 将inputcell组件的输出change事件的参数进行转换
     * @see 不传value则默认取vm.current作为value
     * @param {object} param0 参数对象
     * @param {number} param0.id 字段号
     * @param {string|array} param0.value 更改的值(不传则取vm.current作为终值)
     * @param {number|string|array} param0.old 旧的值
     * @returns {object} change的参数对象
     */
    outChange(item) {
        let { value } = item;
        const { type, isRange } = this.vm;
        if (_.isArray(value)) {
            if (type.includes("file")) {
                value = value.join(";");
            } else {
                value = value.join(",");
            }
        }
        return { ...item, value };
    }
    outInput(value) {
        const { type, isRange } = this.vm;
        if (type.includes("multi") || isRange) {
            // 入参值为数组:出参值也为数组
            if (_.isArray(value)) {
                if (type.includes("file")) {
                    value = value.join(";");
                } else {
                    value = value.join(",");
                }
            }
        }
        return value;
    }
    /**
     * 获取转换器配置
     * @returns {object}
     */
    get convertOption() {
        const self = this;
        const idCompares = ["zid"];
        return {
            compares: {
                zid: idCompares,
                zname: ["name", "zname"],
                readonly: ["readOnly", "readonly"],
                label: ["zlabel", "label"],
                vid: ["viewID", "viewId", "bodyVid"],
                type: ["ztype", "type"],
                children: ["children"],
                value: ["value", "zvalue"],
                // id: ["rid", "id"],
                rid: ["rid", "id"],
            },
            /** 转换多选值 */
            convertValueHook({ to, value, parent }) {
                // 目标为value值&&转换的对象为:字段信息(parent)不是码表的对象
                if (to === "value" && self.vm.type) {
                    if (_.isNumber(value)) {
                        value = String(value);
                    }
                    if (
                        self.vm.type &&
                        (self.vm.type.includes("multi") || self.vm.isRange) &&
                        (_.isString(value) || _.isNumber(value))
                    ) {
                        value = String(value);
                        if (self.vm.type.includes("file")) {
                            value = value
                                ? value.split(";").filter(val => val)
                                : [];
                        } else {
                            value = value
                                ? value.split(",").filter(val => val)
                                : [];
                        }
                    }
                }
                return value;
            },
            isStrict: false,
        };
    }
    convert(data) {
        return this._convert.convert(data);
    }
    inListeners() {
        return this.vm.$listeners;
    }
    outFocus(...params) {}
}
