/**
 * 正则匹配
 * @see 自动判断结果生成数组,无需进一步判断是否匹配到结果,避免一步操作报错
 * @param {Regexp|string} regexp 匹配正则
 * @param {number} targetIndex 目标索引
 * @returns {array|void} 匹配不管成功失败,都会返回数组(指定targetIndex会取值)
 */
export function match(regexp, targetIndex) {
    const res = this.match(regexp);
    if (targetIndex !== undefined) {
        if (res) {
            return res[targetIndex] || "";
        } else {
            return "";
        }
    } else {
        return res || [];
    }
}
