/**
 * @description 确认框指令
 * @author tonny
 * @date 2021-08-15
 */

import vConfirm from "./Confirm.vue";
import _ from "lodash";
import $ from "jquery";

export default {
    install(Vue) {
        Vue.directive("kq-confirm", {
            /**
             * 绑定钩子
             * @see 创建组件实例
             * @returns void
             */
            bind(el, { value, arg, modifiers }) {
                if (
                    !_.isString(value) &&
                    !_.isBoolean(value) &&
                    value !== undefined
                ) {
                    console.error(
                        `[DirectiveBind-confirm]参数错误!value必须为tip的内容字符串或布尔型(用来开关tip)!当前:${JSON.stringify(
                            value
                        )}`
                    );
                    value = "";
                }
                let placement;
                _.forEach(modifiers, (on, name) => {
                    if (!on) {
                        return;
                    }
                    if (
                        [
                            "top",
                            "top-start",
                            "top-end",
                            "bottom",
                            "bottom-start",
                            "bottom-end",
                            "left",
                            "left-start",
                            "left-end",
                            "right",
                            "right-start",
                            "right-end",
                        ].includes(name)
                    ) {
                        placement = name;
                    }
                });
                let options = {};
                if (_.isPlainObject(arg)) {
                    options = arg;
                } else if(_.isFunction(arg)) {
                    options.yes = arg;
                }
                const Confirm = Vue.extend(vConfirm);
                const vm = new Confirm({
                    propsData: {
                        placement,
                        content: value,
                        disabled: !value,
                        ...options,
                    },
                }).$mount();
                el.vm = vm;
            },
            /**
             * el被插入到父组件后的钩子
             * @see 将Confirm的Dom插入到父组件中
             * @returns void
             */ 
            inserted(el) {
                $(el.vm.$el).appendTo($(el).parent());
                $(el).appendTo(el.vm.$refs.slot);
            },
            /**
             * 被绑定所在组件更新钩子
             * @see 更新Confirm组件参数
             * @returns void
             */
            update({ vm }, { value, arg }) {
                vm.disabled = !value;
                value = value || "";
                if (_.isString(value)) {
                    vm.content = value;
                }
                let options = vm.$props;
                if (arg && _.isPlainObject(arg)) {
                    options = { ...options, ...arg };
                }
                _.forEach(options, (v, k) => {
                    vm[k] = v;
                });
            },
            /**
             * 解绑钩子
             * @see 销毁Confirm组件
             * @returns void 
             */
            unbind({ vm }) {
                vm.$destroy();
            },
        });
    },
};
