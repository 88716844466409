import { render, staticRenderFns } from "./Inputcell.vue?vue&type=template&id=66c580d4&scoped=true"
import script from "./Inputcell.vue?vue&type=script&lang=js"
export * from "./Inputcell.vue?vue&type=script&lang=js"
import style0 from "./Inputcell.vue?vue&type=style&index=0&id=66c580d4&prod&lang=less&scoped=true"
import style1 from "./Inputcell.vue?vue&type=style&index=1&id=66c580d4&prod&lang=less"


/* normalize component */
import normalizer from "!../../../../../kooci-saas-recruit/node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "66c580d4",
  null
  
)

export default component.exports