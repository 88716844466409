
import { getValue } from "../Tools/index.js";
const baseInfo = {
    zid: "baseinfo",
    zname: "基础信息",
    subname: "写死的卡片基本信息",
    formList: [
        {
            zid: "icon",
            zname: "图标",
            type: "icon",
            required: true,
            excludeIconList: ["logo-full", "logo-line"],
        },
        {
            zid: "name",
            zname: "标题",
            type: "text-text",
            required: true,
        },
        {
            zid: "subname",
            zname: "描述信息",
            type: "text-text",
            required: true,
        },
        {
            zid: "color",
            zname: "主颜色",
            help: "看一看图标的背景色",
            type: "color",
            value: "#199bfa",
        },
        {
            zid: "actionType",
            zname: "穿透操作",
            type: "select-single-1",
            help: "注意:\n打开应用必须得保证每个数据项中包含应用号(appid)\n打开功能点必须得保证每个数据项中包含功能点号(fid)",
            value:'',
            svalue:'',
            selectList: [
                { label: "打开应用", value: "app", disabled: true },
                { label: "打开功能点", value: "function" },
                {
                    label: "打开功能点详情页",
                    value: "function-detail",
                    disabled: true,
                },
            ],
        },
    ],
};
export default {
    name: "饼图组件",
    component: "kq-lowcode-component-card-pie-chart",
    type: "统计",
    demo: {
        name: "统计项名称",
        subname: "统计项描述",
        icon: "icon-progress-full",
        /** 数据列表 */
        dataList: [
            { value: 1048, name: "Search Engine",fid:0 },
            { value: 735, name: "Direct",fid:0},
            { value: 580, name: "Email" ,fid:0},
            { value: 484, name: "Union Ads" ,fid:0},
            { value: 300, name: "Video Ads",fid:0 }
        ],
        /** 颜色列表 */
        colorList: [
            "#fc8251",
            "#5470c6",
            "#91cd77",
            "#ef6567",
            "#333",
        ]
    },
    configList: [
        baseInfo,
        {
            zid: "async",
            zname: "数据源",
            type: "multi",
            znameField: { zid: "dataName" },
            formList: [
                {
                    zid: "type",
                    zname: "数据类型",
                    type: "select-single-1",
                    value: "",
                    svalue: "",
                    required: true,
                    selectList: [
                        { label: "所有数据", value: "all" },
                        { label: "单项数据", value: "single" },
                    ],
                },
                {
                    zid: "from",
                    zname: "数据来源",
                    type: "select-single-1",
                    required: true,
                    value: "",
                    svalue: "",
                    selectList: [
                        { label: "接口", value: "api" },
                        { label: "功能点", value: "function" },
                    ],
                },
                {
                    zid: "dataName",
                    zname: "数据名称",
                    type: "text-text",
                    required: true,
                    value: "",
                },
                {
                    zid: "apiRoute",
                    zname: "接口地址",
                    type: "text-text",
                    required: true,
                    value: "",
                },
                {
                    zid: "apiConvert",
                    zname: "接口转换",
                    type: "code",
                    required: true,
                    value: "",
                    codeFormat: "javascript",
                },
                {
                    zid: "fid",
                    zname: "功能点",
                    type: "text-text",
                    required: true,
                    value: "",
                },
                {
                    zid: "valueType",
                    zname: "值类型",
                    type: "select-single-1",
                    required: true,
                    svalue: "",
                    value: "",
                    selectList: [{ label: "列表页总数", value: "list-total" }],
                },
            ],
            linkage: [
                {
                    name: "数据类型-数据源联动",
                    subname: "选择数据类型后,联动数据源字段显示",
                    from: [
                        {
                            zid: "type",
                            value: ["all"],
                        },
                    ],
                    to: [
                        {
                            zid: "from",
                            action: "visible-select-disabled",
                            value: ["function"],
                        },
                        {
                            zid: "from",
                            action: "code",
                            value: [_getLinkageTypeFunc()],
                        },
                    ],
                },
                {
                    name: "数据类型-数据源联动",
                    subname: "选择数据类型后,联动数据源字段显示",
                    from: [
                        {
                            zid: "type",
                            value: ["single"],
                        },
                    ],
                    to: [
                        {
                            zid: "from",
                            action: "visible-select-disabled",
                            value: ["api"],
                        },
                        {
                            zid: "from",
                            action: "code",
                            value: [_getLinkageTypeFunc()],
                        },
                    ],
                },
                {
                    name: "数据类型-数据名称联动",
                    subname: "数据类型:单项数据关联数据名称显示",
                    // 联动多字段关系
                    // relationship: "some",
                    from: [
                        {
                            zid: "type",
                            // 判断
                            value: ["single"],
                            // 判断条件(include:包含,exclude:不包含)
                            // condition: "include",
                            // 多个判断值之间的关系(some:或,every:且)
                            // relationship: "some",
                        },
                    ],
                    to: [
                        {
                            zid: "dataName",
                            // 操作类型
                            action: "visible-field",
                            // 操作值
                            value: true,
                            // 错误提示值
                            // message: "提示内容",
                        },
                    ],
                },
                {
                    name: "数据来源联动",
                    subname: "数据来源-api显示对应api的相关",
                    from: [
                        {
                            zid: "type",
                            value: ["all"],
                        },
                    ],
                    to: [
                        {
                            zid: "apiRoute",
                            action: "visible-field",
                            value: true,
                        },
                        {
                            zid: "apiConvert",
                            action: "visible-field",
                            value: true,
                        },
                    ],
                },
                {
                    name: "数据来源-字段联动",
                    subname: "数据来源码值:功能点,关联显示功能点号与值类型",
                    type: "visible",
                    from: [
                        {
                            zid: "type",
                            value: ["single"],
                        },
                    ],
                    to: [
                        {
                            zid: "fid",
                            action: "visible-field",
                            value: true,
                        },
                        {
                            zid: "valueType",
                            action: "visible-field",
                            value: true,
                        },
                    ],
                },
            ],
        },
    ],
};
/**
 * @desc 选择所有数据，来源自动变成接口，选择单向数据，来源变成功能点
 * @returns string
 */
export function _getLinkageTypeFunc() {
    return function (params) {
        const { updateField, changedField } = params;
        if(changedField.value)
        updateField.value = changedField.value=="all"?"api":"function";
    }.toString();
}