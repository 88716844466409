<script>
/**
 * @description 全局组件混入
 * @author tonny
 * @date 2021-06-10
 */
</script>

<script>
const globalModules = {};
import vModelMixin from "../vModel/vModel.vue";
import _ from "lodash";
import $ from "jquery";

export default {
    name: "mixin-global-components",
    mixins: [vModelMixin],
    props: {
        // 是否是全局调用
        isGlobal: Boolean,
        // 是否是指令调用(如果是指令调用,isGlobal肯定为false)
        isDirective: Boolean,
        // 打开回调函数
        onOpen: Function,
        // 打开后回调函数
        onOpened: Function,
        // 关闭回调函数
        onClose: Function,
        // 关闭后的回调函数
        onClosed: Function,
    },
    data() {
        return {
            id_: null, // 当前组件id
            current: false, // v-model值
        };
    },
    watch: {
        /**
         * 监控valu值变化
         * @see 链式调用
         * @see 调用open|close
         * @returns void
         */
        current: {
            handler(value) {
                if (value) {
                    this.open();
                } else if (value !== undefined) {
                    this.close();
                }
            },
            immediate: true,
        },
    },
    // 加载组件后设置id,加对象加入到全局数组中
    mounted() {
        const $list = this._getGlobalModuleList();
        const lastItem = $list[$list.length - 1];
        this.id_ = lastItem ? lastItem.id + 1 : 1;
        $list.push({
            id: this.id_,
            vm: this,
            isGlobal: this.isGlobal,
        });
    },
    /**
     * 销毁钩子
     * @see 移除掉当前eldom
     * @returns void
     */
    beforeDestroy() {
        // 再从列表中删除一遍,为了防止位置直接调用$destory导致列表中有残留
        const $list = this._getGlobalModuleList();
        $list.some(({ id }, index) => {
            if (id == this.id_) {
                $list.splice(index, 1);
                this.$destroy();
                return true;
            }
        });
        // 移除节点
        $(this.$el).remove();
    },
    methods: {
        /**
         * 获得全局模块列表
         * @returns {[object]} {id: 组件di, vm: 组件对象, isGlobal: 是否全局调用}
         */
        _getGlobalModuleList() {
            const name = this.$options.name;
            if (globalModules[name] === undefined) {
                globalModules[name] = [];
            }
            return globalModules[name];
        },
        /**
         * 关闭组件方法
         * @see 关闭组件时调用
         * @see 调用该方法不会去除全局变量中的值,也不会销毁全局调用产生的组件
         * @returns {number} 组件id
         */
        close() {
            this.current = false;
            // this.$emit("close", this.id_, this);
            this.onClose && this.onClose(this.id_, this);
            return this.id_;
        },
        /**
         * 关闭之后方法
         * @see 在关闭动画之后调用该方法
         * @see 如果没有关闭动画,需要手动调用此方法
         * @returns void
         */
        afterClose() {
            this.$emit("closed", this.id_, this);
            this.onClosed && this.onClosed(this.id_, this);
            setTimeout(() => {
                const $list = this._getGlobalModuleList();
                $list.some(({ id }, index) => {
                    if (id == this.id_ && this.isGlobal) {
                        $list.splice(index, 1);
                        this.$destroy();
                        return true;
                    }
                });
            }, 1);
        },
        /**
         * 打开组件
         * @returns {number} 组件id
         */
        show() {
            return this.open();
        },
        /**
         * 打开当前组件
         * @see 通过此方法打开组件,一般全局调用会使用此方法,组件内调用修改v-model的值即可
         * @see 激活`open`信号,传递id与实例
         * @returns {number} 实例id
         */
        open() {
            this.current = true;
            this.$emit("open", this.id_, this);
            this.onOpen && this.onOpen(this.id_, this);
            // console.log(globalModules);
            return this.id_;
        },
        /**
         * 打开组件后
         * @see 具有打开动画的组件动画执行完成后调用
         * @see 激活`opened`信号,传递id与实例参数
         * @returns {number} 实例id
         */
        afterOpen() {
            this.$emit("openend", this.id_, this);
            this.onOpened && this.onOpened(this.id_, this);
            return this.id_;
        },
    },
    /**
     * 静态方法
     * @see 给子混入进行继承
     * @see 调用方法:[子对象] export default {...GlobalComponentMixn.static}
     */
    static: {
        /**
         * 创建一个组件实例
         * @see 全局使用时调用
         * @see 只创建,未挂载
         * @returns {object} 创建的实例对象
         */
        _create(Vue, options) {
            if (!_.isPlainObject(options)) {
                throw new Error(
                    "[GlobalComponent._create]参数错误,options必须为对象!"
                );
            }
            const Cmp = Vue.extend(this);
            return new Cmp({
                propsData: {
                    isGlobal: true,
                    ...options,
                },
            });
        },
        /**
         * 静态打开方法
         * @param {object} prop属性
         * @returns {string|number} 组件id
         */
        open(options) {
            if (!_.isPlainObject(options)) {
                throw new Error(
                    `[GlobalComponent._create]参数错误,options必须为对象:当前值:${options}`
                );
            }
            const vm = this._create(this._vue, { value: true, ...options });
            // 挂载
            vm.$mount();
            // 打开
            vm.open();
            options.callback && options.callback(vm);
            return vm.id_;
        },
        /**
         * 关闭指定的组件
         * @see {string|number} 组件id
         * @returns void
         */
        close(id) {
            const moduleList = this._getGlobalModuleList();
            moduleList.some(({ id: cid, vm }) => {
                if (cid == id) {
                    vm.close();
                }
            });
        },
        /**
         * 关闭当前所有组件
         * @returns void
         */
        clear() {
            const moduleList = this._getGlobalModuleList();
            moduleList.forEach(({ vm }) => {
                vm.close();
            });
        },
        /**
         * 安装(重写)
         * @see 注册个性化的静态函数
         * @see Vue.use调用
         * @param {Function} Vue vue类对象
         * @returns void
         */
        install(Vue) {
            this._vue = Vue;
            if (Vue.prototype.$kq === undefined) {
                Vue.prototype.$kq = {};
            }
            if (!this._name) {
                throw new Error(
                    `[GlobalComponent.install]全局注册Vue.prototype未指定_name名称,组件:${this.name}`
                );
            }
            if (Vue.prototype.$kq[this._name] !== undefined) {
                throw new Error(
                    `[GlobalComponent.install]Vue.prototype.$kq对象注册键值重复:${this.name}`
                );
            }
        },
        /**
         * 获取全局打开性质的模块列表
         * @returns {[object]} [{id:组件id, vm: 组件实例, isGlobal:是否全局调用}, ...]
         */
        _getGlobalModuleList() {
            const name = this.name;
            if (globalModules[name] === undefined) {
                globalModules[name] = [];
            }
            return globalModules[name];
        },
        // Vue对象
        _vue: null,
        // 全局使用的名称(用来检测Vue.prototype.$kq是否注册重复)
        _name: null,
    },
};
</script>
