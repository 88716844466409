/**
 * 视图相关接口
 * @see 视图查询,修改
 */

export default class View {
    /**
     * 获取视图列表
     * @param {object} param0 入参对象
     * @param {string} param0.vidList 视图号列表
     * @param {boolean} [param0.isConvertSelect=false] 是否转换为select组件的数据格式
     * @returns {Promise<[object]>} 成功返回视图列表,失败抛错
     */
    async getViewList({ vidList, tid, isConvertSelect }) {
        if (kq.Tools.isEmpty(vidList, tid)) {
            throw new kq.Err({
                message: "视图号列表与样张号(tid)不能同时为空",
                from: "kqRequest.View.getViewList",
                console: false,
                current: arguments[0],
            });
        }
        let compares = {
            vid: "viewId",
            name: "viewName",
            fid: "funcId",
        };
        if (isConvertSelect) {
            compares = {
                value: "viewId",
                label: "viewName",
            };
        }
        let params;
        if (!kq.Tools.isEmpty(vidList)) {
            params = {
                url: "/api/commonService/queryViewList",
                method: "post",
                data: { vids: vidList },
                convert: {
                    compares,
                },
            };
        } else {
            params = {
                url: "/api/view/tempView",
                params: { tempId: tid },
                convert: {
                    compares,
                },
            };
        }
        return this.request(params, arguments[0]);
    }
    /**
     * 查询视图号
     * @param {object} params
     * @param {string|number} params.fid 功能点号
     * @param {string} params.vlabel 视图别名
     * @returns {Promise<number>} 成功返回视图号,失败抛错
     */
    async getVid(params) {
        const data = await this.getViewInfo({ ...params, convert: true }).catch((err) => {
            throw err;
        });
        return data.vid;
    }
    /**
     * 根据功能点id查询视图信息
     * @see 对应诚迈
     * @param {object} params
     * @param {string|number} params.fid 功能点号
     * @param {string} params.vlabel 视图别名
     * @param {string} params.vid 视图号
     * @param {boolean} [params.isPerson=false] 是否是个人数据的视图详情
     * @returns {Promise<object>} 失败抛错,成功返回视图信息对象
     */
    async getViewInfo({ fid, vlabel, vid, isPerson = false }) {
        // 参数校验
        if (!fid && !vlabel && !vid) {
            return Promise.reject(
                new Error(
                    `[Request.getVid]参数错误!查询视图号必须提供功能点号(fid)或视图别名(vlabel)或视图号(vid)`
                )
            );
        }
        const params = { params: {}, convert: "viewInfo" };
        if (!vid && fid) {
            params.url = "/api/common/dict/queryViewsByFuncId";
            params.params.funcId = fid;
        } else if (vlabel && !vid) {
            vid = await this.request({
                url: "/api/view/getViewIdByLabel",
                params: {
                    label: vlabel,
                },
            }).catch((err) => {
                throw err;
            });
        }
        if (vid) {
            params.url = isPerson ? "/api/view/DIY" : "/api/view";
            params.params.viewId = vid;
        }
        const data = await this.request(
            {
                ...params,
                timeout: 8000,
                timeoutConfig: {
                    reRequest: {
                        enabled: true,
                        maxCount: 3,
                    },
                },
            },
            arguments[0]
        ).catch((err) => {
            throw err;
        });
        if (_.isEmpty(data)) {
            throw new kq.Err({
                title: "配置错误",
                type: "CONFIG",
                message: "该功能点未绑定视图信息,请联系管理员确认",
                from: "kq.Request.View.getViewInfo",
                current: arguments[0],
                console: false,
            });
        }
        if (fid) {
            if (_.isArray(data)) {
                data[0].fid = fid;
                return data[0];
            }
            data.fid = fid;
        }
        if (!data.vid && !params.url.includes("DIY")) {
            console.warn(
                new kq.Err({
                    title: "配置错误",
                    message: "该功能点没有绑定视图号",
                    current: fid,
                    from: "kq.Request.getViewInfoByVid",
                    type: "CONFIG",
                    console: false,
                })
            );
        }
        return data;
    }
    async getViewInfoByVid({ fid, vid, isPerson }) {
        // 参数校验
        if (!fid && !vid) {
            return Promise.reject(
                new Error(`[Request.getVid]参数错误!查询视图号必须提供功能点号(fid)或视图号(vid)`)
            );
        }
        if (!vid) {
            const info = await this.request({
                url: "/api/common/dict/queryViewsByFuncId",
                params: { funcId: fid },
                convert: "viewInfo",
            }).catch((err) => {
                throw err;
            });
            vid = info[0].vid;
            if (!vid) {
                throw new kq.Err({
                    title: "配置错误",
                    message: "该功能点没有绑定视图号",
                    current: fid,
                    from: "kq.Request.getViewInfoByVid",
                    type: "CONFIG",
                });
            }
        }
        return this.request(
            {
                url: isPerson ? "/api/view/DIY" : "/api/view",
                params: { viewId: vid },
                convert: "viewInfo",
            },
            arguments[0]
        ).catch((err) => {
            throw err;
        });
    }
    /**
     * 更新视图
     * @param {object} param 参数表
     * @param {number} [param.fid] 功能点号
     * @param {string} [param.vlabel] 视图别名
     * @param {number} param.vid 视图号
     * @param {number} [param.uid] 人员号
     * @param {[object]} [param.fieldList] 字段列表
     * @param {[object]} [param.linkageList] 字段联动表
     * @param {boolean} [param.isPerson = false] 是否为个人视图更新
     * @returns {Promise<void>} 失败抛错
     */
    async updateView({ fid, vlabel, vid, uid, fieldList, isPerson = false, linkageList }) {
        const from = "kqRequest.View.updateView";
        const console = false;
        const current = arguments[0];
        if (!uid || (!fid && !vlabel && !vid)) {
            throw new kq.Err({
                message: "更新视图的视图号(vid|fid|vlabel)与人员号(!uid)不能为空",
                from,
                console,
                current,
            });
        }
        if (!vid) {
            vid = await this.getVid({ fid, vlabel }).catch((err) => {
                throw err;
            });
        }
        let url, data;
        if (isPerson) {
            url = "/api/view/DIY/update";
            data = this._convertViewFieldList(fieldList).map((field) => ({
                ...field,
                viewId: field.vid || vid,
                userId: field.uid || uid,
                nodeId: field.zid,
            }));
        } else {
            url = "/api/view/update";
            data = {
                viewId: vid,
                viewTableInfo:
                    fieldList &&
                    this._convertViewFieldList(fieldList).map((field) => ({
                        ...field,
                        nodeId: field.zid,
                    })),
                lowCodeLayout: encodeURI(JSON.stringify(linkageList)),
            };
        }
        return this.request(
            {
                url,
                method: "post",
                data,
            },
            arguments[0]
        );
    }
    _convertViewFieldList(fieldList) {
        return (fieldList || []).map((field) => {
            const res = {};
            _.forEach(field, (value, key) => {
                if (["isQuery", "isSort", "isTitle"].includes(key)) {
                    res[key] = Number(value);
                } else {
                    res[key] = value;
                }
            });
            return res;
        });
    }
    /**
     * 不再提醒
     * @param {object} param 参数对象
     * @param {number} param.fid 功能点号
     * @returns {Promise<void>} 失败抛错
     */
    neverNotify({ fid }) {
        if (!fid) {
            throw new kq.Err({
                message: "功能点号(fid)必传",
                current: arguments[0],
                from: "kq.Request.View.neverNotify",
            });
        }
        return this.request(
            {
                url: "/api/app/addFuncHelpNotHint",
                params: { funcId: fid },
            },
            arguments[0]
        );
    }
    /**
     * 获取功能点号与视图号参数表
     * @protected
     * @param {object} param 参数对象
     * @param {number} [param.fid] 功能点号
     * @param {number} [param.vid] 视图号
     * @returns {Promise<object>} 成功返回功能点号和视图号,失败抛错
     */
    async _getFidVidParams({ fid, vid }) {
        if (!fid && !vid) {
            throw new kq.Err({
                title: "参数错误",
                message: "功能点号(fid)和视图号(vid)不能同时为空",
                current: arguments[0],
                from: "kq.Request.getRowsetFieldList",
                console: false,
            });
        }
        if (!vid) {
            vid = await this.getVid({ fid }).catch((err) => {
                throw err;
            });
        }
        if (!fid) {
            const res = await this.getViewInfo({ vid }).catch((err) => {
                throw err;
            });
            fid = res.fid;
        }
        return { fid, vid };
    }
    /**
     * 保存视图
     * @param {object} param 入参对象
     * @param {number} [param.vid] 视图号(空:创建视图)
     * @param {string} param.vname 视图名称
     * @param {[object]} [param.layoutFieldList] 布局字段列表
     * @param {[object]} [param.tableFieldList] 勾选的字段列表
     * @param {number} [param.highBeat] 高拍方向(1:竖向,2:横向)
     * @param {number} [param.commonPrint] 打印列表
     * @param {number} [param.printFormat] 打印格式
     * @param {string} [param.editBeginTime] 开始编辑时间
     * @param {string} [param.editEndTime] 结束编辑时间
     * @param {number} [param.editFormat] 编辑模式
     * @param {number} [param.service] 视图服务
     * @param {number} [param.createView] 视图脚本生成(0:关,1:开)
     * @param {number} [param.label] 视图Label
     * @param {string} [param.sqlDIY] mysql高倍条件
     * @param {string} [param.oracleSqlDiy] oracel高倍条件
     * @param {string} [param.sqlServerDiy] sqlserver高倍条件
     * @param {[object]} [param.linkageList] 字段联动
     * @param {string} [param.queryConditions] 筛选条件代码
     * @param {string} [param.queryConditionsRemark] 筛选条件备注
     * @param {string} [param.physicalViewName] 物理视图名称
     * @param {number} [param.tid] 样张号(创建视图时,样张id不能为空)
     * @param {number} [param.ptid] 主行集样张号
     * @param {boolean} [param.isCopyMode=false] 是否为拷贝视图模式(拷贝视图将会新增视图)
     * @param {string} [param.startAddTime] 开始新增时间
     * @param {string} [param.endAddTime] 结束新增时间
     * @param {string} [params.typical="typical"] 视图类型(typical:典型,extend:万能申报扩展视图)
     * @returns {Promise<number>} 成功返回视图号,失败抛错
     */
    async saveView({
        vid,
        vname,
        layoutFieldList,
        tableFieldList,
        highBeat,
        commonPrint,
        printFormat,
        editBeginTime,
        editEndTime,
        editFormat,
        service,
        label,
        createView,
        sqlDIY,
        sqlServerDiy,
        oracleSqlDiy,
        linkageList,
        queryConditions,
        queryConditionsRemark,
        tid,
        ptid,
        physicalViewName,
        isCopyMode = false,
        startAddTime,
        endAddTime,
        viewType = "typical",
    }) {
        if (kq.Tools.isEmpty(vid) && kq.Tools.isEmptyMaybe(tid, vname)) {
            throw new kq.Err({
                from: "kq.Request.View.saveView",
                console: false,
                message: "当视图号参数为空(vid)时,样张号(tid)与视图名称(vname)不能为空",
            });
        }
        const formGridLayoutInfo = await this._formatLayoutFieldList(layoutFieldList).catch(
            (err) => {
                throw err;
            }
        );
        const data = {
            viewId: isCopyMode ? undefined : vid,
            originalViewId: isCopyMode ? vid : undefined,
            viewName: vname,
            highBeat,
            commonPrint,
            printFormat,
            editBeginTime,
            editEndTime,
            editFormat,
            createView,
            physicalViewName,
            service,
            label,
            printFormatStr: kq.Tools.isNumber(printFormat) ? String(printFormat) : printFormat,
            sqlDIY,
            sqlServerDiy,
            oracleSqlDiy,
            queryConditions,
            queryConditionsRemark,
            formGridLayoutInfo,
            tempId: tid,
            parentTempId: ptid,
            lowCodeLayout: linkageList ? encodeURI(JSON.stringify(linkageList)) : undefined,
            viewTableInfo: this._formatViewFieldList(tableFieldList),
            copyAddFlag: isCopyMode ? "1" : undefined,
            startAddTime,
            endAddTime,
            viewType: viewType || "typical",
        };
        // 去除空的参数key
        const realData = {};
        _.forEach(data, (value, key) => {
            if (value !== null && value !== undefined) {
                realData[key] = value;
            }
        });
        const res = await this.request(
            {
                url: kq.Tools.isEmpty(vid) || isCopyMode ? "/api/view" : "/api/view/update",
                method: "post",
                data: realData,
            },
            arguments[0]
        ).catch((err) => {
            throw err;
        });
        return res.viewId || res || vid;
    }
    /**
     * 格式化试图字段列表
     * @private
     * @desc 对字段key以及默认值进行转换
     * @param {[object]} fieldList 视图字段列表
     * @returns {[object]}
     */
    _formatViewFieldList(fieldList) {
        return fieldList.map(
            (
                {
                    zid,
                    zname,
                    readOnly = 0,
                    isTitle = 1,
                    isRequire = 1,
                    isConfirm = 0,
                    isQuery = 0,
                    dateType = "%Y-%m-%d",
                    hide = 0,
                    defaultValue = "",
                    alias = "",
                    sortIndex,
                    sortType,
                    dbName,
                    type,
                    signatureEnabled = null,
                    // 视图字段类型(0主样张字段，1普通属性，2附属行集字段,3扩展字段)
                    viewFieldType,
                    signatureRequired = null
                },
                index
            ) => {
                if (defaultValue && _.isArray(defaultValue)) {
                    defaultValue = defaultValue
                        .map((value) => (_.isArray(value) ? value.kqLastItem() : value))
                        .join(",");
                }
                /** 签名是否禁用 1:禁用｜0:可用 */
                let signatureDisabled = null;
                if (kq.Tools.isEqual(type, "review") && signatureEnabled !== null) {
                    if (_.isBoolean(signatureEnabled)) {
                        signatureDisabled = signatureEnabled ? 0 : 1;
                    } else if (_.isNumber(signatureEnabled)) {
                        signatureDisabled = kq.Tools.isEqual(signatureEnabled, 1) ? 0 : 1;
                    }
                }
                return {
                    nodeId: zid,
                    nodeName: zname,
                    readOnly,
                    isTitle,
                    isRequire,
                    isConfirm: isConfirm,
                    isQuery: isQuery,
                    isSort: kq.Tools.isEmpty(sortType) ? 0 : 1,
                    dateType: dateType,
                    sortIndex,
                    sortType,
                    hide: hide,
                    signatureDisabled,
                    defaultValue,
                    alias: alias,
                    sortId: index,
                    fieldName: dbName,
                    viewFieldType,
                    signatureRequired: signatureRequired !== null ? signatureRequired : null
                };
            }
        );
    }
    /**
     * 格式化布局字段列表
     * @private
     * @desc 对字段key以及默认值进行转换
     * @desc 请求与补齐分类字段布局id
     * @param {[object]} layoutFieldList 布局字段列表
     * @returns {Promsie<[object]>}
     */
    async _formatLayoutFieldList(layoutFieldList) {
        if (kq.Tools.isEmpty(layoutFieldList)) {
            return null;
        }
        const formatTypeFieldList = [];
        const parentIdFields = {};
        const showParendIdFields = {};
        const fieldList = layoutFieldList.map(
            ({
                zid,
                zname,
                layoutId,
                parentId,
                showParentId,
                textDesc,
                w,
                h,
                x,
                y,
                isType,
                viewFieldType,
            }) => {
                let field2 = {
                    nodeId:
                        isType || kq.Tools.isEqual(zid, layoutId) || kq.Tools.isEqual(zid, -1)
                            ? -1
                            : zid,
                    nodeName: zname,
                    i: zname,
                    id: layoutId,
                    parentId,
                    showParentId,
                    textDesc,
                    w,
                    h,
                    x,
                    y,
                    // 视图字段类型(0主样张字段，1普通属性，2附属行集字段,3扩展字段(万能申报))
                    viewFieldType,
                };
                if (/^\$/.test(String(layoutId))) {
                    formatTypeFieldList.push({ layoutId, field: field2 });
                }
                if (/^\$/.test(String(parentId))) {
                    let children = parentIdFields[parentId];
                    if (!children) {
                        children = [];
                        parentIdFields[parentId] = children;
                    }
                    children.push(field2);
                }
                if (/^\$/.test(String(showParentId))) {
                    let children = showParendIdFields[showParentId];
                    if (!children) {
                        children = [];
                        showParendIdFields[showParentId] = children;
                    }
                    children.push(field2);
                }
                return field2;
            }
        );
        if (!kq.Tools.isEmpty(formatTypeFieldList)) {
            /** 获取 */
            const layoutIdList = await this.request({
                url: "/api/common/dict/getFromgirlabelForId",
                params: { count: formatTypeFieldList.length },
            }).catch((err) => {
                this.errHand__(err);
                throw err;
            });
            formatTypeFieldList.forEach(({ layoutId, field }, index) => {
                const formatLayoutId = layoutIdList[index];
                field.id = formatLayoutId;
                // 补齐父级布局id
                if (parentIdFields[layoutId]) {
                    parentIdFields[layoutId].forEach(($field) => {
                        $field.parentId = formatLayoutId;
                    });
                }
                // 补齐所属分类布局id
                if (showParendIdFields[layoutId]) {
                    showParendIdFields[layoutId].forEach(($field) => {
                        $field.showParentId = formatLayoutId;
                    });
                }
            });
        }
        return fieldList;
    }
    /**
     * 删除1个或多个视图
     * @param {object} param 入参对象
     * @param {number|[number]} param.vid 视图号
     * @returns {Promise<void>} 失败抛错
     */
    deleteView({ vid }) {
        if (!vid) {
            throw new kq.Err({
                message: "视图号(vid)参数不能为空",
                current: arguments[0],
                from: "kq.Request.View.deleteView",
                console: false,
            });
        }
        if (!_.isArray(vid)) {
            vid = [vid];
        }
        return this.request(
            {
                url: "/api/view/delete",
                method: "post",
                data: vid,
            },
            arguments[0]
        );
    }
    /**
     * 同步视图
     * @param {object} param 入参对象
     * @param {number} param.fromVid 同步的视图
     * @param {number} param.toVid 被同步的视图号
     * @returns {Promise<void>} 失败抛错
     */
    syncView({ fromVid, toVid }) {
        if (kq.Tools.isEmptyMaybe(fromVid, toVid)) {
            throw new kq.Err({
                message: "同步的视图号(fromVid)与被同步的视图号(toVid)不能为空",
                current: arguments[0],
                from: "kq.Request.View.syncView",
                console: false,
            });
        }
        return this.request(
            {
                url: "/api/view/viewSync",
                params: {
                    syncViewId: fromVid,
                    beSyncViewId: toVid,
                },
            },
            arguments[0]
        );
    }
}
