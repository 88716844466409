import Tools from "../Utils/tools";
import _ from "lodash";
import $ from "jquery";

function inserted(el, { arg, modifiers, value }) {
    if (value === undefined) {
        value = 1;
    }
    if (_.isNaN(Number(value))) {
        console.error(
            `[DirectiveMount-inserted]参数错误!value只能为最大挂载数(数字)!当前:${JSON.stringify(
                value
            )}`
        );
    }
    el.kqMount.parent = $(el).parent();
    el.kqMount.arg = arg;
    let target;
    let successHandler;
    /** 插入方法 */
    const method = modifiers.prepend ? "prepend" : "append";
    // 将el追加到目标元素中
    function append($dom, isReplace) {
        if (isReplace) {
            // 备份原来的元素
            el.kqMount.oldChildren = $dom.children();
            el.kqMount.targetDom = $dom;
            $dom.children.detach();
        }
        $(el)[`${method}To`]($dom);
    }
    if (arg) {
        if (_.isString(arg)) {
            let domStr;
            if (modifiers.id) {
                domStr = `${arg.includes("#") ? "" : "#"}${arg}`;
                target = () => $(domStr).length;
            } else {
                domStr = `${arg.includes(".") ? "" : "."}${arg}`;
                target = domStr;
            }
            successHandler = () => {
                append($(domStr), modifiers.replace);
            };
        } else if (_.isElement(arg)) {
            target = () => arg;
            successHandler = () => {
                append(arg, modifiers.replace);
            };
        } else if (_.isFunction(arg)) {
            target = () => arg().length;
            successHandler = () => {
                append(arg(), modifiers.replace);
            };
        }
        Tools.isExist(target, successHandler, null, 3);
    } else {
        // 将el放回原处
        $(el)[`${method}To`](el.kqMount.parent);
        // 还原替换前的状态
        if (
            modifiers.replace &&
            el.kqMount.targetDom &&
            el.kqMount.oldChildren
        ) {
            el.kqMount.targetDom[method](el.kqMount.oldChildren);
        }
    }
}

export default {
    install(Vue) {
        Vue.directive("kq-mount", {
            bind(el) {
                el.kqMount = {};
            },
            inserted,
            update(el, params) {
                if (params.arg !== el.kqMount.arg) {
                    inserted(el, params);
                }
            },
            unbind(el) {
                el.kqMount.unbind = true;
            },
        });
    },
};
