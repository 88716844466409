/**
 * @description 行集操作相关接口模块
 * @author tonny
 * @date 2021-12-24
 */

import _ from "lodash";
import View from "./View";

export default class Rowset extends View {
    /**
     * 获取行集数量列表
     * @param {object} param 参数表
     * @param {number} param.uid 人员内部号
     * @param {number} [param.fid] 功能点号(不能与视图号同时为空)
     * @param {number} [param.vid] 视图号(不能与功能点号同时为空)
     * @param {number} [param.rid] 经历号
     * @returns {Promise<[object]>} 失败抛错
     */
    async getRowsetCountList({ rid, vid, uid, fid }) {
        if (kq.Tools.isEmpty(uid)) {
            throw new kq.Err({
                title: "参数错误",
                message: "人员号(uid)不能为空",
                current: arguments[0],
                from: "kq.Request.getRowsetCount",
                console: false,
            });
        }
        const params = await this._getFidVidParams({ fid, vid }).catch(
            (err) => {
                throw err;
            }
        );
        return this.request(
            {
                url: "/api/common/dict/queryRowSetCount",
                params: {
                    viewId: params.vid,
                    billRootId: kq.Tools.isEqual(uid, 0) ? undefined : uid,
                    funcId: params.fid,
                    billId: rid,
                },
                convert: "Field",
            },
            arguments[0]
        );
    }
    /**
     * 审批经历
     * @param {object} param 参数表
     * @param {number} [param.fid] 功能点号(和视图号参数必须传一个)
     * @param {number} [param.vid] 视图号
     * @param {number|[number]} [param.rid] 经历号(和ridList至少有1个不能为空)
     * @param {[number]} [param.ridList] 经历号列表(和rid至少有1个不能为空)
     * @returns {Promise<void>} 失败抛错
     */
    async approve({ fid, vid, rid, ridList }) {
        const from = "kq.Request.Rowset.approve",
            console = false,
            current = arguments[0];
        if (!rid && _.isEmpty(ridList)) {
            throw new kq.Err({
                message: "审批的经历号(rid)经历号列表(ridList)参数不能同时为空",
                current,
                from,
                console,
            });
        }
        if (!fid && !vid) {
            throw new kq.Err({
                message: "审批的功能点号(fid)或视图号(vid)不能同时为空",
                current,
                from,
                console,
            });
        }
        if (!vid) {
            vid = await this.getVid({ fid }).catch((err) => {
                throw err;
            });
        }
        // 转换经历表
        if (rid) {
            if (_.isArray(rid)) {
                ridList = rid;
            } else {
                ridList = [rid];
            }
        }
        return this.request(
            {
                url: "/api/common/dict/auditBill",
                method: "post",
                data: {
                    billIds: ridList,
                    funcId: fid,
                    viewId: vid,
                },
            },
            arguments[0]
        );
    }
    /**
     * 解锁一条记录
     * @param {object} param0 参数对象
     * @param {number} [param0.rid] 经历号(不传则解锁普通属性)
     * @param {number} param0.fid 功能点号
     * @param {number} param0.uid 人员号
     * @returns {Promise<void>} 失败抛错
     */
    async unlock({ rid, fid, uid }) {
        if (!uid || !fid) {
            throw new kq.Err({
                message: "解锁功能功能点号(fid)与用户号(uid)参数不能少",
                current: arguments[0],
                from: "kq.Request.Rowset.unlock",
                console: false,
            });
        }
        return this.request(
            {
                url: "/api/common/dict/updateLockRecord",
                method: "post",
                data: {
                    billId: rid,
                    funcId: fid,
                    userId: uid,
                },
            },
            arguments[0]
        );
    }

    /**
     * 获取是否锁定状态
     * @param {object} param0 参数表
     * @param {number} [param0.rid] 经历号
     * @param {number} param0.fid 功能点号
     * @param {number} param0.uid 人员号
     * @returns {Promise<boolean>} 失败抛错,true:已锁定;false:未锁定
     */
    async getIsLock({ rid, fid, uid }) {
        if (!fid || !uid) {
            throw new kq.Err({
                message: "功能点号(fid)与用户号(uid)不能少",
                current: arguments[0],
                from: "kq.Request.Rowset.getLockStat",
                console: false,
            });
        }
        const data = await this.request(
            {
                url: "/api/common/dict/queryLockRecordStatus",
                method: "post",
                data: {
                    billId: rid,
                    funcId: fid,
                    userId: uid,
                },
            },
            arguments[0]
        ).catch((err) => {
            throw err;
        });
        return Boolean(Number(data));
    }
    /**
     * 锁定一条(普通|行集)功能点记录
     * @see 不传经历号则锁定一个人对应功能点的普通信息
     * @param {object} param0 参数表
     * @param {number} [param0.rid] 经历号
     * @param {number} param0.fid 功能点号
     * @param {number} param0.uid 人员号
     * @returns {Promise<void>} 失败抛错
     */
    async lock({ rid, fid, uid }) {
        if (!fid || !uid) {
            throw new kq.Err({
                message: "功能点号(fid)与用户号(uid)不能少",
                current: arguments[0],
                from: "kq.Request.Rowset.lock",
                console: false,
            });
        }
        return this.request(
            {
                url: "/api/common/dict/queryLockRecordStatus",
                method: "post",
                data: {
                    billId: rid,
                    funcId: fid,
                    userId: uid,
                },
            },
            arguments[0]
        );
    }
    /**
     *
     * @param {object} param 参数表
     * @param {number} param.rid 经历号
     * @returns {Promise<object>} 失败报错
     * @example {tid<number>: 样张号, pid<number>:流程号, uid<number>:经历所属人员号, rid<number>:经历号, isApprove<boolean>:是否审批, zname<string>:行集名称, ...}
     */
    async getRowsetInfo({ rid }) {
        if (!rid) {
            throw new kq.Err({
                message: "经历号参数(rid)必传",
                current: arguments[0],
                from: "kq.Request.Rowset.getRowsetInfo",
            });
        }
        return this.request(
            {
                url: "/api/common/dict/getBill",
                params: {
                    billId: rid,
                },
                convert: {
                    compares: {
                        tid: "templateID",
                        pid: "flowID",
                        uid: "rootBillID",
                        rid: "billID",
                        isApprove: "billStatus",
                        zname: "billName",
                    },
                    convertValueHook({ to, value }) {
                        // 转换是否审批为布尔值
                        if (to === "isApprove") {
                            return !Boolean(Number(value));
                        }
                        return value;
                    },
                },
            },
            arguments[0]
        );
    }
}
