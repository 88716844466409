/**
 * @description 验证器
 * @author tonny
 * @date 2021-12-15
 */
import Vue from "vue";
import _ from "lodash";

/**
 * @class
 */
export default class Validator {
    /**
     * 验证器构造
     * @param {object} param 参数对象|vm实例
     * @param {Vue} param.vm vm实例
     * @param {*} [param.linkage] 联动
     * @returns void
     */
    constructor(param = {}) {
        if (param instanceof Vue) {
            this.vm = param;
        } else {
            const { vm } = param;
            this.vm = vm;
        }
    }
    /**
     * 验证字段表
     * @param {[object]} fieldList 字段列表
     * @returns {boolean} true:验证通过
     */
    validate(fieldList, errHand) {
        let errCount = 0;
        fieldList.forEach(field => {
            if (field.readonly) {
                return;
            }
            /** 输出信息 */
            let message = "";
            Vue.set(field, "error", "");
            message = this._required(field);
            !message && (message = this._regexp(field));
            if (message) {
                if (_.isFunction(errHand)) {
                    errHand(field);
                } else {
                    Vue.set(field, "error", message);
                }
            }
            if (message) {
                errCount++;
            }
        });
        return !errCount;
    }
    /**
     * 必填验证
     * @param {object} field 字段信息
     * @returns {string} 字符串:错误字符(验证不通过),undefined:验证通过
     */
    _required(field) {
        const { value, required } = field;
        if (required && !value) {
            return "字段必填";
        }
    }
    /**
     * 正则表验证
     * @param {object} field 字段信息
     * @returns {string} 字符串:错误字符(验证不通过),undefined:验证通过
     */
    _regexp({ value, regexps }) {
        let msg = "";
        if (_.isArray(regexps)) {
            regexps.some(({ regexp, regexpSuffix = "", message }) => {
                if (!RegExp(regexp, regexpSuffix).test(value)) {
                    msg = message;
                }
                return msg;
            });
        }
        return msg;
    }
}
