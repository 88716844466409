import Field from "./Field.js";
import {
    zlabels,
    guideCompares,
    stepCompares,
    guideRequiredZlabels,
    stepRequiredZlabels,
} from "kooci-lib/src/PubComponents/Mixin/Guide/config.js";
export default class Guide extends Field {
    /**
     * 获取向导ID缓存
     * @returns {Promise<string>}
     */
    async getGuideIdCache() {
        return this.request("/autoGuide/guide", arguments[0]);
    }
    /**
     * 设置向导ID缓存
     * @param {object} param0
     * @param {string} param0.id 向导id
     * @returns {Promise<void>}
     */
    async saveGuideIdCache({ id }) {
        if (kq.Tools.isEmptyMaybe(id)) {
            throw new kq.Err({
                message: "向导id(id)必传",
                from: "kq.Request.Guide.setGuideIdCache",
                console: false,
            });
        }
        return this.request(
            {
                url: "/autoGuide/guide",
                method: "post",
                data: {
                    guidIds: id,
                },
            },
            arguments[0]
        );
    }
    /**
     * 保存向导
     * @param {object} param 入参对象
     * @param {object} param.guide 向导对象
     * @returns {Promise<number>} 成功返回向导id
     */
    async saveGuide({ guide }) {
        if (kq.Tools.isEmptyMaybe(guide)) {
            throw new kq.Err({
                message: "向导信息(guide)不能为空",
                from: "kq.Request.Guide.getGuideByType",
                console: false,
            });
        }
        let guideMethod = "saveField";
        if (kq.Tools.isEmpty(guide.rid)) {
            guideMethod = "createRowset";
        }
        const { rid } =
            (await this[guideMethod]({
                rid: guide.rid,
                fid: this.guideFid,
                fieldList: this._convertGuideSaveFieldList(
                    this.guideFields,
                    guideCompares,
                    guide
                ),
            }).catch((err) => {
                throw err;
            })) || {};
        return rid || guide.rid;
    }
    /**
     * 转换向导保存的字段表
     * @param {object} fields 字段查询表
     * @param {object} compares 转换对照表
     * @param {object} guide 向导(步骤)
     * @returns {[object]} 保存的字段列表
     */
    _convertGuideSaveFieldList(fields, compares, guide) {
        const fieldList = [];
        _.forEach(compares, (zlabel, key) => {
            if (guide[key] !== undefined && fields[zlabel]?.zid !== undefined) {
                fieldList.push({ zid: fields[zlabel].zid, value: guide[key] });
            }
        });
        return fieldList;
    }
    /**
     * 保存向导步骤
     * @param {object} param0 入参对象
     * @param {object} param0.step 步骤对象
     * @returns {Promise<number>} 成功返回经历号,失败抛错
     */
    async saveGuideStep({ step }) {
        if (kq.Tools.isEmptyMaybe(step)) {
            throw new kq.Err({
                message: "向导步骤信息(step)不能为空",
                from: "kq.Request.Guide.saveGuideStep",
                console: false,
            });
        }
        let requestMethod = "saveField";
        if (kq.Tools.isEmpty(step.rid)) {
            requestMethod = "createRowset";
        }
        const { rid } =
            (await this[requestMethod]({
                rid: step.rid,
                fid: this.stepFid,
                fieldList: this._convertGuideSaveFieldList(
                    this.stepFields,
                    stepCompares,
                    step
                ),
            }).catch((err) => {
                throw err;
            })) || {};
        return rid || step.rid;
    }
    /**
     * 删除一个(多个)向导步骤
     * @param {object} param0 入参对象
     * @param {object|[object]} param0.step 向导步骤(列表)
     * @returns {Promise<void>} 失败抛错
     */
    async deleteGuideStep({ step }) {
        if (!kq.Tools.isEmptyMaybe(step, step.rid)) {
            if (!_.isArray(step)) {
                step = [step];
            }
            return this.deleteRowset({
                fid: this.stepFid,
                ridList: step.map(($step) => $step.rid),
            });
        }
    }
    /**
     * 删除向导
     * @param {object} param0 入参对象
     * @param {object} param0.guide 向导
     * @returns {Promise<void>} 失败抛错
     */
    async deleteGuide({ guide }) {
        if (kq.Tools.isEmptyMaybe(guide, guide.rid)) {
            throw new kq.Err({
                message: "向导(guide)参数与向导经历号(guide.rid)不能为空",
                from: "kq.Request.Guide.deleteGuide",
                console: false,
            });
        }
        return this.deleteRowset({
            fid: this.guideFid,
            rid: guide.rid,
            ...arguments[0],
        });
    }
    /**
     * 根据向导类型获取向导信息
     * @param {object} param 入参对象
     * @param {string|[string]} param.id 向导id
     * @param {number} param.guideFid 向导功能点号
     * @param {number} param.stepFid 步骤功能点号
     * @returns {Promise<[object]>}
     */
    async getGuideById({ id, guideFid, stepFid, autoGetStep = true }) {
        if (kq.Tools.isEmptyMaybe(guideFid, stepFid)) {
            throw new kq.Err({
                message: "向导功能点号(guideFid)与步骤功能点号(stepFid)必传",
                from: "kq.Request.Guide.getGuideByType",
                console: false,
            });
        }
        this.guideFid = guideFid;
        this.stepFid = stepFid;
        /** 向导字段查询表 */
        const guideFields = await this._getGuideFields().catch((err) => {
            throw err;
        });
        // 查询向导列表以及步骤字段列表
        const [{ dataList: guideList }, stepFields] = await Promise.all([
            this.getList({
                pageSize: 100,
                fid: guideFid,
                filterList: kq.Tools.isEmpty(id)
                    ? undefined
                    : [
                        {
                            zid: guideFields[zlabels.guideId].zid,
                            value: id,
                        },
                    ],
            }),
            this._getStepFields(),
        ]).catch((err) => {
            throw err;
        });
        // 继续查询步骤字段列表
        if (!kq.Tools.isEmpty(guideList)) {
            let stepList = [];
            if (autoGetStep) {
                const { dataList: stepList2 } = await this.getList({
                    pageSize: 100,
                    fid: stepFid,
                    filterList: [
                        {
                            zid: stepFields[zlabels.guideId].zid,
                            value: guideList.map(
                                (row) => row[stepFields[zlabels.guideId].zid]
                            ),
                        },
                    ],
                }).catch((err) => {
                    throw err;
                });
                stepList = stepList2;
            }
            return guideList.map((guideRow) => {
                return {
                    rid: guideRow.rid,
                    ...this._convertGuideByRow(
                        guideRow,
                        guideFields,
                        guideCompares
                    ),
                    stepList: _.sortBy(
                        stepList.map((stepRow) => {
                            return {
                                rid: stepRow.rid,
                                ...this._convertGuideByRow(
                                    stepRow,
                                    stepFields,
                                    stepCompares
                                ),
                            };
                        }),
                        step => Number(step.sort)
                    ),
                };
            });
        }
    }
    /**
     * 根据行数据转换成向导对象
     * @param {object} data 单行数据
     * @param {object} fields 字段查询表
     * @param {object} convert 转换参照表
     * @returns {object} 向导(步骤)对象
     */
    _convertGuideByRow(data, fields, convert) {
        const result = {};
        _.forEach(convert, (zlabel, key2) => {
            result[key2] = _.get(data, _.get(fields, [zlabel, "zid"]));
        });
        return result;
    }
    /**
     * 获取向导字段查询表
     * @returns {Promise<object>} 向导字段查询表
     */
    async _getGuideFields() {
        if (kq.Tools.isEmpty(this.guideFields)) {
            this.guideFields = await this._getFields({
                fid: this.guideFid,
            }).catch((err) => {
                throw err;
            });
        }
        if (
            _.some(
                guideRequiredZlabels,
                (value, zlabel) => value && !this.guideFields[zlabel]
            )
        ) {
            throw new kq.Err({
                type: "CONFIG",
                message: "请确保自动向导视图配置了所有字段",
                from: "kq.Request.Guide",
                console: false,
            });
        }
        return this.guideFields;
    }
    /**
     * 获取步骤字段查询表
     * @returns {object}
     */
    async _getStepFields() {
        if (kq.Tools.isEmpty(this.stepFields)) {
            this.stepFields = await this._getFields({
                fid: this.stepFid,
            }).catch((err) => {
                throw err;
            });
        }
        if (
            _.some(
                stepRequiredZlabels,
                (value, zlabel) => value && !this.stepFields[zlabel]
            )
        ) {
            throw new kq.Err({
                type: "CONFIG",
                message: "请确保自动向导视图配置了所有字段",
                from: "kq.Request.Guide",
                console: false,
            });
        }
        return this.stepFields;
    }
    /**
     * 获取字段查询表
     * @desc 将字段列表转换为字段查询表
     * @param {object} params 入参对象
     * @returns {object}
     */
    async _getFields(params) {
        const fieldList = await this.getFieldList(params).catch((err) => {
            throw err;
        });
        const fields = {};
        (fieldList || []).kqEachDeep((field) => {
            fields[field.zlabel] = field;
            fields[field.zid] = field;
        });
        return fields;
    }
}
