/**
 * 消息请求类
 * @author tonny
 * @date 2022-09-07
 */
import Base from "../base";
import Tools from "../../Utils/tools";

export default class Transfer extends Base {
    /**
     * 获取转单是否已阅
     * @param {object} param0
     * @param {number} param0.rid 经历号
     * @param {number} param0.transferId 转单号
     * @returns {Promise<boolean>} 成功返回是否已阅(true:已阅),失败抛错
     * @example
     * new kq.Request.Transfer().getReaded({rid: 1, transferId: 2, did: 2})
     */
    async getReaded({ rid, transferId, did }) {
        if (Tools.isEmptyMaybe(did)) {
            throw new kq.Err({
                message: "当前审核部门号(did)不能少",
                current: arguments[0],
                from: "kq.Request.Message",
            });
        }
        const recordList = await this.getRecordList({ rid, transferId }).catch(
            (err) => {
                throw err;
            }
        );
        return recordList.some(
            (record) => Tools.isEqual(record.did, did) && record.isReview
        );
    }
    /**
     *
     * @param {object} param0 参数对象
     * @param {number}
     * @returns {Promise<[object]>} 成功返回转单记录列表,失败抛错
     * @example
     * new kq.Request.Transfer().getRecordList({rid: 1, transferId: 2})
     * @example return
     * [{rid<number>:经历号, did<number>:审核部门id, transferType<string>:转单类型, isSendSms<boolean>:是否已经发送短信, isSendWx<boolean>:是否已经发送微信, isSendMail<boolean>:是否已经发送邮箱, isReview<boolean>:是否已经审核},...]
     */
    async getRecordList({ rid, transferId }) {
        if (Tools.isEmptyMaybe(rid, transferId)) {
            throw new kq.Err({
                message: "经历号(rid)与转单id(transferId)不能少",
                current: arguments[0],
                from: "kq.Request.Message",
            });
        }
        return this.request(
            {
                url: "/api/common/notify/queryRecord",
                params: {
                    billid: rid,
                    svcId: transferId,
                },
                convert: {
                    compares: {
                        rid: "billid",
                        did: "checkDeptid",
                        transferType: "svcName",
                        isSendSms: "smsStatus",
                        isSendWx: "wxStatus",
                        isSendMail: "mailStatus",
                        isReview: "status",
                    },
                    isStrict: true,
                    convertValueHook({ to, value }) {
                        if (/^is/.test(to)) {
                            return Boolean(Number(value));
                        }
                        return value;
                    },
                },
            },
            arguments[0]
        );
    }
    /**
     * 已阅提交
     * @param {object} param0
     * @param {number} param0.rid 经历号
     * @param {number} param0.transferId 转单id
     * @param {number} param0.did 审核部门id
     * @param {number} param0.warnId 预警id(用来消除预警信息)
     * @returns {Promise<void>} 失败抛错
     */
    async readCommit({ rid, transferId, did, warnId }) {
        if (Tools.isEmptyMaybe(rid, transferId, did, warnId)) {
            throw new kq.Err({
                message:
                    "已阅提交参数:经历号(rid),转单号(transferId),审核部门号(did),预警记录号(warnId)不能少",
                current: arguments[0],
                from: "kq.Request.readCommit",
            });
        }
        return this.request(
            {
                url: "/api/common/notify/confirm",
                method: "post",
                data: {
                    billid: rid,
                    svcId: transferId,
                    checkDeptid: did,
                    earlyWarnId: warnId,
                },
            },
            arguments[0]
        );
    }
}
