/**
 * 设置网页标题
 * @desc 会根据调试环境自动加上环境别名
 * @param {string} title 标题文本
 * @returns {string}
 */
export function setWebTitle(title) {
    let { VUE_APP_ENV: env, VUE_APP_ALIA: alia } = process.env;
    alia = alia ? `${alia}-` : "";
    title = `${env === "development" ? alia : ""}${title}`;
    $("title").text(title);
    return title;
}

export function getDataItem(data, prop, key, defaultValue = "") {
    let result = ""
    if (!kq.Tools.isEmpty(data) && !kq.Tools.isEmpty(data[prop])) {
        if (kq.Tools.isEqual(key, "label")) {
            result = this.$utils.getItemLabel(data[prop]);
        } else if (kq.Tools.isEqual(key, "value")) {
            result = this.$utils.dataItemValue(data, prop);
        }
    }
    return result || defaultValue || "";
}