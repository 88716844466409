/**
 * 函数工具原型方法
 * @author tonny
 * @date 2022-09-12
 */

/**
 * 调用方法
 * @param {function} func 源函数
 * @param {}
 */
function call(func, params, resolve, reject) {
    const res = func(...params);
    if (res instanceof Promise) {
        res.then(resolve).catch(reject);
    } else {
        resolve(res);
    }
}

/**
 * 防抖函数
 * @desc 指定时间内只会调用一次,在超时时间内:重复调用将不会执行目标函数
 * @desc 生成的防抖函数通过.from可以直接访问原函数
 * @param {number} timeout 防抖间隔时间(毫秒,不传值或传0则会直接将原函数返回)
 * @param {boolean} [immediate=true] 是否立即调用
 * @param {function} [callback] 调用之后的回调函数
 * @returns {function} 生成的防抖函数调用时会始终返回Promise对象(如果原函数就是返回Promise对象时会原样返回,如果不是,则Promise一个有返回值的结果)
 * @example
 * const function demo = function() {console.log(123)};
 * const demoDebounce = demo.kqDebounce(1000);
 * demoDebounce();
 * // 访问源函数
 * console.log(demoDebounce.from);
 */
export function debounce(timeout, immediate = true, callback = null) {
    if (timeout) {
        let timer = null;
        const _this = this;
        const result = function (...params) {
            return new Promise((resolve, reject) => {
                if (immediate && !timer) {
                    call(_this, params, resolve, reject);
                    callback && callback();
                }
                clearTimeout(timer);
                timer = setTimeout(() => {
                    if (!immediate) {
                        call(_this, params, resolve, reject);
                        callback && callback();
                    }
                    timer = null;
                }, timeout);
            });
        };
        result.from = this;
        return result;
    }
    return this;
}

/**
 * 节流函数
 * @desc 指定时间内才会调用,保证了每次调用的频率想同
 * @desc 目标函数调用会始终返回Promise数据
 * @desc 生成的节流函数通过.from可以直接访问原函数
 * @param {number} [timeout] 节流间隔时间(毫秒,不传值或传0则会直接将原函数返回)
 * @param {boolean} [immediate=true] 是否调用立即执行原函数
 * @param {function} [callback] 调用之后的回调函数
 * @returns {function} 生成的节流函数调用时会始终返回Promise对象(如果原函数就是返回Promise对象时会原样返回,如果不是,则Promise一个有返回值的结果)
 * @example
 * const function demo = function() {console.log(123)};
 * const demoThrottle = demo.kqThrottle(1000);
 * demoThrottle();
 * // 访问源函数
 * console.log(demoThrottle.from);
 */
export function throttle(timeout, immediate = true, callback = null) {
    if (timeout) {
        let timer;
        const _this = this;
        const result = function (...params) {
            return new Promise((resolve, reject) => {
                if (immediate && !timer) {
                    call(_this, params, resolve, reject);
                    callback && callback();
                }
                if (!timer) {
                    timer = setTimeout(() => {
                        if (!immediate) {
                            call(_this, params, resolve, reject);
                            callback && callback();
                        }
                        timer = null;
                    }, timeout);
                }
            });
        };
        result.from = this;
        return result;
    }
    return this;
}
