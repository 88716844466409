import _ from "lodash";

/**
 * 最后一个元素
 * @returns {*|undefined}
 */
export function lastItem() {
    return this[this.length - 1];
}

/**
 * 最后一个索引
 * @returns {number}
 */
export function lastIndex() {
    return this.length - 1;
}

/**
 * 生成一个数组
 * @param {number} count 创建数量
 * @param {function} [map] 遍历生成值得回调函数
 * @returns {array} 生成的数组
 */
export function create(count, map = null) {
    for (let i = 0; i < count; i++) {
        let res;
        if (_.isFunction(map)) {
            res = map(i);
        } else {
            res = _.clone(map);
        }
        this.push(res);
    }
    return this;
}
/**
 * 设置一个不重复的值
 * @desc 针对重复的值,会直接在原位置通过splice追加新元素
 * @desc 针对不重复的值会正常追加
 * @param {*} target 设置的值
 * @param {*} callback 判重的回调函数(返回true则重复)
 * @param {string} [setMethod='push'] 追加方法
 * @returns {[*]} 数组
 */
export function set(target, callback, setMethod = "push") {
    if (_.isFunction(callback)) {
        const findIndex = this.findIndex(callback);
        if (findIndex >= 0) {
            this.splice(findIndex, 1, target);
            return this;
        }
    }
    this[setMethod](target);
    return this;
}
