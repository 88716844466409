import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import _ from "lodash";

Vue.use(Vuex);

/* eslint-disable no-new */
const store = new Vuex.Store({
    plugins: [createPersistedState()],
    modules: {
        user: {
            state: {
                isLogin: false,
                token: "",
                uid: "",
                uname: "",
            },
            actions: {
                disLogin({ state }) {
                    return new Promise((resolve, reject) => {
                        state.isLogin = false;
                        console.warn("注销登录了~");
                        resolve();
                    });
                },
            },
            getters: {
                /**
                 * 当前登录用户id
                 * @returns {number}
                 */
                uid(state) {
                    return state.uid;
                },
                /**
                 * 当前登录用户姓名
                 * @returns {string}
                 */
                uname(state) {
                    return state.uname;
                },
            },
        },
        config: {
            state: {
                system: {
                    /** 附件:文件名正则表达式 */
                    FILE_GET_FILENAME: s => s.match("_(.*)")[1],
                    /** 上传api */
                    UPLOAD_API: "/api/file/fileupload",
                    /** api前缀(用来提供nginx转发) */
                    API_PREFIX: "/api",
                },
            },
        },
        fview: {
            state: {
                /** props参数集 */
                props: {},
            },
            mutations: {
                /**
                 * 设置props属性
                 * @param {object} state 仓库state对象
                 * @param {object} param 参数对象
                 * @param {number} param.fid 功能点号
                 * @param {object} param.props props参数对象
                 */
                setProps(state, { fid, props }) {
                    if (!fid) {
                        new kq.Err({
                            message: "功能点号(fid)不能少",
                            current: arguments[1],
                            from: "store.fview.mutations.setProps",
                        });
                    }
                    Vue.set(state.props, fid, props);
                },
                setProp(state, params) {
                    if (!params.fid) {
                        new kq.Err({
                            message: "功能点号(fid)不能少",
                            current: params,
                            from: "store.fview.mutations.setProps",
                        });
                    }
                    _.forEach(params, (value, key) => {
                        if (!["fid", "appid"].includes(key)) {
                            Vue.set(state[params.fid], key, value);
                        }
                    });
                },
            },
        },
    },
});

export default store;
