<script>
/**
 * @description 下一页混入组件
 * @author tonny
 * @date 2021-12-22
 */
</script>

<script>
import _ from "lodash";

const SUPER = "KQ_TurnPageMixin";

export default {
    SUPER,
    props: {
        /** 每页多少个 */
        onePageSize: {
            type: Number,
            default: 20,
        },
        /** 页面追加模式(concat:数据逐渐累加(无限滚动), replace: 数据每页替换) */
        pageMode: {
            type: String,
            default: "concat",
            validator(val) {
                const compares = ["concat", "replace"];
                if (!compares.includes(val)) {
                    new kq.Err({
                        message: `追加模式必须在:${compares.join()}内`,
                        current: val,
                        from: "kq.TurnPageMixin.props.pageMode",
                    });
                    return false;
                }
                return true;
            },
        },
    },
    data() {
        return {
            /** 加载状态 */
            loading_: false,
            /** 当前页码 */
            currentPage: 1,
            /** 总个数 */
            sumCount: 0,
            /** 下一页加载状态 */
            nextPageLoading: false,
            /** 数据表(用来进行页面数据追加) */
            dataList_: [],
            /** 每页个数(sync双向绑定) */
            onePageSize_: 20,
            /** 总数据表(记录着每一页数据) */
            sumDatas: {},
            /** 懒渲染索引 */
            lazyRenderIndex: 0,
            /** 一页最多懒渲染的条数 */
            onePageMaxLazyRenderCount: 20,
            /** 是否开启懒渲染 */
            lazyRenderEnabled: false,
            /** 刷新数据加载状态 */
            refreshDataLoading: false,
        };
    },
    computed: {
        /**
         * 是否已经完成
         * @see this.currentOage
         * @see this.onePageSize
         * @see this.sumCount
         * @returns {boolean} true:完成
         */
        isFinish() {
            return (
                this.pageMode === "concat" &&
                this.currentPage * this.onePageSize - this.sumCount >=
                    this.onePageSize
            );
        },
        /**
         * 格式化的数据表
         * @see t
         */
        formatDataList() {
            return (this.dataList_ || []).filter(
                (data, index) =>
                    !this.lazyRenderEnabled ||
                    index <=
                        (this.lazyRenderIndex + 1) *
                            this.onePageMaxLazyRenderCount
            );
        },
    },
    watch: {
        currentPage(page) {
            this.pageChange(page);
        },
    },
    created() {
        this.sync__("onePageSize");
    },
    methods: {
        /**
         * 追加懒渲染
         * @returns void
         */
        appendLazyRender() {
            this.lazyRenderIndex++;
        },
        /**
         * 清空懒渲染
         * @returns {Promise<void>}
         */
        clearLazyRender() {
            this.lazyRenderIndex = 0;
            return this.scrollTop();
        },
        /**
         * 置顶操作(纯虚函数)
         * @virtual
         * @returns {Promise<void>}
         */
        async scrollTop() {},
        /**
         * 页码更改事件
         * @description 页码更新时调用:跳往指定页码
         * @param {number} page 页码
         * @returns void
         */
        pageChange(page) {
            this.clearLazyRender().then(() => {
                this.goPage(page);
            });
        },
        /**
         * 加载一页函数
         * @see 用来提供给子混入组件重写加载逻辑
         * @param {object} [param0]
         * @param {number} param0.currentPage 当前页吗
         * @param {number} param0.onePageSize 一页个数
         * @returns {Promise<object|[*]>} 成功要返回含有总数(sum)的数据对象或数据表
         * @example return {sum: 20, dataList: []}
         */
        async loadPage({ currentPage, onePageSize } = {}) {},
        /**
         * 刷新翻页数据
         * @desc 只刷新数据
         * @returns {Promise<void>} 失败抛错
         */
        refreshData(...params) {
            return this.refreshPage(...params);
        },
        clearTurnPage() {
            this.currentPage = 1;
            this.dataList_ = [];
            this.sumDatas = {};
        },
        /**
         * 刷新翻页数据
         * @desc 初始化页码重新加载(类似于refresh函数)
         * @desc 该操作会清空页码数据
         * @returns {Promise<void>} 失败抛错
         */
        async refreshPage() {
            this.loading_ = true;
            this.refreshDataLoading = true;
            this.clearTurnPage();
            await this.$nextTick();
            const res = await this.loadPage({
                currentPage: this.currentPage,
                onePageSize: this.onePageSize_,
            }).catch((err) => {
                this.loading_ = false;
                this.refreshDataLoading = false;
                throw err;
            });
            this._setPageDataList(res);
            this.$nextTick(() => {
                this.loading_ = false;
                this.refreshDataLoading = false;
            });
            return this.afterLoadPage();
        },
        /**
         * 更新翻页数据
         * @desc 该操作不会清空页码和缓存数据
         * @returns {Promise<void>} 失败抛错
         */
        async updatePage() {
            this.loading_ = true;
            this.refreshDataLoading = true;
            await this.$nextTick();
            const res = await this.loadPage({
                currentPage: this.currentPage,
                onePageSize: this.onePageSize_,
            }).catch((err) => {
                this.loading_ = false;
                this.refreshDataLoading = false;
                throw err;
            });
            this._setPageDataList(res);
            this.$nextTick(() => {
                this.loading_ = false;
                this.refreshDataLoading = false;
            });
            return this.afterLoadPage();
        },
        /**
         * 加载页面后操作
         * @returns {Promise<void>}
         */
        async afterLoadPage() {
            return this.$super(SUPER).afterLoadPage();
        },
        /**
         * 加载下一页函数
         * @returns {Promise<void>} 失败抛错
         */
        nextPage() {
            return new Promise((resolve, reject) => {
                this.currentPage++;
                this.isExist__(
                    () => this.error__ || !this.loading_,
                    () => {
                        if (this.error__) {
                            reject(this.error__);
                        } else {
                            resolve();
                        }
                    }
                );
            });
        },
        /**
         * 加载上一页函数
         * @returns {Promise<void>} 失败抛错
         */
        prevPage() {
            return new Promise((resolve, reject) => {
                this.currentPage--;
                this.isExist__(
                    () => this.error__ || !this.loading_,
                    () => {
                        if (this.error__) {
                            reject(this.error__);
                        } else {
                            resolve();
                        }
                    }
                );
            });
        },
        /**
         * 加载最后一页函数
         * @returns {Promise<void>} 失败抛错
         */
        lastPage() {
            return new Promise((resolve, reject) => {
                this.currentPage =
                    Math.ceil(this.sumCount / this.onePageSize_) - 1;
                this.isExist__(
                    () => this.error__ || !this.loading_,
                    () => {
                        if (this.error__) {
                            reject(this.error__);
                        } else {
                            resolve();
                        }
                    }
                );
            });
        },
        /**
         * 缓存翻页
         * @param {number} page 跳转的页码
         * @returns {Promise<[object]>} 缓存页列表
         */
        async cacheGoPage(page) {
            return this.sumDatas[page];
        },
        /**
         * 跳往指定页码
         * @see 调用加载函数,设置数据
         * @see 此函数仅设置预设的dataList_,如果props.dataList需要双向,请在子混入中调用this.sync__绑定
         * @returns {Promise<void>} 失败抛错
         */
        async goPage(page, isCache = true) {
            if (page < 1) {
                page = 1;
            }
            let oldPage = this.currentPage;
            // 预设值(防止重写loadPage直接使用this.currentPage)
            this.currentPage = page;
            // 判断是否有缓存列表
            if (!_.isEmpty(this.sumDatas[page]) && isCache) {
                this.loading_ = "翻页中";
                setTimeout(async () => {
                    this.dataList_ = await this.cacheGoPage(page).catch(
                        (err) => {
                            this.loading_ = false;
                            this.errHand__(err);
                            throw err;
                        }
                    );
                    this.loading_ = false;
                }, 100);
            } else if (!this.isFinish || this.currentPage === 1) {
                this.loading_ = "翻页中";
                const res = await this.loadPage({
                    currentPage: this.currentPage,
                    onePageSize: this.onePageSize,
                }).catch((err) => {
                    this.loading_ = false;
                    throw err;
                });

                // 设置数据
                if (this._setPageDataList(res) === false) {
                    // 设置出错,页码恢复
                    this.currentPage = oldPage;
                }
                await this.afterLoadPage().catch((err) => {
                    throw err;
                });
                this.loading_ = false;
            } else {
                // 未请求,页码恢复
                this.currentPage = oldPage;
            }
            this.nextPageLoading = false;
        },
        /**
         * 设置一页数据
         * @param {object|[object]} res 数据对象
         * @returns {boolean|void} false:设置出错|阻断翻页
         */
        _setPageDataList(res) {
            if (!res) {
                new kq.Err({
                    title: "返回数据异常",
                    message:
                        "loadPage函数应该返回数据表或包含总个数(sum)的对象(比如:{sum: 20, dataList}),这样才能设置是否完成总数请求",
                    current: res,
                    console: false,
                    from: "kqNextPageMixin.methods.nextPage",
                });
                return false;
            }
            let dataList = [];
            if (_.isArray(res)) {
                this.sumCount = res.length;
                dataList = res;
            } else if (_.isPlainObject(res)) {
                this.sumCount = res.sum || 0;
                if (!_.isEmpty(res.dataList)) {
                    dataList = res.dataList;
                }
            } else {
                this.errHand__(
                    new Error(
                        `[${
                            this.$options.name
                        }-dataInit]返回参数错误!只能返回数组或者对象!当前${JSON.stringify(
                            res
                        )}`
                    )
                );
                return false;
            }
            if (this.currentPage === 1) {
                this.dataList_ = [];
            }
            this.sumDatas[this.currentPage] = dataList;
            if (this.pageMode === "concat") {
                this.dataList_ = this.dataList_.concat(dataList);
            } else {
                this.dataList_ = dataList;
            }
        },
    },
};
</script>
