<script>
/**
 * 弹层使用混入组件
 * @desc 该组件用于使用弹层进行二次封装时使用
 * @desc 内部公共了一些props变量,visible双向绑定何常用的弹层的公共方法
 * @author tonny
 * @date 2022-04-09
 */
</script>

<script>
export default {
    props: {
        /** 弹层配置(与dialigProps一致(兼容性)) */
        dialogConfig: {
            type: Object,
            default: () => ({}),
        },
        /** 弹层配置(与dialogConfig一致(兼容性)) */
        dialogProps: {
            type: Object,
            default: () => ({}),
        },
        /** 弹层事件监听器(放入此对象的函数只监听弹层事件,不会出现与插槽组件混淆的情况) */
        dialogListeners: {
            type: Object,
            default: () => ({}),
        },
        /** 弹层是否可见 */
        visible: Boolean,
    },
    data() {
        return {
            visible_: null,
            dialog: {
                cancelBtn: {
                    name: "取消",
                    round: true,
                    size: "small",
                    type: "null",
                    click: this.close,
                },
                submitBtn: {
                    name: "确定",
                    round: true,
                    size: "small",
                    type: "primary",
                    click: this.submit,
                },
                btnList: [],
            },
        };
    },
    created() {
        this.sync__("visible");
        this.dialog.btnList.push(this.dialog.cancelBtn, this.dialog.submitBtn);
    },
    methods: {
        open() {
            this.visible_ = true;
        },
        close() {
            this.visible_ = false;
        },
        submit() {
            this.$emit("submit");
            this.close();
        },
    },
};
</script>
