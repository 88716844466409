import Base from "../base";

export default class User extends Base {
    /**
     * 获取个人签名
     * @returns {Promise<string>} 成功返回个人签名,失败抛错
     */
    getPersonalSignature() {
        return this.request("/api/common/dict/queryMySign", arguments[0]);
    }
    /**
     * 保存个人签名
     * @param {object} param 参数对象
     * @param {string} param.signature 签名链接
     * @returns {Promise<void>} 失败抛错
     */
    async savePersonalSignature({ signature }) {
        if (!signature) {
            throw new kq.Err({
                message: "签名链接(signature)不能少",
                current: arguments[0],
                from: "kq.Request.User.savePersonalSignature",
            });
        }
        return this.request(
            {
                url: "/api/common/dict/saveMySign",
                method: "post",
                data: {
                    url: signature,
                },
            },
            arguments[0]
        );
    }
}
