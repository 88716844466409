/**
 * 功能相关接口封装
 */

import View from "./View.js";

export default class Function extends View {
    /**
     * 获取功能点信息列表
     * @param {object} param0 入参对象
     * @param {[number]} fidList 功能点号列表
     * @param {boolean} [isConvertSelect] 是否转换为select组件的数据格式
     * @returns {Promise<[object]>} 成功返回功能点列表,失败抛错
     */
    getFunctionList({ fidList, isConvertSelect }) {
        if (kq.Tools.isEmpty(fidList)) {
            throw new kq.Err({
                message: "功能点号fidList列表不能为空",
                from: "kqRequest.View.getViewList",
                console: false,
                current: arguments[0],
            });
        }
        let compares = {
            fid: "funcId",
            name: "name",
            vid: "viewId",
        };
        if (isConvertSelect) {
            compares = {
                value: "funcId",
                label: "name",
            };
        }
        return this.request(
            {
                url: "/api/commonService/queryFuncList",
                method: "post",
                data: {
                    fids: fidList,
                },
                convert: {
                    isStrict: isConvertSelect,
                    compares,
                },
            },
            arguments[0]
        );
    }
    /**
     * 保存低码配置
     * @param {object} param 参数对象
     * @param {number} param.fid 功能点号(必须和视图号至少传一个)
     * @param {number} param.vid 视图号(必须和功能点号至少传一个)
     * @param {[object]} lowcodeList
     * @returns {Promise<void>}
     */
    async saveLowcode({ vid, fid, lowcodeList = [] }) {
        if (!vid && !fid) {
            throw new kq.Err({
                message: "功能点号(fid)与视图号(vid)不能都为空",
                current: arguments[0],
                from: "kqRequest.Function.saveLowcode",
            });
        }
        if (!fid) {
            const vinfo = await this.getViewInfo({ vid }).catch((err) => {
                throw err;
            });
            fid = vinfo.fid;
        }
        return this.request({
            url: "/api/app/funcLowCode/update",
            method: "post",
            data: {
                funcId: fid,
                lowCode: encodeURI(JSON.stringify(lowcodeList || [])),
            },
        });
    }
    /**
     * 是否锁定状态
     * @param {object} param 参数对象
     * @param {number} [param.rid] 经历号
     * @param {number} param.fid 功能点号
     * @param {number} param.uid 人员号
     * @returns {Promise<boolean>} 失败抛错,成功:ture-锁定状态
     */
    async isLock({ rid, fid, uid }) {
        if (!fid || !uid) {
            throw new kq.Err({
                message: "功能点号(fid)与人员号(uid)不能为空",
                current: arguments[0],
                from: "kqRequest.Function.getLock",
            });
        }
        if (!rid) {
            rid = undefined;
        }
        const result = await this.request(
            {
                url: "/api/common/dict/queryLockRecordStatus",
                method: "post",
                data: {
                    billId: rid,
                    funcId: fid,
                    userId: uid,
                },
            },
            arguments[0]
        ).catch((err) => {
            throw err;
        });
        return Boolean(Number(result));
    }
    /**
     * 解锁
     * @param {object} param 参数对象
     * @param {number} [param.rid] 经历号
     * @param {number} param.fid 功能点号
     * @param {number} param.uid 人员号
     * @returns {Promise<void>} 失败抛错
     */
    async unlock({ rid, fid, uid }) {
        if (!fid || !uid) {
            throw new kq.Err({
                message: "功能点号(fid)与人员号(uid)不能为空",
                current: arguments[0],
                from: "kqRequest.Function.getLock",
            });
        }
        if (!rid) {
            rid = undefined;
        }
        await this.request(
            {
                url: "/api/common/dict/updateLockRecord",
                method: "post",
                data: {
                    billId: rid,
                    funcId: fid,
                    userId: uid,
                },
            },
            arguments[0]
        ).catch((err) => {
            throw err;
        });
    }
    /**
     * 获取功能点信息
     * @param  {...any} args 功能点参数(参照getFinfo)
     * @returns {Promise<object>} 成功返回功能点信息对象,失败抛错
     */
    getFunctionInfo(...args) {
        return this.getFinfo(...args);
    }
    /**
     * 获取功能点信息
     * @param {object} param 参数对象
     * @param {number} [param.fid] 功能点号(不能与vid,vlabel同时为空)
     * @param {number} [param.vid] 视图号(不能与vid,vlabel同时为空)
     * @param {number} [param.vlabel] 视图别名(不能与vid,vlabel同时为空)
     * @returns {Promise<object>} 成功返回功能点信息对象,失败抛错
     * @example {fid<number>:功能点号,vid<number>:视图号,fname<string>:功能点名称,tid<number>:功能点所属样张号,appid<number>:功能点所属应用号,ftype<number>:功能点所属模板id,disabled<boolean>:功能点是否禁用,isSubmit<boolean>:功能点是否支持提交, ...}
     */
    async getFinfo({ fid, vid, vlabel }) {
        if (!fid && !vid && !vlabel) {
            throw new kq.Err({
                message: "查询功能点信息时,功能点号(fid),视图号(vid),视图别名(vlabel)不能同时为空",
                from: "kq.Request.Function.getFinfo",
                current: arguments[0],
            });
        }
        if (!fid) {
            const vinfo = await this.getViewInfo({ vid, vlabel }).catch((err) => {
                throw err;
            });
            fid = vinfo.fid;
            if (!fid) {
                return vinfo;
            }
        }
        return this.request(
            {
                url: "/api/app/funcView",
                params: {
                    nodeId: fid,
                },
                convert: {
                    compares: {
                        vid: "funcInfo.relatedView",
                        fid: "funcInfo.nodeId",
                        fname: "funcInfo.funcName",
                        appid: "funcInfo.appId",
                        ftype: "funcInfo.funcTempType",
                        help: "funcInfo.helpText",
                        video: "funcInfo.videoUrl",
                        permissionId: "funcInfo.authorityId",
                        tid: "funcInfo.tempId",
                        disabled: "funcInfo.isShow",
                        isSubmit: "funcInfo.isSubmit",
                        lowcodeList: "funcInfo.lowCode",
                        linkageList: "funcInfo.lowCodeLayout",
                        path: "funcInfo.path",
                        component: "funcInfo.component",
                        dataAuth: "funcInfo.dataAuthority",
                    },
                    convertValueHook({ to, value }) {
                        if (to === "isSubmit") {
                            return Boolean(Number(value));
                        }
                        if (to === "disabled") {
                            return !Boolean(Number(value));
                        }
                        if (["lowcodeList", "linkageList"].includes(to)) {
                            /** 转换后的值 */
                            let convertValue = "";
                            try {
                                convertValue = JSON.parse(decodeURI(value));
                            } catch (error) {
                                console.error(error);
                                new kq.Err({
                                    title: "JSON转换出错",
                                    typpe: "DATA",
                                    message: "当前配置数据不是标准的JSON格式,请联系管理员",
                                    from: "/api/app/funcView",
                                    current: decodeURI(value),
                                });
                            }
                            return convertValue;
                        }
                        return value;
                    },
                },
            },
            arguments[0]
        );
    }
    /**
     * 获取功能点列表
     * @param {object} param0 入参对象
     * @param {[number]} param0.fidList 功能点号列表
     * @returns {Promise<object>} 成功返回功能点列表,失败抛错
     */
    async getFunctionInfoList({ fidList }) {
        if (kq.Tools.isEmpty(fidList)) {
            return [];
        }
        return this.request({
            url: "/api/app/queryFuncByIds",
            params: {
                fids: fidList.join(","),
            },
            convert: {
                compares: {
                    fid: "nodeId",
                    fname: "funcName",
                    appid: "appId",
                },
            },
        });
    }
}
