/**
 * @description jquery事件添加
 * @see 在此处自定义jquery事件
 * @see 1.domresize:dom的宽高变化 参数:{width<Number>: 最新宽度, height<Number>: 最新高度, oldWidth<Number>: 上一次宽度, oldHeight<Number>: 上一次高度}
 * @author tonny
 * @date 2021-10-24
 */

import "./style.less";
import $ from "jquery";

(function() {
    /**
     * dom宽高变化事件
     * @see 当dom的宽高变化时激活
     * @param {object} 长度对象{width<Number>: 最新宽度, height<Number>: 最新高度, oldWidth<Number>: 上一次宽度, oldHeight<Number>: 上一次高度}
     */
    $.event.special.domresize = {
        remove: function() {
            $(this)
                .children("iframe.width-changed")
                .remove();
        },
        add: function() {
            const elm = $(this);
            let iframe = elm.children("iframe.width-changed");
            if (!iframe.length) {
                iframe = $("<iframe/>")
                    .addClass("width-changed")
                    .prependTo(this);
            }
            let oldWidth = elm.width();
            let oldHeight = elm.height();
            function elmResized() {
                const width = elm.width();
                const height = elm.height();
                if (oldWidth != width || oldHeight != height) {
                    elm.trigger("domresize", {
                        width,
                        height,
                        oldWidth,
                        oldHeight,
                    });
                    oldWidth = width;
                    oldHeight = height;
                }
            }

            let timer = 0;
            const ielm = iframe[0];
            (ielm.contentWindow || ielm).onresize = function() {
                // 防抖
                clearTimeout(timer);
                timer = setTimeout(elmResized, 20);
            };
        },
    };
    /**
     * 鼠标滚轮事件
     * @emits mouseup 向上滚动
     * @emits mousedown 向下滚动
     */
    $.event.special.wheelup = {
        remove: function() {
            $(this).off("mousewheel DOMMouseScroll");
        },
        add: function() {
            const $el = $(this);
            $el.on("mousewheel DOMMouseScroll", function(event) {
                const delta =
                    (event.originalEvent.wheelDelta &&
                        (event.originalEvent.wheelDelta > 0 ? 1 : -1)) || // chrome & ie
                    (event.originalEvent.detail &&
                        (event.originalEvent.detail > 0 ? -1 : 1)); // firefox
                if (delta > 0) {
                    $el.trigger("wheelup");
                } else if (delta < 0) {
                    $el.trigger("wheeldown");
                }
            });
        },
    };
    $.fn.extend({
        /**
         * 是否存在滚动条判断
         * @returns {boolean}
         */
        hasScroll(direction = "hortical") {
            const dom = this[0];
            if (!dom) {
                return false;
            }
            if (direction === "hortical") {
                return dom.scrollWidth > dom.clientWidth;
            }
            return dom.scrollHeight > dom.clientHeight;
        },
    });
})();
