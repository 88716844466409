var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"v-toolbars-paramconfig-value"},[_c('kq-card-type',{class:{
            'v-toolbars-paramconfig-value-baseinfo': true,
            'v-toolbars-paramconfig-value-baseinfo--no-value':
                !_vm.valueFieldList,
        },attrs:{"name":"基础信息","expand-disabled":""}},[_c('kq-inputcell-group',{ref:"baseGroup",attrs:{"data-list":_vm.baseInfoList}},_vm._l((_vm.formatBaseInfoList),function(field){return _c('kq-inputcell2',_vm._b({key:field.zid,attrs:{"theme":"white","convert":false,"svalue":field.from.svalue,"readonly":field.readonly || _vm.readonly || _vm.infoReadonly},on:{"update:svalue":function($event){return _vm.$set(field.from, "svalue", $event)},"change":function($event){return _vm.$emit('change', $event)}},model:{value:(field.from.value),callback:function ($$v) {_vm.$set(field.from, "value", $$v)},expression:"field.from.value"}},'kq-inputcell2',field,false))}),1)],1),(_vm.value !== undefined)?_c('kq-card-type',{class:{
            'v-toolbars-paramconfig-value-valueinfo': true,
            'v-toolbars-paramconfig-value-valueinfo--array':
                _vm.valueField.type === 'array',
        },attrs:{"name":"值","expand-disabled":""}},[_c('kq-inputcell-group',{ref:"valueGroup"},[_vm._l((_vm.valueFieldList),function(field){return [(field.type !== 'array')?_c('kq-inputcell2',_vm._b({key:field.zid,attrs:{"theme":"white","svalue":field.from.svalue,"qrcode-disabled":"","upload-config":_vm.uploadConfig,"readonly":field.readonly || _vm.readonly},on:{"update:svalue":function($event){return _vm.$set(field.from, "svalue", $event)},"change":function($event){return _vm.$emit('change', $event)}},model:{value:(field.from.value),callback:function ($$v) {_vm.$set(field.from, "value", $$v)},expression:"field.from.value"}},'kq-inputcell2',field,false)):_c('v-inputcell-array',{key:field.zname,staticClass:"kq-paramconfig-value__value-array",attrs:{"upload-config":_vm.uploadConfig,"readonly":field.readonly || _vm.readonly},on:{"change":function($event){return _vm.$emit('change', $event)}},model:{value:(field.from.value),callback:function ($$v) {_vm.$set(field.from, "value", $$v)},expression:"field.from.value"}})]})],2)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }