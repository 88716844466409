/* eslint-disable */
/**
 * 编辑权限验证参数表
 * @see 根据此参数表来判断各个模板的编辑权限
 * @see lock:true 参照锁定状态(锁定后不具有编辑权限)
 * @see process:flase|null 不参照流程提交状态(null表示不存在对应业务情况)
 * @see approve:true 参照审批状态(已审批后不支持编辑)
 * @see process: "delete" 只有删除参照
 * @author tonny
 * @date 2022-01-11
 */

import { FtypeCompares, DataAuth } from "kooci-lib/src/PubComponents/global";
import _ from "lodash";

const Config = {
    /**
     * 获取编辑的禁用状态
     * @param {object} param 参数表
     * @param {number} param.ftype 功能模板类型
     * @param {number} param.dataAuth 数据权限
     * @param {boolean} [param.isLock] 是否锁定
     * @param {string|boolean} [param.approve] 是否已审批
     * @param {boolean} [param.isAdmin] 是否是管理员
     * @param {boolean} [param.isProcess] 是否进入流程
     * @returns {boolean|string} 禁用返回禁用原因字符串,不禁用返回false
     */
    getEditDisable(param) {
        const convertAuth = paramConvert(param);
        const compares = {
            lock: param.isLock,
            process: param.isProcess,
            approve: param.isApprove,
        };
        const mode = param.mode || "write";
        if (!convertAuth) {
            return false;
        }
        const editEnable = convertAuth.editEnable;
        /** 锁定提示列表 */
        const disabledStrList = [];
        _.forEach(editEnable, (auth, name) => {
            if (auth && auth.includes(mode) && compares[name]) {
                let str = "";
                switch (name) {
                    case "lock":
                        str = "已锁定";
                        break;
                    case "process":
                        str = "已进入流程";
                        break;
                    case "approve":
                        str = "已审批";
                        break;
                    default:
                        break;
                }
                str && disabledStrList.push(str);
            }
        });
        if (!_.isEmpty(disabledStrList)) {
            return disabledStrList.join(",");
        }
        return false;
    },
    /** 普通-人员详情 */
    [FtypeCompares.N_MAINTAIN]: {
        [DataAuth.PERSON]: {
            editEnable: {
                lock: true,
                process: null,
                approve: null,
            },
        },
        [DataAuth.DEPART]: {
            editEnable: {
                lock: true,
                process: null,
                approve: null,
            },
        },
        [DataAuth.SCHOOL]: {
            editEnable: {
                lock: true,
                process: null,
                approve: null,
            },
        },
    },
    /** 普通-人员列表 */
    [FtypeCompares.N_PERSONS]: {
        [DataAuth.PERSON]: {
            editEnable: {
                lock: true,
                process: null,
                approve: null,
            },
        },
        [DataAuth.DEPART]: {
            editEnable: {
                lock: true,
                process: null,
                approve: null,
            },
        },
        [DataAuth.SCHOOL]: {
            editEnable: {
                lock: true,
                process: null,
                approve: null,
            },
        },
    },
    /** 行集-申请 */
    [FtypeCompares.R_APPLY]: {
        [DataAuth.PERSON]: {
            editEnable: {
                lock: true,
                process: true,
                approve: true,
            },
        },
        [DataAuth.DEPART]: {
            editEnable: {
                lock: true,
                process: true,
                approve: true,
            },
        },
        [DataAuth.SCHOOL]: {
            editEnable: {
                lock: true,
                process: true,
                approve: true,
            },
        },
    },
    /** 行集-审批(终审) */
    [FtypeCompares.R_APPROVE]: {
        [DataAuth.PERSON]: {
            editEnable: {
                lock: true,
                process: true,
                approve: true,
            },
        },
        [DataAuth.DEPART]: {
            editEnable: {
                lock: true,
                process: false,
                approve: null,
            },
        },
        [DataAuth.SCHOOL]: {
            editEnable: {
                lock: true,
                process: false,
                approve: null,
            },
        },
    },
    /** 行集-审核 */
    [FtypeCompares.R_REVIEW]: {
        [DataAuth.PERSON]: {
            editEnable: {
                lock: true,
                process: true,
                approve: true,
            },
        },
        [DataAuth.DEPART]: {
            editEnable: {
                lock: true,
                process: false,
                approve: null,
            },
        },
        [DataAuth.SCHOOL]: {
            editEnable: {
                lock: true,
                process: false,
                approve: null,
            },
        },
    },
    /** 行集-汇总 */
    [FtypeCompares.R_SUMMARY]: {
        [DataAuth.PERSON]: {
            editEnable: {
                lock: true,
                process: true,
                approve: true,
            },
        },
        [DataAuth.DEPART]: {
            editEnable: {
                lock: true,
                process: false,
                approve: false, // 只有删除参照
                admin: {
                    lock: true,
                    process: false,
                    approve: false,
                },
            },
        },
        [DataAuth.SCHOOL]: {
            editEnable: {
                lock: true,
                process: false,
                approve: false,
                admin: {
                    lock: true,
                    process: false,
                    approve: false,
                },
            },
        },
    },
    /** 行集-管理员审核 */
    [FtypeCompares.R_ADMIN_APPLY]: {
        [DataAuth.PERSON]: {
            editEnable: {
                lock: true,
                process: true,
                approve: true,
            },
        },
        [DataAuth.DEPART]: {
            editEnable: {
                lock: true,
                process: true,
                approve: true,
            },
        },
        [DataAuth.SCHOOL]: {
            editEnable: {
                lock: true,
                process: true,
                approve: true,
            },
        },
    },
    [FtypeCompares.REVIEW_TRANSFER_READED]: null,
};

/**
 * 参数转换
 * @param {object} param 参数对象
 * @param {number} ftype 功能模板类型
 * @param {number} dataAuth 数据权限
 * @param {boolean} isAdmin 是否为管理员
 * @returns {boolean|object} false:参数转换失败 object:返回功能模板对应数据权限配置对象
 */
function paramConvert({ ftype, dataAuth, isAdmin }) {
    if (ftype === undefined || ftype === null) {
        console.warn(
            new kq.Err({
                message: "功能点类型(ftype)与数据权限(dataAuth)不能为空",
                current: arguments[0],
                from: "FunctionView.Validate.getEdotDisable",
                console: false,
            }).message
        );
        return false;
    } else {
        /** 模板类型配置 */
        const func = Config[ftype];
        if (func) {
            /** 数据权限类型配置 */
            const auth = _.cloneDeep(func[dataAuth]);
            if (auth) {
                const editEnable = auth.editEnable;
                if (editEnable) {
                    const { admin } = editEnable;
                    // 管理员权限:设置管理状态
                    if (isAdmin && admin && admin !== true) {
                        editEnable.lock = admin.lock;
                        editEnable.process = admin.process;
                        editEnable.approve = admin.approve;
                    }
                    // 遍历进行转换
                    _.forEach(editEnable, (auth, name) => {
                        if (auth === true) {
                            editEnable[name] = ["write", "delete"];
                        } else if (_.isString(auth)) {
                            editEnable[name] = [auth];
                        }
                    });
                    delete editEnable.admin;
                    return auth;
                } else {
                    console.warn("编辑权限验证:可用配置不存在~");
                }
            } else {
                console.warn("编辑权限验证:数据权限配置不存在~", dataAuth);
            }
        } else {
            console.warn("编辑权限验证:功能点类型配置不存在~", ftype);
        }
    }
}

export default Config;
