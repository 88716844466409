import { render, staticRenderFns } from "./Column.vue?vue&type=template&id=0851b10a&scoped=true"
import script from "./Column.vue?vue&type=script&lang=js"
export * from "./Column.vue?vue&type=script&lang=js"
import style0 from "./Column.vue?vue&type=style&index=0&id=0851b10a&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../kooci-saas-recruit/node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0851b10a",
  null
  
)

export default component.exports