/**
 * 缓存延迟倒计时类
 * @desc 提供一个使用缓存的倒计时器,重新实例化后会进行上次的倒计时
 * @desc 通常用作发送手机验证码
 * @author tonny
 * @date 2022-10-18
 */

import Cache from "./cache";
import moment from "moment";

export default class CacheInterval {
    constructor({
        start = 0,
        end = 60,
        interval = 1000,
        intervalHandler,
        endHandler,
    } = {}) {
        this._start = start;
        this._end = end;
        this._interval = interval;
        this._intervalHandler = intervalHandler;
        this._endHandler = endHandler;
        this._cache = new Cache({ key: "CacheInterval", uid: "kq", days: 1 });
        this._timer = null;
    }
    /**
     * 开始倒计时
     * @returns void
     */
    start(isImmediate = true) {
        clearInterval(this._timer);
        let tempInterval = this._start;
        const cacheTime = this._cache.get("endTime");
        if (cacheTime && moment(cacheTime).isAfter(moment())) {
            tempInterval = moment(cacheTime).diff(moment(), "seconds");
        } else {
            this._cache.set(
                "endTime",
                moment().add(this._start, "s").format("YYYY-MM-DD HH:mm:ss")
            );
        }
        if (isImmediate && _.isFunction(this._intervalHandler)) {
            this._intervalHandler(tempInterval);
        }
        this._timer = setInterval(() => {
            if (tempInterval - 1 < this._end) {
                if (_.isFunction(this._endHandler)) {
                    this._endHandler();
                    this.stop();
                }
            } else {
                if (_.isFunction(this._intervalHandler)) {
                    this._intervalHandler(tempInterval);
                }
                tempInterval--;
            }
        }, this._interval);
    }
    /**
     * 结束倒计时
     * @returns void
     */
    stop() {
        this._cache.delete("endTime");
        clearInterval(this._timer);
        this._timer = null;
    }
    /**
     * 是否结束了倒计时
     * @returns {boolean} true:结束了
     */
    isOver() {
        const cacheTime = this._cache.get("endTime");
        return !cacheTime || moment(cacheTime).isBefore(moment());
    }
}
