/**
 * js脚本插件
 * @see 在详情页与列表页实例化
 * @see 用来获取自定义按钮表和设置标准按钮的钩子函数用以支持脚本文件的个性化
 * @author tonny
 * @date 2021-10-20
 */

import LoadScript from "vue-plugin-load-script";
import _ from "lodash";
export default class Plugin {
    /**
     *
     * @param {object} options 配置对象或vue组件实例
     * @param {object} options.vm vue组件实例
     * @param {string} [options.pluginBaseURI] 插件存储的基础前缀
     * @param {string|number} [options.fid] 功能点号(默认从地址栏获取)
     * @param {string|number} [options.appId] appid(默认从地址栏和本地缓存获取)
     * @param {boolean} [options.permissionDisabled] 关闭权限验证(true:关闭)
     */
    constructor(options) {
        const {
            vm,
            pluginBaseURI,
            fid,
            appId,
            permissionDisabled,
        } = this._paramConvert(options);
        console.log("构造......");
        /** vue组件实例对象 */
        this.vm = vm;
        /** 数据请求对象 */
        this.data_ = kq.Request.mixin({ vm: this.vm, modules: ["system"] });
        /** 功能点号 */
        this._fid = fid;
        if (!this._fid) {
            let res = location.href.match(/f?id=(\d+)/);
            if (res) {
                this._fid = res[1];
            }
        }
        /** 关闭权限验证 */
        this.permissionDisabled = permissionDisabled;
        /** 功能号 */
        this._appId = appId;
        /** 脚本地址 */
        this._pluginBaseURI =
            pluginBaseURI ||
            `https://kooci-jsplugin.oss-cn-hangzhou.aliyuncs.com`;
        /** 插件对象 */
        this._plugin = null;
        /** 权限标识 */
        this._permissionId = 0;
        /** 是否超级管理员 */
        this._isAdmin = false;
        /** 是否应用管理员 */
        this._isAppAdmin = false;
        /** 自定义按钮列表 */
        this.customBtns = {};
        /** 系统预置钩子按钮集 */
        this.customHookBtns = {};
        // 初始化
        this._init();
    }
    /**
     * 初始化
     * @see 自动请求当前的自定义脚本
     * @returns void
     */
    _init() {
        Promise.all([this.getCustomBtns(), this.getHookBtns()])
            .then(([customBtns, hookBtns]) => {
                this.customBtns = customBtns || {};
                this.customHookBtns = hookBtns || {};
            })
            .catch(() => {});
    }
    /**
     * 合并自定义按钮与预制按钮钩子
     * @see 函数会自动筛选掉按钮最外层disabled为true的按钮
     * @see 强烈建议使用此函数来在组件的computed中来配置按钮
     * @param {string} type 按钮类型
     * @param {[object]} [systemBtnList] 系统按钮列表
     * @returns {[object]} 组合后的按钮列表
     */
    mergeCustomBtn(type, systemBtnList = []) {
        if (!type) {
            throw new kq.Err({
                title: "参数出错",
                message: "按钮类型(type)不能为空",
                from: "kq.Plugin.static.mergeBtn",
            });
        }
        const errBtn = systemBtnList.find(
            ({ name, onClick }) => !name || !_.isFunction(onClick)
        );
        if (errBtn) {
            throw new kq.Err({
                title: "参数错误",
                message:
                    "系统预置按钮不为空时,每一项必须包含按钮名称(name)与点击函数(onClick)",
                from: "kq.Plugin.static.mergeBtn",
                current: errBtn.name,
            });
        }
        let resultList = systemBtnList || [];
        // 与钩子按钮集合并
        resultList = resultList.map(btn => {
            const key = `${type}/${btn.name}`;
            const item = this.customHookBtns[key];
            if (item) {
                return {
                    ...btn,
                    ...item,
                    onClick: this.on({
                        key,
                        onClick: btn.onClick,
                    }), // 注册钩子事件
                };
            }
            return {
                style: "",
                className: "",
                ...btn,
                props: {
                    loading: false,
                    type: "primary",
                    ...(btn.props || {}),
                },
            };
        });
        // 与自定义按钮合并
        if (!_.isEmpty(this.customBtns[type])) {
            resultList = resultList.concat(this.customBtns[type]);
        }
        return (
            resultList
                // 筛选外层禁用的
                .filter(({ disabled }) => !disabled)
                // 排序
                .sort(({ sort: sort1 }, { sort: sort2 }) => {
                    sort1 === undefined && (sort1 = 99);
                    sort2 === undefined && (sort2 = 100);
                    return sort1 - sort2;
                })
        );
    }
    /**
     * 获取指定类型按钮数据表
     * @param {string} type 按钮类型
     * @returns {[object]}
     */
    async getCustomBtnList(type) {
        if (!type || !_.isString(type)) {
            throw new kq.Err({
                title: "参数错误",
                message:
                    "按钮类型(type)必须为非空字符串,如果想获取所有自定义按钮,调用getCustomBtns即可",
                from: `${this.contructor.name}.getCustomBtnList`,
                current: type,
            });
        }
        const results = await this.getCustomBtns();
        return results[type] || [];
    }
    /**
     * 获取所有按钮对象
     * @returns {Promise<object>} 成功返回按钮对象
     */
    async getCustomBtns() {
        let isCancel;
        let results = {};
        const plugin = await this._loadPlugin().catch(err => {
            isCancel = true;
        });
        if (!isCancel) {
            const btnList = plugin.customBtnList;
            btnList.forEach(btn => {
                btn.disabled =
                    btn.disabled || !this._checkPermission(btn.permission);
            });
            results = _.groupBy(btnList, "type");
        }
        return results || {};
    }
    /**
     * 获取钩子按钮集
     * @returns {[object]} 所有系统预置按钮钩子
     */
    async getHookBtns() {
        let isCancel;
        const plugin = await this._loadPlugin().catch(err => {
            isCancel = true;
        });
        if (!isCancel) {
            return plugin.hookBtns;
        }
        return {};
    }
    /**
     * 获取配置信息
     * @returns {object}
     */
    async getConfig() {
        const plugin = await this._loadPlugin().catch(() => {});
        if (plugin) {
            return plugin.getConfig();
        }
        return {};
    }
    /**
     * 绑定预置按钮点击事件钩子
     * @param {object} options 配置
     * @param {object} options.btn 按钮vue实例
     * @param {string} options.name 按钮名称
     * @param {function} options.onClick 按钮点击事件
     * @param {string} type 按钮类型
     * @returns void
     */
    on(options) {
        const { key, onClick } = options;
        if (!key || !_.isFunction(onClick)) {
            throw new kq.Err({
                title: "参数错误",
                message:
                    "参数对象中按钮key值,点击处理回调函数(onClick)不能为空",
                current: arguments[0],
                from: `kq.Plugin.on`,
            });
        }
        if (this._plugin) {
            return this._plugin.on(options);
        }
        return onClick;
    }
    /**
     * 销毁
     * @see 卸载当前功能点脚本,置空当前按钮全局变量
     * @returns void
     */
    destroy() {
        this.vm.$unloadScript(this._scriptUrl);
    }
    errHand_(err) {
        this.vm.errHand_(err);
    }
    /**
     * 参数转换
     * @see 将参数转换为统一格式
     * @param {object} options 构造函数参数
     * @returns {object}
     */
    _paramConvert(options) {
        let result = {};
        const errFrom = "Plugin.constructor",
            errTitle = "参数错误",
            errCurrent = options;
        if (!options) {
            throw new kq.Err({
                title: errTitle,
                from: errFrom,
                message: "构造函数参数不能为空",
            });
        }
        if (_.isPlainObject(options)) {
            if (options.$options !== undefined) {
                result.vm = options;
            } else {
                if (!options.vm) {
                    throw new kq.Err({
                        title: errTitle,
                        message: "当构造参数为非vue示例对象是,vm实例必传",
                        from: errFrom,
                        current: errCurrent,
                    });
                }
                result = options;
            }
        } else {
            result.vm = options;
        }
        return result;
    }
    /**
     * 加载插件
     * @see 加载用户自定义插件
     * @returns {Promise<object>} 成功返回插件,失败抛错
     */
    async _loadPlugin() {
        if (!this._fid) {
            console.warn(
                "kq.Plugin.loadPluin未查询到功能点号(无功能点无视即可)"
            );
            throw new Error("404");
        }
        if (!this._plugin) {
            let plugin;
            if (process.env.NODE_ENV === "development") {
                plugin = this.loadDevPlugin(this._fid);
            } else {
                // 判断是否已经加载过了,避免重复加载出错
                plugin = window[`plugin-${this._fid}`];
                if (!plugin) {
                    let url = await this._getScriptUrl().catch(err => {
                        this.vm.errHand_(err);
                        throw err;
                    });
                    let loadSuccess = true;
                    await this.vm.$loadScript(url).catch(err => {
                        loadSuccess = false;
                    });
                    plugin = window[`plugin-${this._fid}`];
                    if (!loadSuccess || !plugin) {
                        loadSuccess = true;
                        url = await this._getScriptUrl(true).catch(err => {
                            throw err;
                        });
                        await this.vm.$loadScript(url).catch(err => {
                            throw err;
                        });
                    }
                    plugin = window[`plugin-${this._fid}`];
                }
                // 获取导出的默认模块
                if (plugin && plugin.default) {
                    plugin = plugin.default;
                }
            }
            if (plugin) {
                if (!this.permissionDisabled) {
                    await this._setPermission().catch(err => {
                        this.vm.errHand_(err);
                        throw err;
                    });
                }
                this._plugin = new plugin();
                this._plugin.vm = this.vm;
                this._plugin.data_ = this.data_;
            } else {
                throw new Error("404");
            }
        }
        return this._plugin;
    }
    /**
     * 加载调试模式插件
     */
    loadDevPlugin() {
        console.warn(
            "需要重载调试模式的自定义脚本插件,通过kq.Plugin.prototype.loadDevPlugin赋值~"
        );
    }
    /**
     * 设置权限
     * @see 查询系统权限
     * @returns {Promise<void>} 失败抛错,成功设置权限
     */
    async _setPermission() {
        const datas = await Promise.all([
            this.data_.getViewInfo({ fid: this._fid }),
            this.data_.getLoginInfo(),
        ]).catch(err => {
            this.vm.errHand_(err);
            throw err;
        });
        // 权限id,角色列表
        const [[{ authorityId }], { roles: roleList }] = datas;
        /** 应用id */
        const appId =
            this._appId ||
            localStorage.getItem("appId") ||
            location.href.match(/appId=(\d+)/)[1];
        roleList.some(role => {
            const [appId2, authorityId2, permissionId] = role.split(":");
            if (appId2 === "0") {
                this._isAdmin = true;
            } else if (appId2 == appId) {
                this._isAppAdmin = authorityId2 === "0";
                if (authorityId2 === authorityId) {
                    this._permissionId = Number(
                        permissionId.match(/\{(\d+)\}/)[1]
                    );
                }
            }
        });
    }
    /**
     * 检查权限
     * @see 检查当前角色是否具有按钮权限
     * @param {number} permission 权限标识
     * @returns {boolean} true:有权限
     */
    _checkPermission(permission) {
        if (
            this.permissionDisabled ||
            permission === undefined ||
            permission === null ||
            this._isAppAdmin ||
            this._isAdmin
        ) {
            return true;
        }
        return (this._permissionId & permission) == permission;
    }
    /**
     * 获取脚本的链接
     * @param {boolean} isPublic 是否为公用脚本(有些应用的功能会在标准页面的修改,但该应用属于云端应用,就会出现此情况)
     * @returns {string}
     */
    async _getScriptUrl(isPublic) {
        let schoolId = await this.data_.getSchoolId().catch(err => {
            throw err;
        });
        if (isPublic) {
            schoolId = "platform";
        }
        let url;
        if (_.isFunction(this._pluginBaseURI)) {
            url = this._pluginBaseURI();
        } else {
            url = `${this._pluginBaseURI}/${schoolId}/plugin-${
                this._fid
            }.umd.min.js?time=${new Date().getTime()}`;
        }
        return url;
    }
    /**
     * 安装动态加载脚本的插件
     * @param {*} Vue Vue对象
     */
    static install(Vue) {
        // Vue.use(LoadScript);
    }
}
