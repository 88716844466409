import CryptoJS from "crypto-js";
import moment from "moment";

/** 加载秘钥 */
const ENCRYPT_KEY = "ryuewd12";

/**
 * 缓存管理类
 * @description 管理一个缓存机制
 * @description 跟当前登录人员号进行绑定
 * @description 可以调用和localStorage或seesionStirage相同的取值与设置值
 * @description 缓存机制基于
 * @author tonny
 * @date 2022-03-18
 */
export default class Cache {
    /**
     * 缓存管理类
     * @constructor
     * @param {object} param 参数对象
     * @param {string} param.key 主键
     * @param {string} [param.type=localStorage] 缓存方式
     * @param {number} param.uid 人员号
     * @param {number} days 超时天数
     * @param {boolean} [encrypt=false] 是否加密缓存
     */
    constructor({
        key,
        type = "localstorage",
        uid,
        encrypt = false,
        days = 30,
    }) {
        if (key === undefined || uid === undefined || !this.keyValidate(key)) {
            throw new kq.Err({
                message: "缓存关键词(key)与用户号(uid)不能少",
                from: "kq.Cache.constructor",
                current: arguments[0],
            });
        }
        /** 缓存关键字 */
        this._key = key;
        /** 缓存类型 */
        this._type = type.includes("local") ? "localStorage" : "sessionStorage";
        /** 用户号 */
        this._uid = uid;
        /** 是否加密 */
        this._encrypt = encrypt;
        /** 自动删除缓存天数 */
        this._days = days;
        /** 综合数据 */
        this._data = this.init();
    }
    /**
     * 初始化
     * @description 从缓存中重新获取data
     * @returns void
     */
    init() {
        this._data = this._initData();
        this._deleteTimeoutData(this._data);
        return this._data;
    }
    /**
     * 查询是否包含key
     * @param {string|number} key 子集key
     * @returns {boolean} true:包含
     */
    has(key) {
        if (this.keyValidate(key)) {
            return !!this._data[key];
        }
    }
    /**
     * 设置一条子集
     * @param {string} key 追加一条数据
     * @param {*} value 数据值(注意,值中不能包含函数体)
     * @returns void
     */
    set(key, value) {
        if (this.keyValidate(key) && this.valueValidate(value)) {
            const dateKey = `date_${key}`;
            this._data[key] = value;
            this._data[dateKey] = moment().format("YYYY-MM-DD HH:mm:ss");
            this._set();
        }
    }
    /**
     * 设置一条子集
     * @param {string} key 追加一条数据
     * @param {*} value 数据值(注意,值中不能包含函数体)
     * @returns void
     */
    setItem(key, value) {
        return this.set(key, value);
    }
    /**
     * 根据key获取缓存的子集
     * @param {string} key 获取子集的key
     * @returns {*}
     */
    get(key) {
        if (this.keyValidate(key)) {
            return this._data[key];
        }
    }
    /**
     * 根据key获取缓存的子集
     * @param {string} key 获取子集的key
     * @returns {*}
     */
    getItem(key) {
        return this.get(key);
    }
    /**
     * 清空缓存区当前key值
     * @param {string} key 需要删除的子key
     * @returns void
     */
    clear(key) {
        if (key && this._data[key]) {
            this.delete(key);
        } else {
            this._data = {};
            window[this._type].removeItem(this.key);
        }
    }
    /**
     * 删除指定子集key
     * @param {string|number} key 删除指定子集key
     * @returns {*|void} 被删除的元素,key不存在返回undefined
     */
    delete(key) {
        if (this.keyValidate(key)) {
            const val = this._data[key];
            delete this._data[key];
            this._set();
            return val;
        }
    }
    /**
     * 删除指定子集key
     * @param {string|number} key 删除指定子集key
     * @returns {*|void} 被删除的元素,key不存在返回undefined
     */
    removeItem(key) {
        return this.delete(key);
    }
    /**
     * 获取当前的主key
     * @returns {string} 组合后的key
     */
    get key() {
        return `kqCache_${this._uid}_${this._key}`;
    }
    /**
     * 验证key是否合规
     * @param {*} key 子key
     * @returns {boolean} true:验证通过
     */
    keyValidate(key) {
        if (_.isObject(key)) {
            console.error(
                new kq.Err({
                    message: "key参数必须为数值或者字符串类型",
                    from: "kq.Eache.keyValidate",
                    current: key,
                    console: false,
                }).message
            );
            return false;
        }
        return true;
    }
    /**
     * 验证值是否符合缓存要求
     * @param {*} value 目标值
     * @returns {boolean} true:验证成功
     */
    valueValidate(value) {
        let hasFunction = false;
        kq.Tools.forEachDeep(value, (val) => {
            if (_.isFunction(val)) {
                hasFunction = true;
            }
            return false;
        });
        if (hasFunction) {
            new kq.Err({
                message: "值中不可以包含函数体",
                from: "kq.Cache.append",
                current: value,
            });
            return false;
        }
        return true;
    }
    /**
     * 删除超时的数据
     * @param {object} data 缓存数据
     * @returns void
     */
    _deleteTimeoutData(data) {
        if (data && !kq.Tools.isEqual(this._days, 0)) {
            const deleteKeys = [];
            _.forEach(data, (value, key) => {
                /** 原来的key(去除日期前缀) */
                const ckey = key.kqMatch(/^date_(.*)/, 1);
                if (
                    ckey &&
                    moment().isAfter(moment(value).add(this._days, "d"))
                ) {
                    // 两个key都清除
                    deleteKeys.push(ckey);
                    deleteKeys.push(key);
                }
            });
            deleteKeys.forEach((key) => {
                delete data[key];
            });
        }
    }
    /**
     * 加密
     * @param {string} value 加密的值
     * @returns {string}
     */
    _encode(value) {
        return `kooci**${CryptoJS.AES.encrypt(
            value,
            CryptoJS.enc.Utf8.parse(ENCRYPT_KEY),
            {
                mode: CryptoJS.mode.ECB,
                padding: CryptoJS.pad.Pkcs7,
            }
        ).toString()}`;
    }
    /**
     * 解密
     * @param {string} value 解密的值
     * @returns {string}
     */
    _decode(value) {
        const bytes = CryptoJS.AES.decrypt(
            value.slice(7),
            CryptoJS.enc.Utf8.parse(ENCRYPT_KEY),
            {
                mode: CryptoJS.mode.ECB,
                padding: CryptoJS.pad.Pkcs7,
            }
        );
        return bytes.toString(CryptoJS.enc.Utf8);
    }
    /**
     * 初始化数据
     * @description 抽取缓存中已存在的key并解析
     * @returns {object} 缓存结果对象
     */
    _initData() {
        let data = window[this._type].getItem(this.key);
        if (data) {
            if (/^kooci\*\*/.test(data)) {
                data = this._decode(data);
            }
            try {
                data = JSON.parse(data);
            } catch (error) {
                console.warn(
                    `[kq.Cache.initData]缓存数据中存在不能被JSON.parse解析的对象,浙江导致数据解析始终为空~`,
                    data
                );
                data = {};
            }
        }
        return data || {};
    }
    /**
     * 设置数据到缓存区
     * @description 将this._data设置到缓存
     * @returns void
     */
    _set() {
        let value = JSON.stringify(this._data);
        value = this._encrypt ? this._encode(value) : value;
        window[this._type].setItem(this.key, value);
    }
}
