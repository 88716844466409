/**
 * 全局过滤器
 */

import _ from "lodash";

/**
 * 字符串统一转换
 * @param {*} target 转换目标
 * @param {string} methodName 方法名称
 * @returns {*}
 */
function stringFormat(target, methodName) {
    if ([null, undefined, ""].includes(target)) {
        return "";
    }
    if (_.isString(target)) {
        return target[methodName]();
    }
    console.warn(
        new kq.Err({
            title: "过滤器转换出错",
            message: `过滤器目标类型必须为字符串或undefined,null类型`,
            current: target,
            from: `Filter.${methodName}`,
            console: false,
        }).message
    );
    return target;
}

export default {
    install(Vue) {
        _.forEach(this, (filter, name) => {
            if (name !== "install") {
                Vue.filter(name, filter);
            }
        });
    },
    /**
     * 转换为html语言
     * @param {*} target 过滤目标
     * @returns {*}
     */
    toHtml(target) {
        return stringFormat(target, "toHtml");
    },
    /**
     * 清除脚本语言
     * @param {*} target 过滤目标
     * @returns {*}
     */
    clearScript(target) {
        return stringFormat(target, "clearScript");
    },
    /**
     * 清空html语言
     * @see 包含了脚本与html标签
     * @param {*} target 过滤目标
     * @returns {*}
     */
    clearHtml(target) {
        return stringFormat(target, "clearHtml");
    },
    /**
     * 数值转换器
     * @param {string|number} target 转换目标
     * @returns {*}
     */
    number(target) {
        let num = Number(target);
        if (Number.isNaN(num)) {
            console.error(
                new kq.Err({
                    title: "转换器转换错误",
                    message: "数字转换器要求目标必须为数字类型或数字字符串",
                    current: target,
                    from: "kqFilter.number",
                    console: false,
                })
            );
            return target;
        } else {
            return num;
        }
    },
    /**
     * css尺寸转换器
     * @see 1: 1px, 100%: 100%, 10vw: 10vw
     * @param {*} target 
     * @returns {*}
     */
    cssSize(target) {
        let num = Number(target);
        if (Number.isNaN(num)) {
            return target;
        } else {
            return num + "px";
        }
    },
};
