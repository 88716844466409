import Notify from "../../Notify/Notify.vue";
import _ from "lodash";
import ansysSourceMap from "./ansys.js";

export default class Err extends Error {
    /**
     * 构造函数
     * @param {string|object} params 构造参数(string:错误信息)
     * @param {string} params.message 错误消息
     * @param {string} [params.title] 错误标题
     * @param {string} [params.type="error"] 错误类型
     * @param {string} [params.info] 错误详细信息
     * @param {number} [params.code] 错误码
     */
    constructor(params) {
        let message;
        if (_.isString(params)) {
            message = params;
        } else if (_.isPlainObject(params)) {
            message = params.message;
        }
        super(message, ...Array.prototype.slice.call(arguments, 1));
        this._msg = message;
        /** 错误标题 */
        this._title = "" || "参数错误";
        /** 错误类型 */
        this._type = "PARAM";
        /** 错误详细信息,用来提供给Notfy复制(不传默认_message) */
        this._info = "";
        /** 错误源 */
        this._from = "";

        params = this._constructorParamsConvert(params);
        const { title, type, from, name, current, console, store, fid, code } = params;
        this._code = code;
        this._title = title || name || "出错提示";
        this._type = type === undefined ? "PARAM" : type;
        this._from = from;
        this._store = store;
        this._request = kq.Request.mixin({
            loginDialogDisabled: true,
            modules: ["system", "login"],
        });
        if (this._type === "API") {
            this._info = `API:${JSON.stringify(current)}`;
        }
        this._info += `\n[${this._from}] - ${this._title}: ${params.info || this.message}`;
        this._fid = fid;
        if (current) {
            if (current instanceof Error && process.env.NODE_ENV === "production") {
                ansysSourceMap(current).then((info) => {
                    this._info += `\n${info}`;
                    this.send();
                });
                return;
            }
            this._info += `\n错误对象: ${
                current instanceof Error ? current.toString() : JSON.stringify(current)
            }`;
        }
        if (console !== false) {
            this.errHand(params);
        }
        // 生产模式发送错误
        if (process.env.NODE_ENV === "production") {
            this.send();
        }
    }
    /** 获取错误详细信息
     * @returns void
     */
    get info() {
        return this._info;
    }
    /** 获取错误标题
     * @returns void
     */
    get title() {
        return this._title;
    }
    /**
     * 获取错误码
     * @returns void
     */
    get code() {
        return this._code;
    }
    /**
     * 获取原始错误信息
     * @returns {string}
     */
    get msg() {
        return this._msg;
    }
    get type() {
        return this._type;
    }
    /**
     * 发送错误
     * @returns void
     */
    async send() {
        if (this._type !== false) {
            let userInfo = {};
            if (_.isFunction(this.getUserInfo)) {
                userInfo = await (async () => this.getUserInfo())().catch(() => {});
            } else {
                userInfo = await this._request.getLoginInfo().catch(() => {});
            }
            /** 用户信息 */
            userInfo = userInfo || {};
            /** 发送错误请求 */
            this._request.sendErr({
                schoolId: userInfo.schoolId,
                wid: userInfo.wid,
                uid: userInfo.uid,
                fid: this._fid,
                type: this._type,
                title: this._title,
                info: this._info,
                version: userInfo.version,
            });
        }
    }
    /**
     * 错误输出
     * @returns void
     */
    errHand() {
        // 非自定义的系统报错!
        if (this.message && this.stack && this.name === undefined) {
            const [, row, col] = err.stack.split("\n")[1].match(/:(\d+):(\d+)/);
            this.message += `错误详情代码行==行:${row} 列:${col}`;
        }
        console.error(this._info);
        Notify.open({
            type: "error",
            title: this._title,
            message: this.message,
            info: this._info,
            duration: 5000,
        });
    }
    /**
     * 参数校验与转化
     * @returns {object} 参数对象
     */
    _constructorParamsConvert(params) {
        let result = {};
        const argList = Array.prototype.slice.call(arguments, 0);
        if (!_.isEmpty(argList)) {
            if (_.isString(params)) {
                this._message = params;
            } else if (_.isPlainObject(params)) {
                if (params.from) {
                    result = params;
                } else {
                    throw new Err({
                        title: "参数错误!",
                        message: "当params参数为对象时,错误来源(from)都不能为空!",
                        from: `${this.constructor.name}.constructor`,
                    });
                }
            }
        } else {
            throw new Err({
                title: "参数错误!",
                message: "参数不能为空!",
                from: `${this.constructor.name}.constructor`,
            });
        }
        return result;
    }
    static install({ getUserInfo }) {
        this.prototype.getUserInfo = getUserInfo;
    }
}
