import * as XLSX from "xlsx";
import axios from "axios";

/**
 * Excel 类，用于读取和写入 Excel 文件。
 */
export default class Excel {
    /**
     * 字符串转ArrayBuffer
     * @param {string} s 字符串
     * @returns {ArrayBuffer} ArrayBuffer
     */
    static s2ab(s) {
        const buf = new ArrayBuffer(s.length);
        const view = new Uint8Array(buf);
        for (let i = 0; i < s.length; i++) {
            view[i] = s.charCodeAt(i) & 0xff;
        }
        return buf;
    }
    /**
     * 读取 Excel 文件并返回一个 Promise，该 Promise 将解析为一个包含所有行的数组。
     * @param {Object} options - 读取选项。
     * @param {File|string} [options.file] - 要读取的文件或文件路径。
     * @param {string} [options.url] - 要下载的文件的 URL。
     * @param {Object} [options.headers] - 要发送的 HTTP 标头。
     * @param {Object} [options.data] - 要发送的数据。
     * @param {Object} [options.params] - 要发送的查询参数。
     * @returns {Promise<Array>} - 一个 Promise，该 Promise 将解析为一个包含所有行的数组。
     */
    read(options) {
        return new Promise((resolve, reject) => {
            options = this._convertReadParams(options);
            let fileReader = new FileReader();
            let arrayBuffer = null;
            fileReader.onload = ({ target }) => {
                const data = new Uint8Array(target.result);
                const { SheetNames, Sheets } = XLSX.read(data, { type: "array" });
                const resultList = [];
                SheetNames.forEach((name) => {
                    const workSheets = Sheets[name];
                    const sheetRows = XLSX.utils.sheet_to_json(workSheets, {
                        header: "A",
                        defval: "",
                    });
                    resultList.push({ sheetName: name, dataList: sheetRows });
                });
                resolve(resultList);
            };
            fileReader.onerror = (err) => {
                reject(
                    new kq.Err({
                        title: "读取错误",
                        message: "读取二进制文件失败,请确认文件是否存在!",
                        console: false,
                        current: err,
                        from: "kq.Excel.read",
                    })
                );
            };
            if (options.url) {
                this._downloadUrl(options)
                    .then((data) => {
                        fileReader.readAsArrayBuffer(
                            new Blob([data], { type: "application/octet-stream" })
                        );
                    })
                    .catch(reject);
            } else if (options.file) {
                arrayBuffer = options.file;
                fileReader.readAsArrayBuffer(arrayBuffer);
            } else {
                reject(
                    new kq.Err({
                        title: "读取错误",
                        message: "读取文件失败,请确认文件是否存在!",
                        console: false,
                        from: "kq.Excel.read",
                        type: "VUE",
                    })
                );
            }
        });
    }
    /**
     * 导出excel数据
     * @param {object} param0 入参对象
     * @param {string} param0.name 文件名
     * @param {[string]} param0.headerList 表头
     * @param {[object]} param0.sheetList sheet列表
     * @param {string} param0.type 文件类型
     * @returns {Promise<void>} 失败抛错
     */
    export({ name, headerList, dataList, type }) {
        if (!name) {
            name = `导出数据.xlsx`;
        }
        if (!type) {
            type = name.kqMatch(/\.(\w+)$/i, 1);
            if (!type) {
                throw new kq.Err({
                    message: "excel文件后缀名称不能为空",
                    current: arguments[0],
                    from: "kq.Excel.exports",
                    console: false,
                });
            }
        }
        const wb = XLSX.utils.book_new();
        // 一维数据结构，不存在多个sheet
        if (dataList.every((item) => item.sheetName === undefined && !item.isSheet)) {
            dataList = [{ sheetName: "sheet1", dataList: dataList }];
        }
        dataList.forEach(({ sheetName, dataList: dataList2 }, index) => {
            const ws = XLSX.utils.json_to_sheet(dataList2, { header: headerList });
            XLSX.utils.book_append_sheet(wb, ws, sheetName || `sheet${index + 1}`);
        });
        const wbout = XLSX.write(wb, { bookType: type || "xlsx", type: "binary" });
        const blob = new Blob([Excel.s2ab(wbout)], { type: "application/octet-stream" });
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = name;
        a.click();
        URL.revokeObjectURL(url);
    }
    /**
     * 将读取选项转换为标准格式。
     * @param {File|string|Object} options - 读取选项。
     * @returns {Object} - 标准格式的读取选项。
     * @throws {kq.Err} - 如果读取选项无效，则抛出错误。
     * @private
     */
    _convertReadParams(options) {
        if (options instanceof File) {
            return { file: options };
        } else if (/^http.+\.(xlsx)|(xls)|(csv)/.test(options) || /^\/api/.test(options)) {
            return { url: options };
        } else if (_.isPlainObject(options)) {
            return options;
        }
        throw new kq.Err({
            from: "kq.Excel.read",
            message: "入参错误",
            current: options,
            console: false,
        });
    }
    /**
     * 下载指定 URL 的文件并返回一个 Promise，该 Promise 将解析为文件的二进制数据。
     * @param {Object} options - 下载选项。
     * @param {string} options.url - 要下载的文件的 URL。
     * @param {Object} [options.headers] - 要发送的 HTTP 标头。
     * @param {Object} [options.data] - 要发送的数据。
     * @param {Object} [options.params] - 要发送的查询参数。
     * @returns {Promise<Uint8Array>} - 一个 Promise，该 Promise 将解析为文件的二进制数据。
     * @private
     */
    async _downloadUrl(options) {
        let {
            url,
            headers = {},
            data = {},
            params = {},
            progress,
            method = "get",
            mime,
            token = "",
        } = options;
        if (/^\/api/.test(url)) {
            if (process.env.NODE_ENV === "production") {
                url = `/api${url}`;
            }
            headers.Authorization = headers.Authorization || token;
            if (!headers.Authorization) {
                if (_.isFunction(window.kqConfig.getToken)) {
                    headers.Authorization = window.kqConfig.getToken();
                } else {
                    console.warn("下载文件可能会出现401错误,因为未获取到token信息~");
                }
            }
            headers.md5 = kq.Request.prototype._getMd5(options);
            if (mime) {
                headers["Content-Type"] = mime;
            }
        }
        if (!url.includes("http") && !/^data:/.test(url)) {
            url =
                process.env.NODE_ENV === "development"
                    ? process.env.VUE_APP_BASE_API + url
                    : window.location.origin + url;
        }
        const response = await axios
            .request({
                url,
                method,
                headers,
                responseType: "arraybuffer",
                data,
                params,
                onFileProgress(event) {
                    progress && progress(parseInt((event.loaded / event.total) * 100));
                },
            })
            .catch((err) => {
                throw new kq.Err({
                    title: "下载错误",
                    type: "API",
                    message: "下载错误,请确认下载资源是否支持跨域或是否存在!",
                    console: false,
                    current: err,
                    from: "kqFile.download",
                });
            });
        return response.data;
    }
}
