var render = function render(){var _vm=this,_c=_vm._self._c;return _c('kq-card-type',{staticClass:"v-toolbars-paramconfig-key",attrs:{"name":_vm.title,"expand-disabled":"","btn-list":_vm.formatBtnList}},[_c('div',{staticClass:"v-toolbars-paramconfig-list"},[_vm._l((_vm.formatDataList),function(data,index){return _c('kq-card-number',{key:index,staticClass:"v-toolbars-paramconfig-list-li",attrs:{"is-focus":_vm.current === data.key,"sort-number":index + 1,"name":data.name,"subname":data.subname,"stat-btn-list":data.statBtnList},on:{"click":function($event){return _vm.onClick(data)},"stat-click":function($event){_vm.currentDeleteKey = data.key}}})}),(!_vm.readonly)?_c('kq-card-number',{ref:"add",class:{
                'v-toolbars-paramconfig-list-li': true,
                'v-toolbars-paramconfig-list-li--adding': _vm.isAdding,
            }},[(!_vm.isAdding)?_c('div',{directives:[{name:"kq-color",rawName:"v-kq-color.label",modifiers:{"label":true}},{name:"kq-color",rawName:"v-kq-color.primary.hover",modifiers:{"primary":true,"hover":true}}],staticClass:"v-toolbars-paramconfig-list-li__add kq-center",attrs:{"is-focus":false},on:{"click":function($event){_vm.isAdding = true}}},[_c('i',{directives:[{name:"kq-icon",rawName:"v-kq-icon:add2-line",arg:"add2-line"}]}),_c('span',[_vm._v("添加一项")])]):_c('el-form',{ref:"form",attrs:{"model":_vm.addForms,"size":"mini","rules":_vm.addRules}},[_c('el-form-item',{attrs:{"label":"变量名","prop":"key"}},[_c('el-select',{attrs:{"filterable":"","placeholder":_vm.keyPlaceholder,"remote":!!_vm.remoteMethod,"remote-method":_vm.fuzzyQueryKeyMethod,"allow-create":!_vm.addDisabled,"default-first-option":"","loading":_vm.fuzzyQueryLoading,"debounce":300},on:{"change":_vm.onKeyChange,"focus":_vm.onKeyFocus},model:{value:(_vm.addForms.key),callback:function ($$v) {_vm.$set(_vm.addForms, "key", $$v)},expression:"addForms.key"}},_vm._l((_vm.keyOptionList),function(item){return _c('el-option',{key:item.rowId,attrs:{"label":item.label,"value":item.value,"disabled":item.disabled}},[_c('el-tooltip',{attrs:{"placement":"right-start"}},[_c('span',{attrs:{"slot":"content"},domProps:{"innerHTML":_vm._s(item.describe.kqToHtml())},slot:"content"}),_c('div',{staticClass:"v-toolbars-paramconfig-key--option"},[(item.icon)?_c('i',{directives:[{name:"kq-icon",rawName:"v-kq-icon:[item.icon]",arg:item.icon},{name:"kq-color",rawName:"v-kq-color.label.light",modifiers:{"label":true,"light":true}}]}):_vm._e(),_c('label',{class:{
                                            'v-primary-color':
                                                _vm.addForms.key == item.value,
                                        }},[_vm._v(_vm._s(item.label || "未配置"))]),_c('span',[_vm._v(_vm._s(item.value))])])])],1)}),1)],1),_c('el-form-item',{attrs:{"label":"值类型","prop":"type"}},[_c('el-select',{attrs:{"placeholder":"请选择值类型"},model:{value:(_vm.addForms.type),callback:function ($$v) {_vm.$set(_vm.addForms, "type", $$v)},expression:"addForms.type"}},_vm._l((_vm.addFormOptions),function(option){return _c('el-option',_vm._b({key:option.label},'el-option',option,false))}),1)],1),_c('el-form-item',{attrs:{"label":"粘贴值","prop":"paste"}},[_c('el-input',{attrs:{"placeholder":"粘贴从其它系统中配置项复制的JSON的数据","type":"textarea","disabled":_vm.addDisabled},model:{value:(_vm.addForms.paste),callback:function ($$v) {_vm.$set(_vm.addForms, "paste", $$v)},expression:"addForms.paste"}})],1),_c('el-form-item',[_c('el-button',{staticStyle:{"margin-top":"10px","float":"right"},attrs:{"type":"primary","size":"mini","round":"","loading":_vm.addBtnLoading},on:{"click":_vm.onAddCommit}},[_vm._v("确认新增")]),_c('el-button',{staticStyle:{"margin-top":"10px","margin-right":"10px","float":"right"},attrs:{"size":"mini","round":""},on:{"click":function($event){_vm.isAdding = false}}},[_vm._v("取消")])],1)],1)],1):_vm._e()],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }