var render = function render(){var _vm=this,_c=_vm._self._c;return _c('kq-pop-confirm',_vm._g(_vm._b({staticClass:"kq-fixed-toolbar-item",attrs:{"disabled":_vm.btn.props.disableHandler(_vm.btn) || _vm.btn.confirm.disableHandler(_vm.btn),"placement":_vm.placement.includes('left') ? 'right' : 'left'},on:{"after-leave":function($event){return _vm.$emit('confirm-after-leave')},"show":function($event){return _vm.$emit('confirm-show')},"hide":function($event){return _vm.$emit('confirm-hide')}}},'kq-pop-confirm',_vm.btn.confirm.props,false),_vm.btn.confirm.listeners),[_c('el-tooltip',_vm._g(_vm._b({attrs:{"disabled":_vm.btn.tooltip.props.disabled || !_vm.btn.tooltip.props.content}},'el-tooltip',_vm.btn.tooltip.props,false),_vm.btn.tooltip.listeners),[_c('el-popover',_vm._g(_vm._b({attrs:{"disabled":_vm.btn.popover.props.disableHandler(_vm.btn),"trigger":_vm.btn.popover.props.trigger || 'manual',"popper-class":"kq-bottom-toolbar--popover-pop"}},'el-popover',_vm.btn.popover.props,false),_vm.btn.popover.listeners),[_c(_vm.btn.popover.component.is,_vm._g(_vm._b({tag:"compontent",attrs:{"v-if":_vm.btn.popover.component.lazy !== false},on:{"mouseenter":function($event){return _vm.$emit('mouseenter', $event)},"mouseleave":function($event){return _vm.$emit('mouseleave', $event)}}},'compontent',_vm.btn.popover.component.props,false),_vm.btn.popover.component.listeners)),_c('div',{class:`kq-fixed-toolbar-item-li ${
                    _vm.btn.props.class
                } kq-fixed-toolbar-item-li--${
                    _vm.btn.props.disableHandler() ? 'disabled' : ''
                } ${_vm.isActive ? 'kq-fixed-toolbar-item-li--active' : ''}`,style:(_vm.btn.props.style),attrs:{"slot":"reference","id":_vm.btn.props.id},on:{"click":function($event){return _vm.$emit('click')},"mouseenter":function($event){return _vm.$emit('mouseenter', $event)},"mouseleave":function($event){return _vm.$emit('mouseleave', $event)}},slot:"reference"},[_c('label',{class:`kft-label v-ellipsis ${
                        _vm.btn.props.iconCss || _vm.btn.props.iconClass
                    }`,attrs:{"title":_vm.btn.props.disableHandler()}},[_vm._v(_vm._s(_vm.btn.name))]),_c('kq-square-icon-btn',{class:`kft-square ${
                        _vm.btn.props.iconCss || _vm.btn.props.iconClass
                    }`,attrs:{"icon":_vm.btn.icon,"icon-size":_vm.btn.props.iconSize,"name":_vm.btn.name,"width":"40","stop-propagation":false,"tooltip-disabled":"","loading":_vm.btn.loadHandler(_vm.btn)}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }