/**
 * 自定义类
 * @see 用来扩展通用页面个性化操作
 * @see 函数命名规则: 按钮类型/按钮名称/按钮操作(click(btn):点击,before(btn):点击前钩子(返回false则中断操作),after(btn):点击后钩子,props:按钮的props参数(仅对自定义按钮有效))/排序
 * @see 在脚本文件用使用(需要继承): window.KOOCI_CUSTOM_PLUGIN = new Custom();
 * @author tonny
 * @date 2021-10-20
 */

import _ from "lodash";
import Tools from "../tools";

export default class Custom {
    /**
     * 个性化操作类
     * @see 用来扩展通用页面个性化操作
     * @see 函数命名规则: 按钮类型/按钮名称/按钮操作(click(btn):点击,before(btn):点击前钩子(返回false则中断操作),after(btn):点击后钩子,props:按钮的props参数(仅对自定义按钮有效))/排序
     * @param {object} options 构造函数参数
     * @param {string} options.split 自定义分隔符
     */
    constructor(options) {
        this._paramConvert(options);
        /** 参数分割(暂不支持自定义) */
        this._split = "/";
        /** 自定义按钮获取配置参照 */
        this._customBtnConfigList = [
            "/click",
            "/props",
            "/before",
            "/after",
            "/config",
        ];
        /** vue组件实例 */
        this.vm = null;
        /** 数据请求实例 */
        this.data_ = null;
        /** 所有按钮对象 */
        this.allBtns = {};
    }
    /**
     * 获取配置信息
     * @returns {object}
     */
    getConfig() {
        return {};
    }
    /**
     * 绑定钩子事件
     * @param {object} param 按钮参数
     * @param {object} param.btn 按钮vue实例
     * @param {string} param.name 名称
     * @param {function} param.handler 点击函数
     */
    on({ key, onClick }) {
        const current = this.getAllBtns()[key];
        if (current) {
            const { before, after } = current.methods;
            return this._mergeClick(before, onClick, after, current);
        }
        return onClick;
    }
    /**
     * 合并点击函数
     * @param {function} before 点击前钩子函数
     * @param {function} click 点击函数
     * @param {function} after 点击后钩子函数
     * @param {object} btn 当前按钮参数对象
     * @returns {function} 合并后的点击函数
     */
    _mergeClick(before, click, after, btn) {
        const _this = this;
        return (args = {}) => {
            // 鼠标默认事件参数
            if(args instanceof PointerEvent || args instanceof MouseEvent) {
                args = {};
            }
            if (!_.isPlainObject(args)) {
                throw new kq.Err({
                    title: "参数错误",
                    message: `按钮名称: [${btn.name}]具有自定义性质的按钮参数必须为json对象类型`,
                    from: "kq.Custom",
                    current: args,
                });
            }
            return Tools.asyncChain(
                [before, click, after]
                    .filter(fn => _.isFunction(fn))
                    .map(fn => () => fn.call(_this, { ...args, btn }))
            );
        };
    }
    /**
     * 多继承模拟
     * @see 该方法不支持子类contructor,子类使用constructor__
     * @see 该方法子类不支持super关键字
     * @param {function} base 基类
     * @param  {...any} mixins 其他派生类(派生类不要各自继承base)
     * @returns {function} 多继承后的类
     */
    static extend(base, ...mixins) {
        /*  create aggregation class  */
        let aggregate = class __Aggregate extends base {
            constructor(...args) {
                /*  call base class constructor  */
                super(...args);

                /*  call mixin's constructor__  */
                mixins.forEach(mixin => {
                    if (typeof mixin.prototype.constructor__ === "function") {
                        mixin.prototype.constructor__.apply(this, args);
                    }
                });
            }
        };

        /*  copy properties  */
        let copyProps = (target, source) => {
            Object.getOwnPropertyNames(source)
                .concat(Object.getOwnPropertySymbols(source))
                .forEach(prop => {
                    if (
                        prop.match(
                            /^(?:constructor__|constructor|prototype|arguments|caller|name|bind|call|apply|toString|length)$/
                        )
                    ) {
                        return;
                    }
                    Object.defineProperty(
                        target,
                        prop,
                        Object.getOwnPropertyDescriptor(source, prop)
                    );
                });
        };

        /*  copy all properties of all mixins into aggregation class  */
        mixins.forEach(mixin => {
            copyProps(aggregate.prototype, mixin.prototype);
            copyProps(aggregate, mixin);
        });

        return aggregate;
    }
    /**
     * 获取所有按钮集
     * @see 该集合中包含了预制按钮的钩子和自定义按钮配置
     * @returns {object}
     */
    getAllBtns() {
        if (_.isEmpty(this.allBtns)) {
            const methodList = this._getMethodList();
            methodList.forEach(methodName => {
                const handler = this[methodName];
                if (
                    this._customBtnConfigList.some(s => methodName.includes(s))
                ) {
                    let item = this._getCustomBtnItem(methodName, this.allBtns);
                    // 按钮点击事件
                    if (methodName.includes(this._split + "click")) {
                        item.methods.click = handler.bind(this);
                    }
                    // vue组件props项
                    else if (methodName.includes(this._split + "props")) {
                        item.props = {
                            ...item.props,
                            ...(handler.call(this) || {}),
                        };
                    }
                    // 按钮点击前钩子
                    else if (methodName.includes(this._split + "before")) {
                        item.methods.before = handler.bind(this);
                    }
                    // 按钮点击后钩子
                    else if (methodName.includes(this._split + "after")) {
                        item.methods.after = handler.bind(this);
                    }
                    // 配置项: 将配置信息全部解压到按钮中
                    else if (methodName.includes(this._split + "config")) {
                        this.allBtns[item.key] = {
                            ...item,
                            ...handler.call(this),
                        };
                    }
                }
            });
        }
        return this.allBtns;
    }
    /**
     * 自定义按钮列表
     * @returns {[object]}
     */
    get customBtnList() {
        let resultList = [];
        const _this = this;
        if (!_.isEmpty(this.getAllBtns())) {
            resultList = _.sortBy(this.allBtns, "sort")
                // 筛选出自定义按钮(自定义按钮必须包含点击事件函数)
                .filter(({ methods: { click } }) => _.isFunction(click))
                // 格式化最终结果
                .map(item => {
                    const { before, click, after } = item.methods;
                    item.onClick = this._mergeClick(before, click, after, item);
                    return item;
                });
        }
        return resultList;
    }
    /**
     * 钩子按钮集
     * @returns {object}
     */
    get hookBtns() {
        let result = {};
        if (!_.isEmpty(this.getAllBtns())) {
            _.forEach(this.allBtns, (btn, name) => {
                if (btn.methods.click === undefined) {
                    result[name] = btn;
                }
            });
        }
        return result;
    }
    /**
     * 错误处理
     * @param {object|string} err 错误对象
     * @returns void
     */
    errHand_(err) {
        if (this.vm) {
            this.vm.errHand_(err);
        } else {
            kq.Notice.error(err.message || err);
        }
    }

    /**
     * 获取类方法列表
     * @see 该方法将始终返回数组
     * @param {array} [result] 结果
     * @param {object} [proto] 属性
     * @returns {array}
     */
    _getMethodList(result, proto) {
        result = result || [];
        proto = proto || this;
        if (proto.__proto__) {
            result.push(...Object.getOwnPropertyNames(proto.__proto__));
            this._getMethodList(result, proto.__proto__);
        }
        return result;
    }
    /**
     * 参数转换
     * @param {object} [param] 构造函数参数
     * @returns {object}
     */
    _paramConvert(param) {
        let result = {};
        const title = "参数错误",
            from = "Custom.constructor",
            current = param;
        if (param) {
            if (!_.isPlainObject(param)) {
                throw new kq.Err({
                    title,
                    from,
                    current,
                    message: "构造函数参数必须为对象",
                });
            }
            const { split } = param;
            if (split) {
                if (!_.isString(split)) {
                    throw new kq.Err({
                        title,
                        from,
                        current: split,
                        message: "构造函数参数中的分隔符(split)只能为字符串",
                    });
                }
                if (split === "-") {
                    throw new kq.Err({
                        title,
                        from,
                        current: split,
                        message: "构造函数参数中的分隔符(split)不可以为: -",
                    });
                }
            }
            result = param;
        }
        return result;
    }
    /**
     * 获取自定义按钮数据
     * @param {string} name 按钮名称
     * @param {object} results 最终结果
     * @returns {object}
     */
    _getCustomBtnItem(name, results) {
        // 位置,按钮名称,排序
        const [type, btnName] = name.split(this._split);
        if (!btnName || !type) {
            throw new Err({
                title: "函数名称规范错误",
                message: `名称必须为:按钮类型[split操作符]按钮名称[split操作符]操作[split操作符]排序`,
                current: name,
            });
        }
        const key = `${type}${this._split}${btnName}`;
        let item = results[key];
        if (!item) {
            item = {
                key,
                name: btnName,
                id: `btn_${kq.Tools.uuid().slice(-5)}`,
                methods: {},
                props: { loading: false, type: "primary" },
                type,
                disabled: false,
            };
            results[key] = item;
        }
        return item;
    }
}
