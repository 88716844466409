/**
 * 表单加载器
 * @see 可用通过重写函数来实现对于表单接口的自定义
 * @author tonny
 * @date 2021-12-01
 */
export default class Loader extends kq.Tools.mixin(
    kq.Request,
    kq.Request.Select,
    kq.Request.System,
    kq.Request.Login,
    kq.Request.Field,
    kq.Request.View,
    kq.Request.Rowset,
    kq.Request.Importexport,
    kq.Request.Process,
    kq.Request.Permission
) {
    /**
     * 获取审核结果码表列表
     * @returns {Promise<[object]>} 成功返回值列表(转换后的),失败抛错
     */
    getReviewResultSelectList() {
        return this.getSelectList({ zlabel: "S_ISPASS" });
    }
}
