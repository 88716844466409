var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"kq-lowcode-column",on:{"mouseenter":function($event){_vm.isActive_ = true},"mouseleave":function($event){_vm.isActive_ = false}}},[(!_vm.component)?_c('div',{class:{
            'kq-lowcode-card-layout kq-center': true,
            'kq-lowcode-card-column--pro': _vm.isProduction,
            'v-label-border-color v-label-color': !_vm.formatActive,
            'v-primary-border-color v-primary-color': _vm.formatActive,
        },on:{"click":function($event){if($event.target !== $event.currentTarget)return null;return _vm.onClick.apply(null, arguments)}}},[_c('i',{directives:[{name:"kq-icon",rawName:"v-kq-icon:view.full.lib",arg:"view",modifiers:{"full":true,"lib":true}},{name:"show",rawName:"v-show",value:(!_vm.formatActive),expression:"!formatActive"}]}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.formatActive),expression:"formatActive"}]},[_c('i',{directives:[{name:"kq-icon",rawName:"v-kq-icon:view.full.lib",arg:"view",modifiers:{"full":true,"lib":true}}]}),_c('span',[_vm._v("添加组件")])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.formatActive),expression:"formatActive"}],staticClass:"kq-lowcode-column__del"},[_c('kq-button',{attrs:{"component":"square-icon-btn","button":_vm.deleteComponentBtn}})],1)]):[_vm._t("default",function(){return [(!_vm.isProduction || !_vm.isEmpty__(_vm.fromDataList))?_c(_vm.component,_vm._b({ref:"component",tag:"component",staticClass:"kq-lowcode-column__component",attrs:{"is-demo":_vm.isProduction ? false : _vm.isEmpty__(_vm.fromDataList),"from-data-list":_vm.fromDataList}},'component',{ ..._vm.$attrs, ..._vm.componentProps },false)):_vm._e()]}),(!_vm.isProduction)?[_c('kq-square-icon-btn',{class:{
                    'v-success-bg v-success-dark-bg-hover': _vm.isBindData,
                    'v-warn-bg v-warn-dark-bg-hover': !_vm.isBindData,
                    'kq-lowcode-column__db v-white-color': true,
                },attrs:{"component":"square-icon-btn","name":`设置数据（${_vm.isBindData ? '已设置,点击更改' : '未设置,点击设置'}）`,"icon":"db-full","width":"30","icon-size":"18"},on:{"click":function($event){return _vm.$emit('data')}}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isActive_),expression:"isActive_"}],staticClass:"kq-lowcode-column__del"},[_c('kq-button',{attrs:{"component":"square-icon-btn","button":_vm.deleteDataBtn}})],1)]:_vm._e()]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }