/**
 * 添加代理
 * @author tonny
 * @date 2021-10-08
 */

module.exports = {
    host: "http://221.131.136.30:7018",
    // host: "http://221.131.136.30:7027/api", // 杭州万向环境
    proxyList: ["/api", "/auth"],
};
