/**
 * @description 文件处理类
 * @see 特别注意:mockjs会直接导致下载的文件打不开,使用mockjs2
 * @see 1.下载文件
 * @see 2.上传文件
 * @see 3.压缩文件
 * @see 4.文件类型判断
 * @author tonny
 * @date 2021-12-06
 */

import axios from "axios";
import _ from "lodash";
export default class File {
    /**
     * 构造函数
     * @param {object} params 参数对象
     * @param {string} [params.url] 链接(下载|压缩用)
     * @param {string} [params.content] 内容(支持blob与直接的文档)
     * @param {string} [params.name] 文件名
     * @param {object} [params.header] 请求头
     * @param {string} [params.method=get] 请求类型(默认get)
     * @param {function} [params.progress] 进度回调函数(参数:整数百分比)
     * @returns void
     */
    constructor(params) {
        File._paramValidator(params);
        const { url, content, name, headers = {}, progress, isOpenMode = false } = params;
        this._url = url;
        this._content = content;
        this._name = name;
        this._headers = headers;
        this._progress = progress;
        this._isOpenMode = isOpenMode;
    }
    /**
     * 下载文件
     * @param {object} params 参数对象
     * @param {string} [params.url] 下载链接
     * @param {string} [params.content] 下载内容(支持blob与直接的文档)
     * @param {string} [params.name] 下载名称
     * @param {string} [params.mime] 下载类型(参照MIME手册[https://www.w3school.com.cn/media/media_mimeref.asp]),默认"application/octet-stream"
     * @param {object} [params.headers] 下载头
     * @param {string} [params.method=get] 请求类型(默认get)
     * @param {function} [params.progress] 进度回调函数(参数:整数百分比)
     * @returns {Promise<void>} 成功无返回,失败抛错
     */
    download(params = {}) {
        return File.download({
            url: this._url,
            content: this._content,
            name: this._name,
            headers: this._headers,
            progress: this._progress,
            isOpenMode: this._isOpenMode,
            ...params,
        });
    }
    static getName(...params) {
        return this.getFileName(...params);
    }
    /**
     * 根据oss的链接获取文件名
     * @param {string} url oss链接
     * @param {boolean} removePrefix 是否去除名称前缀
     * @param {boolean} isDecode 是否解码
     * @returns {string}
     */
    static getFileName(url, removePrefix = true, isDecode = true) {
        if (!url) {
            return "";
        }
        let name = url.kqMatch(/\/?([^\/]+)$/, 1);
        if (removePrefix) {
            let suffix = name.kqMatch(/\.(\w+)$/, 1);
            name = name.kqMatch(`(${suffix}\\d+_)?(.*)`, 2);
        }
        return isDecode ? unescape(name) : name;
    }
    /**
     * 获取文件名后缀
     * @param {string} name 文件名
     * @returns {string}
     */
    static getSuffix(name) {
        if (!name) {
            return "";
        }
        return name.kqMatch(/\.(\w+)$/, 1);
    }
    /**
     * 根据后缀名获取信息
     * @param {string} suffix 后缀名
     * @returns {object}
     * @example {icon<string>:图标, color<string>:颜色}
     */
    static getSuffixInfoBySuffix(suffix) {
        let color, icon;
        switch (suffix) {
        case "docx":
        case "doc":
            icon = "word-line";
            color = "#588BE8";
            break;
        case "pdf":
            icon = "pdf-line";
            color = "#E74C3C";
            break;
        case "ppt":
        case "pptx":
            icon = "ppt-line";
            color = "#C43E1C";
            break;
        case "xls":
        case "xlsx":
            icon = "excel-line";
            color = "#54A662";
            break;
        case "txt":
            icon = "txt-line";
            color = "#34495E";
            break;
        case "mp4":
            icon = "video-full";
            color = "#9b59b6";
            break;
        case "zip":
        case "rar":
            icon = "zip-full";
            color = "#34495e";
            break;
        default:
            if (this.isImage(suffix)) {
                icon = "image-line";
                color = "#2ECC71";
            } else {
                icon = "default-line";
                color = "#95A5A6";
            }
            break;
        }
        return { color, icon };
    }
    /**
     *
     * @param {string} url 文件链接
     * @param {boolean} removePrefix 是否移除前缀
     * @returns
     */
    static getFileInfo(url, removePrefix = true) {
        const name = decodeURI(this.getFileName(url, removePrefix, false));
        return {
            name,
            url,
            ...this.getSuffixInfoBySuffix(this.getSuffix(url)),
        };
    }
    /**
     * 是否为图片
     * @param {string} name 文件名
     * @returns {boolean}
     */
    static isImage(name) {
        if (/[^.]+$/.test(name)) {
            name = "." + name;
        }
        return /\.(png)|(jpg)|(jpeg)|(bmp)|(webp)|(svg)|(gif)$/.test(name.toLowerCase());
    }
    /**
     * 参数验证
     * @param {object} params 参数对象
     * @returns 失败抛错
     */
    static _paramValidator(params) {
        let message;
        const from = "kqFile.download";
        const current = params;
        if (!params) {
            message = "参数不能为空";
        }
        const { url, content, name } = params;
        if (!url && !content) {
            message = `下载链接与内容不能同时为空!`;
        }
        if (!url && !name) {
            message = "当不是下载链接时,文件名不能为空!";
        }
        if (message) {
            throw new kq.Err({
                message,
                from,
                current,
            });
        }
    }
    /**
     * 下载(静态函数)
     * @param {object} param0 参数对象
     * @param {string} [param0.url] 下载链接
     * @param {string} [param0.content] 下载内容(支持blob与直接的文档)
     * @param {string} [param0.name] 下载名称
     * @param {string} [param0.mime] 下载类型(参照MIME手册[https://www.w3school.com.cn/media/media_mimeref.asp]),默认"application/octet-stream"
     * @param {object} [param0.header] 下载头
     * @param {string} [param0.method=get] 请求类型(默认get)
     * @param {function} [param0.progress] 进度回调函数(参数:整数百分比)
     * @param {function} [param0.isOpenMode] 打开方式是否是window.open方式
     * @returns {Promise<void>} 成功无返回,失败抛错
     */
    static async download(param0) {
        File._paramValidator(param0);
        let {
            url,
            content,
            name,
            headers = {},
            data = {},
            params = {},
            progress,
            method = "get",
            mime,
            token = "",
            isOpenMode = false,
        } = param0;
        let data2;
        if (content) {
            data2 = content;
        } else {
            if (/^\/api/.test(url)) {
                if (process.env.NODE_ENV === "production") {
                    url = `/api${url}`;
                }
                headers.Authorization = headers.Authorization || token;
                if (!headers.Authorization) {
                    if (_.isFunction(window.kqConfig.getToken)) {
                        headers.Authorization = window.kqConfig.getToken();
                    } else {
                        console.warn("下载文件可能会出现401错误,因为未获取到token信息~");
                    }
                }
                headers.md5 = kq.Request.prototype._getMd5(param0);
                if (mime) {
                    headers["Content-Type"] = mime;
                }
            }
            if (!url.includes("http") && !/^data:/.test(url)) {
                url =
                    process.env.NODE_ENV === "development"
                        ? process.env.VUE_APP_BASE_API + url
                        : window.location.origin + url;
            }
            if (isOpenMode || this._isOpenMode) {
                window.open(url, "_self");
                return;
            }
            const response = await axios
                .request({
                    url,
                    method,
                    headers,
                    responseType: "arraybuffer",
                    data,
                    params,
                    onFileProgress(event) {
                        progress && progress(parseInt((event.loaded / event.total) * 100));
                    },
                })
                .catch((err) => {
                    throw new kq.Err({
                        title: "下载错误",
                        type: "CONFIG",
                        message: "下载错误,请确认下载资源是否支持跨域或是否存在!",
                        console: false,
                        current: err,
                        from: "kqFile.download",
                    });
                });
            name = name || decodeURI(response.headers.filename) || "未知名称";
            // arraybuffer转字符串
            const enc = new TextDecoder("utf-8");
            const uint8Msg = new Uint8Array(response.data);
            if (enc.decode(uint8Msg).indexOf("数据为空") != -1) {
                throw new kq.Err({
                    title: "下载错误",
                    type: "CONFIG",
                    message: `下载错误,数据为空~url:${url}`,
                    console: false,
                    from: "kqFile.download",
                });
            }
            data2 = response.data;
            if (data2.byteLength < 1024) {
                const convertStr = await File.arraybufferToString(data2).catch((err) => {
                    throw err;
                });
                if (/<[^>]+>.*<\/[^>]+>/.test(convertStr)) {
                    throw new kq.Err({
                        title: "下载错误",
                        type: "CONFIG",
                        message: `下载错误,远程返回错误~url:${url}`,
                        console: false,
                        current: convertStr.kqClearHtml(),
                        from: "kqFile.download",
                    });
                }
            }
        }
        const res = this.downloadBlob(data2, name, mime);
        if (!res) {
            throw new kq.Err({
                title: "下载错误",
                type: "CONFIG",
                message: "当前浏览器不支持下载,请右键选择目标另存为或换成谷歌浏览器",
                console: false,
                from: "kqFile.download",
            });
        }
    }
    /**
     * 下载内容
     * @param {string} data 下载数据内容
     * @param {string} filename 文件名
     * @param {string} mime 下载类型
     * @returns {boolean} true:成功,false:失败
     */
    static downloadBlob(data, filename, mime) {
        const blobData = typeof bom !== "undefined" ? [bom, data] : [data];
        const blob = new Blob(blobData, {
            type: mime || "application/octet-stream",
        });
        let res = true;
        if (typeof window.navigator.msSaveBlob !== "undefined") {
            // IE workaround for "HTML7007: One or more blob URLs were
            // revoked by closing the blob for which they were created.
            // These URLs will no longer resolve as the data backing
            // the URL has been freed."
            res = window.navigator.msSaveBlob(blob, filename);
        } else {
            const blobURL =
                window.URL && window.URL.createObjectURL
                    ? window.URL.createObjectURL(blob)
                    : window.webkitURL.createObjectURL(blob);
            const tempLink = document.createElement("a");
            tempLink.style.display = "none";
            tempLink.href = blobURL;
            tempLink.setAttribute("download", filename);

            // Safari thinks _blank anchor are pop ups. We only want to set _blank
            // target if the browser does not support the HTML5 download attribute.
            // This allows you to download files in desktop safari if pop up blocking
            // is enabled.
            if (typeof tempLink.download === "undefined") {
                tempLink.setAttribute("target", "_blank");
            }

            document.body.appendChild(tempLink);
            tempLink.click();

            setTimeout(function () {
                // 移除临时a标签
                document.body.removeChild(tempLink);
                // 释放内存
                window.URL.revokeObjectURL(blobURL);
            }, 200);
            res = true;
        }
        return res;
    }
    /**
     * 文件压缩
     */
    zip() {}
    static zip() {}
    /**
     * 是否为office文件
     * @param {string} name 文件名
     * @returns {boolean} true:是office文件
     */
    static isOffice(name) {
        return /\.((pptx?)|(xlsx?)|(docx?))$/i.test(name);
    }
    /**
     * arraybuffer转字符串
     * @param {object} target 目标ArrayBuffer对象
     * @returns {Promise<string>} 成功返回转换后的文本,失败抛错
     */
    static arraybufferToString(target) {
        return new Promise((resolve, reject) => {
            const blob = new Blob([target]);
            const reader = new FileReader();
            reader.readAsText(blob, "utf-8");
            reader.onload = function () {
                resolve(reader.result);
            };
            reader.onerror = function () {
                reject(reader.error);
            };
        });
    }
}
